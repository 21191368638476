/**
 *  Plan getters module file
 **/

import { GETTERS } from './constants'

export const getters = {
  [GETTERS.GET_FLAVOUR]: (state) => state.flavour,
  [GETTERS.GET_METADATA]: (state) => state.metadata,
  [GETTERS.GET_PLANS]: (state) => state.plans,
  [GETTERS.GET_PLAN]: (state) => state.plan,
  [GETTERS.GET_PLAN_FILTERS]: (state) => state.plan.filters,
  [GETTERS.GET_ASSETS_META]: (state) => state.assets.meta,
  [GETTERS.GET_ASSETS_COUNT]: (state) => state.assets.count,
  [GETTERS.GET_ASSETS_PAGE]: (state) => state.assets.page,
  [GETTERS.GET_ASSETS_COUNT_EXCLUDED]: (state) => state.assetsExcluded.count,
  [GETTERS.GET_ASSETS_PAGE_EXCLUDED]: (state) => state.assetsExcluded.page,
  [GETTERS.GET_ASSETS_LOADED]: (state) => !state.assets.loading,
  [GETTERS.GET_NEW_POI_DATASET_FORM]: (state) => state.newPoiDatasetForm,
  [GETTERS.GET_SUBPLAN_FILTERS_INDEX]: (state) =>
    state.activeSubPlanFiltersIndex,
  [GETTERS.GET_AUDIENCE_DELIVERY]: (state) => state.latestdelivery,
  [GETTERS.GET_LEGEND_FILTER]: (state) => state.plan.legend,
  [GETTERS.GET_ASSETS_FINISHED_LOAD]: (state) => state.assetsFinishedLoad,
  [GETTERS.GET_EXCLUDED_ASSET_LAYER_AVAILABILITY]: (state) =>
    state.plan.legend.canAssetsExcludedLayerBeShown,
  [GETTERS.GET_POIS_BUFFER_VISIBILITY]: (state) =>
    state.plan.legend.poisBufferVisibility,
  [GETTERS.GET_POIS_VISIBILITY]: (state) => state.plan.legend.poisVisibility,
  [GETTERS.GET_BOUNDARIES_VISIBILITY]: (state) =>
    state.plan.legend.boundariesVisibility,
  [GETTERS.GET_ASSETS_IDS_LAYER_VISIBILITY]: (state) =>
    state.plan.legend.assetsIdsLayerVisibility,
  [GETTERS.GET_COLORIZE_ASSETS]: (state) => state.plan.legend.colorizeAssets,
  [GETTERS.GET_GEOGRAPHICAL_DISTRIBUTION_REGIONS]: (state) =>
    state.geographicalDistributionRegions,
  [GETTERS.GET_IS_LOADING_GEOGRAPHICAL_DISTRIBUTION_REGIONS]: (state) =>
    state.loadingGeographicalDistributionRegions,
  [GETTERS.GET_ASSETS_DETAILS]: (state) => state.assetsDetails,
  [GETTERS.GET_DEFAULT_USER_COUNTRIES]: (state) => state.defaultUserCountries,
  [GETTERS.GET_ASSETS_READY_WITH_ALL_PROPERTIES]: (state) =>
    state.assetsReadyWithAllProperties,
  [GETTERS.GET_PACKAGES_DATA]: (state) => state.packages,
  [GETTERS.GET_PACKAGES_PRICE_RANGE]: (state) =>
    state.plan.package.filter.priceRange,
  [GETTERS.GET_PACKAGES_DATE_RANGE]: (state) =>
    state.plan.package.filter.dateRange,
  [GETTERS.GET_PACKAGE_PRICE]: (state) => state.plan.package.price.totalNet,
  [GETTERS.GET_PACKAGE]: (state) => state.plan.package,
  [GETTERS.GET_PACKAGE_STATE]: (state) => state.plan.package.state,
  [GETTERS.GET_HIGHLIGHTED_ASSETS]: (state) => state.highlightedAssets,
  [GETTERS.GET_FORCE_LOGOUT]: (state) => state.forceLogout,
  [GETTERS.GET_PACKAGES_FILTER]: (state) => state.plan.package.filter,
  [GETTERS.GET_ASSETS_ANGLE_NORTH_MAP]: (state) =>
    state.plan.legend.angleNorthMap,
}
