import { Environment, Metadata, Poi } from '@workspaces/types'
import {
  PoiBrowserService,
  CacheManager,
  AuthManager,
} from '@workspaces/services'
import { getInstance as getAuth0ServiceIntance } from '@/auth/index'
import { FetchLayerDataResult } from '@deck.gl/carto/typed/api/maps-v3-client'
import { MAP_TYPES, fetchLayerData } from '@deck.gl/carto/typed'
import { getFetchLayerCredentials } from './fetch-layer/fetch-layer.helper'
import { getConnection } from '@/plan/metadata/metadata.helper'
import ErrorMessage from '@/utils/errorMessage'
import { SEPARATOR } from '@/utils/customPois'
import { isArrayEmpty } from '@/helpers/array.helper'

export interface PoisCategories {
  name: string
  id: string
  parentId: string
  level: number
  children: PoisCategories[]
  count: number
}

export interface PoisBrands {
  name: string
  id: string
  count: number
  chain_id: string
}

export interface PoisTags {
  name: string
  id: string
  count: number
  tag_id: string
}

export interface PoisLocations {
  name: string
  id: string
  count: number
  countryId?: number
}

function addTopCategoryToCustomPOIs(
  customPOIs: Poi.CustomPOIsGroup[],
  categories: Poi.Category[],
): Poi.CustomPOIsGroup[] {
  const customPOIsWithTopCategory: Poi.CustomPOIsGroup[] = []
  for (const customPOI of customPOIs) {
    if (customPOI.sub_category === null || customPOI.sub_category === '') {
      customPOIsWithTopCategory.push({ ...customPOI })
    } else {
      const category = categories.find(
        (category) => category.sub_category === customPOI.sub_category,
      )
      customPOIsWithTopCategory.push({
        ...customPOI,
        top_category: category ? category.top_category : customPOI.sub_category,
      })
    }
  }
  return customPOIsWithTopCategory
}

export default {
  async getCustomPoisList(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    avoidCache = false,
  ): Promise<Poi.CustomPOIsGroup[]> {
    const cacheManager = CacheManager.getInstance()
    cacheManager.updateCacheInfo(meta)
    const data: Poi.CustomPOIsGroup[] =
      await PoiBrowserService.getCustomPoisList(meta, environment, avoidCache)

    const dataFormatted = data.map((customPOI) => {
      if (customPOI.modified) {
        return {
          ...customPOI,
          modified: new Date(customPOI.modified),
        }
      }
      return customPOI
    })
    return dataFormatted
  },
  async getCustomPoisDataset(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    id: string,
  ): Promise<Poi.CustomPOI[]> {
    const user = getAuth0ServiceIntance().getUser()
    const data: Poi.CustomPOIConvertedByCarto[] =
      await PoiBrowserService.getCustomPoisDataset(meta, environment, {
        id,
        user_email: user,
      })
    const regExp = new RegExp(SEPARATOR, 'g')
    const dataFormatted = data.map((customPOI) => {
      let addressFormatted = ''
      if (customPOI.address) {
        addressFormatted = customPOI.address.replace(regExp, ', ')
      }
      let geom = ''
      if (customPOI.geom) {
        geom = `${customPOI.geom.coordinates[1]}, ${customPOI.geom.coordinates[0]}`
      }

      return {
        ...customPOI,
        geom,
        addressFormatted,
      }
    })
    return dataFormatted
  },
  async getCustomPois(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    categories: Poi.Category[],
    countries: number[] | null,
    avoidCache = false,
  ): Promise<PoisCategories[]> {
    let data: Poi.CustomPOIsGroup[] = await PoiBrowserService.getCustomPois(
      meta,
      environment,
      countries,
      avoidCache,
    )
    data = data.map((customPOI) => {
      if (customPOI.top_category) {
        customPOI.top_category += ' Cat.'
      }
      return customPOI
    })
    data = addTopCategoryToCustomPOIs(data, categories)

    const dataFormatted: PoisCategories[] = []
    if (data.length === 0) {
      return dataFormatted
    }
    let lastCategory = data[0].top_category
    dataFormatted.push({
      name: data[0].top_category,
      id: data[0].top_category,
      parentId: '',
      level: 1,
      count: 0,
      children: [],
    })

    for (const category of data) {
      if (category.top_category !== lastCategory) {
        lastCategory = category.top_category
        dataFormatted.push({
          name: category.top_category,
          id: category.top_category,
          parentId: '',
          level: 1,
          count: category.occurrences,
          children: [
            {
              name: category.name,
              id: category.id,
              parentId: category.top_category,
              level: 2,
              count: category.occurrences,
              children: [],
            },
          ],
        })
      } else {
        dataFormatted[dataFormatted.length - 1].count += category.occurrences
        dataFormatted[dataFormatted.length - 1].children.push({
          name: category.name,
          id: category.id,
          parentId: category.top_category,
          level: 2,
          count: category.occurrences,
          children: [],
        })
      }
    }

    // Reorder the array to put uncategorized at the end
    if (
      dataFormatted.length > 0 &&
      (dataFormatted[0].name === '' || dataFormatted[0].name === null)
    ) {
      const uncategorized = dataFormatted.shift()
      // To avoid lint management related to undefined or null
      if (uncategorized === undefined) {
        throw new Error(
          'Imposible Error and should not happen. Array has content, but shift returns undefined!!!!!',
        )
      }
      uncategorized.name = 'uncategorized'
      dataFormatted.push(uncategorized)
    }

    return dataFormatted
  },

  async getCategories(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    countries: number[],
  ): Promise<PoisCategories[]> {
    const data: Poi.Category[] = await PoiBrowserService.getCategories(
      meta,
      environment,
      countries,
    )
    if (data.length === 0) {
      return []
    }

    const dataRenamedCategories = data.map((category) => {
      category.top_category += ' Cat.'
      return category
    })

    const dataFormatted: PoisCategories[] = []
    let lastCategory = dataRenamedCategories[0].top_category
    dataFormatted.push({
      name: dataRenamedCategories[0].top_category,
      id: dataRenamedCategories[0].top_category,
      parentId: '',
      level: 1,
      count: 0,
      children: [],
    })

    for (const category of dataRenamedCategories) {
      if (category.top_category !== lastCategory) {
        lastCategory = category.top_category
        dataFormatted.push({
          name: category.top_category,
          id: category.top_category,
          parentId: '',
          level: 1,
          count: category.occurrences,
          children: [
            {
              name: category.sub_category,
              id: category.sub_category,
              parentId: category.top_category,
              level: 2,
              count: category.occurrences,
              children: [],
            },
          ],
        })
      } else {
        dataFormatted[dataFormatted.length - 1].count += category.occurrences
        dataFormatted[dataFormatted.length - 1].children.push({
          name: category.sub_category,
          id: category.sub_category,
          parentId: category.top_category,
          level: 2,
          count: category.occurrences,
          children: [],
        })
      }
    }

    return dataFormatted
  },

  async getBrands(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    countries: number[],
  ): Promise<PoisBrands[]> {
    const data: Poi.Brand[] = await PoiBrowserService.getBrands(
      meta,
      environment,
      countries,
    )
    if (data.length === 0) {
      return []
    }

    const dataFormatted: PoisBrands[] = data.map((brand) => {
      return {
        name: brand.brands,
        id: brand.brands,
        count: brand.occurrences,
        chain_id: brand.brands,
      }
    })
    if (dataFormatted[0].name === null) {
      dataFormatted.shift()
    }
    return dataFormatted
  },

  async getTags(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    countries: number[],
  ): Promise<PoisTags[]> {
    const data: Poi.Tag[] = await PoiBrowserService.getTags(
      meta,
      environment,
      countries,
    )
    if (data.length === 0) {
      return []
    }

    const tagsExpanded = data
      .map((tag) => {
        const subTags = tag.tags.split(',')
        return subTags.map((subTag) => {
          return {
            tag: subTag,
            occurrences: tag.occurrences,
          }
        })
      })
      .flat()

    const tagsReduced = tagsExpanded.reduce((acc, tag) => {
      const index = acc.findIndex((accTag) => accTag.tag === tag.tag)
      if (index === -1) {
        acc.push(tag)
      } else {
        acc[index].occurrences += tag.occurrences
      }
      return acc
    }, [] as { tag: string; occurrences: number }[])

    const tagsSorted = tagsReduced.sort((a, b) => {
      const a1 = a.tag.toLowerCase()
      const b1 = b.tag.toLowerCase()
      if (a1 < b1) {
        return -1
      }
      if (a1 > b1) {
        return 1
      }
      return 0
    })

    const dataFormatted: PoisTags[] = tagsSorted.map((tag) => {
      return {
        name: tag.tag,
        id: tag.tag,
        count: tag.occurrences,
        tag_id: tag.tag,
      }
    })
    if (dataFormatted[0].name === null) {
      dataFormatted.shift()
    }
    return dataFormatted
  },

  async getLocationsByPattern(
    pattern: string,
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    countries: number[],
  ): Promise<PoisLocations[]> {
    const data: Poi.Location[] = await PoiBrowserService.getLocationsByPattern(
      pattern,

      meta,
      environment,
      countries,
    )
    if (data.length === 0) {
      return []
    }

    const dataFormatted: PoisLocations[] = data.map((location) => {
      return {
        name: location.name,
        id: location.normalized_name,
        count: location.occurrences,
        countryId: location.country_id,
      }
    })
    return dataFormatted
  },

  async uploadCustomPOIsGroup(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    customPOIsGroup: Poi.CustomPOIsGroupRequest,
  ): Promise<void> {
    const response = await PoiBrowserService.uploadCustomPOIsGroup(
      meta,
      environment,
      customPOIsGroup,
    )
    if (response.errors !== undefined) {
      // eslint-disable-next-line no-console
      console.error('Error uploading custom POIs group', response)
      throw new Error('Error uploading custom POIs group')
    }
  },

  async uploadCustomPOIsAddressGroupJob(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    customPOIsAddressGroup: Poi.CustomPOIsAddressGroupRequest,
  ): Promise<any> {
    const response = await PoiBrowserService.uploadCustomPOIsAddressGroupJob(
      meta,
      environment,
      customPOIsAddressGroup,
    )
    return response
  },

  async checkStatusCustomPoisAddressJob(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    jobId: string,
  ): Promise<any> {
    const cartoJob = await PoiBrowserService.checkStatusCustomPoisAddressJob(
      meta,
      environment,
      jobId,
    )
    return cartoJob
  },

  async updateCustomPOIsGroup(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    customPOIsGroup: Poi.CustomPOIsGroupRequest,
  ): Promise<void> {
    const response = await PoiBrowserService.updateCustomPOIsGroup(
      meta,
      environment,
      customPOIsGroup,
    )
    if (response.errors !== undefined) {
      // eslint-disable-next-line no-console
      console.error('Error updating custom POIs group', response)
      throw new Error('Error updating custom POIs group')
    }
  },

  async deleteCustomPOIsGroup(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    customPOIsGroupId: string,
  ): Promise<void> {
    const response = await PoiBrowserService.deleteCustomPOIsGroup(
      meta,
      environment,
      customPOIsGroupId,
    )
    if (response.errors !== undefined) {
      // eslint-disable-next-line no-console
      let message = 'Error deleting custom POIs group'
      console.error(message, response)
      if (
        response.message &&
        response.message.includes(
          'would affect rows in the streaming buffer, which is not supported',
        )
      ) {
        message = 'Could not delete recently created dataset. Try again later.'
        throw new ErrorMessage(
          'TITLE_ERR_REMOVE',
          'MESSAGE_ERR_DELETE_CUSTOM_POI_1',
          message,
          response.errors[0],
        )
      }
      throw new ErrorMessage(
        'TITLE_ERR_REMOVE',
        'MESSAGE_GENERIC_MESSAGE',
        message,
        response.errors[0],
      )
    }
  },

  async fetchLayer(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    cutomPOIsGroupIds: string[],
  ): Promise<FetchLayerDataResult> {
    const source = PoiBrowserService.fetchCustomPOIsLayerSource(
      meta,
      environment,
      cutomPOIsGroupIds,
    )
    const token = AuthManager.getInstance().getToken()
    const data = await fetchLayerData({
      type: MAP_TYPES.QUERY,
      source,
      credentials: getFetchLayerCredentials(meta.base_url, token),
      connection: getConnection(meta, environment),
      format: 'geojson',
    })

    return data
  },

  async customPOIsGeocoded(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    customPOIsGroupId: string,
  ): Promise<Poi.CustomPOIsGeocoded> {
    const [total, notGeocoded] = await Promise.all([
      PoiBrowserService.getCountCustomPOISGeocoded(
        meta,
        environment,
        customPOIsGroupId,
      ),
      PoiBrowserService.getCustomPOIsUnableToGeocode(
        meta,
        environment,
        customPOIsGroupId,
      ),
    ])
    const geocoded = total - notGeocoded.length
    const unableToGeocode = isArrayEmpty(notGeocoded) ? undefined : notGeocoded

    return {
      total,
      geocoded,
      unableToGeocode,
    }
  },
}
