var gd = Object.defineProperty;
var md = (t, e, n) => e in t ? gd(t, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : t[e] = n;
var je = (t, e, n) => (md(t, typeof e != "symbol" ? e + "" : e, n), n);
import Mc from "jwt-decode";
var wi = /* @__PURE__ */ ((t) => (t[t.Month = 0] = "Month", t[t.Week = 1] = "Week", t))(wi || {}), St = /* @__PURE__ */ ((t) => (t.String = "string", t.Number = "number", t.Boolean = "boolean", t.Date = "date", t.Array = "array", t.Geography = "geography", t.ArrayNumbers = "array_numbers", t.ArrayStrings = "array_strings", t))(St || {}), re = /* @__PURE__ */ ((t) => (t.FirebaseToken = "firebase_token", t.Audit = "audit", t.BigQueryCustomPOIS = "big_query_custom_POIS", t.Aida = "aida", t.SharedPlan = "share_plan", t.Isochrones = "isochrones", t.Auth0 = "auth0", t.CustomGeoboundaries = "custom_geoboundaries", t.SignedUrls = "signed_urls", t.OptimizeDistribution = "optimize_distribution", t.Geocoding = "geocoding", t))(re || {}), be = /* @__PURE__ */ ((t) => (t.Share = "share", t.Assets = "load_assets", t.AssetsPagination = "load_assets_pagination", t.Plan = "load_plan", t.Geoboundary = "geoboundary_geometries", t.CustomPOIs = "custom_POIs", t.Asset = "asset_details", t.Insights = "insights", t.PasswordRequired = "password_required", t.SharedExportPoisAssets = "shared_export_pois_assets", t.SharedExportAllPois = "shared_export_all_pois", t.SharedExportAudiencesAssets = "shared_export_audiences_assets", t))(be || {}), Ns = /* @__PURE__ */ ((t) => (t.Development = "dev", t.Production = "pro", t.Staging = "stg", t))(Ns || {}), ln = /* @__PURE__ */ ((t) => (t.StoreFileData = "store_file_data", t.CheckStatusStoreFileData = "check_status_store_file_data", t.ProcessFileData = "process_file_data", t.GetSignedUrl = "signed_url", t.DeleteFileData = "delete_file_data", t))(ln || {}), yd = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function _d(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
var Us = {}, wd = {
  get exports() {
    return Us;
  },
  set exports(t) {
    Us = t;
  }
};
(function(t, e) {
  var n = typeof self < "u" ? self : yd, s = function() {
    function i() {
      this.fetch = !1, this.DOMException = n.DOMException;
    }
    return i.prototype = n, new i();
  }();
  (function(i) {
    (function(o) {
      var a = {
        searchParams: "URLSearchParams" in i,
        iterable: "Symbol" in i && "iterator" in Symbol,
        blob: "FileReader" in i && "Blob" in i && function() {
          try {
            return new Blob(), !0;
          } catch {
            return !1;
          }
        }(),
        formData: "FormData" in i,
        arrayBuffer: "ArrayBuffer" in i
      };
      function c(f) {
        return f && DataView.prototype.isPrototypeOf(f);
      }
      if (a.arrayBuffer)
        var u = [
          "[object Int8Array]",
          "[object Uint8Array]",
          "[object Uint8ClampedArray]",
          "[object Int16Array]",
          "[object Uint16Array]",
          "[object Int32Array]",
          "[object Uint32Array]",
          "[object Float32Array]",
          "[object Float64Array]"
        ], l = ArrayBuffer.isView || function(f) {
          return f && u.indexOf(Object.prototype.toString.call(f)) > -1;
        };
      function h(f) {
        if (typeof f != "string" && (f = String(f)), /[^a-z0-9\-#$%&'*+.^_`|~]/i.test(f))
          throw new TypeError("Invalid character in header field name");
        return f.toLowerCase();
      }
      function d(f) {
        return typeof f != "string" && (f = String(f)), f;
      }
      function p(f) {
        var _ = {
          next: function() {
            var L = f.shift();
            return { done: L === void 0, value: L };
          }
        };
        return a.iterable && (_[Symbol.iterator] = function() {
          return _;
        }), _;
      }
      function m(f) {
        this.map = {}, f instanceof m ? f.forEach(function(_, L) {
          this.append(L, _);
        }, this) : Array.isArray(f) ? f.forEach(function(_) {
          this.append(_[0], _[1]);
        }, this) : f && Object.getOwnPropertyNames(f).forEach(function(_) {
          this.append(_, f[_]);
        }, this);
      }
      m.prototype.append = function(f, _) {
        f = h(f), _ = d(_);
        var L = this.map[f];
        this.map[f] = L ? L + ", " + _ : _;
      }, m.prototype.delete = function(f) {
        delete this.map[h(f)];
      }, m.prototype.get = function(f) {
        return f = h(f), this.has(f) ? this.map[f] : null;
      }, m.prototype.has = function(f) {
        return this.map.hasOwnProperty(h(f));
      }, m.prototype.set = function(f, _) {
        this.map[h(f)] = d(_);
      }, m.prototype.forEach = function(f, _) {
        for (var L in this.map)
          this.map.hasOwnProperty(L) && f.call(_, this.map[L], L, this);
      }, m.prototype.keys = function() {
        var f = [];
        return this.forEach(function(_, L) {
          f.push(L);
        }), p(f);
      }, m.prototype.values = function() {
        var f = [];
        return this.forEach(function(_) {
          f.push(_);
        }), p(f);
      }, m.prototype.entries = function() {
        var f = [];
        return this.forEach(function(_, L) {
          f.push([L, _]);
        }), p(f);
      }, a.iterable && (m.prototype[Symbol.iterator] = m.prototype.entries);
      function S(f) {
        if (f.bodyUsed)
          return Promise.reject(new TypeError("Already read"));
        f.bodyUsed = !0;
      }
      function P(f) {
        return new Promise(function(_, L) {
          f.onload = function() {
            _(f.result);
          }, f.onerror = function() {
            L(f.error);
          };
        });
      }
      function te(f) {
        var _ = new FileReader(), L = P(_);
        return _.readAsArrayBuffer(f), L;
      }
      function Q(f) {
        var _ = new FileReader(), L = P(_);
        return _.readAsText(f), L;
      }
      function De(f) {
        for (var _ = new Uint8Array(f), L = new Array(_.length), le = 0; le < _.length; le++)
          L[le] = String.fromCharCode(_[le]);
        return L.join("");
      }
      function Ce(f) {
        if (f.slice)
          return f.slice(0);
        var _ = new Uint8Array(f.byteLength);
        return _.set(new Uint8Array(f)), _.buffer;
      }
      function Pe() {
        return this.bodyUsed = !1, this._initBody = function(f) {
          this._bodyInit = f, f ? typeof f == "string" ? this._bodyText = f : a.blob && Blob.prototype.isPrototypeOf(f) ? this._bodyBlob = f : a.formData && FormData.prototype.isPrototypeOf(f) ? this._bodyFormData = f : a.searchParams && URLSearchParams.prototype.isPrototypeOf(f) ? this._bodyText = f.toString() : a.arrayBuffer && a.blob && c(f) ? (this._bodyArrayBuffer = Ce(f.buffer), this._bodyInit = new Blob([this._bodyArrayBuffer])) : a.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(f) || l(f)) ? this._bodyArrayBuffer = Ce(f) : this._bodyText = f = Object.prototype.toString.call(f) : this._bodyText = "", this.headers.get("content-type") || (typeof f == "string" ? this.headers.set("content-type", "text/plain;charset=UTF-8") : this._bodyBlob && this._bodyBlob.type ? this.headers.set("content-type", this._bodyBlob.type) : a.searchParams && URLSearchParams.prototype.isPrototypeOf(f) && this.headers.set("content-type", "application/x-www-form-urlencoded;charset=UTF-8"));
        }, a.blob && (this.blob = function() {
          var f = S(this);
          if (f)
            return f;
          if (this._bodyBlob)
            return Promise.resolve(this._bodyBlob);
          if (this._bodyArrayBuffer)
            return Promise.resolve(new Blob([this._bodyArrayBuffer]));
          if (this._bodyFormData)
            throw new Error("could not read FormData body as blob");
          return Promise.resolve(new Blob([this._bodyText]));
        }, this.arrayBuffer = function() {
          return this._bodyArrayBuffer ? S(this) || Promise.resolve(this._bodyArrayBuffer) : this.blob().then(te);
        }), this.text = function() {
          var f = S(this);
          if (f)
            return f;
          if (this._bodyBlob)
            return Q(this._bodyBlob);
          if (this._bodyArrayBuffer)
            return Promise.resolve(De(this._bodyArrayBuffer));
          if (this._bodyFormData)
            throw new Error("could not read FormData body as text");
          return Promise.resolve(this._bodyText);
        }, a.formData && (this.formData = function() {
          return this.text().then(_n);
        }), this.json = function() {
          return this.text().then(JSON.parse);
        }, this;
      }
      var Et = ["DELETE", "GET", "HEAD", "OPTIONS", "POST", "PUT"];
      function vt(f) {
        var _ = f.toUpperCase();
        return Et.indexOf(_) > -1 ? _ : f;
      }
      function Ve(f, _) {
        _ = _ || {};
        var L = _.body;
        if (f instanceof Ve) {
          if (f.bodyUsed)
            throw new TypeError("Already read");
          this.url = f.url, this.credentials = f.credentials, _.headers || (this.headers = new m(f.headers)), this.method = f.method, this.mode = f.mode, this.signal = f.signal, !L && f._bodyInit != null && (L = f._bodyInit, f.bodyUsed = !0);
        } else
          this.url = String(f);
        if (this.credentials = _.credentials || this.credentials || "same-origin", (_.headers || !this.headers) && (this.headers = new m(_.headers)), this.method = vt(_.method || this.method || "GET"), this.mode = _.mode || this.mode || null, this.signal = _.signal || this.signal, this.referrer = null, (this.method === "GET" || this.method === "HEAD") && L)
          throw new TypeError("Body not allowed for GET or HEAD requests");
        this._initBody(L);
      }
      Ve.prototype.clone = function() {
        return new Ve(this, { body: this._bodyInit });
      };
      function _n(f) {
        var _ = new FormData();
        return f.trim().split("&").forEach(function(L) {
          if (L) {
            var le = L.split("="), ie = le.shift().replace(/\+/g, " "), G = le.join("=").replace(/\+/g, " ");
            _.append(decodeURIComponent(ie), decodeURIComponent(G));
          }
        }), _;
      }
      function Yr(f) {
        var _ = new m(), L = f.replace(/\r?\n[\t ]+/g, " ");
        return L.split(/\r?\n/).forEach(function(le) {
          var ie = le.split(":"), G = ie.shift().trim();
          if (G) {
            var Es = ie.join(":").trim();
            _.append(G, Es);
          }
        }), _;
      }
      Pe.call(Ve.prototype);
      function Le(f, _) {
        _ || (_ = {}), this.type = "default", this.status = _.status === void 0 ? 200 : _.status, this.ok = this.status >= 200 && this.status < 300, this.statusText = "statusText" in _ ? _.statusText : "OK", this.headers = new m(_.headers), this.url = _.url || "", this._initBody(f);
      }
      Pe.call(Le.prototype), Le.prototype.clone = function() {
        return new Le(this._bodyInit, {
          status: this.status,
          statusText: this.statusText,
          headers: new m(this.headers),
          url: this.url
        });
      }, Le.error = function() {
        var f = new Le(null, { status: 0, statusText: "" });
        return f.type = "error", f;
      };
      var pd = [301, 302, 303, 307, 308];
      Le.redirect = function(f, _) {
        if (pd.indexOf(_) === -1)
          throw new RangeError("Invalid status code");
        return new Le(null, { status: _, headers: { location: f } });
      }, o.DOMException = i.DOMException;
      try {
        new o.DOMException();
      } catch {
        o.DOMException = function(_, L) {
          this.message = _, this.name = L;
          var le = Error(_);
          this.stack = le.stack;
        }, o.DOMException.prototype = Object.create(Error.prototype), o.DOMException.prototype.constructor = o.DOMException;
      }
      function Xr(f, _) {
        return new Promise(function(L, le) {
          var ie = new Ve(f, _);
          if (ie.signal && ie.signal.aborted)
            return le(new o.DOMException("Aborted", "AbortError"));
          var G = new XMLHttpRequest();
          function Es() {
            G.abort();
          }
          G.onload = function() {
            var wn = {
              status: G.status,
              statusText: G.statusText,
              headers: Yr(G.getAllResponseHeaders() || "")
            };
            wn.url = "responseURL" in G ? G.responseURL : wn.headers.get("X-Request-URL");
            var Zr = "response" in G ? G.response : G.responseText;
            L(new Le(Zr, wn));
          }, G.onerror = function() {
            le(new TypeError("Network request failed"));
          }, G.ontimeout = function() {
            le(new TypeError("Network request failed"));
          }, G.onabort = function() {
            le(new o.DOMException("Aborted", "AbortError"));
          }, G.open(ie.method, ie.url, !0), ie.credentials === "include" ? G.withCredentials = !0 : ie.credentials === "omit" && (G.withCredentials = !1), "responseType" in G && a.blob && (G.responseType = "blob"), ie.headers.forEach(function(wn, Zr) {
            G.setRequestHeader(Zr, wn);
          }), ie.signal && (ie.signal.addEventListener("abort", Es), G.onreadystatechange = function() {
            G.readyState === 4 && ie.signal.removeEventListener("abort", Es);
          }), G.send(typeof ie._bodyInit > "u" ? null : ie._bodyInit);
        });
      }
      return Xr.polyfill = !0, i.fetch || (i.fetch = Xr, i.Headers = m, i.Request = Ve, i.Response = Le), o.Headers = m, o.Request = Ve, o.Response = Le, o.fetch = Xr, Object.defineProperty(o, "__esModule", { value: !0 }), o;
    })({});
  })(s), s.fetch.ponyfill = !0, delete s.fetch.polyfill;
  var r = s;
  e = r.fetch, e.default = r.fetch, e.fetch = r.fetch, e.Headers = r.Headers, e.Request = r.Request, e.Response = r.Response, t.exports = e;
})(wd, Us);
const Ed = /* @__PURE__ */ _d(Us);
var vd = function(e) {
  return Td(e) && !Sd(e);
};
function Td(t) {
  return !!t && typeof t == "object";
}
function Sd(t) {
  var e = Object.prototype.toString.call(t);
  return e === "[object RegExp]" || e === "[object Date]" || Ad(t);
}
var Id = typeof Symbol == "function" && Symbol.for, bd = Id ? Symbol.for("react.element") : 60103;
function Ad(t) {
  return t.$$typeof === bd;
}
function Cd(t) {
  return Array.isArray(t) ? [] : {};
}
function kn(t, e) {
  return e.clone !== !1 && e.isMergeableObject(t) ? Zt(Cd(t), t, e) : t;
}
function Nd(t, e, n) {
  return t.concat(e).map(function(s) {
    return kn(s, n);
  });
}
function Od(t, e) {
  if (!e.customMerge)
    return Zt;
  var n = e.customMerge(t);
  return typeof n == "function" ? n : Zt;
}
function Rd(t) {
  return Object.getOwnPropertySymbols ? Object.getOwnPropertySymbols(t).filter(function(e) {
    return t.propertyIsEnumerable(e);
  }) : [];
}
function ga(t) {
  return Object.keys(t).concat(Rd(t));
}
function $c(t, e) {
  try {
    return e in t;
  } catch {
    return !1;
  }
}
function kd(t, e) {
  return $c(t, e) && !(Object.hasOwnProperty.call(t, e) && Object.propertyIsEnumerable.call(t, e));
}
function Dd(t, e, n) {
  var s = {};
  return n.isMergeableObject(t) && ga(t).forEach(function(r) {
    s[r] = kn(t[r], n);
  }), ga(e).forEach(function(r) {
    kd(t, r) || ($c(t, r) && n.isMergeableObject(e[r]) ? s[r] = Od(r, n)(t[r], e[r], n) : s[r] = kn(e[r], n));
  }), s;
}
function Zt(t, e, n) {
  n = n || {}, n.arrayMerge = n.arrayMerge || Nd, n.isMergeableObject = n.isMergeableObject || vd, n.cloneUnlessOtherwiseSpecified = kn;
  var s = Array.isArray(e), r = Array.isArray(t), i = s === r;
  return i ? s ? n.arrayMerge(t, e, n) : Dd(t, e, n) : kn(e, n);
}
Zt.all = function(e, n) {
  if (!Array.isArray(e))
    throw new Error("first argument should be an array");
  return e.reduce(function(s, r) {
    return Zt(s, r, n);
  }, {});
};
var Pd = Zt, Zi = Pd, rt = /* @__PURE__ */ ((t) => (t.Get = "GET", t.Post = "POST", t.Put = "PUT", t.Patch = "PATCH", t.Delete = "DELETE", t))(rt || {});
function Uc(t, e) {
  return Array.isArray(e) ? e.map((n) => Uc(t, n)).join("&") : e == null || e === "" ? "" : typeof e == "object" ? `${t}=${encodeURIComponent(JSON.stringify(e))}` : `${t}=${encodeURIComponent(e)}`;
}
function Ld({
  params: t,
  url: e
}) {
  if (!t)
    return e;
  const n = Object.entries(t).map(
    ([s, r]) => Uc(s, r)
  );
  return `${e}?${n.filter(Boolean).join("&")}`;
}
function ma(t) {
  return Zi(
    {
      method: rt.Get,
      headers: {
        Accept: "application/json"
      }
    },
    t
  );
}
function ei(t, e) {
  return Zi(
    {
      method: rt.Post,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(e)
    },
    t
  );
}
const Fd = {
  GET: ma,
  POST: ei,
  PUT: ei,
  PATCH: ei,
  DELETE: ma
};
function Md({
  body: t,
  credentials: e,
  method: n,
  other: s = {}
}) {
  let r = e?.accessToken;
  return r && r.indexOf("Bearer") === -1 && (r = "Bearer " + r), Fd[n](
    Zi(
      {
        method: n,
        headers: { ...!!r && { authorization: r } }
      },
      s
    ),
    t
  );
}
const $d = 6144;
async function xc({
  abortController: t,
  body: e,
  credentials: n,
  method: s = rt.Get,
  params: r,
  url: i,
  requestOptions: { getMaxUrlLength: o } = {
    getMaxUrlLength: $d
  },
  ...a
}) {
  let c = Ld({ url: i, params: r }), u = e, l = s;
  s === rt.Get && c.length > o && (u = r, l = rt.Post, c = i);
  const h = Md({
    body: u,
    credentials: n,
    method: l,
    ...a
  });
  t && (h.signal = t);
  const d = await Ed(c, h);
  if (!d.ok) {
    const p = await d.text();
    return Promise.reject(p);
  }
  return d.status === 204 ? Promise.resolve() : await d.json();
}
const Ud = "https://gcp-us-east1.api.carto.com", xd = "v3", Bd = 3.1;
async function qd({
  cartoCredentials: t,
  cartoConnection: e,
  query: n,
  params: { api_version: s = Bd, ...r } = {},
  queryParameters: i,
  fetcherOptions: { abortController: o, apiVersion: a = xd } = {}
}) {
  const { apiBaseUrl: c = Ud } = t, u = `${c}/${a}/sql/${e}/query`, l = n.trim();
  return xc({
    abortController: o,
    credentials: { accessToken: t.accessToken },
    url: u,
    method: rt.Get,
    params: {
      q: l,
      api_version: s,
      ...r,
      queryParameters: i
    }
  });
}
const zt = class {
  constructor() {
    je(this, "cartoAuthorizer", null);
  }
  async init(e) {
    this.cartoAuthorizer = e;
  }
  static getInstance() {
    return zt.instance || (zt.instance = new zt()), zt.instance;
  }
  getToken() {
    if (this.cartoAuthorizer === null)
      throw new Error("AuthManager not initialized");
    return this.cartoAuthorizer.getToken();
  }
};
let V = zt;
je(V, "instance");
const ut = 10;
async function b(t, e, n) {
  const s = V.getInstance().getToken(), { apiBaseUrl: r, cartoConnection: i } = no(e, n);
  return (await qd({
    cartoCredentials: {
      accessToken: s,
      apiBaseUrl: r
    },
    cartoConnection: i,
    query: t,
    fetcherOptions: {
      abortController: new AbortController().signal
    }
  })).rows;
}
async function $t(t, e, n, s) {
  const r = V.getInstance().getToken(), { apiBaseUrl: i, cartoConnection: o } = no(e, n), c = `/*${new Date().getTime()}*/`, u = encodeURIComponent(t + c), l = `${i}/v3/sql/${o}/query?q=${u}`, h = {
    Authorization: `Bearer ${r}`
  };
  if (s) {
    if (s < ut)
      throw new Error(
        `Cache timeout must be equal or greater than ${ut} seconds`
      );
    h["Cache-Control"] = `max-age=${s}`;
  }
  const p = await (await fetch(l, {
    method: "GET",
    headers: h
  })).json();
  if (p.error)
    throw new Error(p.error);
  return p.rows;
}
async function Vd(t, e, n, s) {
  const r = `
  SELECT
    table_id as table_name,
    FORMAT_TIMESTAMP('%FT%T', TIMESTAMP_MILLIS(last_modified_time), "UTC") AS last_modified
  FROM \`${s}.${n}.__TABLES__\`
  WHERE NOT STARTS_WITH(table_id, 'rollback')
  `;
  return await $t(
    r,
    t,
    e,
    ut
  );
}
const Qt = class {
  constructor() {
    je(this, "cacheInfo", /* @__PURE__ */ new Map());
    je(this, "cacheInfoAsArray", []);
    je(this, "environment", null);
    je(this, "bigQueryDataset", "");
    je(this, "authManager", null);
  }
  async init(e, n, s, r = !1) {
    if (this.environment = e, this.authManager = n, !r && this.cacheInfoAsArray.length > 0)
      return;
    const i = this.environment.getEnvironment(), o = this.environment.isTesting();
    this.bigQueryDataset = o ? `${i}_testing` : i, await this.updateCacheInfo(s);
  }
  async updateCacheInfo(e) {
    if (this.authManager === null || this.environment === null)
      throw new Error("CacheManager not initialized");
    const n = ar(e, this.environment);
    this.cacheInfoAsArray = await Vd(
      e,
      this.environment,
      this.bigQueryDataset,
      n
    ), this.cacheInfoAsArray.reduce((s, r) => (s.set(r.table_name, r.last_modified), s), this.cacheInfo);
  }
  static getInstance() {
    return Qt.instance || (Qt.instance = new Qt()), Qt.instance;
  }
  getCacheInfo() {
    return this.cacheInfoAsArray;
  }
  getCacheInfoForTable(e) {
    const n = this.cacheInfo.get(e);
    if (!n)
      throw new Error(`Cache info not found for table ${e}`);
    return n;
  }
};
let Os = Qt;
je(Os, "instance");
function jd(t) {
  return t.join(", ");
}
var ft = /* @__PURE__ */ ((t) => (t[t.Filterable = 0] = "Filterable", t[t.Autocomplete = 1] = "Autocomplete", t[t.Display = 2] = "Display", t[t.Startup = 3] = "Startup", t[t.Background = 4] = "Background", t))(ft || {});
function eo(t, e) {
  return t.filter((s) => {
    switch (e) {
      case 0:
        return s.filterable;
      case 2:
        return s.display;
      case 1:
        return "autocomplete" in s ? s.autocomplete : !1;
      case 3:
        return s.startup;
      case 4:
        return s.background;
      default:
        throw new Error("Executing getColunmsFromModel: Invalid filter option");
    }
  });
}
function Gd(t, e) {
  return eo(
    t,
    e
  ).map((r) => r.id);
}
function zn(t, e) {
  const n = Gd(t, e);
  return jd(n);
}
function Bc(t, e, n) {
  const s = t.getEnvironment(), i = t.isTesting() ? `${s}_testing` : s, c = `/*${Os.getInstance().getCacheInfoForTable(n)}*/`;
  return `\`${e}.${i}.${n}\` ${c}`;
}
function ar(t, e) {
  const n = e.getEnvironment(), s = t.bq_project;
  switch (n) {
    case Ns.Development:
      return s.dev;
    case Ns.Staging:
      return s.staging;
    case Ns.Production:
      return s.prod;
  }
  throw new Error(
    `Could not find BQ project for environment ${n}. Check metadata file to verify values are correctly set under bq_project parameter`
  );
}
function v(t, e, n) {
  const s = ar(t, e);
  return Bc(e, s, n.table_name);
}
function to(t, e, n, s) {
  const r = ar(t, e), i = s ? n.autocomplete_locations : n.autocomplete;
  return Bc(e, r, i);
}
function no(t, e) {
  return {
    apiBaseUrl: t.base_url,
    cartoConnection: Wd(t, e)
  };
}
function Wd(t, e) {
  const n = e.getEnvironment();
  return `${t.connection}_${n}`;
}
function Hd(t) {
  return t.flavour;
}
function zd(t) {
  return t.plan_filters.widget_audiences.delivery_human_representation;
}
function qc(t) {
  return t.local_storage_keys.firebase_token;
}
function Vc(t) {
  return t.features.export.columnsPOIs;
}
function jc(t) {
  return t.features.export.columnsAllPOIs;
}
function so(t) {
  return t.countries.length > 1;
}
function Qd(t) {
  return t.countries[0].id;
}
function ce(t, e, n) {
  const s = e.getEnvironment(), i = t.app_config.remote_functions[n];
  if (!i)
    throw Error(
      `🛑 Getting remote function. Remote function ${n} not found. Check flavour metadata.`
    );
  if (e.getEnvironmentVariable(
    "VUE_APP_FIREBASE_EMULATOR"
  ) === "true") {
    const a = i.local;
    if (!a)
      throw Error(
        `🛑 Getting remote function. VUE_APP_FIREBASE_EMULATOR is set to true, but no local url define for remote function: ${n}. Check flavour metadata.`
      );
    return a;
  }
  switch (s) {
    case "dev":
      return i.dev || "";
    case "stg":
      return i.staging || "";
    case "pro":
      return i.prod || "";
    default:
      throw Error(
        `🛑 Getting remote function. Unrecognized environment value: ${s} . Check .env file to verify VUE_APP_ENV is set.`
      );
  }
}
function Kd(t, e) {
  const n = e.getEnvironment(), s = t.app_config.firebase;
  switch (n) {
    case "dev":
      return s.dev;
    case "stg":
      return s.staging;
    case "pro":
      return s.prod;
    default:
      throw Error(
        `🛑 Getting Firebase config. Unrecognized environment value: ${e}. Check .env file to verify VUE_APP_ENV is set.`
      );
  }
}
function Jd(t) {
  return t.carto_events_url;
}
var O = /* @__PURE__ */ ((t) => (t.EQUALS = "=", t.NOT_EQUALS = "<>", t))(O || {}), R = /* @__PURE__ */ ((t) => (t.AND = "AND", t.OR = "OR", t.WHERE = "WHERE", t.NONE = "", t))(R || {});
function Rt(t, e, n, s = "", r = !1) {
  return F({
    columnName: t,
    ids: e,
    equalityOperator: n,
    logicalOperator: s,
    normalizedStringSearch: r
  });
}
function F(t) {
  const {
    columnName: e,
    ids: n,
    equalityOperator: s,
    logicalOperator: r,
    normalizedStringSearch: i,
    returnEmptyIfIdsEmpty: o
  } = t;
  if (n.length === 0) {
    if (o)
      return "";
    throw new Error(
      "When generating a where clause from ids, the ids array must not be empty"
    );
  }
  const a = i ? `NORMALIZE_AND_CASEFOLD(${e})` : e;
  if (n.length === 1) {
    let l = n[0];
    return typeof n[0] == "string" && (l = `"${n[0]}"`), `${r} ${a} ${s} ${l}`;
  }
  let c = n.join(", ");
  typeof n[0] == "string" && (c = `"${n.join('", "')}"`);
  let u = `${r} ${a} `;
  return s === "=" ? u += `IN UNNEST([${c}])` : u += `NOT IN UNNEST([${c}])`, u;
}
function Yd(t, e) {
  return e.length ? e.map((s) => ` CONTAINS_SUBSTR((${t}), '${s}') `).join(" OR ") : " 1 = 1 ";
}
function q(t) {
  return `
` + JSON.stringify(t, null, 2);
}
function Xd(t) {
  const e = t.getFullYear(), n = new Date(t.getTime());
  n.setHours(0, 0, 0, 0), n.setDate(n.getDate() + 4 - (n.getDay() || 7));
  const s = new Date(e, 0, 1);
  s.getDay() > 4 ? s.setDate(s.getDate() + 7 - s.getDay()) : s.setDate(s.getDate() - s.getDay());
  const r = Math.floor(
    (n.getTime() - s.getTime()) / 864e5
  ), i = Math.ceil((r + s.getDay() + 1) / 7).toString().padStart(2, "0");
  return { year: e.toString(), week: i };
}
function Zd(t, e, n, s, r, i) {
  const o = v(
    t,
    e,
    t.data_model.audiences
  ), a = Rt(
    "class_uid",
    i,
    O.EQUALS,
    R.AND
  );
  return `${n} AS (
    SELECT
      distinct(asset_id)
    FROM ${o}
    WHERE
      year_month_or_week = "${s}"
      AND index >= ${r}
      ${a}
  )`;
}
function ef(t, e, n) {
  if (n.audiences === void 0 || n.audiences.audiences.length === 0)
    return { ctes: "", audienceCTEsName: [] };
  const s = "cte_assets_by_audience_", r = n.audiences.index, i = n.delivery, o = [];
  return { ctes: n.audiences.audiences.map(
    (u, l) => {
      const h = `${s}${l}`;
      return o.push(h), Zd(
        t,
        e,
        h,
        i,
        r,
        u
      );
    }
  ).join(`, 
`), audienceCTEsName: o };
}
function tf(t, e) {
  if (e.length === 0)
    return "";
  const s = e.map((i) => `SELECT * FROM ${i}`).join(`
 INTERSECT DISTINCT 
`);
  return `${t} AS (
    ${s}
  )`;
}
function nf(t, e, n) {
  const { ctes: s, audienceCTEsName: r } = ef(
    t,
    e,
    n
  ), i = "assets_intersections", o = tf(
    i,
    r
  );
  return `
    WITH
    ${s},
    ${o}

    SELECT *
    from ${i} a`;
}
function Gc(t, e) {
  return !t.andGroup || t.andGroup.length === 0 ? `
      cte_asset_and ${e},
    ` : `
    cte_asset_and_pre AS (
      SELECT
        asset_id,
        COUNT(DISTINCT class_uid) as occurrences,
        AVG(index) as index
      FROM cte_asset_basic_filter
      ${F({
    columnName: "class_uid",
    ids: t.andGroup,
    equalityOperator: O.EQUALS,
    logicalOperator: R.WHERE
  })}
      GROUP BY asset_id
    ),
    cte_asset_and AS (
      SELECT
        asset_id,
        index
      FROM cte_asset_and_pre
      WHERE occurrences >= ${t.andGroup?.length ?? 0}
    ), `;
}
function Wc(t, e) {
  return !t.orGroup || t.orGroup.length === 0 ? `
      cte_asset_or ${e}
    ` : `
    cte_asset_or AS (
      SELECT
        asset_id,
        AVG(index) as index
      FROM cte_asset_basic_filter
      ${F({
    columnName: "class_uid",
    ids: t.orGroup,
    equalityOperator: O.EQUALS,
    logicalOperator: R.WHERE
  })}
      GROUP BY asset_id
    )
    `;
}
function sf(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.audiences
  );
  let r = "";
  n.country_ids && n.country_ids.length > 0 && (r = F({
    columnName: "country_id",
    ids: n.country_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }));
  const i = `
  AS (SELECT 'unknown' as asset_id, 0 as index  limit 0)
  `, o = Gc(n, i), a = Wc(n, i);
  return `
    WITH
    cte_asset_basic_filter AS (
      SELECT asset_id, class_uid, index
      FROM  ${s}
      WHERE year_month_or_week = "${n.delivery}"
      ${r}
      AND index >= ${n.lowerRange}
      AND index <= ${n.upperRange}
    ),
    ${o}
    ${a}
    SELECT asset_id, index
    FROM (
      SELECT asset_id, index FROM cte_asset_and
      UNION DISTINCT
      SELECT asset_id, index FROM cte_asset_or
    )
  `;
}
function rf(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.audiences
  );
  let r = "";
  n.country_ids && n.country_ids.length > 0 && (r = F({
    columnName: "country_id",
    ids: n.country_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }));
  const i = `
  AS (SELECT 'unknown' as asset_id, 0 as index limit 0)
  `, o = Gc(n, i), a = Wc(n, i);
  return `
  WITH
  cte_asset_basic_filter AS (
    SELECT asset_id, class_uid, index
    FROM  ${s}
    WHERE year_month_or_week = "${n.delivery}"
    ${r}
  ),
  ${o}
  ${a}
  ,cte_all_results AS (
    SELECT *
    FROM (
      SELECT * FROM cte_asset_and
      UNION ALL
      SELECT * FROM cte_asset_or
    )
  )
  SELECT asset_id, AVG(index) as index FROM cte_all_results
  group by asset_id
  order by index desc
`;
}
function of(t, e, n) {
  if (n.isIndexActive && !n.isPercentageActive)
    return sf(
      t,
      e,
      n
    );
  if (!n.isIndexActive && n.isPercentageActive)
    return rf(
      t,
      e,
      n
    );
  const s = "🛑 Audience filter is not valid. Expected isIndexActive or isPercentageActive to be true";
  throw console.error(s), new Error(s);
}
async function af(t, e, n, s) {
  const r = t.plan_filters.widget_optimize_distribution.min_distance_between_assets.num_elements_direct_query, i = v(
    t,
    e,
    t.data_model.assets
  );
  let o = "";
  if (n.length <= r)
    o = `
      SELECT
        a as id,
        b as importance
      FROM
        UNNEST([${n.map((c, u) => `STRUCT('${c}' as a, ${u} as b)`).join(",")}])`;
  else {
    const a = v(
      t,
      e,
      t.data_model.assets_optimize_distribution
    ), c = await uf(
      t,
      e,
      n
    );
    o = `
      SELECT
        asset_id as id,
        importance
      FROM
        ${a} /*${Date.now()}*/
      WHERE
        id = '${c}'
      ORDER BY importance asc
    `;
  }
  return `
    WITH raw_assets as (
      ${o}
    ), assets as (
      SELECT
          aa.id as id,
          aa.geom as geom,
          ra.importance as importance
        FROM
          ${i} aa
        JOIN raw_assets ra
          ON aa.id = ra.id
        ORDER BY ra.importance
    ), clusters as (
      SELECT
        id,
        ST_CLUSTERDBSCAN(geom, ${s}, 0) OVER () as cluster_num,
        importance
      FROM
        assets
      ORDER BY importance
    ), ordered_assets as (
      SELECT
        *,
        ROW_NUMBER() OVER (PARTITION BY cluster_num ORDER BY importance) as rn
      FROM
        clusters
      ORDER BY cluster_num, importance
    )
    SELECT
      id as asset_id
    FROM ordered_assets
    WHERE rn = 1
  `;
}
function cf(t, e, n) {
  const s = zn(
    t.data_model.assets.columns,
    ft.Display
  ), r = v(
    t,
    e,
    t.data_model.assets
  );
  return `SELECT ${s} FROM ${r} WHERE id = '${n}'`;
}
async function uf(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`;
  try {
    const i = ce(
      t,
      e,
      re.OptimizeDistribution
    );
    if (!i)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for inserting optimize distribution asset IDs 🔴🔴🔴"
      );
    const o = {
      optimize_distribution_ids: n.join(",")
    };
    return await (await fetch(i, {
      method: "POST",
      body: JSON.stringify(o),
      headers: { Authorization: r }
    })).json();
  } catch (i) {
    throw new Error(`Cloud function error ${q(i)}`);
  }
}
function Hc(t, e, n, s, r) {
  const i = zn(
    t.data_model.assets.columns,
    ft.Background
  ), o = v(
    t,
    e,
    t.data_model.assets
  ), a = n ? F({
    columnName: "country_id",
    ids: [n],
    equalityOperator: O.EQUALS,
    logicalOperator: R.WHERE
  }) : "";
  return `SELECT ${i} FROM ${o}
  ${a}
  ORDER BY id
  LIMIT ${s} OFFSET ${r}`;
}
function lf(t, e) {
  if (typeof t != "string")
    throw new Error(
      `Executing convertFromStringToProvidedType: Value ${t} is not a string`
    );
  if (t === "NULL")
    return null;
  switch (e) {
    case St.String:
      return t;
    case St.Number:
      return Number(t);
    case St.Boolean:
      return t.toLocaleLowerCase() === "true";
    case St.Date:
      return new Date(t);
    case St.ArrayNumbers:
      return t.split(";").map((n) => Number(n));
    default:
      throw new Error(
        `Executing convertFromStringToProvidedType: Type not supported ${e}`
      );
  }
}
async function hf(t, e, n, s, r, i, o) {
  const a = `, '${n}', `, c = v(
    t,
    e,
    t.data_model.assets
  );
  let u = "";
  s.forEach((p) => p.type === St.ArrayNumbers ? u += `COALESCE(SAFE_CAST((SELECT STRING_AGG(CAST(id AS STRING), ';') FROM UNNEST(${p.id}) id) AS STRING), 'NULL')${a}` : u += `COALESCE(SAFE_CAST(${p.id} AS STRING), 'NULL')${a}`), u = u.slice(0, -a.length);
  const l = r === -1 ? "" : `WHERE country_id = ${r}`, h = `
  SELECT CONCAT(${u}) as asset
  FROM ${c}
  ${l}
  ORDER BY id
  LIMIT ${i}
  OFFSET ${o}`;
  return await b(h, t, e);
}
async function zc(t, e, n, s, r, i) {
  const o = [], a = [];
  n.forEach((p) => {
    const m = Math.ceil(p.assets / i);
    for (let S = 0; S < m; S++) {
      const P = S * i;
      o.push(p.country_id), a.push(
        hf(
          t,
          e,
          r,
          s,
          p.country_id,
          i,
          P
        )
      );
    }
  });
  const c = await Promise.all(a), u = o.length, l = [];
  let h = [], d = o[0];
  for (let p = 0; p < u; p++)
    d === o[p] ? h = h.concat(c[p]) : (l.push({
      countryId: d,
      assets: h.map((m) => m.asset)
    }), h = c[p]), d = o[p];
  return l.push({
    countryId: d,
    assets: h.map((p) => p.asset)
  }), l;
}
function Qc(t, e, n) {
  return t.map((r) => {
    const i = r.split(n), o = {};
    return i.forEach((a, c) => {
      const u = e[c], l = lf(a, u.type);
      o[u.id] = l;
    }), o;
  });
}
function df(t) {
  return t.map((n) => ({
    type: "Feature",
    geometry: {
      coordinates: [n.longitude, n.latitude],
      type: "Point"
    },
    properties: n
  }));
}
async function ff(t, e, n, s, r) {
  const i = "%&&%";
  return (await zc(
    t,
    e,
    n,
    s,
    i,
    r
  )).map(
    (c) => {
      const u = Qc(
        c.assets,
        s,
        i
      ), l = df(
        u
      );
      return {
        countryId: c.countryId,
        assets: l
      };
    }
  );
}
async function pf(t, e, n, s) {
  const r = eo(
    t.data_model.assets.columns,
    ft.Startup
  );
  return await ff(
    t,
    e,
    n,
    r,
    s
  );
}
async function gf(t, e, n, s) {
  const r = "%&&%", i = eo(
    t.data_model.assets.columns,
    ft.Background
  );
  return (await zc(
    t,
    e,
    n,
    i,
    r,
    s
  )).map((c) => {
    const u = Qc(
      c.assets,
      i,
      r
    );
    return {
      countryId: c.countryId,
      assets: u
    };
  });
}
async function mf(t, e, n, s, r) {
  const i = Hc(
    t,
    e,
    n,
    s,
    r
  );
  return await b(i, t, e);
}
async function yf(t, e) {
  const n = v(
    t,
    e,
    t.data_model.assets
  );
  let s = "country_id";
  so(t) || (s = `${Qd(t).toString()} as country_id`);
  const r = `SELECT
    ${s},
    COUNT(*) as assets
  FROM ${n}

  GROUP BY country_id`, i = await b(
    r,
    t,
    e
  );
  return i.length === 0 && (console.debug("🔥 ----------------------------------------------------- "), console.debug("🔥 There are no assets in the database                   "), console.debug("🔥 Restore the assets table with the last rollback table "), console.debug("🔥 ----------------------------------------------------- ")), i;
}
async function _f(t, e, n) {
  return await b(n, t, e);
}
function wf(t, e, n, s, r) {
  const i = zn(
    t.data_model.assets.columns,
    ft.Startup
  ), o = v(
    t,
    e,
    t.data_model.assets
  ), a = n ? F({
    columnName: "country_id",
    ids: [n],
    equalityOperator: O.EQUALS,
    logicalOperator: R.WHERE
  }) : "";
  return `SELECT ${i} FROM ${o}
  ${a}
  ORDER BY id
  LIMIT ${s} OFFSET ${r}`;
}
async function Ef(t, e, n) {
  const s = nf(
    t,
    e,
    n
  );
  return (await b(s, t, e)).map((o) => o.asset_id);
}
async function vf(t, e, n) {
  const s = of(
    t,
    e,
    n
  );
  return await b(
    s,
    t,
    e
  );
}
function ke(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.assets
  ), r = `WITH assets_by_countries AS (SELECT * FROM ${s})`;
  return t.countries.length === 1 || n.countries === void 0 || n.countries.length === 0 ? r : `WITH assets_by_countries AS (
    SELECT
      *
    FROM ${s}
    WHERE
      country_id in (${n.countries.join(",")})
  )`;
}
async function Tf(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.business_unit_id,
      a.l1panelclass_id as id,
      a.l1_panel_class as name
    FROM assets_by_countries a
    WHERE a.is_digital = true AND name IS NOT NULL AND id IS NOT NULL`;
  return await b(r, t, e);
}
async function Sf(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.business_unit_id,
      a.l1panelclass_id as id,
      a.l1_panel_class as name
    FROM assets_by_countries a
    WHERE a.is_digital = false AND name IS NOT NULL AND id IS NOT NULL`;
  return await b(r, t, e);
}
async function If(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.country_id,
      NULL as parent_id,
      a.l1panelclass_id as id,
      a.l1panelclass as name
    FROM assets_by_countries a
    WHERE l1panelclass_id IS NOT NULL
    GROUP BY a.country_id, a.l1panelclass_id, a.l1panelclass
    `;
  return await b(r, t, e);
}
async function bf(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.country_id,
      a.l1panelclass_id as parent_id,
      a.l2product_id as id,
      a.l2product as name
    FROM assets_by_countries a
    WHERE l2product_id IS NOT NULL
    GROUP BY a.country_id, a.l1panelclass_id, a.l2product_id, a.l2product
    `;
  return await b(r, t, e);
}
async function Af(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.country_id,
      a.l2product_id as parent_id,
      a.l3subproduct_id as id,
      a.l3subproduct as name
    FROM assets_by_countries a
    WHERE l3subproduct_id IS NOT NULL
    GROUP BY a.country_id, a.l2product_id, a.l3subproduct_id, a.l3subproduct
    `;
  return await b(r, t, e);
}
async function Cf(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.country_id,
      a.l3subproduct_id as parent_id,
      a.l4_id as id,
      a.l4 as name
    FROM assets_by_countries a
    WHERE l4_id IS NOT NULL
    GROUP BY a.country_id, a.l3subproduct_id, a.l4_id, a.l4
    `;
  return await b(r, t, e);
}
async function Nf(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.country_id,
      a.l4_id as parent_id,
      a.l5_id as id,
      a.l5 as name
    FROM assets_by_countries a
    WHERE l5_id IS NOT NULL
    GROUP BY a.country_id, a.l4_id, a.l5_id, a.l5
    `;
  return await b(r, t, e);
}
async function Of(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.country_id,
      a.l5_id as parent_id,
      a.l6_id as id,
      a.l6 as name
    FROM assets_by_countries a
    WHERE l6_id IS NOT NULL
    GROUP BY a.country_id, a.l5_id, a.l6_id, a.l6
    `;
  return await b(r, t, e);
}
async function Rf(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.country_id,
      a.l6_id as parent_id,
      a.l7_id as id,
      a.l7 as name
    FROM assets_by_countries a
    WHERE l7_id IS NOT NULL
    GROUP BY a.country_id, a.l6_id, a.l7_id, a.l7
    `;
  return await b(r, t, e);
}
async function kf(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.country_id,
      a.l7_id as parent_id,
      a.l8_id as id,
      a.l8 as name
    FROM assets_by_countries a
    WHERE l8_id IS NOT NULL
    GROUP BY a.country_id, a.l7_id, a.l8_id, a.l8
    `;
  return await b(r, t, e);
}
async function Df(t, e, n) {
  const r = `
    ${ke(
    t,
    e,
    n
  )}

    SELECT DISTINCT
      a.country_id,
      a.l8_id as parent_id,
      a.l9_id as id,
      a.l9 as name
    FROM assets_by_countries a
    WHERE l9_id IS NOT NULL
    GROUP BY a.country_id, a.l8_id, a.l9_id, a.l9
    `;
  return await b(r, t, e);
}
async function Pf(t, e, n) {
  const s = cf(t, e, n);
  return await b(s, t, e);
}
async function Lf(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`;
  try {
    let i = ce(
      t,
      e,
      re.Aida
    );
    if (!i)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for getting managing custom POIs in BQ. Check .env file 🔴🔴🔴"
      );
    return i = `${i}/file?file=${n}`, await (await fetch(i, {
      method: "GET",
      headers: { Authorization: r }
    })).json();
  } catch (i) {
    throw new Error(`Cloud function error: ${JSON.stringify(i)}`);
  }
}
async function Ff(t, e, n, s) {
  const r = await af(
    t,
    e,
    n,
    s
  );
  return (await b(r, t, e)).flat();
}
const XS = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  fetchLayerSource: wf,
  getAidaAssetsFromFile: Lf,
  getAllAssetsWithBackgroundProperties: gf,
  getAllAssetsWithStartupProperties: pf,
  getAssetById: Pf,
  getAssetProperties: mf,
  getAssetPropertiesQuery: Hc,
  getAssetsByMinimumDistance: Ff,
  getAssetsCountPerCountry: yf,
  getAssetsSQL: _f,
  getAssetsToShowBasedOnAudienceFilter: Ef,
  getAssetsToShowBasedOnAudienceFilterV2: vf,
  getAssetsTypeL1: If,
  getAssetsTypeL1DigitalFilter: Tf,
  getAssetsTypeL1StaticFilter: Sf,
  getAssetsTypeL2: bf,
  getAssetsTypeL3: Af,
  getAssetsTypeL4: Cf,
  getAssetsTypeL5: Nf,
  getAssetsTypeL6: Of,
  getAssetsTypeL7: Rf,
  getAssetsTypeL8: kf,
  getAssetsTypeL9: Df
}, Symbol.toStringTag, { value: "Module" }));
function cr(t) {
  return !t || !Array.isArray(t) ? !1 : t.length > 0;
}
const xs = 1;
function ya(t) {
  if (!t.length)
    return "and  1 = 2";
  let e = `type_id IN (${t.map((s) => s.id).join(",")})`;
  return e = "and (" + t.reduce(
    (s, r) => (cr(r.filterIds) ? s.push(`(id IN (${r.filterIds.join(",")}))`) : s.push(`( type_id = ${r.id} )`), s),
    []
  ).join(" OR ") + ")", e;
}
function _a(t) {
  return t.reduce((e, n) => (n.id !== xs && n.geom && e.push(n), e), []);
}
function Mf(t) {
  return t.reduce((e, n) => (n.id !== xs && !n.geom && e.push(n), e), []);
}
function $f(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.regions
  ), r = [], i = ["id", "name", "type_id", "country_id", "normalized_name"], o = [...i, "geom"], a = n.country_iso2.join("','");
  if (n.active_regions.map((p) => p.id).includes(xs)) {
    const p = `
      SELECT
        ${i.join(",")}
      FROM ${s} regions
      WHERE
        type_id = ${xs}
        and iso2 IN ('${a}')
      ORDER BY type_id, name ASC
    `;
    r.push(p);
  }
  const c = _a(
    n.active_regions
  ), l = _a(
    n.filter_regions
  ).find(
    (p) => p.filterIds.length
  );
  if (cr(c)) {
    const p = ya(c), S = `
      SELECT
        ${l ? o.join(",") : i.join(",")}
      FROM ${s}
      WHERE
      iso2 IN ('${a}')
      ${p}
      ORDER BY type_id, name ASC
    `;
    if (!l)
      r.push(S);
    else {
      const P = `
        WITH topLimitRegions AS (
          SELECT geom
          FROM ${s}
          WHERE id IN (${l.filterIds.join(",")})
        ), filteredRegions AS (
          ${S}
        )
        SELECT
          ${i.join(",")}
        FROM filteredRegions a
        JOIN topLimitRegions f
          ON ST_INTERSECTS(a.geom, ST_BUFFER(f.geom,-1000))
        ORDER BY type_id, name ASC

      `;
      r.push(P);
    }
  }
  const h = Mf(
    n.active_regions
  );
  if (h.length) {
    const p = ya(h), m = `
      SELECT
        ${i.join(",")}
      FROM ${s}
      WHERE
      iso2 IN ('${a}')
      ${p}
      ORDER BY type_id, name ASC
    `;
    r.push(m);
  }
  return r.length === 1 ? r[0] : `(${r.join(") UNION ALL (")})`;
}
function Uf(t, e, n, s = !0) {
  const r = v(
    t,
    e,
    t.data_model.regions
  ), i = F({
    columnName: "type_id",
    ids: [n.type_id],
    equalityOperator: O.EQUALS,
    logicalOperator: R.NONE
  }), o = F({
    columnName: "normalized_name",
    ids: n.names,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  });
  return `SELECT
  name,
  normalized_name,
  ${s ? "" : "ST_ASGEOJSON(geom) as "}geom,
  FROM ${r}
  WHERE
    ${i}
    ${o}
  `;
}
function xf(t, e, n, s = !0) {
  const r = v(
    t,
    e,
    t.data_model.regions
  ), i = Rt(
    "type_id",
    [n.type_id],
    O.EQUALS,
    R.NONE
  ), o = Rt(
    "id",
    n.ids,
    O.EQUALS,
    R.AND
  );
  return `SELECT
  id,
  ${s ? "" : "ST_ASGEOJSON(geom) as "}geom,
  FROM ${r}
  WHERE
    ${i}
    ${o}
  `;
}
const Bf = "normalized_name";
async function qf(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.regions
  ), r = n.countries_id;
  let i = "", o = "", a = "", c = "";
  const u = n.only_results_with_geometry;
  let l = "";
  r && (a = "country_id, country_name, ", i = F({
    columnName: "country_id",
    ids: r,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), o = "ORDER BY country_id", c = "country_id, country_name, "), u && (l = "AND geom IS NOT NULL");
  const h = `SELECT
  ${a}
  count(*) as count,
  type_id as type_id,
  type as type,
  array_agg(struct(id, name, type_id, IF(geom IS NULL, false, true) AS has_geometry)) as data
  FROM ${s}
  WHERE
    ${Bf} LIKE LOWER('%${n.pattern}%')
    ${i}
    ${l}
    GROUP BY ${c} type_id, type
    ${o}`;
  return await b(
    h,
    t,
    e
  );
}
async function Vf(t, e, n) {
  const s = Uf(t, e, n);
  return await b(
    s,
    t,
    e
  );
}
async function jf(t, e, n) {
  const s = xf(t, e, n), r = await b(
    s,
    t,
    e
  ), i = /* @__PURE__ */ new Map();
  for (const o of r)
    i.set(o.id, o.geom);
  return i;
}
async function Gf(t, e, n) {
  const s = $f(
    t,
    e,
    n
  );
  return await b(
    s,
    t,
    e
  );
}
const ZS = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getGeoboundariesByTypeAndCountry: Gf,
  getGeomertyByName: Vf,
  getGeometryByIds: jf,
  getValuesGroupByPattern: qf
}, Symbol.toStringTag, { value: "Module" }));
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Kc = function(t) {
  const e = [];
  let n = 0;
  for (let s = 0; s < t.length; s++) {
    let r = t.charCodeAt(s);
    r < 128 ? e[n++] = r : r < 2048 ? (e[n++] = r >> 6 | 192, e[n++] = r & 63 | 128) : (r & 64512) === 55296 && s + 1 < t.length && (t.charCodeAt(s + 1) & 64512) === 56320 ? (r = 65536 + ((r & 1023) << 10) + (t.charCodeAt(++s) & 1023), e[n++] = r >> 18 | 240, e[n++] = r >> 12 & 63 | 128, e[n++] = r >> 6 & 63 | 128, e[n++] = r & 63 | 128) : (e[n++] = r >> 12 | 224, e[n++] = r >> 6 & 63 | 128, e[n++] = r & 63 | 128);
  }
  return e;
}, Wf = function(t) {
  const e = [];
  let n = 0, s = 0;
  for (; n < t.length; ) {
    const r = t[n++];
    if (r < 128)
      e[s++] = String.fromCharCode(r);
    else if (r > 191 && r < 224) {
      const i = t[n++];
      e[s++] = String.fromCharCode((r & 31) << 6 | i & 63);
    } else if (r > 239 && r < 365) {
      const i = t[n++], o = t[n++], a = t[n++], c = ((r & 7) << 18 | (i & 63) << 12 | (o & 63) << 6 | a & 63) - 65536;
      e[s++] = String.fromCharCode(55296 + (c >> 10)), e[s++] = String.fromCharCode(56320 + (c & 1023));
    } else {
      const i = t[n++], o = t[n++];
      e[s++] = String.fromCharCode((r & 15) << 12 | (i & 63) << 6 | o & 63);
    }
  }
  return e.join("");
}, Jc = {
  /**
   * Maps bytes to characters.
   */
  byteToCharMap_: null,
  /**
   * Maps characters to bytes.
   */
  charToByteMap_: null,
  /**
   * Maps bytes to websafe characters.
   * @private
   */
  byteToCharMapWebSafe_: null,
  /**
   * Maps websafe characters to bytes.
   * @private
   */
  charToByteMapWebSafe_: null,
  /**
   * Our default alphabet, shared between
   * ENCODED_VALS and ENCODED_VALS_WEBSAFE
   */
  ENCODED_VALS_BASE: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  /**
   * Our default alphabet. Value 64 (=) is special; it means "nothing."
   */
  get ENCODED_VALS() {
    return this.ENCODED_VALS_BASE + "+/=";
  },
  /**
   * Our websafe alphabet.
   */
  get ENCODED_VALS_WEBSAFE() {
    return this.ENCODED_VALS_BASE + "-_.";
  },
  /**
   * Whether this browser supports the atob and btoa functions. This extension
   * started at Mozilla but is now implemented by many browsers. We use the
   * ASSUME_* variables to avoid pulling in the full useragent detection library
   * but still allowing the standard per-browser compilations.
   *
   */
  HAS_NATIVE_SUPPORT: typeof atob == "function",
  /**
   * Base64-encode an array of bytes.
   *
   * @param input An array of bytes (numbers with
   *     value in [0, 255]) to encode.
   * @param webSafe Boolean indicating we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeByteArray(t, e) {
    if (!Array.isArray(t))
      throw Error("encodeByteArray takes an array as a parameter");
    this.init_();
    const n = e ? this.byteToCharMapWebSafe_ : this.byteToCharMap_, s = [];
    for (let r = 0; r < t.length; r += 3) {
      const i = t[r], o = r + 1 < t.length, a = o ? t[r + 1] : 0, c = r + 2 < t.length, u = c ? t[r + 2] : 0, l = i >> 2, h = (i & 3) << 4 | a >> 4;
      let d = (a & 15) << 2 | u >> 6, p = u & 63;
      c || (p = 64, o || (d = 64)), s.push(n[l], n[h], n[d], n[p]);
    }
    return s.join("");
  },
  /**
   * Base64-encode a string.
   *
   * @param input A string to encode.
   * @param webSafe If true, we should use the
   *     alternative alphabet.
   * @return The base64 encoded string.
   */
  encodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? btoa(t) : this.encodeByteArray(Kc(t), e);
  },
  /**
   * Base64-decode a string.
   *
   * @param input to decode.
   * @param webSafe True if we should use the
   *     alternative alphabet.
   * @return string representing the decoded value.
   */
  decodeString(t, e) {
    return this.HAS_NATIVE_SUPPORT && !e ? atob(t) : Wf(this.decodeStringToByteArray(t, e));
  },
  /**
   * Base64-decode a string.
   *
   * In base-64 decoding, groups of four characters are converted into three
   * bytes.  If the encoder did not apply padding, the input length may not
   * be a multiple of 4.
   *
   * In this case, the last group will have fewer than 4 characters, and
   * padding will be inferred.  If the group has one or two characters, it decodes
   * to one byte.  If the group has three characters, it decodes to two bytes.
   *
   * @param input Input to decode.
   * @param webSafe True if we should use the web-safe alphabet.
   * @return bytes representing the decoded value.
   */
  decodeStringToByteArray(t, e) {
    this.init_();
    const n = e ? this.charToByteMapWebSafe_ : this.charToByteMap_, s = [];
    for (let r = 0; r < t.length; ) {
      const i = n[t.charAt(r++)], a = r < t.length ? n[t.charAt(r)] : 0;
      ++r;
      const u = r < t.length ? n[t.charAt(r)] : 64;
      ++r;
      const h = r < t.length ? n[t.charAt(r)] : 64;
      if (++r, i == null || a == null || u == null || h == null)
        throw Error();
      const d = i << 2 | a >> 4;
      if (s.push(d), u !== 64) {
        const p = a << 4 & 240 | u >> 2;
        if (s.push(p), h !== 64) {
          const m = u << 6 & 192 | h;
          s.push(m);
        }
      }
    }
    return s;
  },
  /**
   * Lazy static initialization function. Called before
   * accessing any of the static map variables.
   * @private
   */
  init_() {
    if (!this.byteToCharMap_) {
      this.byteToCharMap_ = {}, this.charToByteMap_ = {}, this.byteToCharMapWebSafe_ = {}, this.charToByteMapWebSafe_ = {};
      for (let t = 0; t < this.ENCODED_VALS.length; t++)
        this.byteToCharMap_[t] = this.ENCODED_VALS.charAt(t), this.charToByteMap_[this.byteToCharMap_[t]] = t, this.byteToCharMapWebSafe_[t] = this.ENCODED_VALS_WEBSAFE.charAt(t), this.charToByteMapWebSafe_[this.byteToCharMapWebSafe_[t]] = t, t >= this.ENCODED_VALS_BASE.length && (this.charToByteMap_[this.ENCODED_VALS_WEBSAFE.charAt(t)] = t, this.charToByteMapWebSafe_[this.ENCODED_VALS.charAt(t)] = t);
    }
  }
}, Hf = function(t) {
  const e = Kc(t);
  return Jc.encodeByteArray(e, !0);
}, Bs = function(t) {
  return Hf(t).replace(/\./g, "");
}, Yc = function(t) {
  try {
    return Jc.decodeString(t, !0);
  } catch (e) {
    console.error("base64Decode failed: ", e);
  }
  return null;
};
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function zf() {
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("Unable to locate global object.");
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Qf = () => zf().__FIREBASE_DEFAULTS__, Kf = () => {
  if (typeof process > "u" || typeof process.env > "u")
    return;
  const t = process.env.__FIREBASE_DEFAULTS__;
  if (t)
    return JSON.parse(t);
}, Jf = () => {
  if (typeof document > "u")
    return;
  let t;
  try {
    t = document.cookie.match(/__FIREBASE_DEFAULTS__=([^;]+)/);
  } catch {
    return;
  }
  const e = t && Yc(t[1]);
  return e && JSON.parse(e);
}, ro = () => {
  try {
    return Qf() || Kf() || Jf();
  } catch (t) {
    console.info(`Unable to get __FIREBASE_DEFAULTS__ due to: ${t}`);
    return;
  }
}, Xc = (t) => {
  var e, n;
  return (n = (e = ro()) === null || e === void 0 ? void 0 : e.emulatorHosts) === null || n === void 0 ? void 0 : n[t];
}, Yf = (t) => {
  const e = Xc(t);
  if (!e)
    return;
  const n = e.lastIndexOf(":");
  if (n <= 0 || n + 1 === e.length)
    throw new Error(`Invalid host ${e} with no separate hostname and port!`);
  const s = parseInt(e.substring(n + 1), 10);
  return e[0] === "[" ? [e.substring(1, n - 1), s] : [e.substring(0, n), s];
}, Xf = () => {
  var t;
  return (t = ro()) === null || t === void 0 ? void 0 : t.config;
}, Zc = (t) => {
  var e;
  return (e = ro()) === null || e === void 0 ? void 0 : e[`_${t}`];
};
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Zf {
  constructor() {
    this.reject = () => {
    }, this.resolve = () => {
    }, this.promise = new Promise((e, n) => {
      this.resolve = e, this.reject = n;
    });
  }
  /**
   * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
   * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
   * and returns a node-style callback which will resolve or reject the Deferred's promise.
   */
  wrapCallback(e) {
    return (n, s) => {
      n ? this.reject(n) : this.resolve(s), typeof e == "function" && (this.promise.catch(() => {
      }), e.length === 1 ? e(n) : e(n, s));
    };
  }
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function ep(t, e) {
  if (t.uid)
    throw new Error('The "uid" field is no longer supported by mockUserToken. Please use "sub" instead for Firebase Auth User ID.');
  const n = {
    alg: "none",
    type: "JWT"
  }, s = e || "demo-project", r = t.iat || 0, i = t.sub || t.user_id;
  if (!i)
    throw new Error("mockUserToken must contain 'sub' or 'user_id' field!");
  const o = Object.assign({
    // Set all required fields to decent defaults
    iss: `https://securetoken.google.com/${s}`,
    aud: s,
    iat: r,
    exp: r + 3600,
    auth_time: r,
    sub: i,
    user_id: i,
    firebase: {
      sign_in_provider: "custom",
      identities: {}
    }
  }, t), a = "";
  return [
    Bs(JSON.stringify(n)),
    Bs(JSON.stringify(o)),
    a
  ].join(".");
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function me() {
  return typeof navigator < "u" && typeof navigator.userAgent == "string" ? navigator.userAgent : "";
}
function tp() {
  return typeof window < "u" && // @ts-ignore Setting up an broadly applicable index signature for Window
  // just to deal with this case would probably be a bad idea.
  !!(window.cordova || window.phonegap || window.PhoneGap) && /ios|iphone|ipod|ipad|android|blackberry|iemobile/i.test(me());
}
function np() {
  const t = typeof chrome == "object" ? chrome.runtime : typeof browser == "object" ? browser.runtime : void 0;
  return typeof t == "object" && t.id !== void 0;
}
function sp() {
  return typeof navigator == "object" && navigator.product === "ReactNative";
}
function rp() {
  const t = me();
  return t.indexOf("MSIE ") >= 0 || t.indexOf("Trident/") >= 0;
}
function ip() {
  try {
    return typeof indexedDB == "object";
  } catch {
    return !1;
  }
}
function op() {
  return new Promise((t, e) => {
    try {
      let n = !0;
      const s = "validate-browser-context-for-indexeddb-analytics-module", r = self.indexedDB.open(s);
      r.onsuccess = () => {
        r.result.close(), n || self.indexedDB.deleteDatabase(s), t(!0);
      }, r.onupgradeneeded = () => {
        n = !1;
      }, r.onerror = () => {
        var i;
        e(((i = r.error) === null || i === void 0 ? void 0 : i.message) || "");
      };
    } catch (n) {
      e(n);
    }
  });
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const ap = "FirebaseError";
class Xe extends Error {
  constructor(e, n, s) {
    super(n), this.code = e, this.customData = s, this.name = ap, Object.setPrototypeOf(this, Xe.prototype), Error.captureStackTrace && Error.captureStackTrace(this, Qn.prototype.create);
  }
}
class Qn {
  constructor(e, n, s) {
    this.service = e, this.serviceName = n, this.errors = s;
  }
  create(e, ...n) {
    const s = n[0] || {}, r = `${this.service}/${e}`, i = this.errors[e], o = i ? cp(i, s) : "Error", a = `${this.serviceName}: ${o} (${r}).`;
    return new Xe(r, a, s);
  }
}
function cp(t, e) {
  return t.replace(up, (n, s) => {
    const r = e[s];
    return r != null ? String(r) : `<${s}?>`;
  });
}
const up = /\{\$([^}]+)}/g;
function lp(t) {
  for (const e in t)
    if (Object.prototype.hasOwnProperty.call(t, e))
      return !1;
  return !0;
}
function Dn(t, e) {
  if (t === e)
    return !0;
  const n = Object.keys(t), s = Object.keys(e);
  for (const r of n) {
    if (!s.includes(r))
      return !1;
    const i = t[r], o = e[r];
    if (wa(i) && wa(o)) {
      if (!Dn(i, o))
        return !1;
    } else if (i !== o)
      return !1;
  }
  for (const r of s)
    if (!n.includes(r))
      return !1;
  return !0;
}
function wa(t) {
  return t !== null && typeof t == "object";
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Kn(t) {
  const e = [];
  for (const [n, s] of Object.entries(t))
    Array.isArray(s) ? s.forEach((r) => {
      e.push(encodeURIComponent(n) + "=" + encodeURIComponent(r));
    }) : e.push(encodeURIComponent(n) + "=" + encodeURIComponent(s));
  return e.length ? "&" + e.join("&") : "";
}
function hp(t, e) {
  const n = new dp(t, e);
  return n.subscribe.bind(n);
}
class dp {
  /**
   * @param executor Function which can make calls to a single Observer
   *     as a proxy.
   * @param onNoObservers Callback when count of Observers goes to zero.
   */
  constructor(e, n) {
    this.observers = [], this.unsubscribes = [], this.observerCount = 0, this.task = Promise.resolve(), this.finalized = !1, this.onNoObservers = n, this.task.then(() => {
      e(this);
    }).catch((s) => {
      this.error(s);
    });
  }
  next(e) {
    this.forEachObserver((n) => {
      n.next(e);
    });
  }
  error(e) {
    this.forEachObserver((n) => {
      n.error(e);
    }), this.close(e);
  }
  complete() {
    this.forEachObserver((e) => {
      e.complete();
    }), this.close();
  }
  /**
   * Subscribe function that can be used to add an Observer to the fan-out list.
   *
   * - We require that no event is sent to a subscriber sychronously to their
   *   call to subscribe().
   */
  subscribe(e, n, s) {
    let r;
    if (e === void 0 && n === void 0 && s === void 0)
      throw new Error("Missing Observer.");
    fp(e, [
      "next",
      "error",
      "complete"
    ]) ? r = e : r = {
      next: e,
      error: n,
      complete: s
    }, r.next === void 0 && (r.next = ti), r.error === void 0 && (r.error = ti), r.complete === void 0 && (r.complete = ti);
    const i = this.unsubscribeOne.bind(this, this.observers.length);
    return this.finalized && this.task.then(() => {
      try {
        this.finalError ? r.error(this.finalError) : r.complete();
      } catch {
      }
    }), this.observers.push(r), i;
  }
  // Unsubscribe is synchronous - we guarantee that no events are sent to
  // any unsubscribed Observer.
  unsubscribeOne(e) {
    this.observers === void 0 || this.observers[e] === void 0 || (delete this.observers[e], this.observerCount -= 1, this.observerCount === 0 && this.onNoObservers !== void 0 && this.onNoObservers(this));
  }
  forEachObserver(e) {
    if (!this.finalized)
      for (let n = 0; n < this.observers.length; n++)
        this.sendOne(n, e);
  }
  // Call the Observer via one of it's callback function. We are careful to
  // confirm that the observe has not been unsubscribed since this asynchronous
  // function had been queued.
  sendOne(e, n) {
    this.task.then(() => {
      if (this.observers !== void 0 && this.observers[e] !== void 0)
        try {
          n(this.observers[e]);
        } catch (s) {
          typeof console < "u" && console.error && console.error(s);
        }
    });
  }
  close(e) {
    this.finalized || (this.finalized = !0, e !== void 0 && (this.finalError = e), this.task.then(() => {
      this.observers = void 0, this.onNoObservers = void 0;
    }));
  }
}
function fp(t, e) {
  if (typeof t != "object" || t === null)
    return !1;
  for (const n of e)
    if (n in t && typeof t[n] == "function")
      return !0;
  return !1;
}
function ti() {
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Ee(t) {
  return t && t._delegate ? t._delegate : t;
}
class kt {
  /**
   *
   * @param name The public service name, e.g. app, auth, firestore, database
   * @param instanceFactory Service factory responsible for creating the public interface
   * @param type whether the service provided by the component is public or private
   */
  constructor(e, n, s) {
    this.name = e, this.instanceFactory = n, this.type = s, this.multipleInstances = !1, this.serviceProps = {}, this.instantiationMode = "LAZY", this.onInstanceCreated = null;
  }
  setInstantiationMode(e) {
    return this.instantiationMode = e, this;
  }
  setMultipleInstances(e) {
    return this.multipleInstances = e, this;
  }
  setServiceProps(e) {
    return this.serviceProps = e, this;
  }
  setInstanceCreatedCallback(e) {
    return this.onInstanceCreated = e, this;
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Tt = "[DEFAULT]";
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class pp {
  constructor(e, n) {
    this.name = e, this.container = n, this.component = null, this.instances = /* @__PURE__ */ new Map(), this.instancesDeferred = /* @__PURE__ */ new Map(), this.instancesOptions = /* @__PURE__ */ new Map(), this.onInitCallbacks = /* @__PURE__ */ new Map();
  }
  /**
   * @param identifier A provider can provide mulitple instances of a service
   * if this.component.multipleInstances is true.
   */
  get(e) {
    const n = this.normalizeInstanceIdentifier(e);
    if (!this.instancesDeferred.has(n)) {
      const s = new Zf();
      if (this.instancesDeferred.set(n, s), this.isInitialized(n) || this.shouldAutoInitialize())
        try {
          const r = this.getOrInitializeService({
            instanceIdentifier: n
          });
          r && s.resolve(r);
        } catch {
        }
    }
    return this.instancesDeferred.get(n).promise;
  }
  getImmediate(e) {
    var n;
    const s = this.normalizeInstanceIdentifier(e?.identifier), r = (n = e?.optional) !== null && n !== void 0 ? n : !1;
    if (this.isInitialized(s) || this.shouldAutoInitialize())
      try {
        return this.getOrInitializeService({
          instanceIdentifier: s
        });
      } catch (i) {
        if (r)
          return null;
        throw i;
      }
    else {
      if (r)
        return null;
      throw Error(`Service ${this.name} is not available`);
    }
  }
  getComponent() {
    return this.component;
  }
  setComponent(e) {
    if (e.name !== this.name)
      throw Error(`Mismatching Component ${e.name} for Provider ${this.name}.`);
    if (this.component)
      throw Error(`Component for ${this.name} has already been provided`);
    if (this.component = e, !!this.shouldAutoInitialize()) {
      if (mp(e))
        try {
          this.getOrInitializeService({ instanceIdentifier: Tt });
        } catch {
        }
      for (const [n, s] of this.instancesDeferred.entries()) {
        const r = this.normalizeInstanceIdentifier(n);
        try {
          const i = this.getOrInitializeService({
            instanceIdentifier: r
          });
          s.resolve(i);
        } catch {
        }
      }
    }
  }
  clearInstance(e = Tt) {
    this.instancesDeferred.delete(e), this.instancesOptions.delete(e), this.instances.delete(e);
  }
  // app.delete() will call this method on every provider to delete the services
  // TODO: should we mark the provider as deleted?
  async delete() {
    const e = Array.from(this.instances.values());
    await Promise.all([
      ...e.filter((n) => "INTERNAL" in n).map((n) => n.INTERNAL.delete()),
      ...e.filter((n) => "_delete" in n).map((n) => n._delete())
    ]);
  }
  isComponentSet() {
    return this.component != null;
  }
  isInitialized(e = Tt) {
    return this.instances.has(e);
  }
  getOptions(e = Tt) {
    return this.instancesOptions.get(e) || {};
  }
  initialize(e = {}) {
    const { options: n = {} } = e, s = this.normalizeInstanceIdentifier(e.instanceIdentifier);
    if (this.isInitialized(s))
      throw Error(`${this.name}(${s}) has already been initialized`);
    if (!this.isComponentSet())
      throw Error(`Component ${this.name} has not been registered yet`);
    const r = this.getOrInitializeService({
      instanceIdentifier: s,
      options: n
    });
    for (const [i, o] of this.instancesDeferred.entries()) {
      const a = this.normalizeInstanceIdentifier(i);
      s === a && o.resolve(r);
    }
    return r;
  }
  /**
   *
   * @param callback - a function that will be invoked  after the provider has been initialized by calling provider.initialize().
   * The function is invoked SYNCHRONOUSLY, so it should not execute any longrunning tasks in order to not block the program.
   *
   * @param identifier An optional instance identifier
   * @returns a function to unregister the callback
   */
  onInit(e, n) {
    var s;
    const r = this.normalizeInstanceIdentifier(n), i = (s = this.onInitCallbacks.get(r)) !== null && s !== void 0 ? s : /* @__PURE__ */ new Set();
    i.add(e), this.onInitCallbacks.set(r, i);
    const o = this.instances.get(r);
    return o && e(o, r), () => {
      i.delete(e);
    };
  }
  /**
   * Invoke onInit callbacks synchronously
   * @param instance the service instance`
   */
  invokeOnInitCallbacks(e, n) {
    const s = this.onInitCallbacks.get(n);
    if (s)
      for (const r of s)
        try {
          r(e, n);
        } catch {
        }
  }
  getOrInitializeService({ instanceIdentifier: e, options: n = {} }) {
    let s = this.instances.get(e);
    if (!s && this.component && (s = this.component.instanceFactory(this.container, {
      instanceIdentifier: gp(e),
      options: n
    }), this.instances.set(e, s), this.instancesOptions.set(e, n), this.invokeOnInitCallbacks(s, e), this.component.onInstanceCreated))
      try {
        this.component.onInstanceCreated(this.container, e, s);
      } catch {
      }
    return s || null;
  }
  normalizeInstanceIdentifier(e = Tt) {
    return this.component ? this.component.multipleInstances ? e : Tt : e;
  }
  shouldAutoInitialize() {
    return !!this.component && this.component.instantiationMode !== "EXPLICIT";
  }
}
function gp(t) {
  return t === Tt ? void 0 : t;
}
function mp(t) {
  return t.instantiationMode === "EAGER";
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class yp {
  constructor(e) {
    this.name = e, this.providers = /* @__PURE__ */ new Map();
  }
  /**
   *
   * @param component Component being added
   * @param overwrite When a component with the same name has already been registered,
   * if overwrite is true: overwrite the existing component with the new component and create a new
   * provider with the new component. It can be useful in tests where you want to use different mocks
   * for different tests.
   * if overwrite is false: throw an exception
   */
  addComponent(e) {
    const n = this.getProvider(e.name);
    if (n.isComponentSet())
      throw new Error(`Component ${e.name} has already been registered with ${this.name}`);
    n.setComponent(e);
  }
  addOrOverwriteComponent(e) {
    this.getProvider(e.name).isComponentSet() && this.providers.delete(e.name), this.addComponent(e);
  }
  /**
   * getProvider provides a type safe interface where it can only be called with a field name
   * present in NameServiceMapping interface.
   *
   * Firebase SDKs providing services should extend NameServiceMapping interface to register
   * themselves.
   */
  getProvider(e) {
    if (this.providers.has(e))
      return this.providers.get(e);
    const n = new pp(e, this);
    return this.providers.set(e, n), n;
  }
  getProviders() {
    return Array.from(this.providers.values());
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var x;
(function(t) {
  t[t.DEBUG = 0] = "DEBUG", t[t.VERBOSE = 1] = "VERBOSE", t[t.INFO = 2] = "INFO", t[t.WARN = 3] = "WARN", t[t.ERROR = 4] = "ERROR", t[t.SILENT = 5] = "SILENT";
})(x || (x = {}));
const _p = {
  debug: x.DEBUG,
  verbose: x.VERBOSE,
  info: x.INFO,
  warn: x.WARN,
  error: x.ERROR,
  silent: x.SILENT
}, wp = x.INFO, Ep = {
  [x.DEBUG]: "log",
  [x.VERBOSE]: "log",
  [x.INFO]: "info",
  [x.WARN]: "warn",
  [x.ERROR]: "error"
}, vp = (t, e, ...n) => {
  if (e < t.logLevel)
    return;
  const s = new Date().toISOString(), r = Ep[e];
  if (r)
    console[r](`[${s}]  ${t.name}:`, ...n);
  else
    throw new Error(`Attempted to log a message with an invalid logType (value: ${e})`);
};
class io {
  /**
   * Gives you an instance of a Logger to capture messages according to
   * Firebase's logging scheme.
   *
   * @param name The name that the logs will be associated with
   */
  constructor(e) {
    this.name = e, this._logLevel = wp, this._logHandler = vp, this._userLogHandler = null;
  }
  get logLevel() {
    return this._logLevel;
  }
  set logLevel(e) {
    if (!(e in x))
      throw new TypeError(`Invalid value "${e}" assigned to \`logLevel\``);
    this._logLevel = e;
  }
  // Workaround for setter/getter having to be the same type.
  setLogLevel(e) {
    this._logLevel = typeof e == "string" ? _p[e] : e;
  }
  get logHandler() {
    return this._logHandler;
  }
  set logHandler(e) {
    if (typeof e != "function")
      throw new TypeError("Value assigned to `logHandler` must be a function");
    this._logHandler = e;
  }
  get userLogHandler() {
    return this._userLogHandler;
  }
  set userLogHandler(e) {
    this._userLogHandler = e;
  }
  /**
   * The functions below are all based on the `console` interface
   */
  debug(...e) {
    this._userLogHandler && this._userLogHandler(this, x.DEBUG, ...e), this._logHandler(this, x.DEBUG, ...e);
  }
  log(...e) {
    this._userLogHandler && this._userLogHandler(this, x.VERBOSE, ...e), this._logHandler(this, x.VERBOSE, ...e);
  }
  info(...e) {
    this._userLogHandler && this._userLogHandler(this, x.INFO, ...e), this._logHandler(this, x.INFO, ...e);
  }
  warn(...e) {
    this._userLogHandler && this._userLogHandler(this, x.WARN, ...e), this._logHandler(this, x.WARN, ...e);
  }
  error(...e) {
    this._userLogHandler && this._userLogHandler(this, x.ERROR, ...e), this._logHandler(this, x.ERROR, ...e);
  }
}
const Tp = (t, e) => e.some((n) => t instanceof n);
let Ea, va;
function Sp() {
  return Ea || (Ea = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function Ip() {
  return va || (va = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const eu = /* @__PURE__ */ new WeakMap(), Ei = /* @__PURE__ */ new WeakMap(), tu = /* @__PURE__ */ new WeakMap(), ni = /* @__PURE__ */ new WeakMap(), oo = /* @__PURE__ */ new WeakMap();
function bp(t) {
  const e = new Promise((n, s) => {
    const r = () => {
      t.removeEventListener("success", i), t.removeEventListener("error", o);
    }, i = () => {
      n(it(t.result)), r();
    }, o = () => {
      s(t.error), r();
    };
    t.addEventListener("success", i), t.addEventListener("error", o);
  });
  return e.then((n) => {
    n instanceof IDBCursor && eu.set(n, t);
  }).catch(() => {
  }), oo.set(e, t), e;
}
function Ap(t) {
  if (Ei.has(t))
    return;
  const e = new Promise((n, s) => {
    const r = () => {
      t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
    }, i = () => {
      n(), r();
    }, o = () => {
      s(t.error || new DOMException("AbortError", "AbortError")), r();
    };
    t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
  });
  Ei.set(t, e);
}
let vi = {
  get(t, e, n) {
    if (t instanceof IDBTransaction) {
      if (e === "done")
        return Ei.get(t);
      if (e === "objectStoreNames")
        return t.objectStoreNames || tu.get(t);
      if (e === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return it(t[e]);
  },
  set(t, e, n) {
    return t[e] = n, !0;
  },
  has(t, e) {
    return t instanceof IDBTransaction && (e === "done" || e === "store") ? !0 : e in t;
  }
};
function Cp(t) {
  vi = t(vi);
}
function Np(t) {
  return t === IDBDatabase.prototype.transaction && !("objectStoreNames" in IDBTransaction.prototype) ? function(e, ...n) {
    const s = t.call(si(this), e, ...n);
    return tu.set(s, e.sort ? e.sort() : [e]), it(s);
  } : Ip().includes(t) ? function(...e) {
    return t.apply(si(this), e), it(eu.get(this));
  } : function(...e) {
    return it(t.apply(si(this), e));
  };
}
function Op(t) {
  return typeof t == "function" ? Np(t) : (t instanceof IDBTransaction && Ap(t), Tp(t, Sp()) ? new Proxy(t, vi) : t);
}
function it(t) {
  if (t instanceof IDBRequest)
    return bp(t);
  if (ni.has(t))
    return ni.get(t);
  const e = Op(t);
  return e !== t && (ni.set(t, e), oo.set(e, t)), e;
}
const si = (t) => oo.get(t);
function Rp(t, e, { blocked: n, upgrade: s, blocking: r, terminated: i } = {}) {
  const o = indexedDB.open(t, e), a = it(o);
  return s && o.addEventListener("upgradeneeded", (c) => {
    s(it(o.result), c.oldVersion, c.newVersion, it(o.transaction));
  }), n && o.addEventListener("blocked", () => n()), a.then((c) => {
    i && c.addEventListener("close", () => i()), r && c.addEventListener("versionchange", () => r());
  }).catch(() => {
  }), a;
}
const kp = ["get", "getKey", "getAll", "getAllKeys", "count"], Dp = ["put", "add", "delete", "clear"], ri = /* @__PURE__ */ new Map();
function Ta(t, e) {
  if (!(t instanceof IDBDatabase && !(e in t) && typeof e == "string"))
    return;
  if (ri.get(e))
    return ri.get(e);
  const n = e.replace(/FromIndex$/, ""), s = e !== n, r = Dp.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (s ? IDBIndex : IDBObjectStore).prototype) || !(r || kp.includes(n))
  )
    return;
  const i = async function(o, ...a) {
    const c = this.transaction(o, r ? "readwrite" : "readonly");
    let u = c.store;
    return s && (u = u.index(a.shift())), (await Promise.all([
      u[n](...a),
      r && c.done
    ]))[0];
  };
  return ri.set(e, i), i;
}
Cp((t) => ({
  ...t,
  get: (e, n, s) => Ta(e, n) || t.get(e, n, s),
  has: (e, n) => !!Ta(e, n) || t.has(e, n)
}));
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Pp {
  constructor(e) {
    this.container = e;
  }
  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    return this.container.getProviders().map((n) => {
      if (Lp(n)) {
        const s = n.getImmediate();
        return `${s.library}/${s.version}`;
      } else
        return null;
    }).filter((n) => n).join(" ");
  }
}
function Lp(t) {
  const e = t.getComponent();
  return e?.type === "VERSION";
}
const Ti = "@firebase/app", Sa = "0.9.3";
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Dt = new io("@firebase/app"), Fp = "@firebase/app-compat", Mp = "@firebase/analytics-compat", $p = "@firebase/analytics", Up = "@firebase/app-check-compat", xp = "@firebase/app-check", Bp = "@firebase/auth", qp = "@firebase/auth-compat", Vp = "@firebase/database", jp = "@firebase/database-compat", Gp = "@firebase/functions", Wp = "@firebase/functions-compat", Hp = "@firebase/installations", zp = "@firebase/installations-compat", Qp = "@firebase/messaging", Kp = "@firebase/messaging-compat", Jp = "@firebase/performance", Yp = "@firebase/performance-compat", Xp = "@firebase/remote-config", Zp = "@firebase/remote-config-compat", eg = "@firebase/storage", tg = "@firebase/storage-compat", ng = "@firebase/firestore", sg = "@firebase/firestore-compat", rg = "firebase", ig = "9.17.1";
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Si = "[DEFAULT]", og = {
  [Ti]: "fire-core",
  [Fp]: "fire-core-compat",
  [$p]: "fire-analytics",
  [Mp]: "fire-analytics-compat",
  [xp]: "fire-app-check",
  [Up]: "fire-app-check-compat",
  [Bp]: "fire-auth",
  [qp]: "fire-auth-compat",
  [Vp]: "fire-rtdb",
  [jp]: "fire-rtdb-compat",
  [Gp]: "fire-fn",
  [Wp]: "fire-fn-compat",
  [Hp]: "fire-iid",
  [zp]: "fire-iid-compat",
  [Qp]: "fire-fcm",
  [Kp]: "fire-fcm-compat",
  [Jp]: "fire-perf",
  [Yp]: "fire-perf-compat",
  [Xp]: "fire-rc",
  [Zp]: "fire-rc-compat",
  [eg]: "fire-gcs",
  [tg]: "fire-gcs-compat",
  [ng]: "fire-fst",
  [sg]: "fire-fst-compat",
  "fire-js": "fire-js",
  [rg]: "fire-js-all"
};
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const qs = /* @__PURE__ */ new Map(), Ii = /* @__PURE__ */ new Map();
function ag(t, e) {
  try {
    t.container.addComponent(e);
  } catch (n) {
    Dt.debug(`Component ${e.name} failed to register with FirebaseApp ${t.name}`, n);
  }
}
function en(t) {
  const e = t.name;
  if (Ii.has(e))
    return Dt.debug(`There were multiple attempts to register component ${e}.`), !1;
  Ii.set(e, t);
  for (const n of qs.values())
    ag(n, t);
  return !0;
}
function ur(t, e) {
  const n = t.container.getProvider("heartbeat").getImmediate({ optional: !0 });
  return n && n.triggerHeartbeat(), t.container.getProvider(e);
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const cg = {
  [
    "no-app"
    /* AppError.NO_APP */
  ]: "No Firebase App '{$appName}' has been created - call Firebase App.initializeApp()",
  [
    "bad-app-name"
    /* AppError.BAD_APP_NAME */
  ]: "Illegal App name: '{$appName}",
  [
    "duplicate-app"
    /* AppError.DUPLICATE_APP */
  ]: "Firebase App named '{$appName}' already exists with different options or config",
  [
    "app-deleted"
    /* AppError.APP_DELETED */
  ]: "Firebase App named '{$appName}' already deleted",
  [
    "no-options"
    /* AppError.NO_OPTIONS */
  ]: "Need to provide options, when not being deployed to hosting via source.",
  [
    "invalid-app-argument"
    /* AppError.INVALID_APP_ARGUMENT */
  ]: "firebase.{$appName}() takes either no argument or a Firebase App instance.",
  [
    "invalid-log-argument"
    /* AppError.INVALID_LOG_ARGUMENT */
  ]: "First argument to `onLog` must be null or a function.",
  [
    "idb-open"
    /* AppError.IDB_OPEN */
  ]: "Error thrown when opening IndexedDB. Original error: {$originalErrorMessage}.",
  [
    "idb-get"
    /* AppError.IDB_GET */
  ]: "Error thrown when reading from IndexedDB. Original error: {$originalErrorMessage}.",
  [
    "idb-set"
    /* AppError.IDB_WRITE */
  ]: "Error thrown when writing to IndexedDB. Original error: {$originalErrorMessage}.",
  [
    "idb-delete"
    /* AppError.IDB_DELETE */
  ]: "Error thrown when deleting from IndexedDB. Original error: {$originalErrorMessage}."
}, ot = new Qn("app", "Firebase", cg);
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ug {
  constructor(e, n, s) {
    this._isDeleted = !1, this._options = Object.assign({}, e), this._config = Object.assign({}, n), this._name = n.name, this._automaticDataCollectionEnabled = n.automaticDataCollectionEnabled, this._container = s, this.container.addComponent(new kt(
      "app",
      () => this,
      "PUBLIC"
      /* ComponentType.PUBLIC */
    ));
  }
  get automaticDataCollectionEnabled() {
    return this.checkDestroyed(), this._automaticDataCollectionEnabled;
  }
  set automaticDataCollectionEnabled(e) {
    this.checkDestroyed(), this._automaticDataCollectionEnabled = e;
  }
  get name() {
    return this.checkDestroyed(), this._name;
  }
  get options() {
    return this.checkDestroyed(), this._options;
  }
  get config() {
    return this.checkDestroyed(), this._config;
  }
  get container() {
    return this._container;
  }
  get isDeleted() {
    return this._isDeleted;
  }
  set isDeleted(e) {
    this._isDeleted = e;
  }
  /**
   * This function will throw an Error if the App has already been deleted -
   * use before performing API actions on the App.
   */
  checkDestroyed() {
    if (this.isDeleted)
      throw ot.create("app-deleted", { appName: this._name });
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Jn = ig;
function nu(t, e = {}) {
  let n = t;
  typeof e != "object" && (e = { name: e });
  const s = Object.assign({ name: Si, automaticDataCollectionEnabled: !1 }, e), r = s.name;
  if (typeof r != "string" || !r)
    throw ot.create("bad-app-name", {
      appName: String(r)
    });
  if (n || (n = Xf()), !n)
    throw ot.create(
      "no-options"
      /* AppError.NO_OPTIONS */
    );
  const i = qs.get(r);
  if (i) {
    if (Dn(n, i.options) && Dn(s, i.config))
      return i;
    throw ot.create("duplicate-app", { appName: r });
  }
  const o = new yp(r);
  for (const c of Ii.values())
    o.addComponent(c);
  const a = new ug(n, s, o);
  return qs.set(r, a), a;
}
function lr(t = Si) {
  const e = qs.get(t);
  if (!e && t === Si)
    return nu();
  if (!e)
    throw ot.create("no-app", { appName: t });
  return e;
}
function at(t, e, n) {
  var s;
  let r = (s = og[t]) !== null && s !== void 0 ? s : t;
  n && (r += `-${n}`);
  const i = r.match(/\s|\//), o = e.match(/\s|\//);
  if (i || o) {
    const a = [
      `Unable to register library "${r}" with version "${e}":`
    ];
    i && a.push(`library name "${r}" contains illegal characters (whitespace or "/")`), i && o && a.push("and"), o && a.push(`version name "${e}" contains illegal characters (whitespace or "/")`), Dt.warn(a.join(" "));
    return;
  }
  en(new kt(
    `${r}-version`,
    () => ({ library: r, version: e }),
    "VERSION"
    /* ComponentType.VERSION */
  ));
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const lg = "firebase-heartbeat-database", hg = 1, Pn = "firebase-heartbeat-store";
let ii = null;
function su() {
  return ii || (ii = Rp(lg, hg, {
    upgrade: (t, e) => {
      switch (e) {
        case 0:
          t.createObjectStore(Pn);
      }
    }
  }).catch((t) => {
    throw ot.create("idb-open", {
      originalErrorMessage: t.message
    });
  })), ii;
}
async function dg(t) {
  try {
    return (await su()).transaction(Pn).objectStore(Pn).get(ru(t));
  } catch (e) {
    if (e instanceof Xe)
      Dt.warn(e.message);
    else {
      const n = ot.create("idb-get", {
        originalErrorMessage: e?.message
      });
      Dt.warn(n.message);
    }
  }
}
async function Ia(t, e) {
  try {
    const s = (await su()).transaction(Pn, "readwrite");
    return await s.objectStore(Pn).put(e, ru(t)), s.done;
  } catch (n) {
    if (n instanceof Xe)
      Dt.warn(n.message);
    else {
      const s = ot.create("idb-set", {
        originalErrorMessage: n?.message
      });
      Dt.warn(s.message);
    }
  }
}
function ru(t) {
  return `${t.name}!${t.options.appId}`;
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const fg = 1024, pg = 30 * 24 * 60 * 60 * 1e3;
class gg {
  constructor(e) {
    this.container = e, this._heartbeatsCache = null;
    const n = this.container.getProvider("app").getImmediate();
    this._storage = new yg(n), this._heartbeatsCachePromise = this._storage.read().then((s) => (this._heartbeatsCache = s, s));
  }
  /**
   * Called to report a heartbeat. The function will generate
   * a HeartbeatsByUserAgent object, update heartbeatsCache, and persist it
   * to IndexedDB.
   * Note that we only store one heartbeat per day. So if a heartbeat for today is
   * already logged, subsequent calls to this function in the same day will be ignored.
   */
  async triggerHeartbeat() {
    const n = this.container.getProvider("platform-logger").getImmediate().getPlatformInfoString(), s = ba();
    if (this._heartbeatsCache === null && (this._heartbeatsCache = await this._heartbeatsCachePromise), !(this._heartbeatsCache.lastSentHeartbeatDate === s || this._heartbeatsCache.heartbeats.some((r) => r.date === s)))
      return this._heartbeatsCache.heartbeats.push({ date: s, agent: n }), this._heartbeatsCache.heartbeats = this._heartbeatsCache.heartbeats.filter((r) => {
        const i = new Date(r.date).valueOf();
        return Date.now() - i <= pg;
      }), this._storage.overwrite(this._heartbeatsCache);
  }
  /**
   * Returns a base64 encoded string which can be attached to the heartbeat-specific header directly.
   * It also clears all heartbeats from memory as well as in IndexedDB.
   *
   * NOTE: Consuming product SDKs should not send the header if this method
   * returns an empty string.
   */
  async getHeartbeatsHeader() {
    if (this._heartbeatsCache === null && await this._heartbeatsCachePromise, this._heartbeatsCache === null || this._heartbeatsCache.heartbeats.length === 0)
      return "";
    const e = ba(), { heartbeatsToSend: n, unsentEntries: s } = mg(this._heartbeatsCache.heartbeats), r = Bs(JSON.stringify({ version: 2, heartbeats: n }));
    return this._heartbeatsCache.lastSentHeartbeatDate = e, s.length > 0 ? (this._heartbeatsCache.heartbeats = s, await this._storage.overwrite(this._heartbeatsCache)) : (this._heartbeatsCache.heartbeats = [], this._storage.overwrite(this._heartbeatsCache)), r;
  }
}
function ba() {
  return new Date().toISOString().substring(0, 10);
}
function mg(t, e = fg) {
  const n = [];
  let s = t.slice();
  for (const r of t) {
    const i = n.find((o) => o.agent === r.agent);
    if (i) {
      if (i.dates.push(r.date), Aa(n) > e) {
        i.dates.pop();
        break;
      }
    } else if (n.push({
      agent: r.agent,
      dates: [r.date]
    }), Aa(n) > e) {
      n.pop();
      break;
    }
    s = s.slice(1);
  }
  return {
    heartbeatsToSend: n,
    unsentEntries: s
  };
}
class yg {
  constructor(e) {
    this.app = e, this._canUseIndexedDBPromise = this.runIndexedDBEnvironmentCheck();
  }
  async runIndexedDBEnvironmentCheck() {
    return ip() ? op().then(() => !0).catch(() => !1) : !1;
  }
  /**
   * Read all heartbeats.
   */
  async read() {
    return await this._canUseIndexedDBPromise ? await dg(this.app) || { heartbeats: [] } : { heartbeats: [] };
  }
  // overwrite the storage with the provided heartbeats
  async overwrite(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return Ia(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: e.heartbeats
      });
    } else
      return;
  }
  // add heartbeats
  async add(e) {
    var n;
    if (await this._canUseIndexedDBPromise) {
      const r = await this.read();
      return Ia(this.app, {
        lastSentHeartbeatDate: (n = e.lastSentHeartbeatDate) !== null && n !== void 0 ? n : r.lastSentHeartbeatDate,
        heartbeats: [
          ...r.heartbeats,
          ...e.heartbeats
        ]
      });
    } else
      return;
  }
}
function Aa(t) {
  return Bs(
    // heartbeatsCache wrapper properties
    JSON.stringify({ version: 2, heartbeats: t })
  ).length;
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function _g(t) {
  en(new kt(
    "platform-logger",
    (e) => new Pp(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), en(new kt(
    "heartbeat",
    (e) => new gg(e),
    "PRIVATE"
    /* ComponentType.PRIVATE */
  )), at(Ti, Sa, t), at(Ti, Sa, "esm2017"), at("fire-js", "");
}
_g("");
var wg = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, E, ao = ao || {}, N = wg || self;
function Vs() {
}
function hr(t) {
  var e = typeof t;
  return e = e != "object" ? e : t ? Array.isArray(t) ? "array" : e : "null", e == "array" || e == "object" && typeof t.length == "number";
}
function Yn(t) {
  var e = typeof t;
  return e == "object" && t != null || e == "function";
}
function Eg(t) {
  return Object.prototype.hasOwnProperty.call(t, oi) && t[oi] || (t[oi] = ++vg);
}
var oi = "closure_uid_" + (1e9 * Math.random() >>> 0), vg = 0;
function Tg(t, e, n) {
  return t.call.apply(t.bind, arguments);
}
function Sg(t, e, n) {
  if (!t)
    throw Error();
  if (2 < arguments.length) {
    var s = Array.prototype.slice.call(arguments, 2);
    return function() {
      var r = Array.prototype.slice.call(arguments);
      return Array.prototype.unshift.apply(r, s), t.apply(e, r);
    };
  }
  return function() {
    return t.apply(e, arguments);
  };
}
function pe(t, e, n) {
  return Function.prototype.bind && Function.prototype.bind.toString().indexOf("native code") != -1 ? pe = Tg : pe = Sg, pe.apply(null, arguments);
}
function vs(t, e) {
  var n = Array.prototype.slice.call(arguments, 1);
  return function() {
    var s = n.slice();
    return s.push.apply(s, arguments), t.apply(this, s);
  };
}
function ue(t, e) {
  function n() {
  }
  n.prototype = e.prototype, t.X = e.prototype, t.prototype = new n(), t.prototype.constructor = t, t.Wb = function(s, r, i) {
    for (var o = Array(arguments.length - 2), a = 2; a < arguments.length; a++)
      o[a - 2] = arguments[a];
    return e.prototype[r].apply(s, o);
  };
}
function pt() {
  this.s = this.s, this.o = this.o;
}
var Ig = 0;
pt.prototype.s = !1;
pt.prototype.na = function() {
  !this.s && (this.s = !0, this.M(), Ig != 0) && Eg(this);
};
pt.prototype.M = function() {
  if (this.o)
    for (; this.o.length; )
      this.o.shift()();
};
const iu = Array.prototype.indexOf ? function(t, e) {
  return Array.prototype.indexOf.call(t, e, void 0);
} : function(t, e) {
  if (typeof t == "string")
    return typeof e != "string" || e.length != 1 ? -1 : t.indexOf(e, 0);
  for (let n = 0; n < t.length; n++)
    if (n in t && t[n] === e)
      return n;
  return -1;
};
function co(t) {
  const e = t.length;
  if (0 < e) {
    const n = Array(e);
    for (let s = 0; s < e; s++)
      n[s] = t[s];
    return n;
  }
  return [];
}
function Ca(t, e) {
  for (let n = 1; n < arguments.length; n++) {
    const s = arguments[n];
    if (hr(s)) {
      const r = t.length || 0, i = s.length || 0;
      t.length = r + i;
      for (let o = 0; o < i; o++)
        t[r + o] = s[o];
    } else
      t.push(s);
  }
}
function ge(t, e) {
  this.type = t, this.g = this.target = e, this.defaultPrevented = !1;
}
ge.prototype.h = function() {
  this.defaultPrevented = !0;
};
var bg = function() {
  if (!N.addEventListener || !Object.defineProperty)
    return !1;
  var t = !1, e = Object.defineProperty({}, "passive", { get: function() {
    t = !0;
  } });
  try {
    N.addEventListener("test", Vs, e), N.removeEventListener("test", Vs, e);
  } catch {
  }
  return t;
}();
function js(t) {
  return /^[\s\xa0]*$/.test(t);
}
var Na = String.prototype.trim ? function(t) {
  return t.trim();
} : function(t) {
  return /^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(t)[1];
};
function ai(t, e) {
  return t < e ? -1 : t > e ? 1 : 0;
}
function dr() {
  var t = N.navigator;
  return t && (t = t.userAgent) ? t : "";
}
function Fe(t) {
  return dr().indexOf(t) != -1;
}
function uo(t) {
  return uo[" "](t), t;
}
uo[" "] = Vs;
function Ag(t) {
  var e = Og;
  return Object.prototype.hasOwnProperty.call(e, 9) ? e[9] : e[9] = t(9);
}
var Cg = Fe("Opera"), tn = Fe("Trident") || Fe("MSIE"), ou = Fe("Edge"), bi = ou || tn, au = Fe("Gecko") && !(dr().toLowerCase().indexOf("webkit") != -1 && !Fe("Edge")) && !(Fe("Trident") || Fe("MSIE")) && !Fe("Edge"), Ng = dr().toLowerCase().indexOf("webkit") != -1 && !Fe("Edge");
function cu() {
  var t = N.document;
  return t ? t.documentMode : void 0;
}
var Gs;
e: {
  var ci = "", ui = function() {
    var t = dr();
    if (au)
      return /rv:([^\);]+)(\)|;)/.exec(t);
    if (ou)
      return /Edge\/([\d\.]+)/.exec(t);
    if (tn)
      return /\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(t);
    if (Ng)
      return /WebKit\/(\S+)/.exec(t);
    if (Cg)
      return /(?:Version)[ \/]?(\S+)/.exec(t);
  }();
  if (ui && (ci = ui ? ui[1] : ""), tn) {
    var li = cu();
    if (li != null && li > parseFloat(ci)) {
      Gs = String(li);
      break e;
    }
  }
  Gs = ci;
}
var Og = {};
function Rg() {
  return Ag(function() {
    let t = 0;
    const e = Na(String(Gs)).split("."), n = Na("9").split("."), s = Math.max(e.length, n.length);
    for (let o = 0; t == 0 && o < s; o++) {
      var r = e[o] || "", i = n[o] || "";
      do {
        if (r = /(\d*)(\D*)(.*)/.exec(r) || ["", "", "", ""], i = /(\d*)(\D*)(.*)/.exec(i) || ["", "", "", ""], r[0].length == 0 && i[0].length == 0)
          break;
        t = ai(r[1].length == 0 ? 0 : parseInt(r[1], 10), i[1].length == 0 ? 0 : parseInt(i[1], 10)) || ai(r[2].length == 0, i[2].length == 0) || ai(r[2], i[2]), r = r[3], i = i[3];
      } while (t == 0);
    }
    return 0 <= t;
  });
}
var Ai;
if (N.document && tn) {
  var Oa = cu();
  Ai = Oa || parseInt(Gs, 10) || void 0;
} else
  Ai = void 0;
var kg = Ai;
function Ln(t, e) {
  if (ge.call(this, t ? t.type : ""), this.relatedTarget = this.g = this.target = null, this.button = this.screenY = this.screenX = this.clientY = this.clientX = 0, this.key = "", this.metaKey = this.shiftKey = this.altKey = this.ctrlKey = !1, this.state = null, this.pointerId = 0, this.pointerType = "", this.i = null, t) {
    var n = this.type = t.type, s = t.changedTouches && t.changedTouches.length ? t.changedTouches[0] : null;
    if (this.target = t.target || t.srcElement, this.g = e, e = t.relatedTarget) {
      if (au) {
        e: {
          try {
            uo(e.nodeName);
            var r = !0;
            break e;
          } catch {
          }
          r = !1;
        }
        r || (e = null);
      }
    } else
      n == "mouseover" ? e = t.fromElement : n == "mouseout" && (e = t.toElement);
    this.relatedTarget = e, s ? (this.clientX = s.clientX !== void 0 ? s.clientX : s.pageX, this.clientY = s.clientY !== void 0 ? s.clientY : s.pageY, this.screenX = s.screenX || 0, this.screenY = s.screenY || 0) : (this.clientX = t.clientX !== void 0 ? t.clientX : t.pageX, this.clientY = t.clientY !== void 0 ? t.clientY : t.pageY, this.screenX = t.screenX || 0, this.screenY = t.screenY || 0), this.button = t.button, this.key = t.key || "", this.ctrlKey = t.ctrlKey, this.altKey = t.altKey, this.shiftKey = t.shiftKey, this.metaKey = t.metaKey, this.pointerId = t.pointerId || 0, this.pointerType = typeof t.pointerType == "string" ? t.pointerType : Dg[t.pointerType] || "", this.state = t.state, this.i = t, t.defaultPrevented && Ln.X.h.call(this);
  }
}
ue(Ln, ge);
var Dg = { 2: "touch", 3: "pen", 4: "mouse" };
Ln.prototype.h = function() {
  Ln.X.h.call(this);
  var t = this.i;
  t.preventDefault ? t.preventDefault() : t.returnValue = !1;
};
var Xn = "closure_listenable_" + (1e6 * Math.random() | 0), Pg = 0;
function Lg(t, e, n, s, r) {
  this.listener = t, this.proxy = null, this.src = e, this.type = n, this.capture = !!s, this.ha = r, this.key = ++Pg, this.ba = this.ea = !1;
}
function fr(t) {
  t.ba = !0, t.listener = null, t.proxy = null, t.src = null, t.ha = null;
}
function lo(t, e, n) {
  for (const s in t)
    e.call(n, t[s], s, t);
}
function uu(t) {
  const e = {};
  for (const n in t)
    e[n] = t[n];
  return e;
}
const Ra = "constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");
function lu(t, e) {
  let n, s;
  for (let r = 1; r < arguments.length; r++) {
    s = arguments[r];
    for (n in s)
      t[n] = s[n];
    for (let i = 0; i < Ra.length; i++)
      n = Ra[i], Object.prototype.hasOwnProperty.call(s, n) && (t[n] = s[n]);
  }
}
function pr(t) {
  this.src = t, this.g = {}, this.h = 0;
}
pr.prototype.add = function(t, e, n, s, r) {
  var i = t.toString();
  t = this.g[i], t || (t = this.g[i] = [], this.h++);
  var o = Ni(t, e, s, r);
  return -1 < o ? (e = t[o], n || (e.ea = !1)) : (e = new Lg(e, this.src, i, !!s, r), e.ea = n, t.push(e)), e;
};
function Ci(t, e) {
  var n = e.type;
  if (n in t.g) {
    var s = t.g[n], r = iu(s, e), i;
    (i = 0 <= r) && Array.prototype.splice.call(s, r, 1), i && (fr(e), t.g[n].length == 0 && (delete t.g[n], t.h--));
  }
}
function Ni(t, e, n, s) {
  for (var r = 0; r < t.length; ++r) {
    var i = t[r];
    if (!i.ba && i.listener == e && i.capture == !!n && i.ha == s)
      return r;
  }
  return -1;
}
var ho = "closure_lm_" + (1e6 * Math.random() | 0), hi = {};
function hu(t, e, n, s, r) {
  if (s && s.once)
    return fu(t, e, n, s, r);
  if (Array.isArray(e)) {
    for (var i = 0; i < e.length; i++)
      hu(t, e[i], n, s, r);
    return null;
  }
  return n = go(n), t && t[Xn] ? t.N(e, n, Yn(s) ? !!s.capture : !!s, r) : du(t, e, n, !1, s, r);
}
function du(t, e, n, s, r, i) {
  if (!e)
    throw Error("Invalid event type");
  var o = Yn(r) ? !!r.capture : !!r, a = po(t);
  if (a || (t[ho] = a = new pr(t)), n = a.add(e, n, s, o, i), n.proxy)
    return n;
  if (s = Fg(), n.proxy = s, s.src = t, s.listener = n, t.addEventListener)
    bg || (r = o), r === void 0 && (r = !1), t.addEventListener(e.toString(), s, r);
  else if (t.attachEvent)
    t.attachEvent(gu(e.toString()), s);
  else if (t.addListener && t.removeListener)
    t.addListener(s);
  else
    throw Error("addEventListener and attachEvent are unavailable.");
  return n;
}
function Fg() {
  function t(n) {
    return e.call(t.src, t.listener, n);
  }
  const e = Mg;
  return t;
}
function fu(t, e, n, s, r) {
  if (Array.isArray(e)) {
    for (var i = 0; i < e.length; i++)
      fu(t, e[i], n, s, r);
    return null;
  }
  return n = go(n), t && t[Xn] ? t.O(e, n, Yn(s) ? !!s.capture : !!s, r) : du(t, e, n, !0, s, r);
}
function pu(t, e, n, s, r) {
  if (Array.isArray(e))
    for (var i = 0; i < e.length; i++)
      pu(t, e[i], n, s, r);
  else
    s = Yn(s) ? !!s.capture : !!s, n = go(n), t && t[Xn] ? (t = t.i, e = String(e).toString(), e in t.g && (i = t.g[e], n = Ni(i, n, s, r), -1 < n && (fr(i[n]), Array.prototype.splice.call(i, n, 1), i.length == 0 && (delete t.g[e], t.h--)))) : t && (t = po(t)) && (e = t.g[e.toString()], t = -1, e && (t = Ni(e, n, s, r)), (n = -1 < t ? e[t] : null) && fo(n));
}
function fo(t) {
  if (typeof t != "number" && t && !t.ba) {
    var e = t.src;
    if (e && e[Xn])
      Ci(e.i, t);
    else {
      var n = t.type, s = t.proxy;
      e.removeEventListener ? e.removeEventListener(n, s, t.capture) : e.detachEvent ? e.detachEvent(gu(n), s) : e.addListener && e.removeListener && e.removeListener(s), (n = po(e)) ? (Ci(n, t), n.h == 0 && (n.src = null, e[ho] = null)) : fr(t);
    }
  }
}
function gu(t) {
  return t in hi ? hi[t] : hi[t] = "on" + t;
}
function Mg(t, e) {
  if (t.ba)
    t = !0;
  else {
    e = new Ln(e, this);
    var n = t.listener, s = t.ha || t.src;
    t.ea && fo(t), t = n.call(s, e);
  }
  return t;
}
function po(t) {
  return t = t[ho], t instanceof pr ? t : null;
}
var di = "__closure_events_fn_" + (1e9 * Math.random() >>> 0);
function go(t) {
  return typeof t == "function" ? t : (t[di] || (t[di] = function(e) {
    return t.handleEvent(e);
  }), t[di]);
}
function se() {
  pt.call(this), this.i = new pr(this), this.P = this, this.I = null;
}
ue(se, pt);
se.prototype[Xn] = !0;
se.prototype.removeEventListener = function(t, e, n, s) {
  pu(this, t, e, n, s);
};
function ae(t, e) {
  var n, s = t.I;
  if (s)
    for (n = []; s; s = s.I)
      n.push(s);
  if (t = t.P, s = e.type || e, typeof e == "string")
    e = new ge(e, t);
  else if (e instanceof ge)
    e.target = e.target || t;
  else {
    var r = e;
    e = new ge(s, t), lu(e, r);
  }
  if (r = !0, n)
    for (var i = n.length - 1; 0 <= i; i--) {
      var o = e.g = n[i];
      r = Ts(o, s, !0, e) && r;
    }
  if (o = e.g = t, r = Ts(o, s, !0, e) && r, r = Ts(o, s, !1, e) && r, n)
    for (i = 0; i < n.length; i++)
      o = e.g = n[i], r = Ts(o, s, !1, e) && r;
}
se.prototype.M = function() {
  if (se.X.M.call(this), this.i) {
    var t = this.i, e;
    for (e in t.g) {
      for (var n = t.g[e], s = 0; s < n.length; s++)
        fr(n[s]);
      delete t.g[e], t.h--;
    }
  }
  this.I = null;
};
se.prototype.N = function(t, e, n, s) {
  return this.i.add(String(t), e, !1, n, s);
};
se.prototype.O = function(t, e, n, s) {
  return this.i.add(String(t), e, !0, n, s);
};
function Ts(t, e, n, s) {
  if (e = t.i.g[String(e)], !e)
    return !0;
  e = e.concat();
  for (var r = !0, i = 0; i < e.length; ++i) {
    var o = e[i];
    if (o && !o.ba && o.capture == n) {
      var a = o.listener, c = o.ha || o.src;
      o.ea && Ci(t.i, o), r = a.call(c, s) !== !1 && r;
    }
  }
  return r && !s.defaultPrevented;
}
var mo = N.JSON.stringify;
function $g() {
  var t = _u;
  let e = null;
  return t.g && (e = t.g, t.g = t.g.next, t.g || (t.h = null), e.next = null), e;
}
class Ug {
  constructor() {
    this.h = this.g = null;
  }
  add(e, n) {
    const s = mu.get();
    s.set(e, n), this.h ? this.h.next = s : this.g = s, this.h = s;
  }
}
var mu = new class {
  constructor(t, e) {
    this.i = t, this.j = e, this.h = 0, this.g = null;
  }
  get() {
    let t;
    return 0 < this.h ? (this.h--, t = this.g, this.g = t.next, t.next = null) : t = this.i(), t;
  }
}(() => new xg(), (t) => t.reset());
class xg {
  constructor() {
    this.next = this.g = this.h = null;
  }
  set(e, n) {
    this.h = e, this.g = n, this.next = null;
  }
  reset() {
    this.next = this.g = this.h = null;
  }
}
function Bg(t) {
  N.setTimeout(() => {
    throw t;
  }, 0);
}
function yu(t, e) {
  Oi || qg(), Ri || (Oi(), Ri = !0), _u.add(t, e);
}
var Oi;
function qg() {
  var t = N.Promise.resolve(void 0);
  Oi = function() {
    t.then(Vg);
  };
}
var Ri = !1, _u = new Ug();
function Vg() {
  for (var t; t = $g(); ) {
    try {
      t.h.call(t.g);
    } catch (n) {
      Bg(n);
    }
    var e = mu;
    e.j(t), 100 > e.h && (e.h++, t.next = e.g, e.g = t);
  }
  Ri = !1;
}
function gr(t, e) {
  se.call(this), this.h = t || 1, this.g = e || N, this.j = pe(this.lb, this), this.l = Date.now();
}
ue(gr, se);
E = gr.prototype;
E.ca = !1;
E.R = null;
E.lb = function() {
  if (this.ca) {
    var t = Date.now() - this.l;
    0 < t && t < 0.8 * this.h ? this.R = this.g.setTimeout(this.j, this.h - t) : (this.R && (this.g.clearTimeout(this.R), this.R = null), ae(this, "tick"), this.ca && (yo(this), this.start()));
  }
};
E.start = function() {
  this.ca = !0, this.R || (this.R = this.g.setTimeout(this.j, this.h), this.l = Date.now());
};
function yo(t) {
  t.ca = !1, t.R && (t.g.clearTimeout(t.R), t.R = null);
}
E.M = function() {
  gr.X.M.call(this), yo(this), delete this.g;
};
function _o(t, e, n) {
  if (typeof t == "function")
    n && (t = pe(t, n));
  else if (t && typeof t.handleEvent == "function")
    t = pe(t.handleEvent, t);
  else
    throw Error("Invalid listener argument");
  return 2147483647 < Number(e) ? -1 : N.setTimeout(t, e || 0);
}
function wu(t) {
  t.g = _o(() => {
    t.g = null, t.i && (t.i = !1, wu(t));
  }, t.j);
  const e = t.h;
  t.h = null, t.m.apply(null, e);
}
class jg extends pt {
  constructor(e, n) {
    super(), this.m = e, this.j = n, this.h = null, this.i = !1, this.g = null;
  }
  l(e) {
    this.h = arguments, this.g ? this.i = !0 : wu(this);
  }
  M() {
    super.M(), this.g && (N.clearTimeout(this.g), this.g = null, this.i = !1, this.h = null);
  }
}
function Fn(t) {
  pt.call(this), this.h = t, this.g = {};
}
ue(Fn, pt);
var ka = [];
function Eu(t, e, n, s) {
  Array.isArray(n) || (n && (ka[0] = n.toString()), n = ka);
  for (var r = 0; r < n.length; r++) {
    var i = hu(e, n[r], s || t.handleEvent, !1, t.h || t);
    if (!i)
      break;
    t.g[i.key] = i;
  }
}
function vu(t) {
  lo(t.g, function(e, n) {
    this.g.hasOwnProperty(n) && fo(e);
  }, t), t.g = {};
}
Fn.prototype.M = function() {
  Fn.X.M.call(this), vu(this);
};
Fn.prototype.handleEvent = function() {
  throw Error("EventHandler.handleEvent not implemented");
};
function mr() {
  this.g = !0;
}
mr.prototype.Aa = function() {
  this.g = !1;
};
function Gg(t, e, n, s, r, i) {
  t.info(function() {
    if (t.g)
      if (i)
        for (var o = "", a = i.split("&"), c = 0; c < a.length; c++) {
          var u = a[c].split("=");
          if (1 < u.length) {
            var l = u[0];
            u = u[1];
            var h = l.split("_");
            o = 2 <= h.length && h[1] == "type" ? o + (l + "=" + u + "&") : o + (l + "=redacted&");
          }
        }
      else
        o = null;
    else
      o = i;
    return "XMLHTTP REQ (" + s + ") [attempt " + r + "]: " + e + `
` + n + `
` + o;
  });
}
function Wg(t, e, n, s, r, i, o) {
  t.info(function() {
    return "XMLHTTP RESP (" + s + ") [ attempt " + r + "]: " + e + `
` + n + `
` + i + " " + o;
  });
}
function Wt(t, e, n, s) {
  t.info(function() {
    return "XMLHTTP TEXT (" + e + "): " + zg(t, n) + (s ? " " + s : "");
  });
}
function Hg(t, e) {
  t.info(function() {
    return "TIMEOUT: " + e;
  });
}
mr.prototype.info = function() {
};
function zg(t, e) {
  if (!t.g)
    return e;
  if (!e)
    return null;
  try {
    var n = JSON.parse(e);
    if (n) {
      for (t = 0; t < n.length; t++)
        if (Array.isArray(n[t])) {
          var s = n[t];
          if (!(2 > s.length)) {
            var r = s[1];
            if (Array.isArray(r) && !(1 > r.length)) {
              var i = r[0];
              if (i != "noop" && i != "stop" && i != "close")
                for (var o = 1; o < r.length; o++)
                  r[o] = "";
            }
          }
        }
    }
    return mo(n);
  } catch {
    return e;
  }
}
var Ut = {}, Da = null;
function yr() {
  return Da = Da || new se();
}
Ut.Pa = "serverreachability";
function Tu(t) {
  ge.call(this, Ut.Pa, t);
}
ue(Tu, ge);
function Mn(t) {
  const e = yr();
  ae(e, new Tu(e));
}
Ut.STAT_EVENT = "statevent";
function Su(t, e) {
  ge.call(this, Ut.STAT_EVENT, t), this.stat = e;
}
ue(Su, ge);
function _e(t) {
  const e = yr();
  ae(e, new Su(e, t));
}
Ut.Qa = "timingevent";
function Iu(t, e) {
  ge.call(this, Ut.Qa, t), this.size = e;
}
ue(Iu, ge);
function Zn(t, e) {
  if (typeof t != "function")
    throw Error("Fn must not be null and must be a function");
  return N.setTimeout(function() {
    t();
  }, e);
}
var _r = { NO_ERROR: 0, mb: 1, zb: 2, yb: 3, tb: 4, xb: 5, Ab: 6, Ma: 7, TIMEOUT: 8, Db: 9 }, bu = { rb: "complete", Nb: "success", Na: "error", Ma: "abort", Fb: "ready", Gb: "readystatechange", TIMEOUT: "timeout", Bb: "incrementaldata", Eb: "progress", ub: "downloadprogress", Vb: "uploadprogress" };
function wo() {
}
wo.prototype.h = null;
function Pa(t) {
  return t.h || (t.h = t.i());
}
function Au() {
}
var es = { OPEN: "a", qb: "b", Na: "c", Cb: "d" };
function Eo() {
  ge.call(this, "d");
}
ue(Eo, ge);
function vo() {
  ge.call(this, "c");
}
ue(vo, ge);
var ki;
function wr() {
}
ue(wr, wo);
wr.prototype.g = function() {
  return new XMLHttpRequest();
};
wr.prototype.i = function() {
  return {};
};
ki = new wr();
function ts(t, e, n, s) {
  this.l = t, this.j = e, this.m = n, this.U = s || 1, this.S = new Fn(this), this.O = Qg, t = bi ? 125 : void 0, this.T = new gr(t), this.H = null, this.i = !1, this.s = this.A = this.v = this.K = this.F = this.V = this.B = null, this.D = [], this.g = null, this.C = 0, this.o = this.u = null, this.Y = -1, this.I = !1, this.N = 0, this.L = null, this.$ = this.J = this.Z = this.P = !1, this.h = new Cu();
}
function Cu() {
  this.i = null, this.g = "", this.h = !1;
}
var Qg = 45e3, Di = {}, Ws = {};
E = ts.prototype;
E.setTimeout = function(t) {
  this.O = t;
};
function Pi(t, e, n) {
  t.K = 1, t.v = vr(ze(e)), t.s = n, t.P = !0, Nu(t, null);
}
function Nu(t, e) {
  t.F = Date.now(), ns(t), t.A = ze(t.v);
  var n = t.A, s = t.U;
  Array.isArray(s) || (s = [String(s)]), Mu(n.i, "t", s), t.C = 0, n = t.l.H, t.h = new Cu(), t.g = sl(t.l, n ? e : null, !t.s), 0 < t.N && (t.L = new jg(pe(t.La, t, t.g), t.N)), Eu(t.S, t.g, "readystatechange", t.ib), e = t.H ? uu(t.H) : {}, t.s ? (t.u || (t.u = "POST"), e["Content-Type"] = "application/x-www-form-urlencoded", t.g.da(t.A, t.u, t.s, e)) : (t.u = "GET", t.g.da(t.A, t.u, null, e)), Mn(), Gg(t.j, t.u, t.A, t.m, t.U, t.s);
}
E.ib = function(t) {
  t = t.target;
  const e = this.L;
  e && Ge(t) == 3 ? e.l() : this.La(t);
};
E.La = function(t) {
  try {
    if (t == this.g)
      e: {
        const l = Ge(this.g);
        var e = this.g.Ea();
        const h = this.g.aa();
        if (!(3 > l) && (l != 3 || bi || this.g && (this.h.h || this.g.fa() || $a(this.g)))) {
          this.I || l != 4 || e == 7 || (e == 8 || 0 >= h ? Mn(3) : Mn(2)), Er(this);
          var n = this.g.aa();
          this.Y = n;
          t:
            if (Ou(this)) {
              var s = $a(this.g);
              t = "";
              var r = s.length, i = Ge(this.g) == 4;
              if (!this.h.i) {
                if (typeof TextDecoder > "u") {
                  It(this), bn(this);
                  var o = "";
                  break t;
                }
                this.h.i = new N.TextDecoder();
              }
              for (e = 0; e < r; e++)
                this.h.h = !0, t += this.h.i.decode(s[e], { stream: i && e == r - 1 });
              s.splice(
                0,
                r
              ), this.h.g += t, this.C = 0, o = this.h.g;
            } else
              o = this.g.fa();
          if (this.i = n == 200, Wg(this.j, this.u, this.A, this.m, this.U, l, n), this.i) {
            if (this.Z && !this.J) {
              t: {
                if (this.g) {
                  var a, c = this.g;
                  if ((a = c.g ? c.g.getResponseHeader("X-HTTP-Initial-Response") : null) && !js(a)) {
                    var u = a;
                    break t;
                  }
                }
                u = null;
              }
              if (n = u)
                Wt(this.j, this.m, n, "Initial handshake response via X-HTTP-Initial-Response"), this.J = !0, Li(this, n);
              else {
                this.i = !1, this.o = 3, _e(12), It(this), bn(this);
                break e;
              }
            }
            this.P ? (Ru(this, l, o), bi && this.i && l == 3 && (Eu(this.S, this.T, "tick", this.hb), this.T.start())) : (Wt(this.j, this.m, o, null), Li(this, o)), l == 4 && It(this), this.i && !this.I && (l == 4 ? Zu(this.l, this) : (this.i = !1, ns(this)));
          } else
            n == 400 && 0 < o.indexOf("Unknown SID") ? (this.o = 3, _e(12)) : (this.o = 0, _e(13)), It(this), bn(this);
        }
      }
  } catch {
  } finally {
  }
};
function Ou(t) {
  return t.g ? t.u == "GET" && t.K != 2 && t.l.Da : !1;
}
function Ru(t, e, n) {
  let s = !0, r;
  for (; !t.I && t.C < n.length; )
    if (r = Kg(t, n), r == Ws) {
      e == 4 && (t.o = 4, _e(14), s = !1), Wt(t.j, t.m, null, "[Incomplete Response]");
      break;
    } else if (r == Di) {
      t.o = 4, _e(15), Wt(t.j, t.m, n, "[Invalid Chunk]"), s = !1;
      break;
    } else
      Wt(t.j, t.m, r, null), Li(t, r);
  Ou(t) && r != Ws && r != Di && (t.h.g = "", t.C = 0), e != 4 || n.length != 0 || t.h.h || (t.o = 1, _e(16), s = !1), t.i = t.i && s, s ? 0 < n.length && !t.$ && (t.$ = !0, e = t.l, e.g == t && e.$ && !e.K && (e.j.info("Great, no buffering proxy detected. Bytes received: " + n.length), No(e), e.K = !0, _e(11))) : (Wt(
    t.j,
    t.m,
    n,
    "[Invalid Chunked Response]"
  ), It(t), bn(t));
}
E.hb = function() {
  if (this.g) {
    var t = Ge(this.g), e = this.g.fa();
    this.C < e.length && (Er(this), Ru(this, t, e), this.i && t != 4 && ns(this));
  }
};
function Kg(t, e) {
  var n = t.C, s = e.indexOf(`
`, n);
  return s == -1 ? Ws : (n = Number(e.substring(n, s)), isNaN(n) ? Di : (s += 1, s + n > e.length ? Ws : (e = e.substr(s, n), t.C = s + n, e)));
}
E.cancel = function() {
  this.I = !0, It(this);
};
function ns(t) {
  t.V = Date.now() + t.O, ku(t, t.O);
}
function ku(t, e) {
  if (t.B != null)
    throw Error("WatchDog timer not null");
  t.B = Zn(pe(t.gb, t), e);
}
function Er(t) {
  t.B && (N.clearTimeout(t.B), t.B = null);
}
E.gb = function() {
  this.B = null;
  const t = Date.now();
  0 <= t - this.V ? (Hg(this.j, this.A), this.K != 2 && (Mn(), _e(17)), It(this), this.o = 2, bn(this)) : ku(this, this.V - t);
};
function bn(t) {
  t.l.G == 0 || t.I || Zu(t.l, t);
}
function It(t) {
  Er(t);
  var e = t.L;
  e && typeof e.na == "function" && e.na(), t.L = null, yo(t.T), vu(t.S), t.g && (e = t.g, t.g = null, e.abort(), e.na());
}
function Li(t, e) {
  try {
    var n = t.l;
    if (n.G != 0 && (n.g == t || Fi(n.h, t))) {
      if (!t.J && Fi(n.h, t) && n.G == 3) {
        try {
          var s = n.Fa.g.parse(e);
        } catch {
          s = null;
        }
        if (Array.isArray(s) && s.length == 3) {
          var r = s;
          if (r[0] == 0) {
            e:
              if (!n.u) {
                if (n.g)
                  if (n.g.F + 3e3 < t.F)
                    Qs(n), Ir(n);
                  else
                    break e;
                Co(n), _e(18);
              }
          } else
            n.Ba = r[1], 0 < n.Ba - n.T && 37500 > r[2] && n.L && n.A == 0 && !n.v && (n.v = Zn(pe(n.cb, n), 6e3));
          if (1 >= xu(n.h) && n.ja) {
            try {
              n.ja();
            } catch {
            }
            n.ja = void 0;
          }
        } else
          bt(n, 11);
      } else if ((t.J || n.g == t) && Qs(n), !js(e))
        for (r = n.Fa.g.parse(e), e = 0; e < r.length; e++) {
          let u = r[e];
          if (n.T = u[0], u = u[1], n.G == 2)
            if (u[0] == "c") {
              n.I = u[1], n.ka = u[2];
              const l = u[3];
              l != null && (n.ma = l, n.j.info("VER=" + n.ma));
              const h = u[4];
              h != null && (n.Ca = h, n.j.info("SVER=" + n.Ca));
              const d = u[5];
              d != null && typeof d == "number" && 0 < d && (s = 1.5 * d, n.J = s, n.j.info("backChannelRequestTimeoutMs_=" + s)), s = n;
              const p = t.g;
              if (p) {
                const m = p.g ? p.g.getResponseHeader("X-Client-Wire-Protocol") : null;
                if (m) {
                  var i = s.h;
                  i.g || m.indexOf("spdy") == -1 && m.indexOf("quic") == -1 && m.indexOf("h2") == -1 || (i.j = i.l, i.g = /* @__PURE__ */ new Set(), i.h && (To(i, i.h), i.h = null));
                }
                if (s.D) {
                  const S = p.g ? p.g.getResponseHeader("X-HTTP-Session-Id") : null;
                  S && (s.za = S, H(s.F, s.D, S));
                }
              }
              n.G = 3, n.l && n.l.xa(), n.$ && (n.P = Date.now() - t.F, n.j.info("Handshake RTT: " + n.P + "ms")), s = n;
              var o = t;
              if (s.sa = nl(s, s.H ? s.ka : null, s.V), o.J) {
                Bu(s.h, o);
                var a = o, c = s.J;
                c && a.setTimeout(c), a.B && (Er(a), ns(a)), s.g = o;
              } else
                Yu(s);
              0 < n.i.length && br(n);
            } else
              u[0] != "stop" && u[0] != "close" || bt(n, 7);
          else
            n.G == 3 && (u[0] == "stop" || u[0] == "close" ? u[0] == "stop" ? bt(n, 7) : Ao(n) : u[0] != "noop" && n.l && n.l.wa(u), n.A = 0);
        }
    }
    Mn(4);
  } catch {
  }
}
function Jg(t) {
  if (t.W && typeof t.W == "function")
    return t.W();
  if (typeof Map < "u" && t instanceof Map || typeof Set < "u" && t instanceof Set)
    return Array.from(t.values());
  if (typeof t == "string")
    return t.split("");
  if (hr(t)) {
    for (var e = [], n = t.length, s = 0; s < n; s++)
      e.push(t[s]);
    return e;
  }
  e = [], n = 0;
  for (s in t)
    e[n++] = t[s];
  return e;
}
function Yg(t) {
  if (t.oa && typeof t.oa == "function")
    return t.oa();
  if (!t.W || typeof t.W != "function") {
    if (typeof Map < "u" && t instanceof Map)
      return Array.from(t.keys());
    if (!(typeof Set < "u" && t instanceof Set)) {
      if (hr(t) || typeof t == "string") {
        var e = [];
        t = t.length;
        for (var n = 0; n < t; n++)
          e.push(n);
        return e;
      }
      e = [], n = 0;
      for (const s in t)
        e[n++] = s;
      return e;
    }
  }
}
function Du(t, e) {
  if (t.forEach && typeof t.forEach == "function")
    t.forEach(e, void 0);
  else if (hr(t) || typeof t == "string")
    Array.prototype.forEach.call(t, e, void 0);
  else
    for (var n = Yg(t), s = Jg(t), r = s.length, i = 0; i < r; i++)
      e.call(void 0, s[i], n && n[i], t);
}
var Pu = RegExp("^(?:([^:/?#.]+):)?(?://(?:([^\\\\/?#]*)@)?([^\\\\/?#]*?)(?::([0-9]+))?(?=[\\\\/?#]|$))?([^?#]+)?(?:\\?([^#]*))?(?:#([\\s\\S]*))?$");
function Xg(t, e) {
  if (t) {
    t = t.split("&");
    for (var n = 0; n < t.length; n++) {
      var s = t[n].indexOf("="), r = null;
      if (0 <= s) {
        var i = t[n].substring(0, s);
        r = t[n].substring(s + 1);
      } else
        i = t[n];
      e(i, r ? decodeURIComponent(r.replace(/\+/g, " ")) : "");
    }
  }
}
function Ct(t, e) {
  if (this.g = this.s = this.j = "", this.m = null, this.o = this.l = "", this.h = !1, t instanceof Ct) {
    this.h = e !== void 0 ? e : t.h, Hs(this, t.j), this.s = t.s, this.g = t.g, zs(this, t.m), this.l = t.l, e = t.i;
    var n = new $n();
    n.i = e.i, e.g && (n.g = new Map(e.g), n.h = e.h), La(this, n), this.o = t.o;
  } else
    t && (n = String(t).match(Pu)) ? (this.h = !!e, Hs(this, n[1] || "", !0), this.s = vn(n[2] || ""), this.g = vn(n[3] || "", !0), zs(this, n[4]), this.l = vn(n[5] || "", !0), La(this, n[6] || "", !0), this.o = vn(n[7] || "")) : (this.h = !!e, this.i = new $n(null, this.h));
}
Ct.prototype.toString = function() {
  var t = [], e = this.j;
  e && t.push(Tn(e, Fa, !0), ":");
  var n = this.g;
  return (n || e == "file") && (t.push("//"), (e = this.s) && t.push(Tn(e, Fa, !0), "@"), t.push(encodeURIComponent(String(n)).replace(/%25([0-9a-fA-F]{2})/g, "%$1")), n = this.m, n != null && t.push(":", String(n))), (n = this.l) && (this.g && n.charAt(0) != "/" && t.push("/"), t.push(Tn(n, n.charAt(0) == "/" ? tm : em, !0))), (n = this.i.toString()) && t.push("?", n), (n = this.o) && t.push("#", Tn(n, sm)), t.join("");
};
function ze(t) {
  return new Ct(t);
}
function Hs(t, e, n) {
  t.j = n ? vn(e, !0) : e, t.j && (t.j = t.j.replace(/:$/, ""));
}
function zs(t, e) {
  if (e) {
    if (e = Number(e), isNaN(e) || 0 > e)
      throw Error("Bad port number " + e);
    t.m = e;
  } else
    t.m = null;
}
function La(t, e, n) {
  e instanceof $n ? (t.i = e, rm(t.i, t.h)) : (n || (e = Tn(e, nm)), t.i = new $n(e, t.h));
}
function H(t, e, n) {
  t.i.set(e, n);
}
function vr(t) {
  return H(t, "zx", Math.floor(2147483648 * Math.random()).toString(36) + Math.abs(Math.floor(2147483648 * Math.random()) ^ Date.now()).toString(36)), t;
}
function vn(t, e) {
  return t ? e ? decodeURI(t.replace(/%25/g, "%2525")) : decodeURIComponent(t) : "";
}
function Tn(t, e, n) {
  return typeof t == "string" ? (t = encodeURI(t).replace(e, Zg), n && (t = t.replace(/%25([0-9a-fA-F]{2})/g, "%$1")), t) : null;
}
function Zg(t) {
  return t = t.charCodeAt(0), "%" + (t >> 4 & 15).toString(16) + (t & 15).toString(16);
}
var Fa = /[#\/\?@]/g, em = /[#\?:]/g, tm = /[#\?]/g, nm = /[#\?@]/g, sm = /#/g;
function $n(t, e) {
  this.h = this.g = null, this.i = t || null, this.j = !!e;
}
function gt(t) {
  t.g || (t.g = /* @__PURE__ */ new Map(), t.h = 0, t.i && Xg(t.i, function(e, n) {
    t.add(decodeURIComponent(e.replace(/\+/g, " ")), n);
  }));
}
E = $n.prototype;
E.add = function(t, e) {
  gt(this), this.i = null, t = hn(this, t);
  var n = this.g.get(t);
  return n || this.g.set(t, n = []), n.push(e), this.h += 1, this;
};
function Lu(t, e) {
  gt(t), e = hn(t, e), t.g.has(e) && (t.i = null, t.h -= t.g.get(e).length, t.g.delete(e));
}
function Fu(t, e) {
  return gt(t), e = hn(t, e), t.g.has(e);
}
E.forEach = function(t, e) {
  gt(this), this.g.forEach(function(n, s) {
    n.forEach(function(r) {
      t.call(e, r, s, this);
    }, this);
  }, this);
};
E.oa = function() {
  gt(this);
  const t = Array.from(this.g.values()), e = Array.from(this.g.keys()), n = [];
  for (let s = 0; s < e.length; s++) {
    const r = t[s];
    for (let i = 0; i < r.length; i++)
      n.push(e[s]);
  }
  return n;
};
E.W = function(t) {
  gt(this);
  let e = [];
  if (typeof t == "string")
    Fu(this, t) && (e = e.concat(this.g.get(hn(this, t))));
  else {
    t = Array.from(this.g.values());
    for (let n = 0; n < t.length; n++)
      e = e.concat(t[n]);
  }
  return e;
};
E.set = function(t, e) {
  return gt(this), this.i = null, t = hn(this, t), Fu(this, t) && (this.h -= this.g.get(t).length), this.g.set(t, [e]), this.h += 1, this;
};
E.get = function(t, e) {
  return t ? (t = this.W(t), 0 < t.length ? String(t[0]) : e) : e;
};
function Mu(t, e, n) {
  Lu(t, e), 0 < n.length && (t.i = null, t.g.set(hn(t, e), co(n)), t.h += n.length);
}
E.toString = function() {
  if (this.i)
    return this.i;
  if (!this.g)
    return "";
  const t = [], e = Array.from(this.g.keys());
  for (var n = 0; n < e.length; n++) {
    var s = e[n];
    const i = encodeURIComponent(String(s)), o = this.W(s);
    for (s = 0; s < o.length; s++) {
      var r = i;
      o[s] !== "" && (r += "=" + encodeURIComponent(String(o[s]))), t.push(r);
    }
  }
  return this.i = t.join("&");
};
function hn(t, e) {
  return e = String(e), t.j && (e = e.toLowerCase()), e;
}
function rm(t, e) {
  e && !t.j && (gt(t), t.i = null, t.g.forEach(function(n, s) {
    var r = s.toLowerCase();
    s != r && (Lu(this, s), Mu(this, r, n));
  }, t)), t.j = e;
}
var im = class {
  constructor(e, n) {
    this.h = e, this.g = n;
  }
};
function $u(t) {
  this.l = t || om, N.PerformanceNavigationTiming ? (t = N.performance.getEntriesByType("navigation"), t = 0 < t.length && (t[0].nextHopProtocol == "hq" || t[0].nextHopProtocol == "h2")) : t = !!(N.g && N.g.Ga && N.g.Ga() && N.g.Ga().$b), this.j = t ? this.l : 1, this.g = null, 1 < this.j && (this.g = /* @__PURE__ */ new Set()), this.h = null, this.i = [];
}
var om = 10;
function Uu(t) {
  return t.h ? !0 : t.g ? t.g.size >= t.j : !1;
}
function xu(t) {
  return t.h ? 1 : t.g ? t.g.size : 0;
}
function Fi(t, e) {
  return t.h ? t.h == e : t.g ? t.g.has(e) : !1;
}
function To(t, e) {
  t.g ? t.g.add(e) : t.h = e;
}
function Bu(t, e) {
  t.h && t.h == e ? t.h = null : t.g && t.g.has(e) && t.g.delete(e);
}
$u.prototype.cancel = function() {
  if (this.i = qu(this), this.h)
    this.h.cancel(), this.h = null;
  else if (this.g && this.g.size !== 0) {
    for (const t of this.g.values())
      t.cancel();
    this.g.clear();
  }
};
function qu(t) {
  if (t.h != null)
    return t.i.concat(t.h.D);
  if (t.g != null && t.g.size !== 0) {
    let e = t.i;
    for (const n of t.g.values())
      e = e.concat(n.D);
    return e;
  }
  return co(t.i);
}
function So() {
}
So.prototype.stringify = function(t) {
  return N.JSON.stringify(t, void 0);
};
So.prototype.parse = function(t) {
  return N.JSON.parse(t, void 0);
};
function am() {
  this.g = new So();
}
function cm(t, e, n) {
  const s = n || "";
  try {
    Du(t, function(r, i) {
      let o = r;
      Yn(r) && (o = mo(r)), e.push(s + i + "=" + encodeURIComponent(o));
    });
  } catch (r) {
    throw e.push(s + "type=" + encodeURIComponent("_badmap")), r;
  }
}
function um(t, e) {
  const n = new mr();
  if (N.Image) {
    const s = new Image();
    s.onload = vs(Ss, n, s, "TestLoadImage: loaded", !0, e), s.onerror = vs(Ss, n, s, "TestLoadImage: error", !1, e), s.onabort = vs(Ss, n, s, "TestLoadImage: abort", !1, e), s.ontimeout = vs(Ss, n, s, "TestLoadImage: timeout", !1, e), N.setTimeout(function() {
      s.ontimeout && s.ontimeout();
    }, 1e4), s.src = t;
  } else
    e(!1);
}
function Ss(t, e, n, s, r) {
  try {
    e.onload = null, e.onerror = null, e.onabort = null, e.ontimeout = null, r(s);
  } catch {
  }
}
function ss(t) {
  this.l = t.ac || null, this.j = t.jb || !1;
}
ue(ss, wo);
ss.prototype.g = function() {
  return new Tr(this.l, this.j);
};
ss.prototype.i = function(t) {
  return function() {
    return t;
  };
}({});
function Tr(t, e) {
  se.call(this), this.D = t, this.u = e, this.m = void 0, this.readyState = Io, this.status = 0, this.responseType = this.responseText = this.response = this.statusText = "", this.onreadystatechange = null, this.v = new Headers(), this.h = null, this.C = "GET", this.B = "", this.g = !1, this.A = this.j = this.l = null;
}
ue(Tr, se);
var Io = 0;
E = Tr.prototype;
E.open = function(t, e) {
  if (this.readyState != Io)
    throw this.abort(), Error("Error reopening a connection");
  this.C = t, this.B = e, this.readyState = 1, Un(this);
};
E.send = function(t) {
  if (this.readyState != 1)
    throw this.abort(), Error("need to call open() first. ");
  this.g = !0;
  const e = { headers: this.v, method: this.C, credentials: this.m, cache: void 0 };
  t && (e.body = t), (this.D || N).fetch(new Request(this.B, e)).then(this.Wa.bind(this), this.ga.bind(this));
};
E.abort = function() {
  this.response = this.responseText = "", this.v = new Headers(), this.status = 0, this.j && this.j.cancel("Request was aborted.").catch(() => {
  }), 1 <= this.readyState && this.g && this.readyState != 4 && (this.g = !1, rs(this)), this.readyState = Io;
};
E.Wa = function(t) {
  if (this.g && (this.l = t, this.h || (this.status = this.l.status, this.statusText = this.l.statusText, this.h = t.headers, this.readyState = 2, Un(this)), this.g && (this.readyState = 3, Un(this), this.g)))
    if (this.responseType === "arraybuffer")
      t.arrayBuffer().then(this.Ua.bind(this), this.ga.bind(this));
    else if (typeof N.ReadableStream < "u" && "body" in t) {
      if (this.j = t.body.getReader(), this.u) {
        if (this.responseType)
          throw Error('responseType must be empty for "streamBinaryChunks" mode responses.');
        this.response = [];
      } else
        this.response = this.responseText = "", this.A = new TextDecoder();
      Vu(this);
    } else
      t.text().then(this.Va.bind(this), this.ga.bind(this));
};
function Vu(t) {
  t.j.read().then(t.Ta.bind(t)).catch(t.ga.bind(t));
}
E.Ta = function(t) {
  if (this.g) {
    if (this.u && t.value)
      this.response.push(t.value);
    else if (!this.u) {
      var e = t.value ? t.value : new Uint8Array(0);
      (e = this.A.decode(e, { stream: !t.done })) && (this.response = this.responseText += e);
    }
    t.done ? rs(this) : Un(this), this.readyState == 3 && Vu(this);
  }
};
E.Va = function(t) {
  this.g && (this.response = this.responseText = t, rs(this));
};
E.Ua = function(t) {
  this.g && (this.response = t, rs(this));
};
E.ga = function() {
  this.g && rs(this);
};
function rs(t) {
  t.readyState = 4, t.l = null, t.j = null, t.A = null, Un(t);
}
E.setRequestHeader = function(t, e) {
  this.v.append(t, e);
};
E.getResponseHeader = function(t) {
  return this.h && this.h.get(t.toLowerCase()) || "";
};
E.getAllResponseHeaders = function() {
  if (!this.h)
    return "";
  const t = [], e = this.h.entries();
  for (var n = e.next(); !n.done; )
    n = n.value, t.push(n[0] + ": " + n[1]), n = e.next();
  return t.join(`\r
`);
};
function Un(t) {
  t.onreadystatechange && t.onreadystatechange.call(t);
}
Object.defineProperty(Tr.prototype, "withCredentials", { get: function() {
  return this.m === "include";
}, set: function(t) {
  this.m = t ? "include" : "same-origin";
} });
var lm = N.JSON.parse;
function z(t) {
  se.call(this), this.headers = /* @__PURE__ */ new Map(), this.u = t || null, this.h = !1, this.C = this.g = null, this.H = "", this.m = 0, this.j = "", this.l = this.F = this.v = this.D = !1, this.B = 0, this.A = null, this.J = ju, this.K = this.L = !1;
}
ue(z, se);
var ju = "", hm = /^https?$/i, dm = ["POST", "PUT"];
E = z.prototype;
E.Ka = function(t) {
  this.L = t;
};
E.da = function(t, e, n, s) {
  if (this.g)
    throw Error("[goog.net.XhrIo] Object is active with another request=" + this.H + "; newUri=" + t);
  e = e ? e.toUpperCase() : "GET", this.H = t, this.j = "", this.m = 0, this.D = !1, this.h = !0, this.g = this.u ? this.u.g() : ki.g(), this.C = this.u ? Pa(this.u) : Pa(ki), this.g.onreadystatechange = pe(this.Ha, this);
  try {
    this.F = !0, this.g.open(e, String(t), !0), this.F = !1;
  } catch (i) {
    Ma(this, i);
    return;
  }
  if (t = n || "", n = new Map(this.headers), s)
    if (Object.getPrototypeOf(s) === Object.prototype)
      for (var r in s)
        n.set(r, s[r]);
    else if (typeof s.keys == "function" && typeof s.get == "function")
      for (const i of s.keys())
        n.set(i, s.get(i));
    else
      throw Error("Unknown input type for opt_headers: " + String(s));
  s = Array.from(n.keys()).find((i) => i.toLowerCase() == "content-type"), r = N.FormData && t instanceof N.FormData, !(0 <= iu(dm, e)) || s || r || n.set("Content-Type", "application/x-www-form-urlencoded;charset=utf-8");
  for (const [i, o] of n)
    this.g.setRequestHeader(i, o);
  this.J && (this.g.responseType = this.J), "withCredentials" in this.g && this.g.withCredentials !== this.L && (this.g.withCredentials = this.L);
  try {
    Hu(this), 0 < this.B && ((this.K = fm(this.g)) ? (this.g.timeout = this.B, this.g.ontimeout = pe(this.qa, this)) : this.A = _o(this.qa, this.B, this)), this.v = !0, this.g.send(t), this.v = !1;
  } catch (i) {
    Ma(this, i);
  }
};
function fm(t) {
  return tn && Rg() && typeof t.timeout == "number" && t.ontimeout !== void 0;
}
E.qa = function() {
  typeof ao < "u" && this.g && (this.j = "Timed out after " + this.B + "ms, aborting", this.m = 8, ae(this, "timeout"), this.abort(8));
};
function Ma(t, e) {
  t.h = !1, t.g && (t.l = !0, t.g.abort(), t.l = !1), t.j = e, t.m = 5, Gu(t), Sr(t);
}
function Gu(t) {
  t.D || (t.D = !0, ae(t, "complete"), ae(t, "error"));
}
E.abort = function(t) {
  this.g && this.h && (this.h = !1, this.l = !0, this.g.abort(), this.l = !1, this.m = t || 7, ae(this, "complete"), ae(this, "abort"), Sr(this));
};
E.M = function() {
  this.g && (this.h && (this.h = !1, this.l = !0, this.g.abort(), this.l = !1), Sr(this, !0)), z.X.M.call(this);
};
E.Ha = function() {
  this.s || (this.F || this.v || this.l ? Wu(this) : this.fb());
};
E.fb = function() {
  Wu(this);
};
function Wu(t) {
  if (t.h && typeof ao < "u" && (!t.C[1] || Ge(t) != 4 || t.aa() != 2)) {
    if (t.v && Ge(t) == 4)
      _o(t.Ha, 0, t);
    else if (ae(t, "readystatechange"), Ge(t) == 4) {
      t.h = !1;
      try {
        const a = t.aa();
        e:
          switch (a) {
            case 200:
            case 201:
            case 202:
            case 204:
            case 206:
            case 304:
            case 1223:
              var e = !0;
              break e;
            default:
              e = !1;
          }
        var n;
        if (!(n = e)) {
          var s;
          if (s = a === 0) {
            var r = String(t.H).match(Pu)[1] || null;
            if (!r && N.self && N.self.location) {
              var i = N.self.location.protocol;
              r = i.substr(0, i.length - 1);
            }
            s = !hm.test(r ? r.toLowerCase() : "");
          }
          n = s;
        }
        if (n)
          ae(t, "complete"), ae(
            t,
            "success"
          );
        else {
          t.m = 6;
          try {
            var o = 2 < Ge(t) ? t.g.statusText : "";
          } catch {
            o = "";
          }
          t.j = o + " [" + t.aa() + "]", Gu(t);
        }
      } finally {
        Sr(t);
      }
    }
  }
}
function Sr(t, e) {
  if (t.g) {
    Hu(t);
    const n = t.g, s = t.C[0] ? Vs : null;
    t.g = null, t.C = null, e || ae(t, "ready");
    try {
      n.onreadystatechange = s;
    } catch {
    }
  }
}
function Hu(t) {
  t.g && t.K && (t.g.ontimeout = null), t.A && (N.clearTimeout(t.A), t.A = null);
}
function Ge(t) {
  return t.g ? t.g.readyState : 0;
}
E.aa = function() {
  try {
    return 2 < Ge(this) ? this.g.status : -1;
  } catch {
    return -1;
  }
};
E.fa = function() {
  try {
    return this.g ? this.g.responseText : "";
  } catch {
    return "";
  }
};
E.Sa = function(t) {
  if (this.g) {
    var e = this.g.responseText;
    return t && e.indexOf(t) == 0 && (e = e.substring(t.length)), lm(e);
  }
};
function $a(t) {
  try {
    if (!t.g)
      return null;
    if ("response" in t.g)
      return t.g.response;
    switch (t.J) {
      case ju:
      case "text":
        return t.g.responseText;
      case "arraybuffer":
        if ("mozResponseArrayBuffer" in t.g)
          return t.g.mozResponseArrayBuffer;
    }
    return null;
  } catch {
    return null;
  }
}
E.Ea = function() {
  return this.m;
};
E.Oa = function() {
  return typeof this.j == "string" ? this.j : String(this.j);
};
function zu(t) {
  let e = "";
  return lo(t, function(n, s) {
    e += s, e += ":", e += n, e += `\r
`;
  }), e;
}
function bo(t, e, n) {
  e: {
    for (s in n) {
      var s = !1;
      break e;
    }
    s = !0;
  }
  s || (n = zu(n), typeof t == "string" ? n != null && encodeURIComponent(String(n)) : H(t, e, n));
}
function En(t, e, n) {
  return n && n.internalChannelParams && n.internalChannelParams[t] || e;
}
function Qu(t) {
  this.Ca = 0, this.i = [], this.j = new mr(), this.ka = this.sa = this.F = this.V = this.g = this.za = this.D = this.ia = this.o = this.S = this.s = null, this.ab = this.U = 0, this.Za = En("failFast", !1, t), this.L = this.v = this.u = this.m = this.l = null, this.Y = !0, this.pa = this.Ba = this.T = -1, this.Z = this.A = this.C = 0, this.Xa = En("baseRetryDelayMs", 5e3, t), this.bb = En("retryDelaySeedMs", 1e4, t), this.$a = En("forwardChannelMaxRetries", 2, t), this.ta = En("forwardChannelRequestTimeoutMs", 2e4, t), this.ra = t && t.xmlHttpFactory || void 0, this.Da = t && t.Zb || !1, this.J = void 0, this.H = t && t.supportsCrossDomainXhr || !1, this.I = "", this.h = new $u(t && t.concurrentRequestLimit), this.Fa = new am(), this.O = t && t.fastHandshake || !1, this.N = t && t.encodeInitMessageHeaders || !1, this.O && this.N && (this.N = !1), this.Ya = t && t.Xb || !1, t && t.Aa && this.j.Aa(), t && t.forceLongPolling && (this.Y = !1), this.$ = !this.O && this.Y && t && t.detectBufferingProxy || !1, this.ja = void 0, this.P = 0, this.K = !1, this.la = this.B = null;
}
E = Qu.prototype;
E.ma = 8;
E.G = 1;
function Ao(t) {
  if (Ku(t), t.G == 3) {
    var e = t.U++, n = ze(t.F);
    H(n, "SID", t.I), H(n, "RID", e), H(n, "TYPE", "terminate"), is(t, n), e = new ts(t, t.j, e, void 0), e.K = 2, e.v = vr(ze(n)), n = !1, N.navigator && N.navigator.sendBeacon && (n = N.navigator.sendBeacon(e.v.toString(), "")), !n && N.Image && (new Image().src = e.v, n = !0), n || (e.g = sl(e.l, null), e.g.da(e.v)), e.F = Date.now(), ns(e);
  }
  tl(t);
}
function Ir(t) {
  t.g && (No(t), t.g.cancel(), t.g = null);
}
function Ku(t) {
  Ir(t), t.u && (N.clearTimeout(t.u), t.u = null), Qs(t), t.h.cancel(), t.m && (typeof t.m == "number" && N.clearTimeout(t.m), t.m = null);
}
function br(t) {
  Uu(t.h) || t.m || (t.m = !0, yu(t.Ja, t), t.C = 0);
}
function pm(t, e) {
  return xu(t.h) >= t.h.j - (t.m ? 1 : 0) ? !1 : t.m ? (t.i = e.D.concat(t.i), !0) : t.G == 1 || t.G == 2 || t.C >= (t.Za ? 0 : t.$a) ? !1 : (t.m = Zn(pe(t.Ja, t, e), el(t, t.C)), t.C++, !0);
}
E.Ja = function(t) {
  if (this.m)
    if (this.m = null, this.G == 1) {
      if (!t) {
        this.U = Math.floor(1e5 * Math.random()), t = this.U++;
        const r = new ts(this, this.j, t, void 0);
        let i = this.s;
        if (this.S && (i ? (i = uu(i), lu(i, this.S)) : i = this.S), this.o !== null || this.N || (r.H = i, i = null), this.O)
          e: {
            for (var e = 0, n = 0; n < this.i.length; n++) {
              t: {
                var s = this.i[n];
                if ("__data__" in s.g && (s = s.g.__data__, typeof s == "string")) {
                  s = s.length;
                  break t;
                }
                s = void 0;
              }
              if (s === void 0)
                break;
              if (e += s, 4096 < e) {
                e = n;
                break e;
              }
              if (e === 4096 || n === this.i.length - 1) {
                e = n + 1;
                break e;
              }
            }
            e = 1e3;
          }
        else
          e = 1e3;
        e = Ju(this, r, e), n = ze(this.F), H(n, "RID", t), H(n, "CVER", 22), this.D && H(n, "X-HTTP-Session-Id", this.D), is(this, n), i && (this.N ? e = "headers=" + encodeURIComponent(String(zu(i))) + "&" + e : this.o && bo(n, this.o, i)), To(this.h, r), this.Ya && H(n, "TYPE", "init"), this.O ? (H(n, "$req", e), H(n, "SID", "null"), r.Z = !0, Pi(r, n, null)) : Pi(r, n, e), this.G = 2;
      }
    } else
      this.G == 3 && (t ? Ua(this, t) : this.i.length == 0 || Uu(this.h) || Ua(this));
};
function Ua(t, e) {
  var n;
  e ? n = e.m : n = t.U++;
  const s = ze(t.F);
  H(s, "SID", t.I), H(s, "RID", n), H(s, "AID", t.T), is(t, s), t.o && t.s && bo(s, t.o, t.s), n = new ts(t, t.j, n, t.C + 1), t.o === null && (n.H = t.s), e && (t.i = e.D.concat(t.i)), e = Ju(t, n, 1e3), n.setTimeout(Math.round(0.5 * t.ta) + Math.round(0.5 * t.ta * Math.random())), To(t.h, n), Pi(n, s, e);
}
function is(t, e) {
  t.ia && lo(t.ia, function(n, s) {
    H(e, s, n);
  }), t.l && Du({}, function(n, s) {
    H(e, s, n);
  });
}
function Ju(t, e, n) {
  n = Math.min(t.i.length, n);
  var s = t.l ? pe(t.l.Ra, t.l, t) : null;
  e: {
    var r = t.i;
    let i = -1;
    for (; ; ) {
      const o = ["count=" + n];
      i == -1 ? 0 < n ? (i = r[0].h, o.push("ofs=" + i)) : i = 0 : o.push("ofs=" + i);
      let a = !0;
      for (let c = 0; c < n; c++) {
        let u = r[c].h;
        const l = r[c].g;
        if (u -= i, 0 > u)
          i = Math.max(0, r[c].h - 100), a = !1;
        else
          try {
            cm(l, o, "req" + u + "_");
          } catch {
            s && s(l);
          }
      }
      if (a) {
        s = o.join("&");
        break e;
      }
    }
  }
  return t = t.i.splice(0, n), e.D = t, s;
}
function Yu(t) {
  t.g || t.u || (t.Z = 1, yu(t.Ia, t), t.A = 0);
}
function Co(t) {
  return t.g || t.u || 3 <= t.A ? !1 : (t.Z++, t.u = Zn(pe(t.Ia, t), el(t, t.A)), t.A++, !0);
}
E.Ia = function() {
  if (this.u = null, Xu(this), this.$ && !(this.K || this.g == null || 0 >= this.P)) {
    var t = 2 * this.P;
    this.j.info("BP detection timer enabled: " + t), this.B = Zn(pe(this.eb, this), t);
  }
};
E.eb = function() {
  this.B && (this.B = null, this.j.info("BP detection timeout reached."), this.j.info("Buffering proxy detected and switch to long-polling!"), this.L = !1, this.K = !0, _e(10), Ir(this), Xu(this));
};
function No(t) {
  t.B != null && (N.clearTimeout(t.B), t.B = null);
}
function Xu(t) {
  t.g = new ts(t, t.j, "rpc", t.Z), t.o === null && (t.g.H = t.s), t.g.N = 0;
  var e = ze(t.sa);
  H(e, "RID", "rpc"), H(e, "SID", t.I), H(e, "CI", t.L ? "0" : "1"), H(e, "AID", t.T), H(e, "TYPE", "xmlhttp"), is(t, e), t.o && t.s && bo(e, t.o, t.s), t.J && t.g.setTimeout(t.J);
  var n = t.g;
  t = t.ka, n.K = 1, n.v = vr(ze(e)), n.s = null, n.P = !0, Nu(n, t);
}
E.cb = function() {
  this.v != null && (this.v = null, Ir(this), Co(this), _e(19));
};
function Qs(t) {
  t.v != null && (N.clearTimeout(t.v), t.v = null);
}
function Zu(t, e) {
  var n = null;
  if (t.g == e) {
    Qs(t), No(t), t.g = null;
    var s = 2;
  } else if (Fi(t.h, e))
    n = e.D, Bu(t.h, e), s = 1;
  else
    return;
  if (t.G != 0) {
    if (t.pa = e.Y, e.i)
      if (s == 1) {
        n = e.s ? e.s.length : 0, e = Date.now() - e.F;
        var r = t.C;
        s = yr(), ae(s, new Iu(s, n)), br(t);
      } else
        Yu(t);
    else if (r = e.o, r == 3 || r == 0 && 0 < t.pa || !(s == 1 && pm(t, e) || s == 2 && Co(t)))
      switch (n && 0 < n.length && (e = t.h, e.i = e.i.concat(n)), r) {
        case 1:
          bt(t, 5);
          break;
        case 4:
          bt(t, 10);
          break;
        case 3:
          bt(t, 6);
          break;
        default:
          bt(t, 2);
      }
  }
}
function el(t, e) {
  let n = t.Xa + Math.floor(Math.random() * t.bb);
  return t.l || (n *= 2), n * e;
}
function bt(t, e) {
  if (t.j.info("Error code " + e), e == 2) {
    var n = null;
    t.l && (n = null);
    var s = pe(t.kb, t);
    n || (n = new Ct("//www.google.com/images/cleardot.gif"), N.location && N.location.protocol == "http" || Hs(n, "https"), vr(n)), um(n.toString(), s);
  } else
    _e(2);
  t.G = 0, t.l && t.l.va(e), tl(t), Ku(t);
}
E.kb = function(t) {
  t ? (this.j.info("Successfully pinged google.com"), _e(2)) : (this.j.info("Failed to ping google.com"), _e(1));
};
function tl(t) {
  if (t.G = 0, t.la = [], t.l) {
    const e = qu(t.h);
    (e.length != 0 || t.i.length != 0) && (Ca(t.la, e), Ca(t.la, t.i), t.h.i.length = 0, co(t.i), t.i.length = 0), t.l.ua();
  }
}
function nl(t, e, n) {
  var s = n instanceof Ct ? ze(n) : new Ct(n, void 0);
  if (s.g != "")
    e && (s.g = e + "." + s.g), zs(s, s.m);
  else {
    var r = N.location;
    s = r.protocol, e = e ? e + "." + r.hostname : r.hostname, r = +r.port;
    var i = new Ct(null, void 0);
    s && Hs(i, s), e && (i.g = e), r && zs(i, r), n && (i.l = n), s = i;
  }
  return n = t.D, e = t.za, n && e && H(s, n, e), H(s, "VER", t.ma), is(t, s), s;
}
function sl(t, e, n) {
  if (e && !t.H)
    throw Error("Can't create secondary domain capable XhrIo object.");
  return e = n && t.Da && !t.ra ? new z(new ss({ jb: !0 })) : new z(t.ra), e.Ka(t.H), e;
}
function rl() {
}
E = rl.prototype;
E.xa = function() {
};
E.wa = function() {
};
E.va = function() {
};
E.ua = function() {
};
E.Ra = function() {
};
function Ks() {
  if (tn && !(10 <= Number(kg)))
    throw Error("Environmental error: no available transport.");
}
Ks.prototype.g = function(t, e) {
  return new Ae(t, e);
};
function Ae(t, e) {
  se.call(this), this.g = new Qu(e), this.l = t, this.h = e && e.messageUrlParams || null, t = e && e.messageHeaders || null, e && e.clientProtocolHeaderRequired && (t ? t["X-Client-Protocol"] = "webchannel" : t = { "X-Client-Protocol": "webchannel" }), this.g.s = t, t = e && e.initMessageHeaders || null, e && e.messageContentType && (t ? t["X-WebChannel-Content-Type"] = e.messageContentType : t = { "X-WebChannel-Content-Type": e.messageContentType }), e && e.ya && (t ? t["X-WebChannel-Client-Profile"] = e.ya : t = { "X-WebChannel-Client-Profile": e.ya }), this.g.S = t, (t = e && e.Yb) && !js(t) && (this.g.o = t), this.A = e && e.supportsCrossDomainXhr || !1, this.v = e && e.sendRawJson || !1, (e = e && e.httpSessionIdParam) && !js(e) && (this.g.D = e, t = this.h, t !== null && e in t && (t = this.h, e in t && delete t[e])), this.j = new dn(this);
}
ue(Ae, se);
Ae.prototype.m = function() {
  this.g.l = this.j, this.A && (this.g.H = !0);
  var t = this.g, e = this.l, n = this.h || void 0;
  _e(0), t.V = e, t.ia = n || {}, t.L = t.Y, t.F = nl(t, null, t.V), br(t);
};
Ae.prototype.close = function() {
  Ao(this.g);
};
Ae.prototype.u = function(t) {
  var e = this.g;
  if (typeof t == "string") {
    var n = {};
    n.__data__ = t, t = n;
  } else
    this.v && (n = {}, n.__data__ = mo(t), t = n);
  e.i.push(new im(e.ab++, t)), e.G == 3 && br(e);
};
Ae.prototype.M = function() {
  this.g.l = null, delete this.j, Ao(this.g), delete this.g, Ae.X.M.call(this);
};
function il(t) {
  Eo.call(this);
  var e = t.__sm__;
  if (e) {
    e: {
      for (const n in e) {
        t = n;
        break e;
      }
      t = void 0;
    }
    (this.i = t) && (t = this.i, e = e !== null && t in e ? e[t] : void 0), this.data = e;
  } else
    this.data = t;
}
ue(il, Eo);
function ol() {
  vo.call(this), this.status = 1;
}
ue(ol, vo);
function dn(t) {
  this.g = t;
}
ue(dn, rl);
dn.prototype.xa = function() {
  ae(this.g, "a");
};
dn.prototype.wa = function(t) {
  ae(this.g, new il(t));
};
dn.prototype.va = function(t) {
  ae(this.g, new ol());
};
dn.prototype.ua = function() {
  ae(this.g, "b");
};
Ks.prototype.createWebChannel = Ks.prototype.g;
Ae.prototype.send = Ae.prototype.u;
Ae.prototype.open = Ae.prototype.m;
Ae.prototype.close = Ae.prototype.close;
_r.NO_ERROR = 0;
_r.TIMEOUT = 8;
_r.HTTP_ERROR = 6;
bu.COMPLETE = "complete";
Au.EventType = es;
es.OPEN = "a";
es.CLOSE = "b";
es.ERROR = "c";
es.MESSAGE = "d";
se.prototype.listen = se.prototype.N;
z.prototype.listenOnce = z.prototype.O;
z.prototype.getLastError = z.prototype.Oa;
z.prototype.getLastErrorCode = z.prototype.Ea;
z.prototype.getStatus = z.prototype.aa;
z.prototype.getResponseJson = z.prototype.Sa;
z.prototype.getResponseText = z.prototype.fa;
z.prototype.send = z.prototype.da;
z.prototype.setWithCredentials = z.prototype.Ka;
var gm = function() {
  return new Ks();
}, mm = function() {
  return yr();
}, fi = _r, ym = bu, _m = Ut, xa = { sb: 0, vb: 1, wb: 2, Pb: 3, Ub: 4, Rb: 5, Sb: 6, Qb: 7, Ob: 8, Tb: 9, PROXY: 10, NOPROXY: 11, Mb: 12, Ib: 13, Jb: 14, Hb: 15, Kb: 16, Lb: 17, ob: 18, nb: 19, pb: 20 }, wm = ss, Is = Au, Em = z;
const Ba = "@firebase/firestore";
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class he {
  constructor(e) {
    this.uid = e;
  }
  isAuthenticated() {
    return this.uid != null;
  }
  /**
   * Returns a key representing this user, suitable for inclusion in a
   * dictionary.
   */
  toKey() {
    return this.isAuthenticated() ? "uid:" + this.uid : "anonymous-user";
  }
  isEqual(e) {
    return e.uid === this.uid;
  }
}
he.UNAUTHENTICATED = new he(null), // TODO(mikelehen): Look into getting a proper uid-equivalent for
// non-FirebaseAuth providers.
he.GOOGLE_CREDENTIALS = new he("google-credentials-uid"), he.FIRST_PARTY = new he("first-party-uid"), he.MOCK_USER = new he("mock-user");
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
let fn = "9.17.1";
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Pt = new io("@firebase/firestore");
function qa() {
  return Pt.logLevel;
}
function T(t, ...e) {
  if (Pt.logLevel <= x.DEBUG) {
    const n = e.map(Oo);
    Pt.debug(`Firestore (${fn}): ${t}`, ...n);
  }
}
function Qe(t, ...e) {
  if (Pt.logLevel <= x.ERROR) {
    const n = e.map(Oo);
    Pt.error(`Firestore (${fn}): ${t}`, ...n);
  }
}
function Mi(t, ...e) {
  if (Pt.logLevel <= x.WARN) {
    const n = e.map(Oo);
    Pt.warn(`Firestore (${fn}): ${t}`, ...n);
  }
}
function Oo(t) {
  if (typeof t == "string")
    return t;
  try {
    return e = t, JSON.stringify(e);
  } catch {
    return t;
  }
  /**
  * @license
  * Copyright 2020 Google LLC
  *
  * Licensed under the Apache License, Version 2.0 (the "License");
  * you may not use this file except in compliance with the License.
  * You may obtain a copy of the License at
  *
  *   http://www.apache.org/licenses/LICENSE-2.0
  *
  * Unless required by applicable law or agreed to in writing, software
  * distributed under the License is distributed on an "AS IS" BASIS,
  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  * See the License for the specific language governing permissions and
  * limitations under the License.
  */
  var e;
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function A(t = "Unexpected state") {
  const e = `FIRESTORE (${fn}) INTERNAL ASSERTION FAILED: ` + t;
  throw Qe(e), new Error(e);
}
function j(t, e) {
  t || A();
}
function D(t, e) {
  return t;
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const g = {
  // Causes are copied from:
  // https://github.com/grpc/grpc/blob/bceec94ea4fc5f0085d81235d8e1c06798dc341a/include/grpc%2B%2B/impl/codegen/status_code_enum.h
  /** Not an error; returned on success. */
  OK: "ok",
  /** The operation was cancelled (typically by the caller). */
  CANCELLED: "cancelled",
  /** Unknown error or an error from a different error domain. */
  UNKNOWN: "unknown",
  /**
   * Client specified an invalid argument. Note that this differs from
   * FAILED_PRECONDITION. INVALID_ARGUMENT indicates arguments that are
   * problematic regardless of the state of the system (e.g., a malformed file
   * name).
   */
  INVALID_ARGUMENT: "invalid-argument",
  /**
   * Deadline expired before operation could complete. For operations that
   * change the state of the system, this error may be returned even if the
   * operation has completed successfully. For example, a successful response
   * from a server could have been delayed long enough for the deadline to
   * expire.
   */
  DEADLINE_EXCEEDED: "deadline-exceeded",
  /** Some requested entity (e.g., file or directory) was not found. */
  NOT_FOUND: "not-found",
  /**
   * Some entity that we attempted to create (e.g., file or directory) already
   * exists.
   */
  ALREADY_EXISTS: "already-exists",
  /**
   * The caller does not have permission to execute the specified operation.
   * PERMISSION_DENIED must not be used for rejections caused by exhausting
   * some resource (use RESOURCE_EXHAUSTED instead for those errors).
   * PERMISSION_DENIED must not be used if the caller can not be identified
   * (use UNAUTHENTICATED instead for those errors).
   */
  PERMISSION_DENIED: "permission-denied",
  /**
   * The request does not have valid authentication credentials for the
   * operation.
   */
  UNAUTHENTICATED: "unauthenticated",
  /**
   * Some resource has been exhausted, perhaps a per-user quota, or perhaps the
   * entire file system is out of space.
   */
  RESOURCE_EXHAUSTED: "resource-exhausted",
  /**
   * Operation was rejected because the system is not in a state required for
   * the operation's execution. For example, directory to be deleted may be
   * non-empty, an rmdir operation is applied to a non-directory, etc.
   *
   * A litmus test that may help a service implementor in deciding
   * between FAILED_PRECONDITION, ABORTED, and UNAVAILABLE:
   *  (a) Use UNAVAILABLE if the client can retry just the failing call.
   *  (b) Use ABORTED if the client should retry at a higher-level
   *      (e.g., restarting a read-modify-write sequence).
   *  (c) Use FAILED_PRECONDITION if the client should not retry until
   *      the system state has been explicitly fixed. E.g., if an "rmdir"
   *      fails because the directory is non-empty, FAILED_PRECONDITION
   *      should be returned since the client should not retry unless
   *      they have first fixed up the directory by deleting files from it.
   *  (d) Use FAILED_PRECONDITION if the client performs conditional
   *      REST Get/Update/Delete on a resource and the resource on the
   *      server does not match the condition. E.g., conflicting
   *      read-modify-write on the same resource.
   */
  FAILED_PRECONDITION: "failed-precondition",
  /**
   * The operation was aborted, typically due to a concurrency issue like
   * sequencer check failures, transaction aborts, etc.
   *
   * See litmus test above for deciding between FAILED_PRECONDITION, ABORTED,
   * and UNAVAILABLE.
   */
  ABORTED: "aborted",
  /**
   * Operation was attempted past the valid range. E.g., seeking or reading
   * past end of file.
   *
   * Unlike INVALID_ARGUMENT, this error indicates a problem that may be fixed
   * if the system state changes. For example, a 32-bit file system will
   * generate INVALID_ARGUMENT if asked to read at an offset that is not in the
   * range [0,2^32-1], but it will generate OUT_OF_RANGE if asked to read from
   * an offset past the current file size.
   *
   * There is a fair bit of overlap between FAILED_PRECONDITION and
   * OUT_OF_RANGE. We recommend using OUT_OF_RANGE (the more specific error)
   * when it applies so that callers who are iterating through a space can
   * easily look for an OUT_OF_RANGE error to detect when they are done.
   */
  OUT_OF_RANGE: "out-of-range",
  /** Operation is not implemented or not supported/enabled in this service. */
  UNIMPLEMENTED: "unimplemented",
  /**
   * Internal errors. Means some invariants expected by underlying System has
   * been broken. If you see one of these errors, Something is very broken.
   */
  INTERNAL: "internal",
  /**
   * The service is currently unavailable. This is a most likely a transient
   * condition and may be corrected by retrying with a backoff.
   *
   * See litmus test above for deciding between FAILED_PRECONDITION, ABORTED,
   * and UNAVAILABLE.
   */
  UNAVAILABLE: "unavailable",
  /** Unrecoverable data loss or corruption. */
  DATA_LOSS: "data-loss"
};
class w extends Xe {
  /** @hideconstructor */
  constructor(e, n) {
    super(e, n), this.code = e, this.message = n, // HACK: We write a toString property directly because Error is not a real
    // class and so inheritance does not work correctly. We could alternatively
    // do the same "back-door inheritance" trick that FirebaseError does.
    this.toString = () => `${this.name}: [code=${this.code}]: ${this.message}`;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Me {
  constructor() {
    this.promise = new Promise((e, n) => {
      this.resolve = e, this.reject = n;
    });
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class al {
  constructor(e, n) {
    this.user = n, this.type = "OAuth", this.headers = /* @__PURE__ */ new Map(), this.headers.set("Authorization", `Bearer ${e}`);
  }
}
class vm {
  getToken() {
    return Promise.resolve(null);
  }
  invalidateToken() {
  }
  start(e, n) {
    e.enqueueRetryable(() => n(he.UNAUTHENTICATED));
  }
  shutdown() {
  }
}
class Tm {
  constructor(e) {
    this.token = e, /**
     * Stores the listener registered with setChangeListener()
     * This isn't actually necessary since the UID never changes, but we use this
     * to verify the listen contract is adhered to in tests.
     */
    this.changeListener = null;
  }
  getToken() {
    return Promise.resolve(this.token);
  }
  invalidateToken() {
  }
  start(e, n) {
    this.changeListener = n, // Fire with initial user.
    e.enqueueRetryable(() => n(this.token.user));
  }
  shutdown() {
    this.changeListener = null;
  }
}
class Sm {
  constructor(e) {
    this.t = e, /** Tracks the current User. */
    this.currentUser = he.UNAUTHENTICATED, /**
     * Counter used to detect if the token changed while a getToken request was
     * outstanding.
     */
    this.i = 0, this.forceRefresh = !1, this.auth = null;
  }
  start(e, n) {
    let s = this.i;
    const r = (c) => this.i !== s ? (s = this.i, n(c)) : Promise.resolve();
    let i = new Me();
    this.o = () => {
      this.i++, this.currentUser = this.u(), i.resolve(), i = new Me(), e.enqueueRetryable(() => r(this.currentUser));
    };
    const o = () => {
      const c = i;
      e.enqueueRetryable(async () => {
        await c.promise, await r(this.currentUser);
      });
    }, a = (c) => {
      T("FirebaseAuthCredentialsProvider", "Auth detected"), this.auth = c, this.auth.addAuthTokenListener(this.o), o();
    };
    this.t.onInit((c) => a(c)), // Our users can initialize Auth right after Firestore, so we give it
    // a chance to register itself with the component framework before we
    // determine whether to start up in unauthenticated mode.
    setTimeout(() => {
      if (!this.auth) {
        const c = this.t.getImmediate({
          optional: !0
        });
        c ? a(c) : (
          // If auth is still not available, proceed with `null` user
          (T("FirebaseAuthCredentialsProvider", "Auth not yet detected"), i.resolve(), i = new Me())
        );
      }
    }, 0), o();
  }
  getToken() {
    const e = this.i, n = this.forceRefresh;
    return this.forceRefresh = !1, this.auth ? this.auth.getToken(n).then((s) => (
      // Cancel the request since the token changed while the request was
      // outstanding so the response is potentially for a previous user (which
      // user, we can't be sure).
      this.i !== e ? (T("FirebaseAuthCredentialsProvider", "getToken aborted due to token change."), this.getToken()) : s ? (j(typeof s.accessToken == "string"), new al(s.accessToken, this.currentUser)) : null
    )) : Promise.resolve(null);
  }
  invalidateToken() {
    this.forceRefresh = !0;
  }
  shutdown() {
    this.auth && this.auth.removeAuthTokenListener(this.o);
  }
  // Auth.getUid() can return null even with a user logged in. It is because
  // getUid() is synchronous, but the auth code populating Uid is asynchronous.
  // This method should only be called in the AuthTokenListener callback
  // to guarantee to get the actual user.
  u() {
    const e = this.auth && this.auth.getUid();
    return j(e === null || typeof e == "string"), new he(e);
  }
}
class Im {
  constructor(e, n, s, r) {
    this.h = e, this.l = n, this.m = s, this.g = r, this.type = "FirstParty", this.user = he.FIRST_PARTY, this.p = /* @__PURE__ */ new Map();
  }
  /** Gets an authorization token, using a provided factory function, or falling back to First Party GAPI. */
  I() {
    return this.g ? this.g() : (
      // Make sure this really is a Gapi client.
      (j(!(typeof this.h != "object" || this.h === null || !this.h.auth || !this.h.auth.getAuthHeaderValueForFirstParty)), this.h.auth.getAuthHeaderValueForFirstParty([]))
    );
  }
  get headers() {
    this.p.set("X-Goog-AuthUser", this.l);
    const e = this.I();
    return e && this.p.set("Authorization", e), this.m && this.p.set("X-Goog-Iam-Authorization-Token", this.m), this.p;
  }
}
class bm {
  constructor(e, n, s, r) {
    this.h = e, this.l = n, this.m = s, this.g = r;
  }
  getToken() {
    return Promise.resolve(new Im(this.h, this.l, this.m, this.g));
  }
  start(e, n) {
    e.enqueueRetryable(() => n(he.FIRST_PARTY));
  }
  shutdown() {
  }
  invalidateToken() {
  }
}
class Am {
  constructor(e) {
    this.value = e, this.type = "AppCheck", this.headers = /* @__PURE__ */ new Map(), e && e.length > 0 && this.headers.set("x-firebase-appcheck", this.value);
  }
}
class Cm {
  constructor(e) {
    this.T = e, this.forceRefresh = !1, this.appCheck = null, this.A = null;
  }
  start(e, n) {
    const s = (i) => {
      i.error != null && T("FirebaseAppCheckTokenProvider", `Error getting App Check token; using placeholder token instead. Error: ${i.error.message}`);
      const o = i.token !== this.A;
      return this.A = i.token, T("FirebaseAppCheckTokenProvider", `Received ${o ? "new" : "existing"} token.`), o ? n(i.token) : Promise.resolve();
    };
    this.o = (i) => {
      e.enqueueRetryable(() => s(i));
    };
    const r = (i) => {
      T("FirebaseAppCheckTokenProvider", "AppCheck detected"), this.appCheck = i, this.appCheck.addTokenListener(this.o);
    };
    this.T.onInit((i) => r(i)), // Our users can initialize AppCheck after Firestore, so we give it
    // a chance to register itself with the component framework.
    setTimeout(() => {
      if (!this.appCheck) {
        const i = this.T.getImmediate({
          optional: !0
        });
        i ? r(i) : (
          // If AppCheck is still not available, proceed without it.
          T("FirebaseAppCheckTokenProvider", "AppCheck not yet detected")
        );
      }
    }, 0);
  }
  getToken() {
    const e = this.forceRefresh;
    return this.forceRefresh = !1, this.appCheck ? this.appCheck.getToken(e).then((n) => n ? (j(typeof n.token == "string"), this.A = n.token, new Am(n.token)) : null) : Promise.resolve(null);
  }
  invalidateToken() {
    this.forceRefresh = !0;
  }
  shutdown() {
    this.appCheck && this.appCheck.removeTokenListener(this.o);
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Nm(t) {
  const e = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof self < "u" && (self.crypto || self.msCrypto)
  ), n = new Uint8Array(t);
  if (e && typeof e.getRandomValues == "function")
    e.getRandomValues(n);
  else
    for (let s = 0; s < t; s++)
      n[s] = Math.floor(256 * Math.random());
  return n;
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class cl {
  static R() {
    const e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", n = Math.floor(256 / e.length) * e.length;
    let s = "";
    for (; s.length < 20; ) {
      const r = Nm(40);
      for (let i = 0; i < r.length; ++i)
        s.length < 20 && r[i] < n && (s += e.charAt(r[i] % e.length));
    }
    return s;
  }
}
function B(t, e) {
  return t < e ? -1 : t > e ? 1 : 0;
}
function nn(t, e, n) {
  return t.length === e.length && t.every((s, r) => n(s, e[r]));
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class X {
  /**
   * Creates a new timestamp.
   *
   * @param seconds - The number of seconds of UTC time since Unix epoch
   *     1970-01-01T00:00:00Z. Must be from 0001-01-01T00:00:00Z to
   *     9999-12-31T23:59:59Z inclusive.
   * @param nanoseconds - The non-negative fractions of a second at nanosecond
   *     resolution. Negative second values with fractions must still have
   *     non-negative nanoseconds values that count forward in time. Must be
   *     from 0 to 999,999,999 inclusive.
   */
  constructor(e, n) {
    if (this.seconds = e, this.nanoseconds = n, n < 0)
      throw new w(g.INVALID_ARGUMENT, "Timestamp nanoseconds out of range: " + n);
    if (n >= 1e9)
      throw new w(g.INVALID_ARGUMENT, "Timestamp nanoseconds out of range: " + n);
    if (e < -62135596800)
      throw new w(g.INVALID_ARGUMENT, "Timestamp seconds out of range: " + e);
    if (e >= 253402300800)
      throw new w(g.INVALID_ARGUMENT, "Timestamp seconds out of range: " + e);
  }
  /**
   * Creates a new timestamp with the current date, with millisecond precision.
   *
   * @returns a new timestamp representing the current date.
   */
  static now() {
    return X.fromMillis(Date.now());
  }
  /**
   * Creates a new timestamp from the given date.
   *
   * @param date - The date to initialize the `Timestamp` from.
   * @returns A new `Timestamp` representing the same point in time as the given
   *     date.
   */
  static fromDate(e) {
    return X.fromMillis(e.getTime());
  }
  /**
   * Creates a new timestamp from the given number of milliseconds.
   *
   * @param milliseconds - Number of milliseconds since Unix epoch
   *     1970-01-01T00:00:00Z.
   * @returns A new `Timestamp` representing the same point in time as the given
   *     number of milliseconds.
   */
  static fromMillis(e) {
    const n = Math.floor(e / 1e3), s = Math.floor(1e6 * (e - 1e3 * n));
    return new X(n, s);
  }
  /**
   * Converts a `Timestamp` to a JavaScript `Date` object. This conversion
   * causes a loss of precision since `Date` objects only support millisecond
   * precision.
   *
   * @returns JavaScript `Date` object representing the same point in time as
   *     this `Timestamp`, with millisecond precision.
   */
  toDate() {
    return new Date(this.toMillis());
  }
  /**
   * Converts a `Timestamp` to a numeric timestamp (in milliseconds since
   * epoch). This operation causes a loss of precision.
   *
   * @returns The point in time corresponding to this timestamp, represented as
   *     the number of milliseconds since Unix epoch 1970-01-01T00:00:00Z.
   */
  toMillis() {
    return 1e3 * this.seconds + this.nanoseconds / 1e6;
  }
  _compareTo(e) {
    return this.seconds === e.seconds ? B(this.nanoseconds, e.nanoseconds) : B(this.seconds, e.seconds);
  }
  /**
   * Returns true if this `Timestamp` is equal to the provided one.
   *
   * @param other - The `Timestamp` to compare against.
   * @returns true if this `Timestamp` is equal to the provided one.
   */
  isEqual(e) {
    return e.seconds === this.seconds && e.nanoseconds === this.nanoseconds;
  }
  /** Returns a textual representation of this `Timestamp`. */
  toString() {
    return "Timestamp(seconds=" + this.seconds + ", nanoseconds=" + this.nanoseconds + ")";
  }
  /** Returns a JSON-serializable representation of this `Timestamp`. */
  toJSON() {
    return {
      seconds: this.seconds,
      nanoseconds: this.nanoseconds
    };
  }
  /**
   * Converts this object to a primitive string, which allows `Timestamp` objects
   * to be compared using the `>`, `<=`, `>=` and `>` operators.
   */
  valueOf() {
    const e = this.seconds - -62135596800;
    return String(e).padStart(12, "0") + "." + String(this.nanoseconds).padStart(9, "0");
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class k {
  constructor(e) {
    this.timestamp = e;
  }
  static fromTimestamp(e) {
    return new k(e);
  }
  static min() {
    return new k(new X(0, 0));
  }
  static max() {
    return new k(new X(253402300799, 999999999));
  }
  compareTo(e) {
    return this.timestamp._compareTo(e.timestamp);
  }
  isEqual(e) {
    return this.timestamp.isEqual(e.timestamp);
  }
  /** Returns a number representation of the version for use in spec tests. */
  toMicroseconds() {
    return 1e6 * this.timestamp.seconds + this.timestamp.nanoseconds / 1e3;
  }
  toString() {
    return "SnapshotVersion(" + this.timestamp.toString() + ")";
  }
  toTimestamp() {
    return this.timestamp;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class xn {
  constructor(e, n, s) {
    n === void 0 ? n = 0 : n > e.length && A(), s === void 0 ? s = e.length - n : s > e.length - n && A(), this.segments = e, this.offset = n, this.len = s;
  }
  get length() {
    return this.len;
  }
  isEqual(e) {
    return xn.comparator(this, e) === 0;
  }
  child(e) {
    const n = this.segments.slice(this.offset, this.limit());
    return e instanceof xn ? e.forEach((s) => {
      n.push(s);
    }) : n.push(e), this.construct(n);
  }
  /** The index of one past the last segment of the path. */
  limit() {
    return this.offset + this.length;
  }
  popFirst(e) {
    return e = e === void 0 ? 1 : e, this.construct(this.segments, this.offset + e, this.length - e);
  }
  popLast() {
    return this.construct(this.segments, this.offset, this.length - 1);
  }
  firstSegment() {
    return this.segments[this.offset];
  }
  lastSegment() {
    return this.get(this.length - 1);
  }
  get(e) {
    return this.segments[this.offset + e];
  }
  isEmpty() {
    return this.length === 0;
  }
  isPrefixOf(e) {
    if (e.length < this.length)
      return !1;
    for (let n = 0; n < this.length; n++)
      if (this.get(n) !== e.get(n))
        return !1;
    return !0;
  }
  isImmediateParentOf(e) {
    if (this.length + 1 !== e.length)
      return !1;
    for (let n = 0; n < this.length; n++)
      if (this.get(n) !== e.get(n))
        return !1;
    return !0;
  }
  forEach(e) {
    for (let n = this.offset, s = this.limit(); n < s; n++)
      e(this.segments[n]);
  }
  toArray() {
    return this.segments.slice(this.offset, this.limit());
  }
  static comparator(e, n) {
    const s = Math.min(e.length, n.length);
    for (let r = 0; r < s; r++) {
      const i = e.get(r), o = n.get(r);
      if (i < o)
        return -1;
      if (i > o)
        return 1;
    }
    return e.length < n.length ? -1 : e.length > n.length ? 1 : 0;
  }
}
class W extends xn {
  construct(e, n, s) {
    return new W(e, n, s);
  }
  canonicalString() {
    return this.toArray().join("/");
  }
  toString() {
    return this.canonicalString();
  }
  /**
   * Creates a resource path from the given slash-delimited string. If multiple
   * arguments are provided, all components are combined. Leading and trailing
   * slashes from all components are ignored.
   */
  static fromString(...e) {
    const n = [];
    for (const s of e) {
      if (s.indexOf("//") >= 0)
        throw new w(g.INVALID_ARGUMENT, `Invalid segment (${s}). Paths must not contain // in them.`);
      n.push(...s.split("/").filter((r) => r.length > 0));
    }
    return new W(n);
  }
  static emptyPath() {
    return new W([]);
  }
}
const Om = /^[_a-zA-Z][_a-zA-Z0-9]*$/;
class fe extends xn {
  construct(e, n, s) {
    return new fe(e, n, s);
  }
  /**
   * Returns true if the string could be used as a segment in a field path
   * without escaping.
   */
  static isValidIdentifier(e) {
    return Om.test(e);
  }
  canonicalString() {
    return this.toArray().map((e) => (e = e.replace(/\\/g, "\\\\").replace(/`/g, "\\`"), fe.isValidIdentifier(e) || (e = "`" + e + "`"), e)).join(".");
  }
  toString() {
    return this.canonicalString();
  }
  /**
   * Returns true if this field references the key of a document.
   */
  isKeyField() {
    return this.length === 1 && this.get(0) === "__name__";
  }
  /**
   * The field designating the key of a document.
   */
  static keyField() {
    return new fe(["__name__"]);
  }
  /**
   * Parses a field string from the given server-formatted string.
   *
   * - Splitting the empty string is not allowed (for now at least).
   * - Empty segments within the string (e.g. if there are two consecutive
   *   separators) are not allowed.
   *
   * TODO(b/37244157): we should make this more strict. Right now, it allows
   * non-identifier path components, even if they aren't escaped.
   */
  static fromServerFormat(e) {
    const n = [];
    let s = "", r = 0;
    const i = () => {
      if (s.length === 0)
        throw new w(g.INVALID_ARGUMENT, `Invalid field path (${e}). Paths must not be empty, begin with '.', end with '.', or contain '..'`);
      n.push(s), s = "";
    };
    let o = !1;
    for (; r < e.length; ) {
      const a = e[r];
      if (a === "\\") {
        if (r + 1 === e.length)
          throw new w(g.INVALID_ARGUMENT, "Path has trailing escape character: " + e);
        const c = e[r + 1];
        if (c !== "\\" && c !== "." && c !== "`")
          throw new w(g.INVALID_ARGUMENT, "Path has invalid escape sequence: " + e);
        s += c, r += 2;
      } else
        a === "`" ? (o = !o, r++) : a !== "." || o ? (s += a, r++) : (i(), r++);
    }
    if (i(), o)
      throw new w(g.INVALID_ARGUMENT, "Unterminated ` in path: " + e);
    return new fe(n);
  }
  static emptyPath() {
    return new fe([]);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class I {
  constructor(e) {
    this.path = e;
  }
  static fromPath(e) {
    return new I(W.fromString(e));
  }
  static fromName(e) {
    return new I(W.fromString(e).popFirst(5));
  }
  static empty() {
    return new I(W.emptyPath());
  }
  get collectionGroup() {
    return this.path.popLast().lastSegment();
  }
  /** Returns true if the document is in the specified collectionId. */
  hasCollectionId(e) {
    return this.path.length >= 2 && this.path.get(this.path.length - 2) === e;
  }
  /** Returns the collection group (i.e. the name of the parent collection) for this key. */
  getCollectionGroup() {
    return this.path.get(this.path.length - 2);
  }
  /** Returns the fully qualified path to the parent collection. */
  getCollectionPath() {
    return this.path.popLast();
  }
  isEqual(e) {
    return e !== null && W.comparator(this.path, e.path) === 0;
  }
  toString() {
    return this.path.toString();
  }
  static comparator(e, n) {
    return W.comparator(e.path, n.path);
  }
  static isDocumentKey(e) {
    return e.length % 2 == 0;
  }
  /**
   * Creates and returns a new document key with the given segments.
   *
   * @param segments - The segments of the path to the document
   * @returns A new instance of DocumentKey
   */
  static fromSegments(e) {
    return new I(new W(e.slice()));
  }
}
function Rm(t, e) {
  const n = t.toTimestamp().seconds, s = t.toTimestamp().nanoseconds + 1, r = k.fromTimestamp(s === 1e9 ? new X(n + 1, 0) : new X(n, s));
  return new lt(r, I.empty(), e);
}
function km(t) {
  return new lt(t.readTime, t.key, -1);
}
class lt {
  constructor(e, n, s) {
    this.readTime = e, this.documentKey = n, this.largestBatchId = s;
  }
  /** Returns an offset that sorts before all regular offsets. */
  static min() {
    return new lt(k.min(), I.empty(), -1);
  }
  /** Returns an offset that sorts after all regular offsets. */
  static max() {
    return new lt(k.max(), I.empty(), -1);
  }
}
function Dm(t, e) {
  let n = t.readTime.compareTo(e.readTime);
  return n !== 0 ? n : (n = I.comparator(t.documentKey, e.documentKey), n !== 0 ? n : B(t.largestBatchId, e.largestBatchId));
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Pm = "The current tab is not in the required state to perform this operation. It might be necessary to refresh the browser tab.";
class Lm {
  constructor() {
    this.onCommittedListeners = [];
  }
  addOnCommittedListener(e) {
    this.onCommittedListeners.push(e);
  }
  raiseOnCommittedEvent() {
    this.onCommittedListeners.forEach((e) => e());
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function os(t) {
  if (t.code !== g.FAILED_PRECONDITION || t.message !== Pm)
    throw t;
  T("LocalStore", "Unexpectedly lost primary lease");
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class y {
  constructor(e) {
    this.nextCallback = null, this.catchCallback = null, // When the operation resolves, we'll set result or error and mark isDone.
    this.result = void 0, this.error = void 0, this.isDone = !1, // Set to true when .then() or .catch() are called and prevents additional
    // chaining.
    this.callbackAttached = !1, e((n) => {
      this.isDone = !0, this.result = n, this.nextCallback && // value should be defined unless T is Void, but we can't express
      // that in the type system.
      this.nextCallback(n);
    }, (n) => {
      this.isDone = !0, this.error = n, this.catchCallback && this.catchCallback(n);
    });
  }
  catch(e) {
    return this.next(void 0, e);
  }
  next(e, n) {
    return this.callbackAttached && A(), this.callbackAttached = !0, this.isDone ? this.error ? this.wrapFailure(n, this.error) : this.wrapSuccess(e, this.result) : new y((s, r) => {
      this.nextCallback = (i) => {
        this.wrapSuccess(e, i).next(s, r);
      }, this.catchCallback = (i) => {
        this.wrapFailure(n, i).next(s, r);
      };
    });
  }
  toPromise() {
    return new Promise((e, n) => {
      this.next(e, n);
    });
  }
  wrapUserFunction(e) {
    try {
      const n = e();
      return n instanceof y ? n : y.resolve(n);
    } catch (n) {
      return y.reject(n);
    }
  }
  wrapSuccess(e, n) {
    return e ? this.wrapUserFunction(() => e(n)) : y.resolve(n);
  }
  wrapFailure(e, n) {
    return e ? this.wrapUserFunction(() => e(n)) : y.reject(n);
  }
  static resolve(e) {
    return new y((n, s) => {
      n(e);
    });
  }
  static reject(e) {
    return new y((n, s) => {
      s(e);
    });
  }
  static waitFor(e) {
    return new y((n, s) => {
      let r = 0, i = 0, o = !1;
      e.forEach((a) => {
        ++r, a.next(() => {
          ++i, o && i === r && n();
        }, (c) => s(c));
      }), o = !0, i === r && n();
    });
  }
  /**
   * Given an array of predicate functions that asynchronously evaluate to a
   * boolean, implements a short-circuiting `or` between the results. Predicates
   * will be evaluated until one of them returns `true`, then stop. The final
   * result will be whether any of them returned `true`.
   */
  static or(e) {
    let n = y.resolve(!1);
    for (const s of e)
      n = n.next((r) => r ? y.resolve(r) : s());
    return n;
  }
  static forEach(e, n) {
    const s = [];
    return e.forEach((r, i) => {
      s.push(n.call(this, r, i));
    }), this.waitFor(s);
  }
  /**
   * Concurrently map all array elements through asynchronous function.
   */
  static mapArray(e, n) {
    return new y((s, r) => {
      const i = e.length, o = new Array(i);
      let a = 0;
      for (let c = 0; c < i; c++) {
        const u = c;
        n(e[u]).next((l) => {
          o[u] = l, ++a, a === i && s(o);
        }, (l) => r(l));
      }
    });
  }
  /**
   * An alternative to recursive PersistencePromise calls, that avoids
   * potential memory problems from unbounded chains of promises.
   *
   * The `action` will be called repeatedly while `condition` is true.
   */
  static doWhile(e, n) {
    return new y((s, r) => {
      const i = () => {
        e() === !0 ? n().next(() => {
          i();
        }, r) : s();
      };
      i();
    });
  }
}
function as(t) {
  return t.name === "IndexedDbTransactionError";
}
/**
 * @license
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ro {
  constructor(e, n) {
    this.previousValue = e, n && (n.sequenceNumberHandler = (s) => this.ut(s), this.ct = (s) => n.writeSequenceNumber(s));
  }
  ut(e) {
    return this.previousValue = Math.max(e, this.previousValue), this.previousValue;
  }
  next() {
    const e = ++this.previousValue;
    return this.ct && this.ct(e), e;
  }
}
Ro.at = -1;
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Fm {
  /**
   * Constructs a DatabaseInfo using the provided host, databaseId and
   * persistenceKey.
   *
   * @param databaseId - The database to use.
   * @param appId - The Firebase App Id.
   * @param persistenceKey - A unique identifier for this Firestore's local
   * storage (used in conjunction with the databaseId).
   * @param host - The Firestore backend host to connect to.
   * @param ssl - Whether to use SSL when connecting.
   * @param forceLongPolling - Whether to use the forceLongPolling option
   * when using WebChannel as the network transport.
   * @param autoDetectLongPolling - Whether to use the detectBufferingProxy
   * option when using WebChannel as the network transport.
   * @param useFetchStreams Whether to use the Fetch API instead of
   * XMLHTTPRequest
   */
  constructor(e, n, s, r, i, o, a, c) {
    this.databaseId = e, this.appId = n, this.persistenceKey = s, this.host = r, this.ssl = i, this.forceLongPolling = o, this.autoDetectLongPolling = a, this.useFetchStreams = c;
  }
}
class Bn {
  constructor(e, n) {
    this.projectId = e, this.database = n || "(default)";
  }
  static empty() {
    return new Bn("", "");
  }
  get isDefaultDatabase() {
    return this.database === "(default)";
  }
  isEqual(e) {
    return e instanceof Bn && e.projectId === this.projectId && e.database === this.database;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Va(t) {
  let e = 0;
  for (const n in t)
    Object.prototype.hasOwnProperty.call(t, n) && e++;
  return e;
}
function xt(t, e) {
  for (const n in t)
    Object.prototype.hasOwnProperty.call(t, n) && e(n, t[n]);
}
function ul(t) {
  for (const e in t)
    if (Object.prototype.hasOwnProperty.call(t, e))
      return !1;
  return !0;
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Ar(t) {
  return t == null;
}
function Js(t) {
  return t === 0 && 1 / t == -1 / 0;
}
function Mm(t) {
  return typeof t == "number" && Number.isInteger(t) && !Js(t) && t <= Number.MAX_SAFE_INTEGER && t >= Number.MIN_SAFE_INTEGER;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ye {
  constructor(e) {
    this.binaryString = e;
  }
  static fromBase64String(e) {
    const n = atob(e);
    return new ye(n);
  }
  static fromUint8Array(e) {
    const n = (
      /**
      * Helper function to convert an Uint8array to a binary string.
      */
      function(s) {
        let r = "";
        for (let i = 0; i < s.length; ++i)
          r += String.fromCharCode(s[i]);
        return r;
      }(e)
    );
    return new ye(n);
  }
  [Symbol.iterator]() {
    let e = 0;
    return {
      next: () => e < this.binaryString.length ? {
        value: this.binaryString.charCodeAt(e++),
        done: !1
      } : {
        value: void 0,
        done: !0
      }
    };
  }
  toBase64() {
    return e = this.binaryString, btoa(e);
    var e;
  }
  toUint8Array() {
    return function(e) {
      const n = new Uint8Array(e.length);
      for (let s = 0; s < e.length; s++)
        n[s] = e.charCodeAt(s);
      return n;
    }(this.binaryString);
  }
  approximateByteSize() {
    return 2 * this.binaryString.length;
  }
  compareTo(e) {
    return B(this.binaryString, e.binaryString);
  }
  isEqual(e) {
    return this.binaryString === e.binaryString;
  }
}
ye.EMPTY_BYTE_STRING = new ye("");
const $m = new RegExp(/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(?:\.(\d+))?Z$/);
function ht(t) {
  if (j(!!t), typeof t == "string") {
    let e = 0;
    const n = $m.exec(t);
    if (j(!!n), n[1]) {
      let r = n[1];
      r = (r + "000000000").substr(0, 9), e = Number(r);
    }
    const s = new Date(t);
    return {
      seconds: Math.floor(s.getTime() / 1e3),
      nanos: e
    };
  }
  return {
    seconds: J(t.seconds),
    nanos: J(t.nanos)
  };
}
function J(t) {
  return typeof t == "number" ? t : typeof t == "string" ? Number(t) : 0;
}
function sn(t) {
  return typeof t == "string" ? ye.fromBase64String(t) : ye.fromUint8Array(t);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function ll(t) {
  var e, n;
  return ((n = (((e = t?.mapValue) === null || e === void 0 ? void 0 : e.fields) || {}).__type__) === null || n === void 0 ? void 0 : n.stringValue) === "server_timestamp";
}
function hl(t) {
  const e = t.mapValue.fields.__previous_value__;
  return ll(e) ? hl(e) : e;
}
function qn(t) {
  const e = ht(t.mapValue.fields.__local_write_time__.timestampValue);
  return new X(e.seconds, e.nanos);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const bs = {
  mapValue: {
    fields: {
      __type__: {
        stringValue: "__max__"
      }
    }
  }
};
function Lt(t) {
  return "nullValue" in t ? 0 : "booleanValue" in t ? 1 : "integerValue" in t || "doubleValue" in t ? 2 : "timestampValue" in t ? 3 : "stringValue" in t ? 5 : "bytesValue" in t ? 6 : "referenceValue" in t ? 7 : "geoPointValue" in t ? 8 : "arrayValue" in t ? 9 : "mapValue" in t ? ll(t) ? 4 : Um(t) ? 9007199254740991 : 10 : A();
}
function Be(t, e) {
  if (t === e)
    return !0;
  const n = Lt(t);
  if (n !== Lt(e))
    return !1;
  switch (n) {
    case 0:
    case 9007199254740991:
      return !0;
    case 1:
      return t.booleanValue === e.booleanValue;
    case 4:
      return qn(t).isEqual(qn(e));
    case 3:
      return function(s, r) {
        if (typeof s.timestampValue == "string" && typeof r.timestampValue == "string" && s.timestampValue.length === r.timestampValue.length)
          return s.timestampValue === r.timestampValue;
        const i = ht(s.timestampValue), o = ht(r.timestampValue);
        return i.seconds === o.seconds && i.nanos === o.nanos;
      }(t, e);
    case 5:
      return t.stringValue === e.stringValue;
    case 6:
      return function(s, r) {
        return sn(s.bytesValue).isEqual(sn(r.bytesValue));
      }(t, e);
    case 7:
      return t.referenceValue === e.referenceValue;
    case 8:
      return function(s, r) {
        return J(s.geoPointValue.latitude) === J(r.geoPointValue.latitude) && J(s.geoPointValue.longitude) === J(r.geoPointValue.longitude);
      }(t, e);
    case 2:
      return function(s, r) {
        if ("integerValue" in s && "integerValue" in r)
          return J(s.integerValue) === J(r.integerValue);
        if ("doubleValue" in s && "doubleValue" in r) {
          const i = J(s.doubleValue), o = J(r.doubleValue);
          return i === o ? Js(i) === Js(o) : isNaN(i) && isNaN(o);
        }
        return !1;
      }(t, e);
    case 9:
      return nn(t.arrayValue.values || [], e.arrayValue.values || [], Be);
    case 10:
      return function(s, r) {
        const i = s.mapValue.fields || {}, o = r.mapValue.fields || {};
        if (Va(i) !== Va(o))
          return !1;
        for (const a in i)
          if (i.hasOwnProperty(a) && (o[a] === void 0 || !Be(i[a], o[a])))
            return !1;
        return !0;
      }(t, e);
    default:
      return A();
  }
}
function Vn(t, e) {
  return (t.values || []).find((n) => Be(n, e)) !== void 0;
}
function rn(t, e) {
  if (t === e)
    return 0;
  const n = Lt(t), s = Lt(e);
  if (n !== s)
    return B(n, s);
  switch (n) {
    case 0:
    case 9007199254740991:
      return 0;
    case 1:
      return B(t.booleanValue, e.booleanValue);
    case 2:
      return function(r, i) {
        const o = J(r.integerValue || r.doubleValue), a = J(i.integerValue || i.doubleValue);
        return o < a ? -1 : o > a ? 1 : o === a ? 0 : (
          // one or both are NaN.
          isNaN(o) ? isNaN(a) ? 0 : -1 : 1
        );
      }(t, e);
    case 3:
      return ja(t.timestampValue, e.timestampValue);
    case 4:
      return ja(qn(t), qn(e));
    case 5:
      return B(t.stringValue, e.stringValue);
    case 6:
      return function(r, i) {
        const o = sn(r), a = sn(i);
        return o.compareTo(a);
      }(t.bytesValue, e.bytesValue);
    case 7:
      return function(r, i) {
        const o = r.split("/"), a = i.split("/");
        for (let c = 0; c < o.length && c < a.length; c++) {
          const u = B(o[c], a[c]);
          if (u !== 0)
            return u;
        }
        return B(o.length, a.length);
      }(t.referenceValue, e.referenceValue);
    case 8:
      return function(r, i) {
        const o = B(J(r.latitude), J(i.latitude));
        return o !== 0 ? o : B(J(r.longitude), J(i.longitude));
      }(t.geoPointValue, e.geoPointValue);
    case 9:
      return function(r, i) {
        const o = r.values || [], a = i.values || [];
        for (let c = 0; c < o.length && c < a.length; ++c) {
          const u = rn(o[c], a[c]);
          if (u)
            return u;
        }
        return B(o.length, a.length);
      }(t.arrayValue, e.arrayValue);
    case 10:
      return function(r, i) {
        if (r === bs.mapValue && i === bs.mapValue)
          return 0;
        if (r === bs.mapValue)
          return 1;
        if (i === bs.mapValue)
          return -1;
        const o = r.fields || {}, a = Object.keys(o), c = i.fields || {}, u = Object.keys(c);
        a.sort(), u.sort();
        for (let l = 0; l < a.length && l < u.length; ++l) {
          const h = B(a[l], u[l]);
          if (h !== 0)
            return h;
          const d = rn(o[a[l]], c[u[l]]);
          if (d !== 0)
            return d;
        }
        return B(a.length, u.length);
      }(t.mapValue, e.mapValue);
    default:
      throw A();
  }
}
function ja(t, e) {
  if (typeof t == "string" && typeof e == "string" && t.length === e.length)
    return B(t, e);
  const n = ht(t), s = ht(e), r = B(n.seconds, s.seconds);
  return r !== 0 ? r : B(n.nanos, s.nanos);
}
function on(t) {
  return $i(t);
}
function $i(t) {
  return "nullValue" in t ? "null" : "booleanValue" in t ? "" + t.booleanValue : "integerValue" in t ? "" + t.integerValue : "doubleValue" in t ? "" + t.doubleValue : "timestampValue" in t ? function(s) {
    const r = ht(s);
    return `time(${r.seconds},${r.nanos})`;
  }(t.timestampValue) : "stringValue" in t ? t.stringValue : "bytesValue" in t ? sn(t.bytesValue).toBase64() : "referenceValue" in t ? (n = t.referenceValue, I.fromName(n).toString()) : "geoPointValue" in t ? `geo(${(e = t.geoPointValue).latitude},${e.longitude})` : "arrayValue" in t ? function(s) {
    let r = "[", i = !0;
    for (const o of s.values || [])
      i ? i = !1 : r += ",", r += $i(o);
    return r + "]";
  }(t.arrayValue) : "mapValue" in t ? function(s) {
    const r = Object.keys(s.fields || {}).sort();
    let i = "{", o = !0;
    for (const a of r)
      o ? o = !1 : i += ",", i += `${a}:${$i(s.fields[a])}`;
    return i + "}";
  }(t.mapValue) : A();
  var e, n;
}
function Ga(t, e) {
  return {
    referenceValue: `projects/${t.projectId}/databases/${t.database}/documents/${e.path.canonicalString()}`
  };
}
function Ui(t) {
  return !!t && "integerValue" in t;
}
function ko(t) {
  return !!t && "arrayValue" in t;
}
function Wa(t) {
  return !!t && "nullValue" in t;
}
function Ha(t) {
  return !!t && "doubleValue" in t && isNaN(Number(t.doubleValue));
}
function Rs(t) {
  return !!t && "mapValue" in t;
}
function An(t) {
  if (t.geoPointValue)
    return {
      geoPointValue: Object.assign({}, t.geoPointValue)
    };
  if (t.timestampValue && typeof t.timestampValue == "object")
    return {
      timestampValue: Object.assign({}, t.timestampValue)
    };
  if (t.mapValue) {
    const e = {
      mapValue: {
        fields: {}
      }
    };
    return xt(t.mapValue.fields, (n, s) => e.mapValue.fields[n] = An(s)), e;
  }
  if (t.arrayValue) {
    const e = {
      arrayValue: {
        values: []
      }
    };
    for (let n = 0; n < (t.arrayValue.values || []).length; ++n)
      e.arrayValue.values[n] = An(t.arrayValue.values[n]);
    return e;
  }
  return Object.assign({}, t);
}
function Um(t) {
  return (((t.mapValue || {}).fields || {}).__type__ || {}).stringValue === "__max__";
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ys {
  constructor(e, n) {
    this.position = e, this.inclusive = n;
  }
}
function za(t, e, n) {
  let s = 0;
  for (let r = 0; r < t.position.length; r++) {
    const i = e[r], o = t.position[r];
    if (i.field.isKeyField() ? s = I.comparator(I.fromName(o.referenceValue), n.key) : s = rn(o, n.data.field(i.field)), i.dir === "desc" && (s *= -1), s !== 0)
      break;
  }
  return s;
}
function Qa(t, e) {
  if (t === null)
    return e === null;
  if (e === null || t.inclusive !== e.inclusive || t.position.length !== e.position.length)
    return !1;
  for (let n = 0; n < t.position.length; n++)
    if (!Be(t.position[n], e.position[n]))
      return !1;
  return !0;
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class dl {
}
class Y extends dl {
  constructor(e, n, s) {
    super(), this.field = e, this.op = n, this.value = s;
  }
  /**
   * Creates a filter based on the provided arguments.
   */
  static create(e, n, s) {
    return e.isKeyField() ? n === "in" || n === "not-in" ? this.createKeyFieldInFilter(e, n, s) : new Bm(e, n, s) : n === "array-contains" ? new jm(e, s) : n === "in" ? new Gm(e, s) : n === "not-in" ? new Wm(e, s) : n === "array-contains-any" ? new Hm(e, s) : new Y(e, n, s);
  }
  static createKeyFieldInFilter(e, n, s) {
    return n === "in" ? new qm(e, s) : new Vm(e, s);
  }
  matches(e) {
    const n = e.data.field(this.field);
    return this.op === "!=" ? n !== null && this.matchesComparison(rn(n, this.value)) : n !== null && Lt(this.value) === Lt(n) && this.matchesComparison(rn(n, this.value));
  }
  matchesComparison(e) {
    switch (this.op) {
      case "<":
        return e < 0;
      case "<=":
        return e <= 0;
      case "==":
        return e === 0;
      case "!=":
        return e !== 0;
      case ">":
        return e > 0;
      case ">=":
        return e >= 0;
      default:
        return A();
    }
  }
  isInequality() {
    return [
      "<",
      "<=",
      ">",
      ">=",
      "!=",
      "not-in"
      /* Operator.NOT_IN */
    ].indexOf(this.op) >= 0;
  }
  getFlattenedFilters() {
    return [this];
  }
  getFilters() {
    return [this];
  }
  getFirstInequalityField() {
    return this.isInequality() ? this.field : null;
  }
}
class Oe extends dl {
  constructor(e, n) {
    super(), this.filters = e, this.op = n, this.ht = null;
  }
  /**
   * Creates a filter based on the provided arguments.
   */
  static create(e, n) {
    return new Oe(e, n);
  }
  matches(e) {
    return fl(this) ? this.filters.find((n) => !n.matches(e)) === void 0 : this.filters.find((n) => n.matches(e)) !== void 0;
  }
  getFlattenedFilters() {
    return this.ht !== null || (this.ht = this.filters.reduce((e, n) => e.concat(n.getFlattenedFilters()), [])), this.ht;
  }
  // Returns a mutable copy of `this.filters`
  getFilters() {
    return Object.assign([], this.filters);
  }
  getFirstInequalityField() {
    const e = this.lt((n) => n.isInequality());
    return e !== null ? e.field : null;
  }
  // Performs a depth-first search to find and return the first FieldFilter in the composite filter
  // that satisfies the predicate. Returns `null` if none of the FieldFilters satisfy the
  // predicate.
  lt(e) {
    for (const n of this.getFlattenedFilters())
      if (e(n))
        return n;
    return null;
  }
}
function fl(t) {
  return t.op === "and";
}
function pl(t) {
  return xm(t) && fl(t);
}
function xm(t) {
  for (const e of t.filters)
    if (e instanceof Oe)
      return !1;
  return !0;
}
function xi(t) {
  if (t instanceof Y)
    return t.field.canonicalString() + t.op.toString() + on(t.value);
  if (pl(t))
    return t.filters.map((e) => xi(e)).join(",");
  {
    const e = t.filters.map((n) => xi(n)).join(",");
    return `${t.op}(${e})`;
  }
}
function gl(t, e) {
  return t instanceof Y ? function(n, s) {
    return s instanceof Y && n.op === s.op && n.field.isEqual(s.field) && Be(n.value, s.value);
  }(t, e) : t instanceof Oe ? function(n, s) {
    return s instanceof Oe && n.op === s.op && n.filters.length === s.filters.length ? n.filters.reduce((r, i, o) => r && gl(i, s.filters[o]), !0) : !1;
  }(t, e) : void A();
}
function ml(t) {
  return t instanceof Y ? function(e) {
    return `${e.field.canonicalString()} ${e.op} ${on(e.value)}`;
  }(t) : t instanceof Oe ? function(e) {
    return e.op.toString() + " {" + e.getFilters().map(ml).join(" ,") + "}";
  }(t) : "Filter";
}
class Bm extends Y {
  constructor(e, n, s) {
    super(e, n, s), this.key = I.fromName(s.referenceValue);
  }
  matches(e) {
    const n = I.comparator(e.key, this.key);
    return this.matchesComparison(n);
  }
}
class qm extends Y {
  constructor(e, n) {
    super(e, "in", n), this.keys = yl("in", n);
  }
  matches(e) {
    return this.keys.some((n) => n.isEqual(e.key));
  }
}
class Vm extends Y {
  constructor(e, n) {
    super(e, "not-in", n), this.keys = yl("not-in", n);
  }
  matches(e) {
    return !this.keys.some((n) => n.isEqual(e.key));
  }
}
function yl(t, e) {
  var n;
  return (((n = e.arrayValue) === null || n === void 0 ? void 0 : n.values) || []).map((s) => I.fromName(s.referenceValue));
}
class jm extends Y {
  constructor(e, n) {
    super(e, "array-contains", n);
  }
  matches(e) {
    const n = e.data.field(this.field);
    return ko(n) && Vn(n.arrayValue, this.value);
  }
}
class Gm extends Y {
  constructor(e, n) {
    super(e, "in", n);
  }
  matches(e) {
    const n = e.data.field(this.field);
    return n !== null && Vn(this.value.arrayValue, n);
  }
}
class Wm extends Y {
  constructor(e, n) {
    super(e, "not-in", n);
  }
  matches(e) {
    if (Vn(this.value.arrayValue, {
      nullValue: "NULL_VALUE"
    }))
      return !1;
    const n = e.data.field(this.field);
    return n !== null && !Vn(this.value.arrayValue, n);
  }
}
class Hm extends Y {
  constructor(e, n) {
    super(e, "array-contains-any", n);
  }
  matches(e) {
    const n = e.data.field(this.field);
    return !(!ko(n) || !n.arrayValue.values) && n.arrayValue.values.some((s) => Vn(this.value.arrayValue, s));
  }
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Cn {
  constructor(e, n = "asc") {
    this.field = e, this.dir = n;
  }
}
function zm(t, e) {
  return t.dir === e.dir && t.field.isEqual(e.field);
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ee {
  constructor(e, n) {
    this.comparator = e, this.root = n || oe.EMPTY;
  }
  // Returns a copy of the map, with the specified key/value added or replaced.
  insert(e, n) {
    return new ee(this.comparator, this.root.insert(e, n, this.comparator).copy(null, null, oe.BLACK, null, null));
  }
  // Returns a copy of the map, with the specified key removed.
  remove(e) {
    return new ee(this.comparator, this.root.remove(e, this.comparator).copy(null, null, oe.BLACK, null, null));
  }
  // Returns the value of the node with the given key, or null.
  get(e) {
    let n = this.root;
    for (; !n.isEmpty(); ) {
      const s = this.comparator(e, n.key);
      if (s === 0)
        return n.value;
      s < 0 ? n = n.left : s > 0 && (n = n.right);
    }
    return null;
  }
  // Returns the index of the element in this sorted map, or -1 if it doesn't
  // exist.
  indexOf(e) {
    let n = 0, s = this.root;
    for (; !s.isEmpty(); ) {
      const r = this.comparator(e, s.key);
      if (r === 0)
        return n + s.left.size;
      r < 0 ? s = s.left : (
        // Count all nodes left of the node plus the node itself
        (n += s.left.size + 1, s = s.right)
      );
    }
    return -1;
  }
  isEmpty() {
    return this.root.isEmpty();
  }
  // Returns the total number of nodes in the map.
  get size() {
    return this.root.size;
  }
  // Returns the minimum key in the map.
  minKey() {
    return this.root.minKey();
  }
  // Returns the maximum key in the map.
  maxKey() {
    return this.root.maxKey();
  }
  // Traverses the map in key order and calls the specified action function
  // for each key/value pair. If action returns true, traversal is aborted.
  // Returns the first truthy value returned by action, or the last falsey
  // value returned by action.
  inorderTraversal(e) {
    return this.root.inorderTraversal(e);
  }
  forEach(e) {
    this.inorderTraversal((n, s) => (e(n, s), !1));
  }
  toString() {
    const e = [];
    return this.inorderTraversal((n, s) => (e.push(`${n}:${s}`), !1)), `{${e.join(", ")}}`;
  }
  // Traverses the map in reverse key order and calls the specified action
  // function for each key/value pair. If action returns true, traversal is
  // aborted.
  // Returns the first truthy value returned by action, or the last falsey
  // value returned by action.
  reverseTraversal(e) {
    return this.root.reverseTraversal(e);
  }
  // Returns an iterator over the SortedMap.
  getIterator() {
    return new As(this.root, null, this.comparator, !1);
  }
  getIteratorFrom(e) {
    return new As(this.root, e, this.comparator, !1);
  }
  getReverseIterator() {
    return new As(this.root, null, this.comparator, !0);
  }
  getReverseIteratorFrom(e) {
    return new As(this.root, e, this.comparator, !0);
  }
}
class As {
  constructor(e, n, s, r) {
    this.isReverse = r, this.nodeStack = [];
    let i = 1;
    for (; !e.isEmpty(); )
      if (i = n ? s(e.key, n) : 1, // flip the comparison if we're going in reverse
      n && r && (i *= -1), i < 0)
        e = this.isReverse ? e.left : e.right;
      else {
        if (i === 0) {
          this.nodeStack.push(e);
          break;
        }
        this.nodeStack.push(e), e = this.isReverse ? e.right : e.left;
      }
  }
  getNext() {
    let e = this.nodeStack.pop();
    const n = {
      key: e.key,
      value: e.value
    };
    if (this.isReverse)
      for (e = e.left; !e.isEmpty(); )
        this.nodeStack.push(e), e = e.right;
    else
      for (e = e.right; !e.isEmpty(); )
        this.nodeStack.push(e), e = e.left;
    return n;
  }
  hasNext() {
    return this.nodeStack.length > 0;
  }
  peek() {
    if (this.nodeStack.length === 0)
      return null;
    const e = this.nodeStack[this.nodeStack.length - 1];
    return {
      key: e.key,
      value: e.value
    };
  }
}
class oe {
  constructor(e, n, s, r, i) {
    this.key = e, this.value = n, this.color = s ?? oe.RED, this.left = r ?? oe.EMPTY, this.right = i ?? oe.EMPTY, this.size = this.left.size + 1 + this.right.size;
  }
  // Returns a copy of the current node, optionally replacing pieces of it.
  copy(e, n, s, r, i) {
    return new oe(e ?? this.key, n ?? this.value, s ?? this.color, r ?? this.left, i ?? this.right);
  }
  isEmpty() {
    return !1;
  }
  // Traverses the tree in key order and calls the specified action function
  // for each node. If action returns true, traversal is aborted.
  // Returns the first truthy value returned by action, or the last falsey
  // value returned by action.
  inorderTraversal(e) {
    return this.left.inorderTraversal(e) || e(this.key, this.value) || this.right.inorderTraversal(e);
  }
  // Traverses the tree in reverse key order and calls the specified action
  // function for each node. If action returns true, traversal is aborted.
  // Returns the first truthy value returned by action, or the last falsey
  // value returned by action.
  reverseTraversal(e) {
    return this.right.reverseTraversal(e) || e(this.key, this.value) || this.left.reverseTraversal(e);
  }
  // Returns the minimum node in the tree.
  min() {
    return this.left.isEmpty() ? this : this.left.min();
  }
  // Returns the maximum key in the tree.
  minKey() {
    return this.min().key;
  }
  // Returns the maximum key in the tree.
  maxKey() {
    return this.right.isEmpty() ? this.key : this.right.maxKey();
  }
  // Returns new tree, with the key/value added.
  insert(e, n, s) {
    let r = this;
    const i = s(e, r.key);
    return r = i < 0 ? r.copy(null, null, null, r.left.insert(e, n, s), null) : i === 0 ? r.copy(null, n, null, null, null) : r.copy(null, null, null, null, r.right.insert(e, n, s)), r.fixUp();
  }
  removeMin() {
    if (this.left.isEmpty())
      return oe.EMPTY;
    let e = this;
    return e.left.isRed() || e.left.left.isRed() || (e = e.moveRedLeft()), e = e.copy(null, null, null, e.left.removeMin(), null), e.fixUp();
  }
  // Returns new tree, with the specified item removed.
  remove(e, n) {
    let s, r = this;
    if (n(e, r.key) < 0)
      r.left.isEmpty() || r.left.isRed() || r.left.left.isRed() || (r = r.moveRedLeft()), r = r.copy(null, null, null, r.left.remove(e, n), null);
    else {
      if (r.left.isRed() && (r = r.rotateRight()), r.right.isEmpty() || r.right.isRed() || r.right.left.isRed() || (r = r.moveRedRight()), n(e, r.key) === 0) {
        if (r.right.isEmpty())
          return oe.EMPTY;
        s = r.right.min(), r = r.copy(s.key, s.value, null, null, r.right.removeMin());
      }
      r = r.copy(null, null, null, null, r.right.remove(e, n));
    }
    return r.fixUp();
  }
  isRed() {
    return this.color;
  }
  // Returns new tree after performing any needed rotations.
  fixUp() {
    let e = this;
    return e.right.isRed() && !e.left.isRed() && (e = e.rotateLeft()), e.left.isRed() && e.left.left.isRed() && (e = e.rotateRight()), e.left.isRed() && e.right.isRed() && (e = e.colorFlip()), e;
  }
  moveRedLeft() {
    let e = this.colorFlip();
    return e.right.left.isRed() && (e = e.copy(null, null, null, null, e.right.rotateRight()), e = e.rotateLeft(), e = e.colorFlip()), e;
  }
  moveRedRight() {
    let e = this.colorFlip();
    return e.left.left.isRed() && (e = e.rotateRight(), e = e.colorFlip()), e;
  }
  rotateLeft() {
    const e = this.copy(null, null, oe.RED, null, this.right.left);
    return this.right.copy(null, null, this.color, e, null);
  }
  rotateRight() {
    const e = this.copy(null, null, oe.RED, this.left.right, null);
    return this.left.copy(null, null, this.color, null, e);
  }
  colorFlip() {
    const e = this.left.copy(null, null, !this.left.color, null, null), n = this.right.copy(null, null, !this.right.color, null, null);
    return this.copy(null, null, !this.color, e, n);
  }
  // For testing.
  checkMaxDepth() {
    const e = this.check();
    return Math.pow(2, e) <= this.size + 1;
  }
  // In a balanced RB tree, the black-depth (number of black nodes) from root to
  // leaves is equal on both sides.  This function verifies that or asserts.
  check() {
    if (this.isRed() && this.left.isRed() || this.right.isRed())
      throw A();
    const e = this.left.check();
    if (e !== this.right.check())
      throw A();
    return e + (this.isRed() ? 0 : 1);
  }
}
oe.EMPTY = null, oe.RED = !0, oe.BLACK = !1;
oe.EMPTY = new // Represents an empty node (a leaf node in the Red-Black Tree).
class {
  constructor() {
    this.size = 0;
  }
  get key() {
    throw A();
  }
  get value() {
    throw A();
  }
  get color() {
    throw A();
  }
  get left() {
    throw A();
  }
  get right() {
    throw A();
  }
  // Returns a copy of the current node.
  copy(t, e, n, s, r) {
    return this;
  }
  // Returns a copy of the tree, with the specified key/value added.
  insert(t, e, n) {
    return new oe(t, e);
  }
  // Returns a copy of the tree, with the specified key removed.
  remove(t, e) {
    return this;
  }
  isEmpty() {
    return !0;
  }
  inorderTraversal(t) {
    return !1;
  }
  reverseTraversal(t) {
    return !1;
  }
  minKey() {
    return null;
  }
  maxKey() {
    return null;
  }
  isRed() {
    return !1;
  }
  // For testing.
  checkMaxDepth() {
    return !0;
  }
  check() {
    return 0;
  }
}();
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Z {
  constructor(e) {
    this.comparator = e, this.data = new ee(this.comparator);
  }
  has(e) {
    return this.data.get(e) !== null;
  }
  first() {
    return this.data.minKey();
  }
  last() {
    return this.data.maxKey();
  }
  get size() {
    return this.data.size;
  }
  indexOf(e) {
    return this.data.indexOf(e);
  }
  /** Iterates elements in order defined by "comparator" */
  forEach(e) {
    this.data.inorderTraversal((n, s) => (e(n), !1));
  }
  /** Iterates over `elem`s such that: range[0] &lt;= elem &lt; range[1]. */
  forEachInRange(e, n) {
    const s = this.data.getIteratorFrom(e[0]);
    for (; s.hasNext(); ) {
      const r = s.getNext();
      if (this.comparator(r.key, e[1]) >= 0)
        return;
      n(r.key);
    }
  }
  /**
   * Iterates over `elem`s such that: start &lt;= elem until false is returned.
   */
  forEachWhile(e, n) {
    let s;
    for (s = n !== void 0 ? this.data.getIteratorFrom(n) : this.data.getIterator(); s.hasNext(); )
      if (!e(s.getNext().key))
        return;
  }
  /** Finds the least element greater than or equal to `elem`. */
  firstAfterOrEqual(e) {
    const n = this.data.getIteratorFrom(e);
    return n.hasNext() ? n.getNext().key : null;
  }
  getIterator() {
    return new Ka(this.data.getIterator());
  }
  getIteratorFrom(e) {
    return new Ka(this.data.getIteratorFrom(e));
  }
  /** Inserts or updates an element */
  add(e) {
    return this.copy(this.data.remove(e).insert(e, !0));
  }
  /** Deletes an element */
  delete(e) {
    return this.has(e) ? this.copy(this.data.remove(e)) : this;
  }
  isEmpty() {
    return this.data.isEmpty();
  }
  unionWith(e) {
    let n = this;
    return n.size < e.size && (n = e, e = this), e.forEach((s) => {
      n = n.add(s);
    }), n;
  }
  isEqual(e) {
    if (!(e instanceof Z) || this.size !== e.size)
      return !1;
    const n = this.data.getIterator(), s = e.data.getIterator();
    for (; n.hasNext(); ) {
      const r = n.getNext().key, i = s.getNext().key;
      if (this.comparator(r, i) !== 0)
        return !1;
    }
    return !0;
  }
  toArray() {
    const e = [];
    return this.forEach((n) => {
      e.push(n);
    }), e;
  }
  toString() {
    const e = [];
    return this.forEach((n) => e.push(n)), "SortedSet(" + e.toString() + ")";
  }
  copy(e) {
    const n = new Z(this.comparator);
    return n.data = e, n;
  }
}
class Ka {
  constructor(e) {
    this.iter = e;
  }
  getNext() {
    return this.iter.getNext().key;
  }
  hasNext() {
    return this.iter.hasNext();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Se {
  constructor(e) {
    this.fields = e, // TODO(dimond): validation of FieldMask
    // Sort the field mask to support `FieldMask.isEqual()` and assert below.
    e.sort(fe.comparator);
  }
  static empty() {
    return new Se([]);
  }
  /**
   * Returns a new FieldMask object that is the result of adding all the given
   * fields paths to this field mask.
   */
  unionWith(e) {
    let n = new Z(fe.comparator);
    for (const s of this.fields)
      n = n.add(s);
    for (const s of e)
      n = n.add(s);
    return new Se(n.toArray());
  }
  /**
   * Verifies that `fieldPath` is included by at least one field in this field
   * mask.
   *
   * This is an O(n) operation, where `n` is the size of the field mask.
   */
  covers(e) {
    for (const n of this.fields)
      if (n.isPrefixOf(e))
        return !0;
    return !1;
  }
  isEqual(e) {
    return nn(this.fields, e.fields, (n, s) => n.isEqual(s));
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ve {
  constructor(e) {
    this.value = e;
  }
  static empty() {
    return new ve({
      mapValue: {}
    });
  }
  /**
   * Returns the value at the given path or null.
   *
   * @param path - the path to search
   * @returns The value at the path or null if the path is not set.
   */
  field(e) {
    if (e.isEmpty())
      return this.value;
    {
      let n = this.value;
      for (let s = 0; s < e.length - 1; ++s)
        if (n = (n.mapValue.fields || {})[e.get(s)], !Rs(n))
          return null;
      return n = (n.mapValue.fields || {})[e.lastSegment()], n || null;
    }
  }
  /**
   * Sets the field to the provided value.
   *
   * @param path - The field path to set.
   * @param value - The value to set.
   */
  set(e, n) {
    this.getFieldsMap(e.popLast())[e.lastSegment()] = An(n);
  }
  /**
   * Sets the provided fields to the provided values.
   *
   * @param data - A map of fields to values (or null for deletes).
   */
  setAll(e) {
    let n = fe.emptyPath(), s = {}, r = [];
    e.forEach((o, a) => {
      if (!n.isImmediateParentOf(a)) {
        const c = this.getFieldsMap(n);
        this.applyChanges(c, s, r), s = {}, r = [], n = a.popLast();
      }
      o ? s[a.lastSegment()] = An(o) : r.push(a.lastSegment());
    });
    const i = this.getFieldsMap(n);
    this.applyChanges(i, s, r);
  }
  /**
   * Removes the field at the specified path. If there is no field at the
   * specified path, nothing is changed.
   *
   * @param path - The field path to remove.
   */
  delete(e) {
    const n = this.field(e.popLast());
    Rs(n) && n.mapValue.fields && delete n.mapValue.fields[e.lastSegment()];
  }
  isEqual(e) {
    return Be(this.value, e.value);
  }
  /**
   * Returns the map that contains the leaf element of `path`. If the parent
   * entry does not yet exist, or if it is not a map, a new map will be created.
   */
  getFieldsMap(e) {
    let n = this.value;
    n.mapValue.fields || (n.mapValue = {
      fields: {}
    });
    for (let s = 0; s < e.length; ++s) {
      let r = n.mapValue.fields[e.get(s)];
      Rs(r) && r.mapValue.fields || (r = {
        mapValue: {
          fields: {}
        }
      }, n.mapValue.fields[e.get(s)] = r), n = r;
    }
    return n.mapValue.fields;
  }
  /**
   * Modifies `fieldsMap` by adding, replacing or deleting the specified
   * entries.
   */
  applyChanges(e, n, s) {
    xt(n, (r, i) => e[r] = i);
    for (const r of s)
      delete e[r];
  }
  clone() {
    return new ve(An(this.value));
  }
}
function _l(t) {
  const e = [];
  return xt(t.fields, (n, s) => {
    const r = new fe([n]);
    if (Rs(s)) {
      const i = _l(s.mapValue).fields;
      if (i.length === 0)
        e.push(r);
      else
        for (const o of i)
          e.push(r.child(o));
    } else
      e.push(r);
  }), new Se(e);
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class de {
  constructor(e, n, s, r, i, o, a) {
    this.key = e, this.documentType = n, this.version = s, this.readTime = r, this.createTime = i, this.data = o, this.documentState = a;
  }
  /**
   * Creates a document with no known version or data, but which can serve as
   * base document for mutations.
   */
  static newInvalidDocument(e) {
    return new de(
      e,
      0,
      /* version */
      k.min(),
      /* readTime */
      k.min(),
      /* createTime */
      k.min(),
      ve.empty(),
      0
      /* DocumentState.SYNCED */
    );
  }
  /**
   * Creates a new document that is known to exist with the given data at the
   * given version.
   */
  static newFoundDocument(e, n, s, r) {
    return new de(
      e,
      1,
      /* version */
      n,
      /* readTime */
      k.min(),
      /* createTime */
      s,
      r,
      0
      /* DocumentState.SYNCED */
    );
  }
  /** Creates a new document that is known to not exist at the given version. */
  static newNoDocument(e, n) {
    return new de(
      e,
      2,
      /* version */
      n,
      /* readTime */
      k.min(),
      /* createTime */
      k.min(),
      ve.empty(),
      0
      /* DocumentState.SYNCED */
    );
  }
  /**
   * Creates a new document that is known to exist at the given version but
   * whose data is not known (e.g. a document that was updated without a known
   * base document).
   */
  static newUnknownDocument(e, n) {
    return new de(
      e,
      3,
      /* version */
      n,
      /* readTime */
      k.min(),
      /* createTime */
      k.min(),
      ve.empty(),
      2
      /* DocumentState.HAS_COMMITTED_MUTATIONS */
    );
  }
  /**
   * Changes the document type to indicate that it exists and that its version
   * and data are known.
   */
  convertToFoundDocument(e, n) {
    return !this.createTime.isEqual(k.min()) || this.documentType !== 2 && this.documentType !== 0 || (this.createTime = e), this.version = e, this.documentType = 1, this.data = n, this.documentState = 0, this;
  }
  /**
   * Changes the document type to indicate that it doesn't exist at the given
   * version.
   */
  convertToNoDocument(e) {
    return this.version = e, this.documentType = 2, this.data = ve.empty(), this.documentState = 0, this;
  }
  /**
   * Changes the document type to indicate that it exists at a given version but
   * that its data is not known (e.g. a document that was updated without a known
   * base document).
   */
  convertToUnknownDocument(e) {
    return this.version = e, this.documentType = 3, this.data = ve.empty(), this.documentState = 2, this;
  }
  setHasCommittedMutations() {
    return this.documentState = 2, this;
  }
  setHasLocalMutations() {
    return this.documentState = 1, this.version = k.min(), this;
  }
  setReadTime(e) {
    return this.readTime = e, this;
  }
  get hasLocalMutations() {
    return this.documentState === 1;
  }
  get hasCommittedMutations() {
    return this.documentState === 2;
  }
  get hasPendingWrites() {
    return this.hasLocalMutations || this.hasCommittedMutations;
  }
  isValidDocument() {
    return this.documentType !== 0;
  }
  isFoundDocument() {
    return this.documentType === 1;
  }
  isNoDocument() {
    return this.documentType === 2;
  }
  isUnknownDocument() {
    return this.documentType === 3;
  }
  isEqual(e) {
    return e instanceof de && this.key.isEqual(e.key) && this.version.isEqual(e.version) && this.documentType === e.documentType && this.documentState === e.documentState && this.data.isEqual(e.data);
  }
  mutableCopy() {
    return new de(this.key, this.documentType, this.version, this.readTime, this.createTime, this.data.clone(), this.documentState);
  }
  toString() {
    return `Document(${this.key}, ${this.version}, ${JSON.stringify(this.data.value)}, {createTime: ${this.createTime}}), {documentType: ${this.documentType}}), {documentState: ${this.documentState}})`;
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Qm {
  constructor(e, n = null, s = [], r = [], i = null, o = null, a = null) {
    this.path = e, this.collectionGroup = n, this.orderBy = s, this.filters = r, this.limit = i, this.startAt = o, this.endAt = a, this.ft = null;
  }
}
function Ja(t, e = null, n = [], s = [], r = null, i = null, o = null) {
  return new Qm(t, e, n, s, r, i, o);
}
function Do(t) {
  const e = D(t);
  if (e.ft === null) {
    let n = e.path.canonicalString();
    e.collectionGroup !== null && (n += "|cg:" + e.collectionGroup), n += "|f:", n += e.filters.map((s) => xi(s)).join(","), n += "|ob:", n += e.orderBy.map((s) => function(r) {
      return r.field.canonicalString() + r.dir;
    }(s)).join(","), Ar(e.limit) || (n += "|l:", n += e.limit), e.startAt && (n += "|lb:", n += e.startAt.inclusive ? "b:" : "a:", n += e.startAt.position.map((s) => on(s)).join(",")), e.endAt && (n += "|ub:", n += e.endAt.inclusive ? "a:" : "b:", n += e.endAt.position.map((s) => on(s)).join(",")), e.ft = n;
  }
  return e.ft;
}
function Po(t, e) {
  if (t.limit !== e.limit || t.orderBy.length !== e.orderBy.length)
    return !1;
  for (let n = 0; n < t.orderBy.length; n++)
    if (!zm(t.orderBy[n], e.orderBy[n]))
      return !1;
  if (t.filters.length !== e.filters.length)
    return !1;
  for (let n = 0; n < t.filters.length; n++)
    if (!gl(t.filters[n], e.filters[n]))
      return !1;
  return t.collectionGroup === e.collectionGroup && !!t.path.isEqual(e.path) && !!Qa(t.startAt, e.startAt) && Qa(t.endAt, e.endAt);
}
function Bi(t) {
  return I.isDocumentKey(t.path) && t.collectionGroup === null && t.filters.length === 0;
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class cs {
  /**
   * Initializes a Query with a path and optional additional query constraints.
   * Path must currently be empty if this is a collection group query.
   */
  constructor(e, n = null, s = [], r = [], i = null, o = "F", a = null, c = null) {
    this.path = e, this.collectionGroup = n, this.explicitOrderBy = s, this.filters = r, this.limit = i, this.limitType = o, this.startAt = a, this.endAt = c, this.dt = null, // The corresponding `Target` of this `Query` instance.
    this._t = null, this.startAt, this.endAt;
  }
}
function Km(t, e, n, s, r, i, o, a) {
  return new cs(t, e, n, s, r, i, o, a);
}
function Lo(t) {
  return new cs(t);
}
function Ya(t) {
  return t.filters.length === 0 && t.limit === null && t.startAt == null && t.endAt == null && (t.explicitOrderBy.length === 0 || t.explicitOrderBy.length === 1 && t.explicitOrderBy[0].field.isKeyField());
}
function wl(t) {
  return t.explicitOrderBy.length > 0 ? t.explicitOrderBy[0].field : null;
}
function Fo(t) {
  for (const e of t.filters) {
    const n = e.getFirstInequalityField();
    if (n !== null)
      return n;
  }
  return null;
}
function El(t) {
  return t.collectionGroup !== null;
}
function Kt(t) {
  const e = D(t);
  if (e.dt === null) {
    e.dt = [];
    const n = Fo(e), s = wl(e);
    if (n !== null && s === null)
      n.isKeyField() || e.dt.push(new Cn(n)), e.dt.push(new Cn(
        fe.keyField(),
        "asc"
        /* Direction.ASCENDING */
      ));
    else {
      let r = !1;
      for (const i of e.explicitOrderBy)
        e.dt.push(i), i.field.isKeyField() && (r = !0);
      if (!r) {
        const i = e.explicitOrderBy.length > 0 ? e.explicitOrderBy[e.explicitOrderBy.length - 1].dir : "asc";
        e.dt.push(new Cn(fe.keyField(), i));
      }
    }
  }
  return e.dt;
}
function qe(t) {
  const e = D(t);
  if (!e._t)
    if (e.limitType === "F")
      e._t = Ja(e.path, e.collectionGroup, Kt(e), e.filters, e.limit, e.startAt, e.endAt);
    else {
      const n = [];
      for (const i of Kt(e)) {
        const o = i.dir === "desc" ? "asc" : "desc";
        n.push(new Cn(i.field, o));
      }
      const s = e.endAt ? new Ys(e.endAt.position, e.endAt.inclusive) : null, r = e.startAt ? new Ys(e.startAt.position, e.startAt.inclusive) : null;
      e._t = Ja(e.path, e.collectionGroup, n, e.filters, e.limit, s, r);
    }
  return e._t;
}
function qi(t, e) {
  e.getFirstInequalityField(), Fo(t);
  const n = t.filters.concat([e]);
  return new cs(t.path, t.collectionGroup, t.explicitOrderBy.slice(), n, t.limit, t.limitType, t.startAt, t.endAt);
}
function Vi(t, e, n) {
  return new cs(t.path, t.collectionGroup, t.explicitOrderBy.slice(), t.filters.slice(), e, n, t.startAt, t.endAt);
}
function Cr(t, e) {
  return Po(qe(t), qe(e)) && t.limitType === e.limitType;
}
function vl(t) {
  return `${Do(qe(t))}|lt:${t.limitType}`;
}
function ji(t) {
  return `Query(target=${function(e) {
    let n = e.path.canonicalString();
    return e.collectionGroup !== null && (n += " collectionGroup=" + e.collectionGroup), e.filters.length > 0 && (n += `, filters: [${e.filters.map((s) => ml(s)).join(", ")}]`), Ar(e.limit) || (n += ", limit: " + e.limit), e.orderBy.length > 0 && (n += `, orderBy: [${e.orderBy.map((s) => function(r) {
      return `${r.field.canonicalString()} (${r.dir})`;
    }(s)).join(", ")}]`), e.startAt && (n += ", startAt: ", n += e.startAt.inclusive ? "b:" : "a:", n += e.startAt.position.map((s) => on(s)).join(",")), e.endAt && (n += ", endAt: ", n += e.endAt.inclusive ? "a:" : "b:", n += e.endAt.position.map((s) => on(s)).join(",")), `Target(${n})`;
  }(qe(t))}; limitType=${t.limitType})`;
}
function Nr(t, e) {
  return e.isFoundDocument() && function(n, s) {
    const r = s.key.path;
    return n.collectionGroup !== null ? s.key.hasCollectionId(n.collectionGroup) && n.path.isPrefixOf(r) : I.isDocumentKey(n.path) ? n.path.isEqual(r) : n.path.isImmediateParentOf(r);
  }(t, e) && function(n, s) {
    for (const r of Kt(n))
      if (!r.field.isKeyField() && s.data.field(r.field) === null)
        return !1;
    return !0;
  }(t, e) && function(n, s) {
    for (const r of n.filters)
      if (!r.matches(s))
        return !1;
    return !0;
  }(t, e) && function(n, s) {
    return !(n.startAt && !/**
    * Returns true if a document sorts before a bound using the provided sort
    * order.
    */
    function(r, i, o) {
      const a = za(r, i, o);
      return r.inclusive ? a <= 0 : a < 0;
    }(n.startAt, Kt(n), s) || n.endAt && !function(r, i, o) {
      const a = za(r, i, o);
      return r.inclusive ? a >= 0 : a > 0;
    }(n.endAt, Kt(n), s));
  }(t, e);
}
function Jm(t) {
  return t.collectionGroup || (t.path.length % 2 == 1 ? t.path.lastSegment() : t.path.get(t.path.length - 2));
}
function Tl(t) {
  return (e, n) => {
    let s = !1;
    for (const r of Kt(t)) {
      const i = Ym(r, e, n);
      if (i !== 0)
        return i;
      s = s || r.field.isKeyField();
    }
    return 0;
  };
}
function Ym(t, e, n) {
  const s = t.field.isKeyField() ? I.comparator(e.key, n.key) : function(r, i, o) {
    const a = i.data.field(r), c = o.data.field(r);
    return a !== null && c !== null ? rn(a, c) : A();
  }(t.field, e, n);
  switch (t.dir) {
    case "asc":
      return s;
    case "desc":
      return -1 * s;
    default:
      return A();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Sl(t, e) {
  if (t.wt) {
    if (isNaN(e))
      return {
        doubleValue: "NaN"
      };
    if (e === 1 / 0)
      return {
        doubleValue: "Infinity"
      };
    if (e === -1 / 0)
      return {
        doubleValue: "-Infinity"
      };
  }
  return {
    doubleValue: Js(e) ? "-0" : e
  };
}
function Il(t) {
  return {
    integerValue: "" + t
  };
}
function Xm(t, e) {
  return Mm(e) ? Il(e) : Sl(t, e);
}
/**
 * @license
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Or {
  constructor() {
    this._ = void 0;
  }
}
function Zm(t, e, n) {
  return t instanceof Xs ? function(s, r) {
    const i = {
      fields: {
        __type__: {
          stringValue: "server_timestamp"
        },
        __local_write_time__: {
          timestampValue: {
            seconds: s.seconds,
            nanos: s.nanoseconds
          }
        }
      }
    };
    return r && (i.fields.__previous_value__ = r), {
      mapValue: i
    };
  }(n, e) : t instanceof jn ? Al(t, e) : t instanceof Gn ? Cl(t, e) : function(s, r) {
    const i = bl(s, r), o = Xa(i) + Xa(s.gt);
    return Ui(i) && Ui(s.gt) ? Il(o) : Sl(s.yt, o);
  }(t, e);
}
function ey(t, e, n) {
  return t instanceof jn ? Al(t, e) : t instanceof Gn ? Cl(t, e) : n;
}
function bl(t, e) {
  return t instanceof Zs ? Ui(n = e) || function(s) {
    return !!s && "doubleValue" in s;
  }(n) ? e : {
    integerValue: 0
  } : null;
  var n;
}
class Xs extends Or {
}
class jn extends Or {
  constructor(e) {
    super(), this.elements = e;
  }
}
function Al(t, e) {
  const n = Nl(e);
  for (const s of t.elements)
    n.some((r) => Be(r, s)) || n.push(s);
  return {
    arrayValue: {
      values: n
    }
  };
}
class Gn extends Or {
  constructor(e) {
    super(), this.elements = e;
  }
}
function Cl(t, e) {
  let n = Nl(e);
  for (const s of t.elements)
    n = n.filter((r) => !Be(r, s));
  return {
    arrayValue: {
      values: n
    }
  };
}
class Zs extends Or {
  constructor(e, n) {
    super(), this.yt = e, this.gt = n;
  }
}
function Xa(t) {
  return J(t.integerValue || t.doubleValue);
}
function Nl(t) {
  return ko(t) && t.arrayValue.values ? t.arrayValue.values.slice() : [];
}
function ty(t, e) {
  return t.field.isEqual(e.field) && function(n, s) {
    return n instanceof jn && s instanceof jn || n instanceof Gn && s instanceof Gn ? nn(n.elements, s.elements, Be) : n instanceof Zs && s instanceof Zs ? Be(n.gt, s.gt) : n instanceof Xs && s instanceof Xs;
  }(t.transform, e.transform);
}
class ny {
  constructor(e, n) {
    this.version = e, this.transformResults = n;
  }
}
class Ne {
  constructor(e, n) {
    this.updateTime = e, this.exists = n;
  }
  /** Creates a new empty Precondition. */
  static none() {
    return new Ne();
  }
  /** Creates a new Precondition with an exists flag. */
  static exists(e) {
    return new Ne(void 0, e);
  }
  /** Creates a new Precondition based on a version a document exists at. */
  static updateTime(e) {
    return new Ne(e);
  }
  /** Returns whether this Precondition is empty. */
  get isNone() {
    return this.updateTime === void 0 && this.exists === void 0;
  }
  isEqual(e) {
    return this.exists === e.exists && (this.updateTime ? !!e.updateTime && this.updateTime.isEqual(e.updateTime) : !e.updateTime);
  }
}
function ks(t, e) {
  return t.updateTime !== void 0 ? e.isFoundDocument() && e.version.isEqual(t.updateTime) : t.exists === void 0 || t.exists === e.isFoundDocument();
}
class Rr {
}
function Ol(t, e) {
  if (!t.hasLocalMutations || e && e.fields.length === 0)
    return null;
  if (e === null)
    return t.isNoDocument() ? new Mo(t.key, Ne.none()) : new us(t.key, t.data, Ne.none());
  {
    const n = t.data, s = ve.empty();
    let r = new Z(fe.comparator);
    for (let i of e.fields)
      if (!r.has(i)) {
        let o = n.field(i);
        o === null && i.length > 1 && (i = i.popLast(), o = n.field(i)), o === null ? s.delete(i) : s.set(i, o), r = r.add(i);
      }
    return new mt(t.key, s, new Se(r.toArray()), Ne.none());
  }
}
function sy(t, e, n) {
  t instanceof us ? function(s, r, i) {
    const o = s.value.clone(), a = ec(s.fieldTransforms, r, i.transformResults);
    o.setAll(a), r.convertToFoundDocument(i.version, o).setHasCommittedMutations();
  }(t, e, n) : t instanceof mt ? function(s, r, i) {
    if (!ks(s.precondition, r))
      return void r.convertToUnknownDocument(i.version);
    const o = ec(s.fieldTransforms, r, i.transformResults), a = r.data;
    a.setAll(Rl(s)), a.setAll(o), r.convertToFoundDocument(i.version, a).setHasCommittedMutations();
  }(t, e, n) : function(s, r, i) {
    r.convertToNoDocument(i.version).setHasCommittedMutations();
  }(0, e, n);
}
function Nn(t, e, n, s) {
  return t instanceof us ? function(r, i, o, a) {
    if (!ks(r.precondition, i))
      return o;
    const c = r.value.clone(), u = tc(r.fieldTransforms, a, i);
    return c.setAll(u), i.convertToFoundDocument(i.version, c).setHasLocalMutations(), null;
  }(t, e, n, s) : t instanceof mt ? function(r, i, o, a) {
    if (!ks(r.precondition, i))
      return o;
    const c = tc(r.fieldTransforms, a, i), u = i.data;
    return u.setAll(Rl(r)), u.setAll(c), i.convertToFoundDocument(i.version, u).setHasLocalMutations(), o === null ? null : o.unionWith(r.fieldMask.fields).unionWith(r.fieldTransforms.map((l) => l.field));
  }(t, e, n, s) : function(r, i, o) {
    return ks(r.precondition, i) ? (i.convertToNoDocument(i.version).setHasLocalMutations(), null) : o;
  }(t, e, n);
}
function ry(t, e) {
  let n = null;
  for (const s of t.fieldTransforms) {
    const r = e.data.field(s.field), i = bl(s.transform, r || null);
    i != null && (n === null && (n = ve.empty()), n.set(s.field, i));
  }
  return n || null;
}
function Za(t, e) {
  return t.type === e.type && !!t.key.isEqual(e.key) && !!t.precondition.isEqual(e.precondition) && !!function(n, s) {
    return n === void 0 && s === void 0 || !(!n || !s) && nn(n, s, (r, i) => ty(r, i));
  }(t.fieldTransforms, e.fieldTransforms) && (t.type === 0 ? t.value.isEqual(e.value) : t.type !== 1 || t.data.isEqual(e.data) && t.fieldMask.isEqual(e.fieldMask));
}
class us extends Rr {
  constructor(e, n, s, r = []) {
    super(), this.key = e, this.value = n, this.precondition = s, this.fieldTransforms = r, this.type = 0;
  }
  getFieldMask() {
    return null;
  }
}
class mt extends Rr {
  constructor(e, n, s, r, i = []) {
    super(), this.key = e, this.data = n, this.fieldMask = s, this.precondition = r, this.fieldTransforms = i, this.type = 1;
  }
  getFieldMask() {
    return this.fieldMask;
  }
}
function Rl(t) {
  const e = /* @__PURE__ */ new Map();
  return t.fieldMask.fields.forEach((n) => {
    if (!n.isEmpty()) {
      const s = t.data.field(n);
      e.set(n, s);
    }
  }), e;
}
function ec(t, e, n) {
  const s = /* @__PURE__ */ new Map();
  j(t.length === n.length);
  for (let r = 0; r < n.length; r++) {
    const i = t[r], o = i.transform, a = e.data.field(i.field);
    s.set(i.field, ey(o, a, n[r]));
  }
  return s;
}
function tc(t, e, n) {
  const s = /* @__PURE__ */ new Map();
  for (const r of t) {
    const i = r.transform, o = n.data.field(r.field);
    s.set(r.field, Zm(i, o, e));
  }
  return s;
}
class Mo extends Rr {
  constructor(e, n) {
    super(), this.key = e, this.precondition = n, this.type = 2, this.fieldTransforms = [];
  }
  getFieldMask() {
    return null;
  }
}
class iy extends Rr {
  constructor(e, n) {
    super(), this.key = e, this.precondition = n, this.type = 3, this.fieldTransforms = [];
  }
  getFieldMask() {
    return null;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class oy {
  // TODO(b/33078163): just use simplest form of existence filter for now
  constructor(e) {
    this.count = e;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var K, $;
function ay(t) {
  switch (t) {
    default:
      return A();
    case g.CANCELLED:
    case g.UNKNOWN:
    case g.DEADLINE_EXCEEDED:
    case g.RESOURCE_EXHAUSTED:
    case g.INTERNAL:
    case g.UNAVAILABLE:
    case g.UNAUTHENTICATED:
      return !1;
    case g.INVALID_ARGUMENT:
    case g.NOT_FOUND:
    case g.ALREADY_EXISTS:
    case g.PERMISSION_DENIED:
    case g.FAILED_PRECONDITION:
    case g.ABORTED:
    case g.OUT_OF_RANGE:
    case g.UNIMPLEMENTED:
    case g.DATA_LOSS:
      return !0;
  }
}
function kl(t) {
  if (t === void 0)
    return Qe("GRPC error has no .code"), g.UNKNOWN;
  switch (t) {
    case K.OK:
      return g.OK;
    case K.CANCELLED:
      return g.CANCELLED;
    case K.UNKNOWN:
      return g.UNKNOWN;
    case K.DEADLINE_EXCEEDED:
      return g.DEADLINE_EXCEEDED;
    case K.RESOURCE_EXHAUSTED:
      return g.RESOURCE_EXHAUSTED;
    case K.INTERNAL:
      return g.INTERNAL;
    case K.UNAVAILABLE:
      return g.UNAVAILABLE;
    case K.UNAUTHENTICATED:
      return g.UNAUTHENTICATED;
    case K.INVALID_ARGUMENT:
      return g.INVALID_ARGUMENT;
    case K.NOT_FOUND:
      return g.NOT_FOUND;
    case K.ALREADY_EXISTS:
      return g.ALREADY_EXISTS;
    case K.PERMISSION_DENIED:
      return g.PERMISSION_DENIED;
    case K.FAILED_PRECONDITION:
      return g.FAILED_PRECONDITION;
    case K.ABORTED:
      return g.ABORTED;
    case K.OUT_OF_RANGE:
      return g.OUT_OF_RANGE;
    case K.UNIMPLEMENTED:
      return g.UNIMPLEMENTED;
    case K.DATA_LOSS:
      return g.DATA_LOSS;
    default:
      return A();
  }
}
($ = K || (K = {}))[$.OK = 0] = "OK", $[$.CANCELLED = 1] = "CANCELLED", $[$.UNKNOWN = 2] = "UNKNOWN", $[$.INVALID_ARGUMENT = 3] = "INVALID_ARGUMENT", $[$.DEADLINE_EXCEEDED = 4] = "DEADLINE_EXCEEDED", $[$.NOT_FOUND = 5] = "NOT_FOUND", $[$.ALREADY_EXISTS = 6] = "ALREADY_EXISTS", $[$.PERMISSION_DENIED = 7] = "PERMISSION_DENIED", $[$.UNAUTHENTICATED = 16] = "UNAUTHENTICATED", $[$.RESOURCE_EXHAUSTED = 8] = "RESOURCE_EXHAUSTED", $[$.FAILED_PRECONDITION = 9] = "FAILED_PRECONDITION", $[$.ABORTED = 10] = "ABORTED", $[$.OUT_OF_RANGE = 11] = "OUT_OF_RANGE", $[$.UNIMPLEMENTED = 12] = "UNIMPLEMENTED", $[$.INTERNAL = 13] = "INTERNAL", $[$.UNAVAILABLE = 14] = "UNAVAILABLE", $[$.DATA_LOSS = 15] = "DATA_LOSS";
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class pn {
  constructor(e, n) {
    this.mapKeyFn = e, this.equalsFn = n, /**
     * The inner map for a key/value pair. Due to the possibility of collisions we
     * keep a list of entries that we do a linear search through to find an actual
     * match. Note that collisions should be rare, so we still expect near
     * constant time lookups in practice.
     */
    this.inner = {}, /** The number of entries stored in the map */
    this.innerSize = 0;
  }
  /** Get a value for this key, or undefined if it does not exist. */
  get(e) {
    const n = this.mapKeyFn(e), s = this.inner[n];
    if (s !== void 0) {
      for (const [r, i] of s)
        if (this.equalsFn(r, e))
          return i;
    }
  }
  has(e) {
    return this.get(e) !== void 0;
  }
  /** Put this key and value in the map. */
  set(e, n) {
    const s = this.mapKeyFn(e), r = this.inner[s];
    if (r === void 0)
      return this.inner[s] = [[e, n]], void this.innerSize++;
    for (let i = 0; i < r.length; i++)
      if (this.equalsFn(r[i][0], e))
        return void (r[i] = [e, n]);
    r.push([e, n]), this.innerSize++;
  }
  /**
   * Remove this key from the map. Returns a boolean if anything was deleted.
   */
  delete(e) {
    const n = this.mapKeyFn(e), s = this.inner[n];
    if (s === void 0)
      return !1;
    for (let r = 0; r < s.length; r++)
      if (this.equalsFn(s[r][0], e))
        return s.length === 1 ? delete this.inner[n] : s.splice(r, 1), this.innerSize--, !0;
    return !1;
  }
  forEach(e) {
    xt(this.inner, (n, s) => {
      for (const [r, i] of s)
        e(r, i);
    });
  }
  isEmpty() {
    return ul(this.inner);
  }
  size() {
    return this.innerSize;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const cy = new ee(I.comparator);
function Ke() {
  return cy;
}
const Dl = new ee(I.comparator);
function Sn(...t) {
  let e = Dl;
  for (const n of t)
    e = e.insert(n.key, n);
  return e;
}
function Pl(t) {
  let e = Dl;
  return t.forEach((n, s) => e = e.insert(n, s.overlayedDocument)), e;
}
function At() {
  return On();
}
function Ll() {
  return On();
}
function On() {
  return new pn((t) => t.toString(), (t, e) => t.isEqual(e));
}
const uy = new ee(I.comparator), ly = new Z(I.comparator);
function M(...t) {
  let e = ly;
  for (const n of t)
    e = e.add(n);
  return e;
}
const hy = new Z(B);
function Fl() {
  return hy;
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class kr {
  constructor(e, n, s, r, i) {
    this.snapshotVersion = e, this.targetChanges = n, this.targetMismatches = s, this.documentUpdates = r, this.resolvedLimboDocuments = i;
  }
  /**
   * HACK: Views require RemoteEvents in order to determine whether the view is
   * CURRENT, but secondary tabs don't receive remote events. So this method is
   * used to create a synthesized RemoteEvent that can be used to apply a
   * CURRENT status change to a View, for queries executed in a different tab.
   */
  // PORTING NOTE: Multi-tab only
  static createSynthesizedRemoteEventForCurrentChange(e, n, s) {
    const r = /* @__PURE__ */ new Map();
    return r.set(e, ls.createSynthesizedTargetChangeForCurrentChange(e, n, s)), new kr(k.min(), r, Fl(), Ke(), M());
  }
}
class ls {
  constructor(e, n, s, r, i) {
    this.resumeToken = e, this.current = n, this.addedDocuments = s, this.modifiedDocuments = r, this.removedDocuments = i;
  }
  /**
   * This method is used to create a synthesized TargetChanges that can be used to
   * apply a CURRENT status change to a View (for queries executed in a different
   * tab) or for new queries (to raise snapshots with correct CURRENT status).
   */
  static createSynthesizedTargetChangeForCurrentChange(e, n, s) {
    return new ls(s, n, M(), M(), M());
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ds {
  constructor(e, n, s, r) {
    this.It = e, this.removedTargetIds = n, this.key = s, this.Tt = r;
  }
}
class Ml {
  constructor(e, n) {
    this.targetId = e, this.Et = n;
  }
}
class $l {
  constructor(e, n, s = ye.EMPTY_BYTE_STRING, r = null) {
    this.state = e, this.targetIds = n, this.resumeToken = s, this.cause = r;
  }
}
class nc {
  constructor() {
    this.At = 0, /**
     * Keeps track of the document changes since the last raised snapshot.
     *
     * These changes are continuously updated as we receive document updates and
     * always reflect the current set of changes against the last issued snapshot.
     */
    this.Rt = rc(), /** See public getters for explanations of these fields. */
    this.bt = ye.EMPTY_BYTE_STRING, this.Pt = !1, /**
     * Whether this target state should be included in the next snapshot. We
     * initialize to true so that newly-added targets are included in the next
     * RemoteEvent.
     */
    this.vt = !0;
  }
  /**
   * Whether this target has been marked 'current'.
   *
   * 'Current' has special meaning in the RPC protocol: It implies that the
   * Watch backend has sent us all changes up to the point at which the target
   * was added and that the target is consistent with the rest of the watch
   * stream.
   */
  get current() {
    return this.Pt;
  }
  /** The last resume token sent to us for this target. */
  get resumeToken() {
    return this.bt;
  }
  /** Whether this target has pending target adds or target removes. */
  get Vt() {
    return this.At !== 0;
  }
  /** Whether we have modified any state that should trigger a snapshot. */
  get St() {
    return this.vt;
  }
  /**
   * Applies the resume token to the TargetChange, but only when it has a new
   * value. Empty resumeTokens are discarded.
   */
  Dt(e) {
    e.approximateByteSize() > 0 && (this.vt = !0, this.bt = e);
  }
  /**
   * Creates a target change from the current set of changes.
   *
   * To reset the document changes after raising this snapshot, call
   * `clearPendingChanges()`.
   */
  Ct() {
    let e = M(), n = M(), s = M();
    return this.Rt.forEach((r, i) => {
      switch (i) {
        case 0:
          e = e.add(r);
          break;
        case 2:
          n = n.add(r);
          break;
        case 1:
          s = s.add(r);
          break;
        default:
          A();
      }
    }), new ls(this.bt, this.Pt, e, n, s);
  }
  /**
   * Resets the document changes and sets `hasPendingChanges` to false.
   */
  xt() {
    this.vt = !1, this.Rt = rc();
  }
  Nt(e, n) {
    this.vt = !0, this.Rt = this.Rt.insert(e, n);
  }
  kt(e) {
    this.vt = !0, this.Rt = this.Rt.remove(e);
  }
  Ot() {
    this.At += 1;
  }
  Mt() {
    this.At -= 1;
  }
  Ft() {
    this.vt = !0, this.Pt = !0;
  }
}
class dy {
  constructor(e) {
    this.$t = e, /** The internal state of all tracked targets. */
    this.Bt = /* @__PURE__ */ new Map(), /** Keeps track of the documents to update since the last raised snapshot. */
    this.Lt = Ke(), /** A mapping of document keys to their set of target IDs. */
    this.qt = sc(), /**
     * A list of targets with existence filter mismatches. These targets are
     * known to be inconsistent and their listens needs to be re-established by
     * RemoteStore.
     */
    this.Ut = new Z(B);
  }
  /**
   * Processes and adds the DocumentWatchChange to the current set of changes.
   */
  Kt(e) {
    for (const n of e.It)
      e.Tt && e.Tt.isFoundDocument() ? this.Gt(n, e.Tt) : this.Qt(n, e.key, e.Tt);
    for (const n of e.removedTargetIds)
      this.Qt(n, e.key, e.Tt);
  }
  /** Processes and adds the WatchTargetChange to the current set of changes. */
  jt(e) {
    this.forEachTarget(e, (n) => {
      const s = this.Wt(n);
      switch (e.state) {
        case 0:
          this.zt(n) && s.Dt(e.resumeToken);
          break;
        case 1:
          s.Mt(), s.Vt || // We have a freshly added target, so we need to reset any state
          // that we had previously. This can happen e.g. when remove and add
          // back a target for existence filter mismatches.
          s.xt(), s.Dt(e.resumeToken);
          break;
        case 2:
          s.Mt(), s.Vt || this.removeTarget(n);
          break;
        case 3:
          this.zt(n) && (s.Ft(), s.Dt(e.resumeToken));
          break;
        case 4:
          this.zt(n) && // Reset the target and synthesizes removes for all existing
          // documents. The backend will re-add any documents that still
          // match the target before it sends the next global snapshot.
          (this.Ht(n), s.Dt(e.resumeToken));
          break;
        default:
          A();
      }
    });
  }
  /**
   * Iterates over all targetIds that the watch change applies to: either the
   * targetIds explicitly listed in the change or the targetIds of all currently
   * active targets.
   */
  forEachTarget(e, n) {
    e.targetIds.length > 0 ? e.targetIds.forEach(n) : this.Bt.forEach((s, r) => {
      this.zt(r) && n(r);
    });
  }
  /**
   * Handles existence filters and synthesizes deletes for filter mismatches.
   * Targets that are invalidated by filter mismatches are added to
   * `pendingTargetResets`.
   */
  Jt(e) {
    const n = e.targetId, s = e.Et.count, r = this.Yt(n);
    if (r) {
      const i = r.target;
      if (Bi(i))
        if (s === 0) {
          const o = new I(i.path);
          this.Qt(n, o, de.newNoDocument(o, k.min()));
        } else
          j(s === 1);
      else
        this.Xt(n) !== s && // Existence filter mismatch: We reset the mapping and raise a new
        // snapshot with `isFromCache:true`.
        (this.Ht(n), this.Ut = this.Ut.add(n));
    }
  }
  /**
   * Converts the currently accumulated state into a remote event at the
   * provided snapshot version. Resets the accumulated changes before returning.
   */
  Zt(e) {
    const n = /* @__PURE__ */ new Map();
    this.Bt.forEach((i, o) => {
      const a = this.Yt(o);
      if (a) {
        if (i.current && Bi(a.target)) {
          const c = new I(a.target.path);
          this.Lt.get(c) !== null || this.te(o, c) || this.Qt(o, c, de.newNoDocument(c, e));
        }
        i.St && (n.set(o, i.Ct()), i.xt());
      }
    });
    let s = M();
    this.qt.forEach((i, o) => {
      let a = !0;
      o.forEachWhile((c) => {
        const u = this.Yt(c);
        return !u || u.purpose === 2 || (a = !1, !1);
      }), a && (s = s.add(i));
    }), this.Lt.forEach((i, o) => o.setReadTime(e));
    const r = new kr(e, n, this.Ut, this.Lt, s);
    return this.Lt = Ke(), this.qt = sc(), this.Ut = new Z(B), r;
  }
  /**
   * Adds the provided document to the internal list of document updates and
   * its document key to the given target's mapping.
   */
  // Visible for testing.
  Gt(e, n) {
    if (!this.zt(e))
      return;
    const s = this.te(e, n.key) ? 2 : 0;
    this.Wt(e).Nt(n.key, s), this.Lt = this.Lt.insert(n.key, n), this.qt = this.qt.insert(n.key, this.ee(n.key).add(e));
  }
  /**
   * Removes the provided document from the target mapping. If the
   * document no longer matches the target, but the document's state is still
   * known (e.g. we know that the document was deleted or we received the change
   * that caused the filter mismatch), the new document can be provided
   * to update the remote document cache.
   */
  // Visible for testing.
  Qt(e, n, s) {
    if (!this.zt(e))
      return;
    const r = this.Wt(e);
    this.te(e, n) ? r.Nt(
      n,
      1
      /* ChangeType.Removed */
    ) : (
      // The document may have entered and left the target before we raised a
      // snapshot, so we can just ignore the change.
      r.kt(n)
    ), this.qt = this.qt.insert(n, this.ee(n).delete(e)), s && (this.Lt = this.Lt.insert(n, s));
  }
  removeTarget(e) {
    this.Bt.delete(e);
  }
  /**
   * Returns the current count of documents in the target. This includes both
   * the number of documents that the LocalStore considers to be part of the
   * target as well as any accumulated changes.
   */
  Xt(e) {
    const n = this.Wt(e).Ct();
    return this.$t.getRemoteKeysForTarget(e).size + n.addedDocuments.size - n.removedDocuments.size;
  }
  /**
   * Increment the number of acks needed from watch before we can consider the
   * server to be 'in-sync' with the client's active targets.
   */
  Ot(e) {
    this.Wt(e).Ot();
  }
  Wt(e) {
    let n = this.Bt.get(e);
    return n || (n = new nc(), this.Bt.set(e, n)), n;
  }
  ee(e) {
    let n = this.qt.get(e);
    return n || (n = new Z(B), this.qt = this.qt.insert(e, n)), n;
  }
  /**
   * Verifies that the user is still interested in this target (by calling
   * `getTargetDataForTarget()`) and that we are not waiting for pending ADDs
   * from watch.
   */
  zt(e) {
    const n = this.Yt(e) !== null;
    return n || T("WatchChangeAggregator", "Detected inactive target", e), n;
  }
  /**
   * Returns the TargetData for an active target (i.e. a target that the user
   * is still interested in that has no outstanding target change requests).
   */
  Yt(e) {
    const n = this.Bt.get(e);
    return n && n.Vt ? null : this.$t.ne(e);
  }
  /**
   * Resets the state of a Watch target to its initial state (e.g. sets
   * 'current' to false, clears the resume token and removes its target mapping
   * from all documents).
   */
  Ht(e) {
    this.Bt.set(e, new nc()), this.$t.getRemoteKeysForTarget(e).forEach((n) => {
      this.Qt(
        e,
        n,
        /*updatedDocument=*/
        null
      );
    });
  }
  /**
   * Returns whether the LocalStore considers the document to be part of the
   * specified target.
   */
  te(e, n) {
    return this.$t.getRemoteKeysForTarget(e).has(n);
  }
}
function sc() {
  return new ee(I.comparator);
}
function rc() {
  return new ee(I.comparator);
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const fy = (() => ({
  asc: "ASCENDING",
  desc: "DESCENDING"
}))(), py = (() => ({
  "<": "LESS_THAN",
  "<=": "LESS_THAN_OR_EQUAL",
  ">": "GREATER_THAN",
  ">=": "GREATER_THAN_OR_EQUAL",
  "==": "EQUAL",
  "!=": "NOT_EQUAL",
  "array-contains": "ARRAY_CONTAINS",
  in: "IN",
  "not-in": "NOT_IN",
  "array-contains-any": "ARRAY_CONTAINS_ANY"
}))(), gy = (() => ({
  and: "AND",
  or: "OR"
}))();
class my {
  constructor(e, n) {
    this.databaseId = e, this.wt = n;
  }
}
function er(t, e) {
  return t.wt ? `${new Date(1e3 * e.seconds).toISOString().replace(/\.\d*/, "").replace("Z", "")}.${("000000000" + e.nanoseconds).slice(-9)}Z` : {
    seconds: "" + e.seconds,
    nanos: e.nanoseconds
  };
}
function Ul(t, e) {
  return t.wt ? e.toBase64() : e.toUint8Array();
}
function yy(t, e) {
  return er(t, e.toTimestamp());
}
function $e(t) {
  return j(!!t), k.fromTimestamp(function(e) {
    const n = ht(e);
    return new X(n.seconds, n.nanos);
  }(t));
}
function $o(t, e) {
  return function(n) {
    return new W(["projects", n.projectId, "databases", n.database]);
  }(t).child("documents").child(e).canonicalString();
}
function xl(t) {
  const e = W.fromString(t);
  return j(Gl(e)), e;
}
function Gi(t, e) {
  return $o(t.databaseId, e.path);
}
function pi(t, e) {
  const n = xl(e);
  if (n.get(1) !== t.databaseId.projectId)
    throw new w(g.INVALID_ARGUMENT, "Tried to deserialize key from different project: " + n.get(1) + " vs " + t.databaseId.projectId);
  if (n.get(3) !== t.databaseId.database)
    throw new w(g.INVALID_ARGUMENT, "Tried to deserialize key from different database: " + n.get(3) + " vs " + t.databaseId.database);
  return new I(Bl(n));
}
function Wi(t, e) {
  return $o(t.databaseId, e);
}
function _y(t) {
  const e = xl(t);
  return e.length === 4 ? W.emptyPath() : Bl(e);
}
function Hi(t) {
  return new W(["projects", t.databaseId.projectId, "databases", t.databaseId.database]).canonicalString();
}
function Bl(t) {
  return j(t.length > 4 && t.get(4) === "documents"), t.popFirst(5);
}
function ic(t, e, n) {
  return {
    name: Gi(t, e),
    fields: n.value.mapValue.fields
  };
}
function wy(t, e) {
  let n;
  if ("targetChange" in e) {
    e.targetChange;
    const s = function(c) {
      return c === "NO_CHANGE" ? 0 : c === "ADD" ? 1 : c === "REMOVE" ? 2 : c === "CURRENT" ? 3 : c === "RESET" ? 4 : A();
    }(e.targetChange.targetChangeType || "NO_CHANGE"), r = e.targetChange.targetIds || [], i = function(c, u) {
      return c.wt ? (j(u === void 0 || typeof u == "string"), ye.fromBase64String(u || "")) : (j(u === void 0 || u instanceof Uint8Array), ye.fromUint8Array(u || new Uint8Array()));
    }(t, e.targetChange.resumeToken), o = e.targetChange.cause, a = o && function(c) {
      const u = c.code === void 0 ? g.UNKNOWN : kl(c.code);
      return new w(u, c.message || "");
    }(o);
    n = new $l(s, r, i, a || null);
  } else if ("documentChange" in e) {
    e.documentChange;
    const s = e.documentChange;
    s.document, s.document.name, s.document.updateTime;
    const r = pi(t, s.document.name), i = $e(s.document.updateTime), o = s.document.createTime ? $e(s.document.createTime) : k.min(), a = new ve({
      mapValue: {
        fields: s.document.fields
      }
    }), c = de.newFoundDocument(r, i, o, a), u = s.targetIds || [], l = s.removedTargetIds || [];
    n = new Ds(u, l, c.key, c);
  } else if ("documentDelete" in e) {
    e.documentDelete;
    const s = e.documentDelete;
    s.document;
    const r = pi(t, s.document), i = s.readTime ? $e(s.readTime) : k.min(), o = de.newNoDocument(r, i), a = s.removedTargetIds || [];
    n = new Ds([], a, o.key, o);
  } else if ("documentRemove" in e) {
    e.documentRemove;
    const s = e.documentRemove;
    s.document;
    const r = pi(t, s.document), i = s.removedTargetIds || [];
    n = new Ds([], i, r, null);
  } else {
    if (!("filter" in e))
      return A();
    {
      e.filter;
      const s = e.filter;
      s.targetId;
      const r = s.count || 0, i = new oy(r), o = s.targetId;
      n = new Ml(o, i);
    }
  }
  return n;
}
function Ey(t, e) {
  let n;
  if (e instanceof us)
    n = {
      update: ic(t, e.key, e.value)
    };
  else if (e instanceof Mo)
    n = {
      delete: Gi(t, e.key)
    };
  else if (e instanceof mt)
    n = {
      update: ic(t, e.key, e.data),
      updateMask: Ny(e.fieldMask)
    };
  else {
    if (!(e instanceof iy))
      return A();
    n = {
      verify: Gi(t, e.key)
    };
  }
  return e.fieldTransforms.length > 0 && (n.updateTransforms = e.fieldTransforms.map((s) => function(r, i) {
    const o = i.transform;
    if (o instanceof Xs)
      return {
        fieldPath: i.field.canonicalString(),
        setToServerValue: "REQUEST_TIME"
      };
    if (o instanceof jn)
      return {
        fieldPath: i.field.canonicalString(),
        appendMissingElements: {
          values: o.elements
        }
      };
    if (o instanceof Gn)
      return {
        fieldPath: i.field.canonicalString(),
        removeAllFromArray: {
          values: o.elements
        }
      };
    if (o instanceof Zs)
      return {
        fieldPath: i.field.canonicalString(),
        increment: o.gt
      };
    throw A();
  }(0, s))), e.precondition.isNone || (n.currentDocument = function(s, r) {
    return r.updateTime !== void 0 ? {
      updateTime: yy(s, r.updateTime)
    } : r.exists !== void 0 ? {
      exists: r.exists
    } : A();
  }(t, e.precondition)), n;
}
function vy(t, e) {
  return t && t.length > 0 ? (j(e !== void 0), t.map((n) => function(s, r) {
    let i = s.updateTime ? $e(s.updateTime) : $e(r);
    return i.isEqual(k.min()) && // The Firestore Emulator currently returns an update time of 0 for
    // deletes of non-existing documents (rather than null). This breaks the
    // test "get deleted doc while offline with source=cache" as NoDocuments
    // with version 0 are filtered by IndexedDb's RemoteDocumentCache.
    // TODO(#2149): Remove this when Emulator is fixed
    (i = $e(r)), new ny(i, s.transformResults || []);
  }(n, e))) : [];
}
function Ty(t, e) {
  return {
    documents: [Wi(t, e.path)]
  };
}
function ql(t, e) {
  const n = {
    structuredQuery: {}
  }, s = e.path;
  e.collectionGroup !== null ? (n.parent = Wi(t, s), n.structuredQuery.from = [{
    collectionId: e.collectionGroup,
    allDescendants: !0
  }]) : (n.parent = Wi(t, s.popLast()), n.structuredQuery.from = [{
    collectionId: s.lastSegment()
  }]);
  const r = function(c) {
    if (c.length !== 0)
      return jl(Oe.create(
        c,
        "and"
        /* CompositeOperator.AND */
      ));
  }(e.filters);
  r && (n.structuredQuery.where = r);
  const i = function(c) {
    if (c.length !== 0)
      return c.map((u) => (
        // visible for testing
        function(l) {
          return {
            field: jt(l.field),
            direction: by(l.dir)
          };
        }(u)
      ));
  }(e.orderBy);
  i && (n.structuredQuery.orderBy = i);
  const o = function(c, u) {
    return c.wt || Ar(u) ? u : {
      value: u
    };
  }(t, e.limit);
  var a;
  return o !== null && (n.structuredQuery.limit = o), e.startAt && (n.structuredQuery.startAt = {
    before: (a = e.startAt).inclusive,
    values: a.position
  }), e.endAt && (n.structuredQuery.endAt = function(c) {
    return {
      before: !c.inclusive,
      values: c.position
    };
  }(e.endAt)), n;
}
function Sy(t) {
  let e = _y(t.parent);
  const n = t.structuredQuery, s = n.from ? n.from.length : 0;
  let r = null;
  if (s > 0) {
    j(s === 1);
    const l = n.from[0];
    l.allDescendants ? r = l.collectionId : e = e.child(l.collectionId);
  }
  let i = [];
  n.where && (i = function(l) {
    const h = Vl(l);
    return h instanceof Oe && pl(h) ? h.getFilters() : [h];
  }(n.where));
  let o = [];
  n.orderBy && (o = n.orderBy.map((l) => function(h) {
    return new Cn(
      Gt(h.field),
      // visible for testing
      function(d) {
        switch (d) {
          case "ASCENDING":
            return "asc";
          case "DESCENDING":
            return "desc";
          default:
            return;
        }
      }(h.direction)
    );
  }(l)));
  let a = null;
  n.limit && (a = function(l) {
    let h;
    return h = typeof l == "object" ? l.value : l, Ar(h) ? null : h;
  }(n.limit));
  let c = null;
  n.startAt && (c = function(l) {
    const h = !!l.before, d = l.values || [];
    return new Ys(d, h);
  }(n.startAt));
  let u = null;
  return n.endAt && (u = function(l) {
    const h = !l.before, d = l.values || [];
    return new Ys(d, h);
  }(n.endAt)), Km(e, r, o, i, a, "F", c, u);
}
function Iy(t, e) {
  const n = function(s, r) {
    switch (r) {
      case 0:
        return null;
      case 1:
        return "existence-filter-mismatch";
      case 2:
        return "limbo-document";
      default:
        return A();
    }
  }(0, e.purpose);
  return n == null ? null : {
    "goog-listen-tags": n
  };
}
function Vl(t) {
  return t.unaryFilter !== void 0 ? function(e) {
    switch (e.unaryFilter.op) {
      case "IS_NAN":
        const n = Gt(e.unaryFilter.field);
        return Y.create(n, "==", {
          doubleValue: NaN
        });
      case "IS_NULL":
        const s = Gt(e.unaryFilter.field);
        return Y.create(s, "==", {
          nullValue: "NULL_VALUE"
        });
      case "IS_NOT_NAN":
        const r = Gt(e.unaryFilter.field);
        return Y.create(r, "!=", {
          doubleValue: NaN
        });
      case "IS_NOT_NULL":
        const i = Gt(e.unaryFilter.field);
        return Y.create(i, "!=", {
          nullValue: "NULL_VALUE"
        });
      default:
        return A();
    }
  }(t) : t.fieldFilter !== void 0 ? function(e) {
    return Y.create(Gt(e.fieldFilter.field), function(n) {
      switch (n) {
        case "EQUAL":
          return "==";
        case "NOT_EQUAL":
          return "!=";
        case "GREATER_THAN":
          return ">";
        case "GREATER_THAN_OR_EQUAL":
          return ">=";
        case "LESS_THAN":
          return "<";
        case "LESS_THAN_OR_EQUAL":
          return "<=";
        case "ARRAY_CONTAINS":
          return "array-contains";
        case "IN":
          return "in";
        case "NOT_IN":
          return "not-in";
        case "ARRAY_CONTAINS_ANY":
          return "array-contains-any";
        default:
          return A();
      }
    }(e.fieldFilter.op), e.fieldFilter.value);
  }(t) : t.compositeFilter !== void 0 ? function(e) {
    return Oe.create(e.compositeFilter.filters.map((n) => Vl(n)), function(n) {
      switch (n) {
        case "AND":
          return "and";
        case "OR":
          return "or";
        default:
          return A();
      }
    }(e.compositeFilter.op));
  }(t) : A();
}
function by(t) {
  return fy[t];
}
function Ay(t) {
  return py[t];
}
function Cy(t) {
  return gy[t];
}
function jt(t) {
  return {
    fieldPath: t.canonicalString()
  };
}
function Gt(t) {
  return fe.fromServerFormat(t.fieldPath);
}
function jl(t) {
  return t instanceof Y ? function(e) {
    if (e.op === "==") {
      if (Ha(e.value))
        return {
          unaryFilter: {
            field: jt(e.field),
            op: "IS_NAN"
          }
        };
      if (Wa(e.value))
        return {
          unaryFilter: {
            field: jt(e.field),
            op: "IS_NULL"
          }
        };
    } else if (e.op === "!=") {
      if (Ha(e.value))
        return {
          unaryFilter: {
            field: jt(e.field),
            op: "IS_NOT_NAN"
          }
        };
      if (Wa(e.value))
        return {
          unaryFilter: {
            field: jt(e.field),
            op: "IS_NOT_NULL"
          }
        };
    }
    return {
      fieldFilter: {
        field: jt(e.field),
        op: Ay(e.op),
        value: e.value
      }
    };
  }(t) : t instanceof Oe ? function(e) {
    const n = e.getFilters().map((s) => jl(s));
    return n.length === 1 ? n[0] : {
      compositeFilter: {
        op: Cy(e.op),
        filters: n
      }
    };
  }(t) : A();
}
function Ny(t) {
  const e = [];
  return t.fields.forEach((n) => e.push(n.canonicalString())), {
    fieldPaths: e
  };
}
function Gl(t) {
  return t.length >= 4 && t.get(0) === "projects" && t.get(2) === "databases";
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Oy {
  /**
   * @param batchId - The unique ID of this mutation batch.
   * @param localWriteTime - The original write time of this mutation.
   * @param baseMutations - Mutations that are used to populate the base
   * values when this mutation is applied locally. This can be used to locally
   * overwrite values that are persisted in the remote document cache. Base
   * mutations are never sent to the backend.
   * @param mutations - The user-provided mutations in this mutation batch.
   * User-provided mutations are applied both locally and remotely on the
   * backend.
   */
  constructor(e, n, s, r) {
    this.batchId = e, this.localWriteTime = n, this.baseMutations = s, this.mutations = r;
  }
  /**
   * Applies all the mutations in this MutationBatch to the specified document
   * to compute the state of the remote document
   *
   * @param document - The document to apply mutations to.
   * @param batchResult - The result of applying the MutationBatch to the
   * backend.
   */
  applyToRemoteDocument(e, n) {
    const s = n.mutationResults;
    for (let r = 0; r < this.mutations.length; r++) {
      const i = this.mutations[r];
      i.key.isEqual(e.key) && sy(i, e, s[r]);
    }
  }
  /**
   * Computes the local view of a document given all the mutations in this
   * batch.
   *
   * @param document - The document to apply mutations to.
   * @param mutatedFields - Fields that have been updated before applying this mutation batch.
   * @returns A `FieldMask` representing all the fields that are mutated.
   */
  applyToLocalView(e, n) {
    for (const s of this.baseMutations)
      s.key.isEqual(e.key) && (n = Nn(s, e, n, this.localWriteTime));
    for (const s of this.mutations)
      s.key.isEqual(e.key) && (n = Nn(s, e, n, this.localWriteTime));
    return n;
  }
  /**
   * Computes the local view for all provided documents given the mutations in
   * this batch. Returns a `DocumentKey` to `Mutation` map which can be used to
   * replace all the mutation applications.
   */
  applyToLocalDocumentSet(e, n) {
    const s = Ll();
    return this.mutations.forEach((r) => {
      const i = e.get(r.key), o = i.overlayedDocument;
      let a = this.applyToLocalView(o, i.mutatedFields);
      a = n.has(r.key) ? null : a;
      const c = Ol(o, a);
      c !== null && s.set(r.key, c), o.isValidDocument() || o.convertToNoDocument(k.min());
    }), s;
  }
  keys() {
    return this.mutations.reduce((e, n) => e.add(n.key), M());
  }
  isEqual(e) {
    return this.batchId === e.batchId && nn(this.mutations, e.mutations, (n, s) => Za(n, s)) && nn(this.baseMutations, e.baseMutations, (n, s) => Za(n, s));
  }
}
class Uo {
  constructor(e, n, s, r) {
    this.batch = e, this.commitVersion = n, this.mutationResults = s, this.docVersions = r;
  }
  /**
   * Creates a new MutationBatchResult for the given batch and results. There
   * must be one result for each mutation in the batch. This static factory
   * caches a document=&gt;version mapping (docVersions).
   */
  static from(e, n, s) {
    j(e.mutations.length === s.length);
    let r = uy;
    const i = e.mutations;
    for (let o = 0; o < i.length; o++)
      r = r.insert(i[o].key, s[o].version);
    return new Uo(e, n, s, r);
  }
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ry {
  constructor(e, n) {
    this.largestBatchId = e, this.mutation = n;
  }
  getKey() {
    return this.mutation.key;
  }
  isEqual(e) {
    return e !== null && this.mutation === e.mutation;
  }
  toString() {
    return `Overlay{
      largestBatchId: ${this.largestBatchId},
      mutation: ${this.mutation.toString()}
    }`;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Nt {
  constructor(e, n, s, r, i = k.min(), o = k.min(), a = ye.EMPTY_BYTE_STRING) {
    this.target = e, this.targetId = n, this.purpose = s, this.sequenceNumber = r, this.snapshotVersion = i, this.lastLimboFreeSnapshotVersion = o, this.resumeToken = a;
  }
  /** Creates a new target data instance with an updated sequence number. */
  withSequenceNumber(e) {
    return new Nt(this.target, this.targetId, this.purpose, e, this.snapshotVersion, this.lastLimboFreeSnapshotVersion, this.resumeToken);
  }
  /**
   * Creates a new target data instance with an updated resume token and
   * snapshot version.
   */
  withResumeToken(e, n) {
    return new Nt(this.target, this.targetId, this.purpose, this.sequenceNumber, n, this.lastLimboFreeSnapshotVersion, e);
  }
  /**
   * Creates a new target data instance with an updated last limbo free
   * snapshot version number.
   */
  withLastLimboFreeSnapshotVersion(e) {
    return new Nt(this.target, this.targetId, this.purpose, this.sequenceNumber, this.snapshotVersion, e, this.resumeToken);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ky {
  constructor(e) {
    this.ie = e;
  }
}
function Dy(t) {
  const e = Sy({
    parent: t.parent,
    structuredQuery: t.structuredQuery
  });
  return t.limitType === "LAST" ? Vi(
    e,
    e.limit,
    "L"
    /* LimitType.Last */
  ) : e;
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Py {
  constructor() {
    this.Je = new Ly();
  }
  addToCollectionParentIndex(e, n) {
    return this.Je.add(n), y.resolve();
  }
  getCollectionParents(e, n) {
    return y.resolve(this.Je.getEntries(n));
  }
  addFieldIndex(e, n) {
    return y.resolve();
  }
  deleteFieldIndex(e, n) {
    return y.resolve();
  }
  getDocumentsMatchingTarget(e, n) {
    return y.resolve(null);
  }
  getIndexType(e, n) {
    return y.resolve(
      0
      /* IndexType.NONE */
    );
  }
  getFieldIndexes(e, n) {
    return y.resolve([]);
  }
  getNextCollectionGroupToUpdate(e) {
    return y.resolve(null);
  }
  getMinOffset(e, n) {
    return y.resolve(lt.min());
  }
  getMinOffsetFromCollectionGroup(e, n) {
    return y.resolve(lt.min());
  }
  updateCollectionGroup(e, n, s) {
    return y.resolve();
  }
  updateIndexEntries(e, n) {
    return y.resolve();
  }
}
class Ly {
  constructor() {
    this.index = {};
  }
  // Returns false if the entry already existed.
  add(e) {
    const n = e.lastSegment(), s = e.popLast(), r = this.index[n] || new Z(W.comparator), i = !r.has(s);
    return this.index[n] = r.add(s), i;
  }
  has(e) {
    const n = e.lastSegment(), s = e.popLast(), r = this.index[n];
    return r && r.has(s);
  }
  getEntries(e) {
    return (this.index[e] || new Z(W.comparator)).toArray();
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class an {
  constructor(e) {
    this.bn = e;
  }
  next() {
    return this.bn += 2, this.bn;
  }
  static Pn() {
    return new an(0);
  }
  static vn() {
    return new an(-1);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Fy {
  constructor() {
    this.changes = new pn((e) => e.toString(), (e, n) => e.isEqual(n)), this.changesApplied = !1;
  }
  /**
   * Buffers a `RemoteDocumentCache.addEntry()` call.
   *
   * You can only modify documents that have already been retrieved via
   * `getEntry()/getEntries()` (enforced via IndexedDbs `apply()`).
   */
  addEntry(e) {
    this.assertNotApplied(), this.changes.set(e.key, e);
  }
  /**
   * Buffers a `RemoteDocumentCache.removeEntry()` call.
   *
   * You can only remove documents that have already been retrieved via
   * `getEntry()/getEntries()` (enforced via IndexedDbs `apply()`).
   */
  removeEntry(e, n) {
    this.assertNotApplied(), this.changes.set(e, de.newInvalidDocument(e).setReadTime(n));
  }
  /**
   * Looks up an entry in the cache. The buffered changes will first be checked,
   * and if no buffered change applies, this will forward to
   * `RemoteDocumentCache.getEntry()`.
   *
   * @param transaction - The transaction in which to perform any persistence
   *     operations.
   * @param documentKey - The key of the entry to look up.
   * @returns The cached document or an invalid document if we have nothing
   * cached.
   */
  getEntry(e, n) {
    this.assertNotApplied();
    const s = this.changes.get(n);
    return s !== void 0 ? y.resolve(s) : this.getFromCache(e, n);
  }
  /**
   * Looks up several entries in the cache, forwarding to
   * `RemoteDocumentCache.getEntry()`.
   *
   * @param transaction - The transaction in which to perform any persistence
   *     operations.
   * @param documentKeys - The keys of the entries to look up.
   * @returns A map of cached documents, indexed by key. If an entry cannot be
   *     found, the corresponding key will be mapped to an invalid document.
   */
  getEntries(e, n) {
    return this.getAllFromCache(e, n);
  }
  /**
   * Applies buffered changes to the underlying RemoteDocumentCache, using
   * the provided transaction.
   */
  apply(e) {
    return this.assertNotApplied(), this.changesApplied = !0, this.applyChanges(e);
  }
  /** Helper to assert this.changes is not null  */
  assertNotApplied() {
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class My {
  constructor(e, n) {
    this.overlayedDocument = e, this.mutatedFields = n;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class $y {
  constructor(e, n, s, r) {
    this.remoteDocumentCache = e, this.mutationQueue = n, this.documentOverlayCache = s, this.indexManager = r;
  }
  /**
   * Get the local view of the document identified by `key`.
   *
   * @returns Local view of the document or null if we don't have any cached
   * state for it.
   */
  getDocument(e, n) {
    let s = null;
    return this.documentOverlayCache.getOverlay(e, n).next((r) => (s = r, this.remoteDocumentCache.getEntry(e, n))).next((r) => (s !== null && Nn(s.mutation, r, Se.empty(), X.now()), r));
  }
  /**
   * Gets the local view of the documents identified by `keys`.
   *
   * If we don't have cached state for a document in `keys`, a NoDocument will
   * be stored for that key in the resulting set.
   */
  getDocuments(e, n) {
    return this.remoteDocumentCache.getEntries(e, n).next((s) => this.getLocalViewOfDocuments(e, s, M()).next(() => s));
  }
  /**
   * Similar to `getDocuments`, but creates the local view from the given
   * `baseDocs` without retrieving documents from the local store.
   *
   * @param transaction - The transaction this operation is scoped to.
   * @param docs - The documents to apply local mutations to get the local views.
   * @param existenceStateChanged - The set of document keys whose existence state
   *   is changed. This is useful to determine if some documents overlay needs
   *   to be recalculated.
   */
  getLocalViewOfDocuments(e, n, s = M()) {
    const r = At();
    return this.populateOverlays(e, r, n).next(() => this.computeViews(e, n, r, s).next((i) => {
      let o = Sn();
      return i.forEach((a, c) => {
        o = o.insert(a, c.overlayedDocument);
      }), o;
    }));
  }
  /**
   * Gets the overlayed documents for the given document map, which will include
   * the local view of those documents and a `FieldMask` indicating which fields
   * are mutated locally, `null` if overlay is a Set or Delete mutation.
   */
  getOverlayedDocuments(e, n) {
    const s = At();
    return this.populateOverlays(e, s, n).next(() => this.computeViews(e, n, s, M()));
  }
  /**
   * Fetches the overlays for {@code docs} and adds them to provided overlay map
   * if the map does not already contain an entry for the given document key.
   */
  populateOverlays(e, n, s) {
    const r = [];
    return s.forEach((i) => {
      n.has(i) || r.push(i);
    }), this.documentOverlayCache.getOverlays(e, r).next((i) => {
      i.forEach((o, a) => {
        n.set(o, a);
      });
    });
  }
  /**
   * Computes the local view for the given documents.
   *
   * @param docs - The documents to compute views for. It also has the base
   *   version of the documents.
   * @param overlays - The overlays that need to be applied to the given base
   *   version of the documents.
   * @param existenceStateChanged - A set of documents whose existence states
   *   might have changed. This is used to determine if we need to re-calculate
   *   overlays from mutation queues.
   * @return A map represents the local documents view.
   */
  computeViews(e, n, s, r) {
    let i = Ke();
    const o = On(), a = On();
    return n.forEach((c, u) => {
      const l = s.get(u.key);
      r.has(u.key) && (l === void 0 || l.mutation instanceof mt) ? i = i.insert(u.key, u) : l !== void 0 ? (o.set(u.key, l.mutation.getFieldMask()), Nn(l.mutation, u, l.mutation.getFieldMask(), X.now())) : (
        // no overlay exists
        // Using EMPTY to indicate there is no overlay for the document.
        o.set(u.key, Se.empty())
      );
    }), this.recalculateAndSaveOverlays(e, i).next((c) => (c.forEach((u, l) => o.set(u, l)), n.forEach((u, l) => {
      var h;
      return a.set(u, new My(l, (h = o.get(u)) !== null && h !== void 0 ? h : null));
    }), a));
  }
  recalculateAndSaveOverlays(e, n) {
    const s = On();
    let r = new ee((o, a) => o - a), i = M();
    return this.mutationQueue.getAllMutationBatchesAffectingDocumentKeys(e, n).next((o) => {
      for (const a of o)
        a.keys().forEach((c) => {
          const u = n.get(c);
          if (u === null)
            return;
          let l = s.get(c) || Se.empty();
          l = a.applyToLocalView(u, l), s.set(c, l);
          const h = (r.get(a.batchId) || M()).add(c);
          r = r.insert(a.batchId, h);
        });
    }).next(() => {
      const o = [], a = r.getReverseIterator();
      for (; a.hasNext(); ) {
        const c = a.getNext(), u = c.key, l = c.value, h = Ll();
        l.forEach((d) => {
          if (!i.has(d)) {
            const p = Ol(n.get(d), s.get(d));
            p !== null && h.set(d, p), i = i.add(d);
          }
        }), o.push(this.documentOverlayCache.saveOverlays(e, u, h));
      }
      return y.waitFor(o);
    }).next(() => s);
  }
  /**
   * Recalculates overlays by reading the documents from remote document cache
   * first, and saves them after they are calculated.
   */
  recalculateAndSaveOverlaysForDocumentKeys(e, n) {
    return this.remoteDocumentCache.getEntries(e, n).next((s) => this.recalculateAndSaveOverlays(e, s));
  }
  /**
   * Performs a query against the local view of all documents.
   *
   * @param transaction - The persistence transaction.
   * @param query - The query to match documents against.
   * @param offset - Read time and key to start scanning by (exclusive).
   */
  getDocumentsMatchingQuery(e, n, s) {
    return function(r) {
      return I.isDocumentKey(r.path) && r.collectionGroup === null && r.filters.length === 0;
    }(n) ? this.getDocumentsMatchingDocumentQuery(e, n.path) : El(n) ? this.getDocumentsMatchingCollectionGroupQuery(e, n, s) : this.getDocumentsMatchingCollectionQuery(e, n, s);
  }
  /**
   * Given a collection group, returns the next documents that follow the provided offset, along
   * with an updated batch ID.
   *
   * <p>The documents returned by this method are ordered by remote version from the provided
   * offset. If there are no more remote documents after the provided offset, documents with
   * mutations in order of batch id from the offset are returned. Since all documents in a batch are
   * returned together, the total number of documents returned can exceed {@code count}.
   *
   * @param transaction
   * @param collectionGroup The collection group for the documents.
   * @param offset The offset to index into.
   * @param count The number of documents to return
   * @return A LocalWriteResult with the documents that follow the provided offset and the last processed batch id.
   */
  getNextDocuments(e, n, s, r) {
    return this.remoteDocumentCache.getAllFromCollectionGroup(e, n, s, r).next((i) => {
      const o = r - i.size > 0 ? this.documentOverlayCache.getOverlaysForCollectionGroup(e, n, s.largestBatchId, r - i.size) : y.resolve(At());
      let a = -1, c = i;
      return o.next((u) => y.forEach(u, (l, h) => (a < h.largestBatchId && (a = h.largestBatchId), i.get(l) ? y.resolve() : this.remoteDocumentCache.getEntry(e, l).next((d) => {
        c = c.insert(l, d);
      }))).next(() => this.populateOverlays(e, u, i)).next(() => this.computeViews(e, c, u, M())).next((l) => ({
        batchId: a,
        changes: Pl(l)
      })));
    });
  }
  getDocumentsMatchingDocumentQuery(e, n) {
    return this.getDocument(e, new I(n)).next((s) => {
      let r = Sn();
      return s.isFoundDocument() && (r = r.insert(s.key, s)), r;
    });
  }
  getDocumentsMatchingCollectionGroupQuery(e, n, s) {
    const r = n.collectionGroup;
    let i = Sn();
    return this.indexManager.getCollectionParents(e, r).next((o) => y.forEach(o, (a) => {
      const c = function(u, l) {
        return new cs(
          l,
          /*collectionGroup=*/
          null,
          u.explicitOrderBy.slice(),
          u.filters.slice(),
          u.limit,
          u.limitType,
          u.startAt,
          u.endAt
        );
      }(n, a.child(r));
      return this.getDocumentsMatchingCollectionQuery(e, c, s).next((u) => {
        u.forEach((l, h) => {
          i = i.insert(l, h);
        });
      });
    }).next(() => i));
  }
  getDocumentsMatchingCollectionQuery(e, n, s) {
    let r;
    return this.documentOverlayCache.getOverlaysForCollection(e, n.path, s.largestBatchId).next((i) => (r = i, this.remoteDocumentCache.getDocumentsMatchingQuery(e, n, s, r))).next((i) => {
      r.forEach((a, c) => {
        const u = c.getKey();
        i.get(u) === null && (i = i.insert(u, de.newInvalidDocument(u)));
      });
      let o = Sn();
      return i.forEach((a, c) => {
        const u = r.get(a);
        u !== void 0 && Nn(u.mutation, c, Se.empty(), X.now()), // Finally, insert the documents that still match the query
        Nr(n, c) && (o = o.insert(a, c));
      }), o;
    });
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Uy {
  constructor(e) {
    this.yt = e, this.Zn = /* @__PURE__ */ new Map(), this.ts = /* @__PURE__ */ new Map();
  }
  getBundleMetadata(e, n) {
    return y.resolve(this.Zn.get(n));
  }
  saveBundleMetadata(e, n) {
    var s;
    return this.Zn.set(n.id, {
      id: (s = n).id,
      version: s.version,
      createTime: $e(s.createTime)
    }), y.resolve();
  }
  getNamedQuery(e, n) {
    return y.resolve(this.ts.get(n));
  }
  saveNamedQuery(e, n) {
    return this.ts.set(n.name, function(s) {
      return {
        name: s.name,
        query: Dy(s.bundledQuery),
        readTime: $e(s.readTime)
      };
    }(n)), y.resolve();
  }
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class xy {
  constructor() {
    this.overlays = new ee(I.comparator), this.es = /* @__PURE__ */ new Map();
  }
  getOverlay(e, n) {
    return y.resolve(this.overlays.get(n));
  }
  getOverlays(e, n) {
    const s = At();
    return y.forEach(n, (r) => this.getOverlay(e, r).next((i) => {
      i !== null && s.set(r, i);
    })).next(() => s);
  }
  saveOverlays(e, n, s) {
    return s.forEach((r, i) => {
      this.oe(e, n, i);
    }), y.resolve();
  }
  removeOverlaysForBatchId(e, n, s) {
    const r = this.es.get(s);
    return r !== void 0 && (r.forEach((i) => this.overlays = this.overlays.remove(i)), this.es.delete(s)), y.resolve();
  }
  getOverlaysForCollection(e, n, s) {
    const r = At(), i = n.length + 1, o = new I(n.child("")), a = this.overlays.getIteratorFrom(o);
    for (; a.hasNext(); ) {
      const c = a.getNext().value, u = c.getKey();
      if (!n.isPrefixOf(u.path))
        break;
      u.path.length === i && c.largestBatchId > s && r.set(c.getKey(), c);
    }
    return y.resolve(r);
  }
  getOverlaysForCollectionGroup(e, n, s, r) {
    let i = new ee((u, l) => u - l);
    const o = this.overlays.getIterator();
    for (; o.hasNext(); ) {
      const u = o.getNext().value;
      if (u.getKey().getCollectionGroup() === n && u.largestBatchId > s) {
        let l = i.get(u.largestBatchId);
        l === null && (l = At(), i = i.insert(u.largestBatchId, l)), l.set(u.getKey(), u);
      }
    }
    const a = At(), c = i.getIterator();
    for (; c.hasNext() && (c.getNext().value.forEach((u, l) => a.set(u, l)), !(a.size() >= r)); )
      ;
    return y.resolve(a);
  }
  oe(e, n, s) {
    const r = this.overlays.get(s.key);
    if (r !== null) {
      const o = this.es.get(r.largestBatchId).delete(s.key);
      this.es.set(r.largestBatchId, o);
    }
    this.overlays = this.overlays.insert(s.key, new Ry(n, s));
    let i = this.es.get(n);
    i === void 0 && (i = M(), this.es.set(n, i)), this.es.set(n, i.add(s.key));
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class xo {
  constructor() {
    this.ns = new Z(ne.ss), // A set of outstanding references to a document sorted by target id.
    this.rs = new Z(ne.os);
  }
  /** Returns true if the reference set contains no references. */
  isEmpty() {
    return this.ns.isEmpty();
  }
  /** Adds a reference to the given document key for the given ID. */
  addReference(e, n) {
    const s = new ne(e, n);
    this.ns = this.ns.add(s), this.rs = this.rs.add(s);
  }
  /** Add references to the given document keys for the given ID. */
  us(e, n) {
    e.forEach((s) => this.addReference(s, n));
  }
  /**
   * Removes a reference to the given document key for the given
   * ID.
   */
  removeReference(e, n) {
    this.cs(new ne(e, n));
  }
  hs(e, n) {
    e.forEach((s) => this.removeReference(s, n));
  }
  /**
   * Clears all references with a given ID. Calls removeRef() for each key
   * removed.
   */
  ls(e) {
    const n = new I(new W([])), s = new ne(n, e), r = new ne(n, e + 1), i = [];
    return this.rs.forEachInRange([s, r], (o) => {
      this.cs(o), i.push(o.key);
    }), i;
  }
  fs() {
    this.ns.forEach((e) => this.cs(e));
  }
  cs(e) {
    this.ns = this.ns.delete(e), this.rs = this.rs.delete(e);
  }
  ds(e) {
    const n = new I(new W([])), s = new ne(n, e), r = new ne(n, e + 1);
    let i = M();
    return this.rs.forEachInRange([s, r], (o) => {
      i = i.add(o.key);
    }), i;
  }
  containsKey(e) {
    const n = new ne(e, 0), s = this.ns.firstAfterOrEqual(n);
    return s !== null && e.isEqual(s.key);
  }
}
class ne {
  constructor(e, n) {
    this.key = e, this._s = n;
  }
  /** Compare by key then by ID */
  static ss(e, n) {
    return I.comparator(e.key, n.key) || B(e._s, n._s);
  }
  /** Compare by ID then by key */
  static os(e, n) {
    return B(e._s, n._s) || I.comparator(e.key, n.key);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class By {
  constructor(e, n) {
    this.indexManager = e, this.referenceDelegate = n, /**
     * The set of all mutations that have been sent but not yet been applied to
     * the backend.
     */
    this.mutationQueue = [], /** Next value to use when assigning sequential IDs to each mutation batch. */
    this.ws = 1, /** An ordered mapping between documents and the mutations batch IDs. */
    this.gs = new Z(ne.ss);
  }
  checkEmpty(e) {
    return y.resolve(this.mutationQueue.length === 0);
  }
  addMutationBatch(e, n, s, r) {
    const i = this.ws;
    this.ws++, this.mutationQueue.length > 0 && this.mutationQueue[this.mutationQueue.length - 1];
    const o = new Oy(i, n, s, r);
    this.mutationQueue.push(o);
    for (const a of r)
      this.gs = this.gs.add(new ne(a.key, i)), this.indexManager.addToCollectionParentIndex(e, a.key.path.popLast());
    return y.resolve(o);
  }
  lookupMutationBatch(e, n) {
    return y.resolve(this.ys(n));
  }
  getNextMutationBatchAfterBatchId(e, n) {
    const s = n + 1, r = this.ps(s), i = r < 0 ? 0 : r;
    return y.resolve(this.mutationQueue.length > i ? this.mutationQueue[i] : null);
  }
  getHighestUnacknowledgedBatchId() {
    return y.resolve(this.mutationQueue.length === 0 ? -1 : this.ws - 1);
  }
  getAllMutationBatches(e) {
    return y.resolve(this.mutationQueue.slice());
  }
  getAllMutationBatchesAffectingDocumentKey(e, n) {
    const s = new ne(n, 0), r = new ne(n, Number.POSITIVE_INFINITY), i = [];
    return this.gs.forEachInRange([s, r], (o) => {
      const a = this.ys(o._s);
      i.push(a);
    }), y.resolve(i);
  }
  getAllMutationBatchesAffectingDocumentKeys(e, n) {
    let s = new Z(B);
    return n.forEach((r) => {
      const i = new ne(r, 0), o = new ne(r, Number.POSITIVE_INFINITY);
      this.gs.forEachInRange([i, o], (a) => {
        s = s.add(a._s);
      });
    }), y.resolve(this.Is(s));
  }
  getAllMutationBatchesAffectingQuery(e, n) {
    const s = n.path, r = s.length + 1;
    let i = s;
    I.isDocumentKey(i) || (i = i.child(""));
    const o = new ne(new I(i), 0);
    let a = new Z(B);
    return this.gs.forEachWhile((c) => {
      const u = c.key.path;
      return !!s.isPrefixOf(u) && // Rows with document keys more than one segment longer than the query
      // path can't be matches. For example, a query on 'rooms' can't match
      // the document /rooms/abc/messages/xyx.
      // TODO(mcg): we'll need a different scanner when we implement
      // ancestor queries.
      (u.length === r && (a = a.add(c._s)), !0);
    }, o), y.resolve(this.Is(a));
  }
  Is(e) {
    const n = [];
    return e.forEach((s) => {
      const r = this.ys(s);
      r !== null && n.push(r);
    }), n;
  }
  removeMutationBatch(e, n) {
    j(this.Ts(n.batchId, "removed") === 0), this.mutationQueue.shift();
    let s = this.gs;
    return y.forEach(n.mutations, (r) => {
      const i = new ne(r.key, n.batchId);
      return s = s.delete(i), this.referenceDelegate.markPotentiallyOrphaned(e, r.key);
    }).next(() => {
      this.gs = s;
    });
  }
  An(e) {
  }
  containsKey(e, n) {
    const s = new ne(n, 0), r = this.gs.firstAfterOrEqual(s);
    return y.resolve(n.isEqual(r && r.key));
  }
  performConsistencyCheck(e) {
    return this.mutationQueue.length, y.resolve();
  }
  /**
   * Finds the index of the given batchId in the mutation queue and asserts that
   * the resulting index is within the bounds of the queue.
   *
   * @param batchId - The batchId to search for
   * @param action - A description of what the caller is doing, phrased in passive
   * form (e.g. "acknowledged" in a routine that acknowledges batches).
   */
  Ts(e, n) {
    return this.ps(e);
  }
  /**
   * Finds the index of the given batchId in the mutation queue. This operation
   * is O(1).
   *
   * @returns The computed index of the batch with the given batchId, based on
   * the state of the queue. Note this index can be negative if the requested
   * batchId has already been remvoed from the queue or past the end of the
   * queue if the batchId is larger than the last added batch.
   */
  ps(e) {
    return this.mutationQueue.length === 0 ? 0 : e - this.mutationQueue[0].batchId;
  }
  /**
   * A version of lookupMutationBatch that doesn't return a promise, this makes
   * other functions that uses this code easier to read and more efficent.
   */
  ys(e) {
    const n = this.ps(e);
    return n < 0 || n >= this.mutationQueue.length ? null : this.mutationQueue[n];
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class qy {
  /**
   * @param sizer - Used to assess the size of a document. For eager GC, this is
   * expected to just return 0 to avoid unnecessarily doing the work of
   * calculating the size.
   */
  constructor(e) {
    this.Es = e, /** Underlying cache of documents and their read times. */
    this.docs = new ee(I.comparator), /** Size of all cached documents. */
    this.size = 0;
  }
  setIndexManager(e) {
    this.indexManager = e;
  }
  /**
   * Adds the supplied entry to the cache and updates the cache size as appropriate.
   *
   * All calls of `addEntry`  are required to go through the RemoteDocumentChangeBuffer
   * returned by `newChangeBuffer()`.
   */
  addEntry(e, n) {
    const s = n.key, r = this.docs.get(s), i = r ? r.size : 0, o = this.Es(n);
    return this.docs = this.docs.insert(s, {
      document: n.mutableCopy(),
      size: o
    }), this.size += o - i, this.indexManager.addToCollectionParentIndex(e, s.path.popLast());
  }
  /**
   * Removes the specified entry from the cache and updates the cache size as appropriate.
   *
   * All calls of `removeEntry` are required to go through the RemoteDocumentChangeBuffer
   * returned by `newChangeBuffer()`.
   */
  removeEntry(e) {
    const n = this.docs.get(e);
    n && (this.docs = this.docs.remove(e), this.size -= n.size);
  }
  getEntry(e, n) {
    const s = this.docs.get(n);
    return y.resolve(s ? s.document.mutableCopy() : de.newInvalidDocument(n));
  }
  getEntries(e, n) {
    let s = Ke();
    return n.forEach((r) => {
      const i = this.docs.get(r);
      s = s.insert(r, i ? i.document.mutableCopy() : de.newInvalidDocument(r));
    }), y.resolve(s);
  }
  getDocumentsMatchingQuery(e, n, s, r) {
    let i = Ke();
    const o = n.path, a = new I(o.child("")), c = this.docs.getIteratorFrom(a);
    for (; c.hasNext(); ) {
      const { key: u, value: { document: l } } = c.getNext();
      if (!o.isPrefixOf(u.path))
        break;
      u.path.length > o.length + 1 || Dm(km(l), s) <= 0 || (r.has(l.key) || Nr(n, l)) && (i = i.insert(l.key, l.mutableCopy()));
    }
    return y.resolve(i);
  }
  getAllFromCollectionGroup(e, n, s, r) {
    A();
  }
  As(e, n) {
    return y.forEach(this.docs, (s) => n(s));
  }
  newChangeBuffer(e) {
    return new Vy(this);
  }
  getSize(e) {
    return y.resolve(this.size);
  }
}
class Vy extends Fy {
  constructor(e) {
    super(), this.Yn = e;
  }
  applyChanges(e) {
    const n = [];
    return this.changes.forEach((s, r) => {
      r.isValidDocument() ? n.push(this.Yn.addEntry(e, r)) : this.Yn.removeEntry(s);
    }), y.waitFor(n);
  }
  getFromCache(e, n) {
    return this.Yn.getEntry(e, n);
  }
  getAllFromCache(e, n) {
    return this.Yn.getEntries(e, n);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class jy {
  constructor(e) {
    this.persistence = e, /**
     * Maps a target to the data about that target
     */
    this.Rs = new pn((n) => Do(n), Po), /** The last received snapshot version. */
    this.lastRemoteSnapshotVersion = k.min(), /** The highest numbered target ID encountered. */
    this.highestTargetId = 0, /** The highest sequence number encountered. */
    this.bs = 0, /**
     * A ordered bidirectional mapping between documents and the remote target
     * IDs.
     */
    this.Ps = new xo(), this.targetCount = 0, this.vs = an.Pn();
  }
  forEachTarget(e, n) {
    return this.Rs.forEach((s, r) => n(r)), y.resolve();
  }
  getLastRemoteSnapshotVersion(e) {
    return y.resolve(this.lastRemoteSnapshotVersion);
  }
  getHighestSequenceNumber(e) {
    return y.resolve(this.bs);
  }
  allocateTargetId(e) {
    return this.highestTargetId = this.vs.next(), y.resolve(this.highestTargetId);
  }
  setTargetsMetadata(e, n, s) {
    return s && (this.lastRemoteSnapshotVersion = s), n > this.bs && (this.bs = n), y.resolve();
  }
  Dn(e) {
    this.Rs.set(e.target, e);
    const n = e.targetId;
    n > this.highestTargetId && (this.vs = new an(n), this.highestTargetId = n), e.sequenceNumber > this.bs && (this.bs = e.sequenceNumber);
  }
  addTargetData(e, n) {
    return this.Dn(n), this.targetCount += 1, y.resolve();
  }
  updateTargetData(e, n) {
    return this.Dn(n), y.resolve();
  }
  removeTargetData(e, n) {
    return this.Rs.delete(n.target), this.Ps.ls(n.targetId), this.targetCount -= 1, y.resolve();
  }
  removeTargets(e, n, s) {
    let r = 0;
    const i = [];
    return this.Rs.forEach((o, a) => {
      a.sequenceNumber <= n && s.get(a.targetId) === null && (this.Rs.delete(o), i.push(this.removeMatchingKeysForTargetId(e, a.targetId)), r++);
    }), y.waitFor(i).next(() => r);
  }
  getTargetCount(e) {
    return y.resolve(this.targetCount);
  }
  getTargetData(e, n) {
    const s = this.Rs.get(n) || null;
    return y.resolve(s);
  }
  addMatchingKeys(e, n, s) {
    return this.Ps.us(n, s), y.resolve();
  }
  removeMatchingKeys(e, n, s) {
    this.Ps.hs(n, s);
    const r = this.persistence.referenceDelegate, i = [];
    return r && n.forEach((o) => {
      i.push(r.markPotentiallyOrphaned(e, o));
    }), y.waitFor(i);
  }
  removeMatchingKeysForTargetId(e, n) {
    return this.Ps.ls(n), y.resolve();
  }
  getMatchingKeysForTargetId(e, n) {
    const s = this.Ps.ds(n);
    return y.resolve(s);
  }
  containsKey(e, n) {
    return y.resolve(this.Ps.containsKey(n));
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Gy {
  /**
   * The constructor accepts a factory for creating a reference delegate. This
   * allows both the delegate and this instance to have strong references to
   * each other without having nullable fields that would then need to be
   * checked or asserted on every access.
   */
  constructor(e, n) {
    this.Vs = {}, this.overlays = {}, this.Ss = new Ro(0), this.Ds = !1, this.Ds = !0, this.referenceDelegate = e(this), this.Cs = new jy(this), this.indexManager = new Py(), this.remoteDocumentCache = function(s) {
      return new qy(s);
    }((s) => this.referenceDelegate.xs(s)), this.yt = new ky(n), this.Ns = new Uy(this.yt);
  }
  start() {
    return Promise.resolve();
  }
  shutdown() {
    return this.Ds = !1, Promise.resolve();
  }
  get started() {
    return this.Ds;
  }
  setDatabaseDeletedListener() {
  }
  setNetworkEnabled() {
  }
  getIndexManager(e) {
    return this.indexManager;
  }
  getDocumentOverlayCache(e) {
    let n = this.overlays[e.toKey()];
    return n || (n = new xy(), this.overlays[e.toKey()] = n), n;
  }
  getMutationQueue(e, n) {
    let s = this.Vs[e.toKey()];
    return s || (s = new By(n, this.referenceDelegate), this.Vs[e.toKey()] = s), s;
  }
  getTargetCache() {
    return this.Cs;
  }
  getRemoteDocumentCache() {
    return this.remoteDocumentCache;
  }
  getBundleCache() {
    return this.Ns;
  }
  runTransaction(e, n, s) {
    T("MemoryPersistence", "Starting transaction:", e);
    const r = new Wy(this.Ss.next());
    return this.referenceDelegate.ks(), s(r).next((i) => this.referenceDelegate.Os(r).next(() => i)).toPromise().then((i) => (r.raiseOnCommittedEvent(), i));
  }
  Ms(e, n) {
    return y.or(Object.values(this.Vs).map((s) => () => s.containsKey(e, n)));
  }
}
class Wy extends Lm {
  constructor(e) {
    super(), this.currentSequenceNumber = e;
  }
}
class Bo {
  constructor(e) {
    this.persistence = e, /** Tracks all documents that are active in Query views. */
    this.Fs = new xo(), /** The list of documents that are potentially GCed after each transaction. */
    this.$s = null;
  }
  static Bs(e) {
    return new Bo(e);
  }
  get Ls() {
    if (this.$s)
      return this.$s;
    throw A();
  }
  addReference(e, n, s) {
    return this.Fs.addReference(s, n), this.Ls.delete(s.toString()), y.resolve();
  }
  removeReference(e, n, s) {
    return this.Fs.removeReference(s, n), this.Ls.add(s.toString()), y.resolve();
  }
  markPotentiallyOrphaned(e, n) {
    return this.Ls.add(n.toString()), y.resolve();
  }
  removeTarget(e, n) {
    this.Fs.ls(n.targetId).forEach((r) => this.Ls.add(r.toString()));
    const s = this.persistence.getTargetCache();
    return s.getMatchingKeysForTargetId(e, n.targetId).next((r) => {
      r.forEach((i) => this.Ls.add(i.toString()));
    }).next(() => s.removeTargetData(e, n));
  }
  ks() {
    this.$s = /* @__PURE__ */ new Set();
  }
  Os(e) {
    const n = this.persistence.getRemoteDocumentCache().newChangeBuffer();
    return y.forEach(this.Ls, (s) => {
      const r = I.fromPath(s);
      return this.qs(e, r).next((i) => {
        i || n.removeEntry(r, k.min());
      });
    }).next(() => (this.$s = null, n.apply(e)));
  }
  updateLimboDocument(e, n) {
    return this.qs(e, n).next((s) => {
      s ? this.Ls.delete(n.toString()) : this.Ls.add(n.toString());
    });
  }
  xs(e) {
    return 0;
  }
  qs(e, n) {
    return y.or([() => y.resolve(this.Fs.containsKey(n)), () => this.persistence.getTargetCache().containsKey(e, n), () => this.persistence.Ms(e, n)]);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class qo {
  constructor(e, n, s, r) {
    this.targetId = e, this.fromCache = n, this.Si = s, this.Di = r;
  }
  static Ci(e, n) {
    let s = M(), r = M();
    for (const i of n.docChanges)
      switch (i.type) {
        case 0:
          s = s.add(i.doc.key);
          break;
        case 1:
          r = r.add(i.doc.key);
      }
    return new qo(e, n.fromCache, s, r);
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Hy {
  constructor() {
    this.xi = !1;
  }
  /** Sets the document view to query against. */
  initialize(e, n) {
    this.Ni = e, this.indexManager = n, this.xi = !0;
  }
  /** Returns all local documents matching the specified query. */
  getDocumentsMatchingQuery(e, n, s, r) {
    return this.ki(e, n).next((i) => i || this.Oi(e, n, r, s)).next((i) => i || this.Mi(e, n));
  }
  /**
   * Performs an indexed query that evaluates the query based on a collection's
   * persisted index values. Returns `null` if an index is not available.
   */
  ki(e, n) {
    if (Ya(n))
      return y.resolve(null);
    let s = qe(n);
    return this.indexManager.getIndexType(e, s).next((r) => r === 0 ? null : (n.limit !== null && r === 1 && // We cannot apply a limit for targets that are served using a partial
    // index. If a partial index will be used to serve the target, the
    // query may return a superset of documents that match the target
    // (e.g. if the index doesn't include all the target's filters), or
    // may return the correct set of documents in the wrong order (e.g. if
    // the index doesn't include a segment for one of the orderBys).
    // Therefore, a limit should not be applied in such cases.
    (n = Vi(
      n,
      null,
      "F"
      /* LimitType.First */
    ), s = qe(n)), this.indexManager.getDocumentsMatchingTarget(e, s).next((i) => {
      const o = M(...i);
      return this.Ni.getDocuments(e, o).next((a) => this.indexManager.getMinOffset(e, s).next((c) => {
        const u = this.Fi(n, a);
        return this.$i(n, u, o, c.readTime) ? this.ki(e, Vi(
          n,
          null,
          "F"
          /* LimitType.First */
        )) : this.Bi(e, u, n, c);
      }));
    })));
  }
  /**
   * Performs a query based on the target's persisted query mapping. Returns
   * `null` if the mapping is not available or cannot be used.
   */
  Oi(e, n, s, r) {
    return Ya(n) || r.isEqual(k.min()) ? this.Mi(e, n) : this.Ni.getDocuments(e, s).next((i) => {
      const o = this.Fi(n, i);
      return this.$i(n, o, s, r) ? this.Mi(e, n) : (qa() <= x.DEBUG && T("QueryEngine", "Re-using previous result from %s to execute query: %s", r.toString(), ji(n)), this.Bi(e, o, n, Rm(r, -1)));
    });
  }
  /** Applies the query filter and sorting to the provided documents.  */
  Fi(e, n) {
    let s = new Z(Tl(e));
    return n.forEach((r, i) => {
      Nr(e, i) && (s = s.add(i));
    }), s;
  }
  /**
   * Determines if a limit query needs to be refilled from cache, making it
   * ineligible for index-free execution.
   *
   * @param query - The query.
   * @param sortedPreviousResults - The documents that matched the query when it
   * was last synchronized, sorted by the query's comparator.
   * @param remoteKeys - The document keys that matched the query at the last
   * snapshot.
   * @param limboFreeSnapshotVersion - The version of the snapshot when the
   * query was last synchronized.
   */
  $i(e, n, s, r) {
    if (e.limit === null)
      return !1;
    if (s.size !== n.size)
      return !0;
    const i = e.limitType === "F" ? n.last() : n.first();
    return !!i && (i.hasPendingWrites || i.version.compareTo(r) > 0);
  }
  Mi(e, n) {
    return qa() <= x.DEBUG && T("QueryEngine", "Using full collection scan to execute query:", ji(n)), this.Ni.getDocumentsMatchingQuery(e, n, lt.min());
  }
  /**
   * Combines the results from an indexed execution with the remaining documents
   * that have not yet been indexed.
   */
  Bi(e, n, s, r) {
    return this.Ni.getDocumentsMatchingQuery(e, s, r).next((i) => (
      // Merge with existing results
      (n.forEach((o) => {
        i = i.insert(o.key, o);
      }), i)
    ));
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class zy {
  constructor(e, n, s, r) {
    this.persistence = e, this.Li = n, this.yt = r, /**
     * Maps a targetID to data about its target.
     *
     * PORTING NOTE: We are using an immutable data structure on Web to make re-runs
     * of `applyRemoteEvent()` idempotent.
     */
    this.qi = new ee(B), /** Maps a target to its targetID. */
    // TODO(wuandy): Evaluate if TargetId can be part of Target.
    this.Ui = new pn((i) => Do(i), Po), /**
     * A per collection group index of the last read time processed by
     * `getNewDocumentChanges()`.
     *
     * PORTING NOTE: This is only used for multi-tab synchronization.
     */
    this.Ki = /* @__PURE__ */ new Map(), this.Gi = e.getRemoteDocumentCache(), this.Cs = e.getTargetCache(), this.Ns = e.getBundleCache(), this.Qi(s);
  }
  Qi(e) {
    this.documentOverlayCache = this.persistence.getDocumentOverlayCache(e), this.indexManager = this.persistence.getIndexManager(e), this.mutationQueue = this.persistence.getMutationQueue(e, this.indexManager), this.localDocuments = new $y(this.Gi, this.mutationQueue, this.documentOverlayCache, this.indexManager), this.Gi.setIndexManager(this.indexManager), this.Li.initialize(this.localDocuments, this.indexManager);
  }
  collectGarbage(e) {
    return this.persistence.runTransaction("Collect garbage", "readwrite-primary", (n) => e.collect(n, this.qi));
  }
}
function Qy(t, e, n, s) {
  return new zy(t, e, n, s);
}
async function Wl(t, e) {
  const n = D(t);
  return await n.persistence.runTransaction("Handle user change", "readonly", (s) => {
    let r;
    return n.mutationQueue.getAllMutationBatches(s).next((i) => (r = i, n.Qi(e), n.mutationQueue.getAllMutationBatches(s))).next((i) => {
      const o = [], a = [];
      let c = M();
      for (const u of r) {
        o.push(u.batchId);
        for (const l of u.mutations)
          c = c.add(l.key);
      }
      for (const u of i) {
        a.push(u.batchId);
        for (const l of u.mutations)
          c = c.add(l.key);
      }
      return n.localDocuments.getDocuments(s, c).next((u) => ({
        ji: u,
        removedBatchIds: o,
        addedBatchIds: a
      }));
    });
  });
}
function Ky(t, e) {
  const n = D(t);
  return n.persistence.runTransaction("Acknowledge batch", "readwrite-primary", (s) => {
    const r = e.batch.keys(), i = n.Gi.newChangeBuffer({
      trackRemovals: !0
    });
    return function(o, a, c, u) {
      const l = c.batch, h = l.keys();
      let d = y.resolve();
      return h.forEach((p) => {
        d = d.next(() => u.getEntry(a, p)).next((m) => {
          const S = c.docVersions.get(p);
          j(S !== null), m.version.compareTo(S) < 0 && (l.applyToRemoteDocument(m, c), m.isValidDocument() && // We use the commitVersion as the readTime rather than the
          // document's updateTime since the updateTime is not advanced
          // for updates that do not modify the underlying document.
          (m.setReadTime(c.commitVersion), u.addEntry(m)));
        });
      }), d.next(() => o.mutationQueue.removeMutationBatch(a, l));
    }(n, s, e, i).next(() => i.apply(s)).next(() => n.mutationQueue.performConsistencyCheck(s)).next(() => n.documentOverlayCache.removeOverlaysForBatchId(s, r, e.batch.batchId)).next(() => n.localDocuments.recalculateAndSaveOverlaysForDocumentKeys(s, function(o) {
      let a = M();
      for (let c = 0; c < o.mutationResults.length; ++c)
        o.mutationResults[c].transformResults.length > 0 && (a = a.add(o.batch.mutations[c].key));
      return a;
    }(e))).next(() => n.localDocuments.getDocuments(s, r));
  });
}
function Hl(t) {
  const e = D(t);
  return e.persistence.runTransaction("Get last remote snapshot version", "readonly", (n) => e.Cs.getLastRemoteSnapshotVersion(n));
}
function Jy(t, e) {
  const n = D(t), s = e.snapshotVersion;
  let r = n.qi;
  return n.persistence.runTransaction("Apply remote event", "readwrite-primary", (i) => {
    const o = n.Gi.newChangeBuffer({
      trackRemovals: !0
    });
    r = n.qi;
    const a = [];
    e.targetChanges.forEach((l, h) => {
      const d = r.get(h);
      if (!d)
        return;
      a.push(n.Cs.removeMatchingKeys(i, l.removedDocuments, h).next(() => n.Cs.addMatchingKeys(i, l.addedDocuments, h)));
      let p = d.withSequenceNumber(i.currentSequenceNumber);
      e.targetMismatches.has(h) ? p = p.withResumeToken(ye.EMPTY_BYTE_STRING, k.min()).withLastLimboFreeSnapshotVersion(k.min()) : l.resumeToken.approximateByteSize() > 0 && (p = p.withResumeToken(l.resumeToken, s)), r = r.insert(h, p), // Update the target data if there are target changes (or if
      // sufficient time has passed since the last update).
      /**
      * Returns true if the newTargetData should be persisted during an update of
      * an active target. TargetData should always be persisted when a target is
      * being released and should not call this function.
      *
      * While the target is active, TargetData updates can be omitted when nothing
      * about the target has changed except metadata like the resume token or
      * snapshot version. Occasionally it's worth the extra write to prevent these
      * values from getting too stale after a crash, but this doesn't have to be
      * too frequent.
      */
      function(m, S, P) {
        return m.resumeToken.approximateByteSize() === 0 || S.snapshotVersion.toMicroseconds() - m.snapshotVersion.toMicroseconds() >= 3e8 ? !0 : P.addedDocuments.size + P.modifiedDocuments.size + P.removedDocuments.size > 0;
      }(d, p, l) && a.push(n.Cs.updateTargetData(i, p));
    });
    let c = Ke(), u = M();
    if (e.documentUpdates.forEach((l) => {
      e.resolvedLimboDocuments.has(l) && a.push(n.persistence.referenceDelegate.updateLimboDocument(i, l));
    }), // Each loop iteration only affects its "own" doc, so it's safe to get all
    // the remote documents in advance in a single call.
    a.push(Yy(i, o, e.documentUpdates).next((l) => {
      c = l.Wi, u = l.zi;
    })), !s.isEqual(k.min())) {
      const l = n.Cs.getLastRemoteSnapshotVersion(i).next((h) => n.Cs.setTargetsMetadata(i, i.currentSequenceNumber, s));
      a.push(l);
    }
    return y.waitFor(a).next(() => o.apply(i)).next(() => n.localDocuments.getLocalViewOfDocuments(i, c, u)).next(() => c);
  }).then((i) => (n.qi = r, i));
}
function Yy(t, e, n) {
  let s = M(), r = M();
  return n.forEach((i) => s = s.add(i)), e.getEntries(t, s).next((i) => {
    let o = Ke();
    return n.forEach((a, c) => {
      const u = i.get(a);
      c.isFoundDocument() !== u.isFoundDocument() && (r = r.add(a)), // Note: The order of the steps below is important, since we want
      // to ensure that rejected limbo resolutions (which fabricate
      // NoDocuments with SnapshotVersion.min()) never add documents to
      // cache.
      c.isNoDocument() && c.version.isEqual(k.min()) ? (
        // NoDocuments with SnapshotVersion.min() are used in manufactured
        // events. We remove these documents from cache since we lost
        // access.
        (e.removeEntry(a, c.readTime), o = o.insert(a, c))
      ) : !u.isValidDocument() || c.version.compareTo(u.version) > 0 || c.version.compareTo(u.version) === 0 && u.hasPendingWrites ? (e.addEntry(c), o = o.insert(a, c)) : T("LocalStore", "Ignoring outdated watch update for ", a, ". Current version:", u.version, " Watch version:", c.version);
    }), {
      Wi: o,
      zi: r
    };
  });
}
function Xy(t, e) {
  const n = D(t);
  return n.persistence.runTransaction("Get next mutation batch", "readonly", (s) => (e === void 0 && (e = -1), n.mutationQueue.getNextMutationBatchAfterBatchId(s, e)));
}
function Zy(t, e) {
  const n = D(t);
  return n.persistence.runTransaction("Allocate target", "readwrite", (s) => {
    let r;
    return n.Cs.getTargetData(s, e).next((i) => i ? (
      // This target has been listened to previously, so reuse the
      // previous targetID.
      // TODO(mcg): freshen last accessed date?
      (r = i, y.resolve(r))
    ) : n.Cs.allocateTargetId(s).next((o) => (r = new Nt(e, o, 0, s.currentSequenceNumber), n.Cs.addTargetData(s, r).next(() => r))));
  }).then((s) => {
    const r = n.qi.get(s.targetId);
    return (r === null || s.snapshotVersion.compareTo(r.snapshotVersion) > 0) && (n.qi = n.qi.insert(s.targetId, s), n.Ui.set(e, s.targetId)), s;
  });
}
async function zi(t, e, n) {
  const s = D(t), r = s.qi.get(e), i = n ? "readwrite" : "readwrite-primary";
  try {
    n || await s.persistence.runTransaction("Release target", i, (o) => s.persistence.referenceDelegate.removeTarget(o, r));
  } catch (o) {
    if (!as(o))
      throw o;
    T("LocalStore", `Failed to update sequence numbers for target ${e}: ${o}`);
  }
  s.qi = s.qi.remove(e), s.Ui.delete(r.target);
}
function oc(t, e, n) {
  const s = D(t);
  let r = k.min(), i = M();
  return s.persistence.runTransaction("Execute query", "readonly", (o) => function(a, c, u) {
    const l = D(a), h = l.Ui.get(u);
    return h !== void 0 ? y.resolve(l.qi.get(h)) : l.Cs.getTargetData(c, u);
  }(s, o, qe(e)).next((a) => {
    if (a)
      return r = a.lastLimboFreeSnapshotVersion, s.Cs.getMatchingKeysForTargetId(o, a.targetId).next((c) => {
        i = c;
      });
  }).next(() => s.Li.getDocumentsMatchingQuery(o, e, n ? r : k.min(), n ? i : M())).next((a) => (e_(s, Jm(e), a), {
    documents: a,
    Hi: i
  })));
}
function e_(t, e, n) {
  let s = t.Ki.get(e) || k.min();
  n.forEach((r, i) => {
    i.readTime.compareTo(s) > 0 && (s = i.readTime);
  }), t.Ki.set(e, s);
}
class ac {
  constructor() {
    this.activeTargetIds = Fl();
  }
  er(e) {
    this.activeTargetIds = this.activeTargetIds.add(e);
  }
  nr(e) {
    this.activeTargetIds = this.activeTargetIds.delete(e);
  }
  /**
   * Converts this entry into a JSON-encoded format we can use for WebStorage.
   * Does not encode `clientId` as it is part of the key in WebStorage.
   */
  tr() {
    const e = {
      activeTargetIds: this.activeTargetIds.toArray(),
      updateTimeMs: Date.now()
    };
    return JSON.stringify(e);
  }
}
class t_ {
  constructor() {
    this.Lr = new ac(), this.qr = {}, this.onlineStateHandler = null, this.sequenceNumberHandler = null;
  }
  addPendingMutation(e) {
  }
  updateMutationState(e, n, s) {
  }
  addLocalQueryTarget(e) {
    return this.Lr.er(e), this.qr[e] || "not-current";
  }
  updateQueryState(e, n, s) {
    this.qr[e] = n;
  }
  removeLocalQueryTarget(e) {
    this.Lr.nr(e);
  }
  isLocalQueryTarget(e) {
    return this.Lr.activeTargetIds.has(e);
  }
  clearQueryState(e) {
    delete this.qr[e];
  }
  getAllActiveQueryTargets() {
    return this.Lr.activeTargetIds;
  }
  isActiveQueryTarget(e) {
    return this.Lr.activeTargetIds.has(e);
  }
  start() {
    return this.Lr = new ac(), Promise.resolve();
  }
  handleUserChange(e, n, s) {
  }
  setOnlineState(e) {
  }
  shutdown() {
  }
  writeSequenceNumber(e) {
  }
  notifyBundleLoaded(e) {
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class n_ {
  Ur(e) {
  }
  shutdown() {
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class cc {
  constructor() {
    this.Kr = () => this.Gr(), this.Qr = () => this.jr(), this.Wr = [], this.zr();
  }
  Ur(e) {
    this.Wr.push(e);
  }
  shutdown() {
    window.removeEventListener("online", this.Kr), window.removeEventListener("offline", this.Qr);
  }
  zr() {
    window.addEventListener("online", this.Kr), window.addEventListener("offline", this.Qr);
  }
  Gr() {
    T("ConnectivityMonitor", "Network connectivity changed: AVAILABLE");
    for (const e of this.Wr)
      e(
        0
        /* NetworkStatus.AVAILABLE */
      );
  }
  jr() {
    T("ConnectivityMonitor", "Network connectivity changed: UNAVAILABLE");
    for (const e of this.Wr)
      e(
        1
        /* NetworkStatus.UNAVAILABLE */
      );
  }
  // TODO(chenbrian): Consider passing in window either into this component or
  // here for testing via FakeWindow.
  /** Checks that all used attributes of window are available. */
  static C() {
    return typeof window < "u" && window.addEventListener !== void 0 && window.removeEventListener !== void 0;
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const s_ = {
  BatchGetDocuments: "batchGet",
  Commit: "commit",
  RunQuery: "runQuery",
  RunAggregationQuery: "runAggregationQuery"
};
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class r_ {
  constructor(e) {
    this.Hr = e.Hr, this.Jr = e.Jr;
  }
  Yr(e) {
    this.Xr = e;
  }
  Zr(e) {
    this.eo = e;
  }
  onMessage(e) {
    this.no = e;
  }
  close() {
    this.Jr();
  }
  send(e) {
    this.Hr(e);
  }
  so() {
    this.Xr();
  }
  io(e) {
    this.eo(e);
  }
  ro(e) {
    this.no(e);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class i_ extends /**
 * Base class for all Rest-based connections to the backend (WebChannel and
 * HTTP).
 */
class {
  constructor(e) {
    this.databaseInfo = e, this.databaseId = e.databaseId;
    const n = e.ssl ? "https" : "http";
    this.oo = n + "://" + e.host, this.uo = "projects/" + this.databaseId.projectId + "/databases/" + this.databaseId.database + "/documents";
  }
  get co() {
    return !1;
  }
  ao(e, n, s, r, i) {
    const o = this.ho(e, n);
    T("RestConnection", "Sending: ", o, s);
    const a = {};
    return this.lo(a, r, i), this.fo(e, o, a, s).then((c) => (T("RestConnection", "Received: ", c), c), (c) => {
      throw Mi("RestConnection", `${e} failed with error: `, c, "url: ", o, "request:", s), c;
    });
  }
  _o(e, n, s, r, i, o) {
    return this.ao(e, n, s, r, i);
  }
  /**
   * Modifies the headers for a request, adding any authorization token if
   * present and any additional headers for the request.
   */
  lo(e, n, s) {
    e["X-Goog-Api-Client"] = "gl-js/ fire/" + fn, // Content-Type: text/plain will avoid preflight requests which might
    // mess with CORS and redirects by proxies. If we add custom headers
    // we will need to change this code to potentially use the $httpOverwrite
    // parameter supported by ESF to avoid triggering preflight requests.
    e["Content-Type"] = "text/plain", this.databaseInfo.appId && (e["X-Firebase-GMPID"] = this.databaseInfo.appId), n && n.headers.forEach((r, i) => e[i] = r), s && s.headers.forEach((r, i) => e[i] = r);
  }
  ho(e, n) {
    const s = s_[e];
    return `${this.oo}/v1/${n}:${s}`;
  }
} {
  constructor(e) {
    super(e), this.forceLongPolling = e.forceLongPolling, this.autoDetectLongPolling = e.autoDetectLongPolling, this.useFetchStreams = e.useFetchStreams;
  }
  fo(e, n, s, r) {
    return new Promise((i, o) => {
      const a = new Em();
      a.setWithCredentials(!0), a.listenOnce(ym.COMPLETE, () => {
        try {
          switch (a.getLastErrorCode()) {
            case fi.NO_ERROR:
              const u = a.getResponseJson();
              T("Connection", "XHR received:", JSON.stringify(u)), i(u);
              break;
            case fi.TIMEOUT:
              T("Connection", 'RPC "' + e + '" timed out'), o(new w(g.DEADLINE_EXCEEDED, "Request time out"));
              break;
            case fi.HTTP_ERROR:
              const l = a.getStatus();
              if (T("Connection", 'RPC "' + e + '" failed with status:', l, "response text:", a.getResponseText()), l > 0) {
                let h = a.getResponseJson();
                Array.isArray(h) && (h = h[0]);
                const d = h?.error;
                if (d && d.status && d.message) {
                  const p = function(m) {
                    const S = m.toLowerCase().replace(/_/g, "-");
                    return Object.values(g).indexOf(S) >= 0 ? S : g.UNKNOWN;
                  }(d.status);
                  o(new w(p, d.message));
                } else
                  o(new w(g.UNKNOWN, "Server responded with status " + a.getStatus()));
              } else
                o(new w(g.UNAVAILABLE, "Connection failed."));
              break;
            default:
              A();
          }
        } finally {
          T("Connection", 'RPC "' + e + '" completed.');
        }
      });
      const c = JSON.stringify(r);
      a.send(n, "POST", c, s, 15);
    });
  }
  wo(e, n, s) {
    const r = [this.oo, "/", "google.firestore.v1.Firestore", "/", e, "/channel"], i = gm(), o = mm(), a = {
      // Required for backend stickiness, routing behavior is based on this
      // parameter.
      httpSessionIdParam: "gsessionid",
      initMessageHeaders: {},
      messageUrlParams: {
        // This param is used to improve routing and project isolation by the
        // backend and must be included in every request.
        database: `projects/${this.databaseId.projectId}/databases/${this.databaseId.database}`
      },
      sendRawJson: !0,
      supportsCrossDomainXhr: !0,
      internalChannelParams: {
        // Override the default timeout (randomized between 10-20 seconds) since
        // a large write batch on a slow internet connection may take a long
        // time to send to the backend. Rather than have WebChannel impose a
        // tight timeout which could lead to infinite timeouts and retries, we
        // set it very large (5-10 minutes) and rely on the browser's builtin
        // timeouts to kick in if the request isn't working.
        forwardChannelRequestTimeoutMs: 6e5
      },
      forceLongPolling: this.forceLongPolling,
      detectBufferingProxy: this.autoDetectLongPolling
    };
    this.useFetchStreams && (a.xmlHttpFactory = new wm({})), this.lo(a.initMessageHeaders, n, s), // Sending the custom headers we just added to request.initMessageHeaders
    // (Authorization, etc.) will trigger the browser to make a CORS preflight
    // request because the XHR will no longer meet the criteria for a "simple"
    // CORS request:
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
    // Therefore to avoid the CORS preflight request (an extra network
    // roundtrip), we use the encodeInitMessageHeaders option to specify that
    // the headers should instead be encoded in the request's POST payload,
    // which is recognized by the webchannel backend.
    a.encodeInitMessageHeaders = !0;
    const c = r.join("");
    T("Connection", "Creating WebChannel: " + c, a);
    const u = i.createWebChannel(c, a);
    let l = !1, h = !1;
    const d = new r_({
      Hr: (m) => {
        h ? T("Connection", "Not sending because WebChannel is closed:", m) : (l || (T("Connection", "Opening WebChannel transport."), u.open(), l = !0), T("Connection", "WebChannel sending:", m), u.send(m));
      },
      Jr: () => u.close()
    }), p = (m, S, P) => {
      m.listen(S, (te) => {
        try {
          P(te);
        } catch (Q) {
          setTimeout(() => {
            throw Q;
          }, 0);
        }
      });
    };
    return p(u, Is.EventType.OPEN, () => {
      h || T("Connection", "WebChannel transport opened.");
    }), p(u, Is.EventType.CLOSE, () => {
      h || (h = !0, T("Connection", "WebChannel transport closed"), d.io());
    }), p(u, Is.EventType.ERROR, (m) => {
      h || (h = !0, Mi("Connection", "WebChannel transport errored:", m), d.io(new w(g.UNAVAILABLE, "The operation could not be completed")));
    }), p(u, Is.EventType.MESSAGE, (m) => {
      var S;
      if (!h) {
        const P = m.data[0];
        j(!!P);
        const te = P, Q = te.error || ((S = te[0]) === null || S === void 0 ? void 0 : S.error);
        if (Q) {
          T("Connection", "WebChannel received error:", Q);
          const De = Q.status;
          let Ce = (
            /**
            * Maps an error Code from a GRPC status identifier like 'NOT_FOUND'.
            *
            * @returns The Code equivalent to the given status string or undefined if
            *     there is no match.
            */
            function(Et) {
              const vt = K[Et];
              if (vt !== void 0)
                return kl(vt);
            }(De)
          ), Pe = Q.message;
          Ce === void 0 && (Ce = g.INTERNAL, Pe = "Unknown error status: " + De + " with message " + Q.message), // Mark closed so no further events are propagated
          h = !0, d.io(new w(Ce, Pe)), u.close();
        } else
          T("Connection", "WebChannel received:", P), d.ro(P);
      }
    }), p(o, _m.STAT_EVENT, (m) => {
      m.stat === xa.PROXY ? T("Connection", "Detected buffering proxy") : m.stat === xa.NOPROXY && T("Connection", "Detected no buffering proxy");
    }), setTimeout(() => {
      d.so();
    }, 0), d;
  }
}
function gi() {
  return typeof document < "u" ? document : null;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Dr(t) {
  return new my(
    t,
    /* useProto3Json= */
    !0
  );
}
class zl {
  constructor(e, n, s = 1e3, r = 1.5, i = 6e4) {
    this.Hs = e, this.timerId = n, this.mo = s, this.yo = r, this.po = i, this.Io = 0, this.To = null, /** The last backoff attempt, as epoch milliseconds. */
    this.Eo = Date.now(), this.reset();
  }
  /**
   * Resets the backoff delay.
   *
   * The very next backoffAndWait() will have no delay. If it is called again
   * (i.e. due to an error), initialDelayMs (plus jitter) will be used, and
   * subsequent ones will increase according to the backoffFactor.
   */
  reset() {
    this.Io = 0;
  }
  /**
   * Resets the backoff delay to the maximum delay (e.g. for use after a
   * RESOURCE_EXHAUSTED error).
   */
  Ao() {
    this.Io = this.po;
  }
  /**
   * Returns a promise that resolves after currentDelayMs, and increases the
   * delay for any subsequent attempts. If there was a pending backoff operation
   * already, it will be canceled.
   */
  Ro(e) {
    this.cancel();
    const n = Math.floor(this.Io + this.bo()), s = Math.max(0, Date.now() - this.Eo), r = Math.max(0, n - s);
    r > 0 && T("ExponentialBackoff", `Backing off for ${r} ms (base delay: ${this.Io} ms, delay with jitter: ${n} ms, last attempt: ${s} ms ago)`), this.To = this.Hs.enqueueAfterDelay(this.timerId, r, () => (this.Eo = Date.now(), e())), // Apply backoff factor to determine next delay and ensure it is within
    // bounds.
    this.Io *= this.yo, this.Io < this.mo && (this.Io = this.mo), this.Io > this.po && (this.Io = this.po);
  }
  Po() {
    this.To !== null && (this.To.skipDelay(), this.To = null);
  }
  cancel() {
    this.To !== null && (this.To.cancel(), this.To = null);
  }
  /** Returns a random value in the range [-currentBaseMs/2, currentBaseMs/2] */
  bo() {
    return (Math.random() - 0.5) * this.Io;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ql {
  constructor(e, n, s, r, i, o, a, c) {
    this.Hs = e, this.vo = s, this.Vo = r, this.connection = i, this.authCredentialsProvider = o, this.appCheckCredentialsProvider = a, this.listener = c, this.state = 0, /**
     * A close count that's incremented every time the stream is closed; used by
     * getCloseGuardedDispatcher() to invalidate callbacks that happen after
     * close.
     */
    this.So = 0, this.Do = null, this.Co = null, this.stream = null, this.xo = new zl(e, n);
  }
  /**
   * Returns true if start() has been called and no error has occurred. True
   * indicates the stream is open or in the process of opening (which
   * encompasses respecting backoff, getting auth tokens, and starting the
   * actual RPC). Use isOpen() to determine if the stream is open and ready for
   * outbound requests.
   */
  No() {
    return this.state === 1 || this.state === 5 || this.ko();
  }
  /**
   * Returns true if the underlying RPC is open (the onOpen() listener has been
   * called) and the stream is ready for outbound requests.
   */
  ko() {
    return this.state === 2 || this.state === 3;
  }
  /**
   * Starts the RPC. Only allowed if isStarted() returns false. The stream is
   * not immediately ready for use: onOpen() will be invoked when the RPC is
   * ready for outbound requests, at which point isOpen() will return true.
   *
   * When start returns, isStarted() will return true.
   */
  start() {
    this.state !== 4 ? this.auth() : this.Oo();
  }
  /**
   * Stops the RPC. This call is idempotent and allowed regardless of the
   * current isStarted() state.
   *
   * When stop returns, isStarted() and isOpen() will both return false.
   */
  async stop() {
    this.No() && await this.close(
      0
      /* PersistentStreamState.Initial */
    );
  }
  /**
   * After an error the stream will usually back off on the next attempt to
   * start it. If the error warrants an immediate restart of the stream, the
   * sender can use this to indicate that the receiver should not back off.
   *
   * Each error will call the onClose() listener. That function can decide to
   * inhibit backoff if required.
   */
  Mo() {
    this.state = 0, this.xo.reset();
  }
  /**
   * Marks this stream as idle. If no further actions are performed on the
   * stream for one minute, the stream will automatically close itself and
   * notify the stream's onClose() handler with Status.OK. The stream will then
   * be in a !isStarted() state, requiring the caller to start the stream again
   * before further use.
   *
   * Only streams that are in state 'Open' can be marked idle, as all other
   * states imply pending network operations.
   */
  Fo() {
    this.ko() && this.Do === null && (this.Do = this.Hs.enqueueAfterDelay(this.vo, 6e4, () => this.$o()));
  }
  /** Sends a message to the underlying stream. */
  Bo(e) {
    this.Lo(), this.stream.send(e);
  }
  /** Called by the idle timer when the stream should close due to inactivity. */
  async $o() {
    if (this.ko())
      return this.close(
        0
        /* PersistentStreamState.Initial */
      );
  }
  /** Marks the stream as active again. */
  Lo() {
    this.Do && (this.Do.cancel(), this.Do = null);
  }
  /** Cancels the health check delayed operation. */
  qo() {
    this.Co && (this.Co.cancel(), this.Co = null);
  }
  /**
   * Closes the stream and cleans up as necessary:
   *
   * * closes the underlying GRPC stream;
   * * calls the onClose handler with the given 'error';
   * * sets internal stream state to 'finalState';
   * * adjusts the backoff timer based on the error
   *
   * A new stream can be opened by calling start().
   *
   * @param finalState - the intended state of the stream after closing.
   * @param error - the error the connection was closed with.
   */
  async close(e, n) {
    this.Lo(), this.qo(), this.xo.cancel(), // Invalidates any stream-related callbacks (e.g. from auth or the
    // underlying stream), guaranteeing they won't execute.
    this.So++, e !== 4 ? (
      // If this is an intentional close ensure we don't delay our next connection attempt.
      this.xo.reset()
    ) : n && n.code === g.RESOURCE_EXHAUSTED ? (
      // Log the error. (Probably either 'quota exceeded' or 'max queue length reached'.)
      (Qe(n.toString()), Qe("Using maximum backoff delay to prevent overloading the backend."), this.xo.Ao())
    ) : n && n.code === g.UNAUTHENTICATED && this.state !== 3 && // "unauthenticated" error means the token was rejected. This should rarely
    // happen since both Auth and AppCheck ensure a sufficient TTL when we
    // request a token. If a user manually resets their system clock this can
    // fail, however. In this case, we should get a Code.UNAUTHENTICATED error
    // before we received the first message and we need to invalidate the token
    // to ensure that we fetch a new token.
    (this.authCredentialsProvider.invalidateToken(), this.appCheckCredentialsProvider.invalidateToken()), // Clean up the underlying stream because we are no longer interested in events.
    this.stream !== null && (this.Uo(), this.stream.close(), this.stream = null), // This state must be assigned before calling onClose() to allow the callback to
    // inhibit backoff or otherwise manipulate the state in its non-started state.
    this.state = e, // Notify the listener that the stream closed.
    await this.listener.Zr(n);
  }
  /**
   * Can be overridden to perform additional cleanup before the stream is closed.
   * Calling super.tearDown() is not required.
   */
  Uo() {
  }
  auth() {
    this.state = 1;
    const e = this.Ko(this.So), n = this.So;
    Promise.all([this.authCredentialsProvider.getToken(), this.appCheckCredentialsProvider.getToken()]).then(([s, r]) => {
      this.So === n && // Normally we'd have to schedule the callback on the AsyncQueue.
      // However, the following calls are safe to be called outside the
      // AsyncQueue since they don't chain asynchronous calls
      this.Go(s, r);
    }, (s) => {
      e(() => {
        const r = new w(g.UNKNOWN, "Fetching auth token failed: " + s.message);
        return this.Qo(r);
      });
    });
  }
  Go(e, n) {
    const s = this.Ko(this.So);
    this.stream = this.jo(e, n), this.stream.Yr(() => {
      s(() => (this.state = 2, this.Co = this.Hs.enqueueAfterDelay(this.Vo, 1e4, () => (this.ko() && (this.state = 3), Promise.resolve())), this.listener.Yr()));
    }), this.stream.Zr((r) => {
      s(() => this.Qo(r));
    }), this.stream.onMessage((r) => {
      s(() => this.onMessage(r));
    });
  }
  Oo() {
    this.state = 5, this.xo.Ro(async () => {
      this.state = 0, this.start();
    });
  }
  // Visible for tests
  Qo(e) {
    return T("PersistentStream", `close with error: ${e}`), this.stream = null, this.close(4, e);
  }
  /**
   * Returns a "dispatcher" function that dispatches operations onto the
   * AsyncQueue but only runs them if closeCount remains unchanged. This allows
   * us to turn auth / stream callbacks into no-ops if the stream is closed /
   * re-opened, etc.
   */
  Ko(e) {
    return (n) => {
      this.Hs.enqueueAndForget(() => this.So === e ? n() : (T("PersistentStream", "stream callback skipped by getCloseGuardedDispatcher."), Promise.resolve()));
    };
  }
}
class o_ extends Ql {
  constructor(e, n, s, r, i, o) {
    super(e, "listen_stream_connection_backoff", "listen_stream_idle", "health_check_timeout", n, s, r, o), this.yt = i;
  }
  jo(e, n) {
    return this.connection.wo("Listen", e, n);
  }
  onMessage(e) {
    this.xo.reset();
    const n = wy(this.yt, e), s = function(r) {
      if (!("targetChange" in r))
        return k.min();
      const i = r.targetChange;
      return i.targetIds && i.targetIds.length ? k.min() : i.readTime ? $e(i.readTime) : k.min();
    }(e);
    return this.listener.Wo(n, s);
  }
  /**
   * Registers interest in the results of the given target. If the target
   * includes a resumeToken it will be included in the request. Results that
   * affect the target will be streamed back as WatchChange messages that
   * reference the targetId.
   */
  zo(e) {
    const n = {};
    n.database = Hi(this.yt), n.addTarget = function(r, i) {
      let o;
      const a = i.target;
      return o = Bi(a) ? {
        documents: Ty(r, a)
      } : {
        query: ql(r, a)
      }, o.targetId = i.targetId, i.resumeToken.approximateByteSize() > 0 ? o.resumeToken = Ul(r, i.resumeToken) : i.snapshotVersion.compareTo(k.min()) > 0 && // TODO(wuandy): Consider removing above check because it is most likely true.
      // Right now, many tests depend on this behaviour though (leaving min() out
      // of serialization).
      (o.readTime = er(r, i.snapshotVersion.toTimestamp())), o;
    }(this.yt, e);
    const s = Iy(this.yt, e);
    s && (n.labels = s), this.Bo(n);
  }
  /**
   * Unregisters interest in the results of the target associated with the
   * given targetId.
   */
  Ho(e) {
    const n = {};
    n.database = Hi(this.yt), n.removeTarget = e, this.Bo(n);
  }
}
class a_ extends Ql {
  constructor(e, n, s, r, i, o) {
    super(e, "write_stream_connection_backoff", "write_stream_idle", "health_check_timeout", n, s, r, o), this.yt = i, this.Jo = !1;
  }
  /**
   * Tracks whether or not a handshake has been successfully exchanged and
   * the stream is ready to accept mutations.
   */
  get Yo() {
    return this.Jo;
  }
  // Override of PersistentStream.start
  start() {
    this.Jo = !1, this.lastStreamToken = void 0, super.start();
  }
  Uo() {
    this.Jo && this.Xo([]);
  }
  jo(e, n) {
    return this.connection.wo("Write", e, n);
  }
  onMessage(e) {
    if (
      // Always capture the last stream token.
      j(!!e.streamToken), this.lastStreamToken = e.streamToken, this.Jo
    ) {
      this.xo.reset();
      const n = vy(e.writeResults, e.commitTime), s = $e(e.commitTime);
      return this.listener.Zo(s, n);
    }
    return j(!e.writeResults || e.writeResults.length === 0), this.Jo = !0, this.listener.tu();
  }
  /**
   * Sends an initial streamToken to the server, performing the handshake
   * required to make the StreamingWrite RPC work. Subsequent
   * calls should wait until onHandshakeComplete was called.
   */
  eu() {
    const e = {};
    e.database = Hi(this.yt), this.Bo(e);
  }
  /** Sends a group of mutations to the Firestore backend to apply. */
  Xo(e) {
    const n = {
      streamToken: this.lastStreamToken,
      writes: e.map((s) => Ey(this.yt, s))
    };
    this.Bo(n);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class c_ extends class {
} {
  constructor(e, n, s, r) {
    super(), this.authCredentials = e, this.appCheckCredentials = n, this.connection = s, this.yt = r, this.nu = !1;
  }
  su() {
    if (this.nu)
      throw new w(g.FAILED_PRECONDITION, "The client has already been terminated.");
  }
  /** Invokes the provided RPC with auth and AppCheck tokens. */
  ao(e, n, s) {
    return this.su(), Promise.all([this.authCredentials.getToken(), this.appCheckCredentials.getToken()]).then(([r, i]) => this.connection.ao(e, n, s, r, i)).catch((r) => {
      throw r.name === "FirebaseError" ? (r.code === g.UNAUTHENTICATED && (this.authCredentials.invalidateToken(), this.appCheckCredentials.invalidateToken()), r) : new w(g.UNKNOWN, r.toString());
    });
  }
  /** Invokes the provided RPC with streamed results with auth and AppCheck tokens. */
  _o(e, n, s, r) {
    return this.su(), Promise.all([this.authCredentials.getToken(), this.appCheckCredentials.getToken()]).then(([i, o]) => this.connection._o(e, n, s, i, o, r)).catch((i) => {
      throw i.name === "FirebaseError" ? (i.code === g.UNAUTHENTICATED && (this.authCredentials.invalidateToken(), this.appCheckCredentials.invalidateToken()), i) : new w(g.UNKNOWN, i.toString());
    });
  }
  terminate() {
    this.nu = !0;
  }
}
async function u_(t, e) {
  const n = D(t), s = function(i, o) {
    const a = ql(i, o);
    return {
      structuredAggregationQuery: {
        aggregations: [{
          count: {},
          alias: "count_alias"
        }],
        structuredQuery: a.structuredQuery
      },
      parent: a.parent
    };
  }(n.yt, qe(e)), r = s.parent;
  return n.connection.co || delete s.parent, (await n._o(
    "RunAggregationQuery",
    r,
    s,
    /*expectedResponseCount=*/
    1
  )).filter((i) => !!i.result).map((i) => i.result.aggregateFields);
}
class l_ {
  constructor(e, n) {
    this.asyncQueue = e, this.onlineStateHandler = n, /** The current OnlineState. */
    this.state = "Unknown", /**
     * A count of consecutive failures to open the stream. If it reaches the
     * maximum defined by MAX_WATCH_STREAM_FAILURES, we'll set the OnlineState to
     * Offline.
     */
    this.iu = 0, /**
     * A timer that elapses after ONLINE_STATE_TIMEOUT_MS, at which point we
     * transition from OnlineState.Unknown to OnlineState.Offline without waiting
     * for the stream to actually fail (MAX_WATCH_STREAM_FAILURES times).
     */
    this.ru = null, /**
     * Whether the client should log a warning message if it fails to connect to
     * the backend (initially true, cleared after a successful stream, or if we've
     * logged the message already).
     */
    this.ou = !0;
  }
  /**
   * Called by RemoteStore when a watch stream is started (including on each
   * backoff attempt).
   *
   * If this is the first attempt, it sets the OnlineState to Unknown and starts
   * the onlineStateTimer.
   */
  uu() {
    this.iu === 0 && (this.cu(
      "Unknown"
      /* OnlineState.Unknown */
    ), this.ru = this.asyncQueue.enqueueAfterDelay("online_state_timeout", 1e4, () => (this.ru = null, this.au("Backend didn't respond within 10 seconds."), this.cu(
      "Offline"
      /* OnlineState.Offline */
    ), Promise.resolve())));
  }
  /**
   * Updates our OnlineState as appropriate after the watch stream reports a
   * failure. The first failure moves us to the 'Unknown' state. We then may
   * allow multiple failures (based on MAX_WATCH_STREAM_FAILURES) before we
   * actually transition to the 'Offline' state.
   */
  hu(e) {
    this.state === "Online" ? this.cu(
      "Unknown"
      /* OnlineState.Unknown */
    ) : (this.iu++, this.iu >= 1 && (this.lu(), this.au(`Connection failed 1 times. Most recent error: ${e.toString()}`), this.cu(
      "Offline"
      /* OnlineState.Offline */
    )));
  }
  /**
   * Explicitly sets the OnlineState to the specified state.
   *
   * Note that this resets our timers / failure counters, etc. used by our
   * Offline heuristics, so must not be used in place of
   * handleWatchStreamStart() and handleWatchStreamFailure().
   */
  set(e) {
    this.lu(), this.iu = 0, e === "Online" && // We've connected to watch at least once. Don't warn the developer
    // about being offline going forward.
    (this.ou = !1), this.cu(e);
  }
  cu(e) {
    e !== this.state && (this.state = e, this.onlineStateHandler(e));
  }
  au(e) {
    const n = `Could not reach Cloud Firestore backend. ${e}
This typically indicates that your device does not have a healthy Internet connection at the moment. The client will operate in offline mode until it is able to successfully connect to the backend.`;
    this.ou ? (Qe(n), this.ou = !1) : T("OnlineStateTracker", n);
  }
  lu() {
    this.ru !== null && (this.ru.cancel(), this.ru = null);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class h_ {
  constructor(e, n, s, r, i) {
    this.localStore = e, this.datastore = n, this.asyncQueue = s, this.remoteSyncer = {}, /**
     * A list of up to MAX_PENDING_WRITES writes that we have fetched from the
     * LocalStore via fillWritePipeline() and have or will send to the write
     * stream.
     *
     * Whenever writePipeline.length > 0 the RemoteStore will attempt to start or
     * restart the write stream. When the stream is established the writes in the
     * pipeline will be sent in order.
     *
     * Writes remain in writePipeline until they are acknowledged by the backend
     * and thus will automatically be re-sent if the stream is interrupted /
     * restarted before they're acknowledged.
     *
     * Write responses from the backend are linked to their originating request
     * purely based on order, and so we can just shift() writes from the front of
     * the writePipeline as we receive responses.
     */
    this.fu = [], /**
     * A mapping of watched targets that the client cares about tracking and the
     * user has explicitly called a 'listen' for this target.
     *
     * These targets may or may not have been sent to or acknowledged by the
     * server. On re-establishing the listen stream, these targets should be sent
     * to the server. The targets removed with unlistens are removed eagerly
     * without waiting for confirmation from the listen stream.
     */
    this.du = /* @__PURE__ */ new Map(), /**
     * A set of reasons for why the RemoteStore may be offline. If empty, the
     * RemoteStore may start its network connections.
     */
    this._u = /* @__PURE__ */ new Set(), /**
     * Event handlers that get called when the network is disabled or enabled.
     *
     * PORTING NOTE: These functions are used on the Web client to create the
     * underlying streams (to support tree-shakeable streams). On Android and iOS,
     * the streams are created during construction of RemoteStore.
     */
    this.wu = [], this.mu = i, this.mu.Ur((o) => {
      s.enqueueAndForget(async () => {
        yt(this) && (T("RemoteStore", "Restarting streams for network reachability change."), await async function(a) {
          const c = D(a);
          c._u.add(
            4
            /* OfflineCause.ConnectivityChange */
          ), await hs(c), c.gu.set(
            "Unknown"
            /* OnlineState.Unknown */
          ), c._u.delete(
            4
            /* OfflineCause.ConnectivityChange */
          ), await Pr(c);
        }(this));
      });
    }), this.gu = new l_(s, r);
  }
}
async function Pr(t) {
  if (yt(t))
    for (const e of t.wu)
      await e(
        /* enabled= */
        !0
      );
}
async function hs(t) {
  for (const e of t.wu)
    await e(
      /* enabled= */
      !1
    );
}
function Kl(t, e) {
  const n = D(t);
  n.du.has(e.targetId) || // Mark this as something the client is currently listening for.
  (n.du.set(e.targetId, e), Go(n) ? (
    // The listen will be sent in onWatchStreamOpen
    jo(n)
  ) : gn(n).ko() && Vo(n, e));
}
function Jl(t, e) {
  const n = D(t), s = gn(n);
  n.du.delete(e), s.ko() && Yl(n, e), n.du.size === 0 && (s.ko() ? s.Fo() : yt(n) && // Revert to OnlineState.Unknown if the watch stream is not open and we
  // have no listeners, since without any listens to send we cannot
  // confirm if the stream is healthy and upgrade to OnlineState.Online.
  n.gu.set(
    "Unknown"
    /* OnlineState.Unknown */
  ));
}
function Vo(t, e) {
  t.yu.Ot(e.targetId), gn(t).zo(e);
}
function Yl(t, e) {
  t.yu.Ot(e), gn(t).Ho(e);
}
function jo(t) {
  t.yu = new dy({
    getRemoteKeysForTarget: (e) => t.remoteSyncer.getRemoteKeysForTarget(e),
    ne: (e) => t.du.get(e) || null
  }), gn(t).start(), t.gu.uu();
}
function Go(t) {
  return yt(t) && !gn(t).No() && t.du.size > 0;
}
function yt(t) {
  return D(t)._u.size === 0;
}
function Xl(t) {
  t.yu = void 0;
}
async function d_(t) {
  t.du.forEach((e, n) => {
    Vo(t, e);
  });
}
async function f_(t, e) {
  Xl(t), // If we still need the watch stream, retry the connection.
  Go(t) ? (t.gu.hu(e), jo(t)) : (
    // No need to restart watch stream because there are no active targets.
    // The online state is set to unknown because there is no active attempt
    // at establishing a connection
    t.gu.set(
      "Unknown"
      /* OnlineState.Unknown */
    )
  );
}
async function p_(t, e, n) {
  if (
    // Mark the client as online since we got a message from the server
    t.gu.set(
      "Online"
      /* OnlineState.Online */
    ), e instanceof $l && e.state === 2 && e.cause
  )
    try {
      await /** Handles an error on a target */
      async function(s, r) {
        const i = r.cause;
        for (const o of r.targetIds)
          s.du.has(o) && (await s.remoteSyncer.rejectListen(o, i), s.du.delete(o), s.yu.removeTarget(o));
      }(t, e);
    } catch (s) {
      T("RemoteStore", "Failed to remove targets %s: %s ", e.targetIds.join(","), s), await tr(t, s);
    }
  else if (e instanceof Ds ? t.yu.Kt(e) : e instanceof Ml ? t.yu.Jt(e) : t.yu.jt(e), !n.isEqual(k.min()))
    try {
      const s = await Hl(t.localStore);
      n.compareTo(s) >= 0 && // We have received a target change with a global snapshot if the snapshot
      // version is not equal to SnapshotVersion.min().
      await /**
      * Takes a batch of changes from the Datastore, repackages them as a
      * RemoteEvent, and passes that on to the listener, which is typically the
      * SyncEngine.
      */
      function(r, i) {
        const o = r.yu.Zt(i);
        return o.targetChanges.forEach((a, c) => {
          if (a.resumeToken.approximateByteSize() > 0) {
            const u = r.du.get(c);
            u && r.du.set(c, u.withResumeToken(a.resumeToken, i));
          }
        }), // Re-establish listens for the targets that have been invalidated by
        // existence filter mismatches.
        o.targetMismatches.forEach((a) => {
          const c = r.du.get(a);
          if (!c)
            return;
          r.du.set(a, c.withResumeToken(ye.EMPTY_BYTE_STRING, c.snapshotVersion)), // Cause a hard reset by unwatching and rewatching immediately, but
          // deliberately don't send a resume token so that we get a full update.
          Yl(r, a);
          const u = new Nt(c.target, a, 1, c.sequenceNumber);
          Vo(r, u);
        }), r.remoteSyncer.applyRemoteEvent(o);
      }(t, n);
    } catch (s) {
      T("RemoteStore", "Failed to raise snapshot:", s), await tr(t, s);
    }
}
async function tr(t, e, n) {
  if (!as(e))
    throw e;
  t._u.add(
    1
    /* OfflineCause.IndexedDbFailed */
  ), // Disable network and raise offline snapshots
  await hs(t), t.gu.set(
    "Offline"
    /* OnlineState.Offline */
  ), n || // Use a simple read operation to determine if IndexedDB recovered.
  // Ideally, we would expose a health check directly on SimpleDb, but
  // RemoteStore only has access to persistence through LocalStore.
  (n = () => Hl(t.localStore)), // Probe IndexedDB periodically and re-enable network
  t.asyncQueue.enqueueRetryable(async () => {
    T("RemoteStore", "Retrying IndexedDB access"), await n(), t._u.delete(
      1
      /* OfflineCause.IndexedDbFailed */
    ), await Pr(t);
  });
}
function Zl(t, e) {
  return e().catch((n) => tr(t, n, e));
}
async function Lr(t) {
  const e = D(t), n = dt(e);
  let s = e.fu.length > 0 ? e.fu[e.fu.length - 1].batchId : -1;
  for (; g_(e); )
    try {
      const r = await Xy(e.localStore, s);
      if (r === null) {
        e.fu.length === 0 && n.Fo();
        break;
      }
      s = r.batchId, m_(e, r);
    } catch (r) {
      await tr(e, r);
    }
  eh(e) && th(e);
}
function g_(t) {
  return yt(t) && t.fu.length < 10;
}
function m_(t, e) {
  t.fu.push(e);
  const n = dt(t);
  n.ko() && n.Yo && n.Xo(e.mutations);
}
function eh(t) {
  return yt(t) && !dt(t).No() && t.fu.length > 0;
}
function th(t) {
  dt(t).start();
}
async function y_(t) {
  dt(t).eu();
}
async function __(t) {
  const e = dt(t);
  for (const n of t.fu)
    e.Xo(n.mutations);
}
async function w_(t, e, n) {
  const s = t.fu.shift(), r = Uo.from(s, e, n);
  await Zl(t, () => t.remoteSyncer.applySuccessfulWrite(r)), // It's possible that with the completion of this mutation another
  // slot has freed up.
  await Lr(t);
}
async function E_(t, e) {
  e && dt(t).Yo && // This error affects the actual write.
  await async function(n, s) {
    if (r = s.code, ay(r) && r !== g.ABORTED) {
      const i = n.fu.shift();
      dt(n).Mo(), await Zl(n, () => n.remoteSyncer.rejectFailedWrite(i.batchId, s)), // It's possible that with the completion of this mutation
      // another slot has freed up.
      await Lr(n);
    }
    var r;
  }(t, e), // The write stream might have been started by refilling the write
  // pipeline for failed writes
  eh(t) && th(t);
}
async function uc(t, e) {
  const n = D(t);
  n.asyncQueue.verifyOperationInProgress(), T("RemoteStore", "RemoteStore received new credentials");
  const s = yt(n);
  n._u.add(
    3
    /* OfflineCause.CredentialChange */
  ), await hs(n), s && // Don't set the network status to Unknown if we are offline.
  n.gu.set(
    "Unknown"
    /* OnlineState.Unknown */
  ), await n.remoteSyncer.handleCredentialChange(e), n._u.delete(
    3
    /* OfflineCause.CredentialChange */
  ), await Pr(n);
}
async function v_(t, e) {
  const n = D(t);
  e ? (n._u.delete(
    2
    /* OfflineCause.IsSecondary */
  ), await Pr(n)) : e || (n._u.add(
    2
    /* OfflineCause.IsSecondary */
  ), await hs(n), n.gu.set(
    "Unknown"
    /* OnlineState.Unknown */
  ));
}
function gn(t) {
  return t.pu || // Create stream (but note that it is not started yet).
  (t.pu = function(e, n, s) {
    const r = D(e);
    return r.su(), new o_(n, r.connection, r.authCredentials, r.appCheckCredentials, r.yt, s);
  }(t.datastore, t.asyncQueue, {
    Yr: d_.bind(null, t),
    Zr: f_.bind(null, t),
    Wo: p_.bind(null, t)
  }), t.wu.push(async (e) => {
    e ? (t.pu.Mo(), Go(t) ? jo(t) : t.gu.set(
      "Unknown"
      /* OnlineState.Unknown */
    )) : (await t.pu.stop(), Xl(t));
  })), t.pu;
}
function dt(t) {
  return t.Iu || // Create stream (but note that it is not started yet).
  (t.Iu = function(e, n, s) {
    const r = D(e);
    return r.su(), new a_(n, r.connection, r.authCredentials, r.appCheckCredentials, r.yt, s);
  }(t.datastore, t.asyncQueue, {
    Yr: y_.bind(null, t),
    Zr: E_.bind(null, t),
    tu: __.bind(null, t),
    Zo: w_.bind(null, t)
  }), t.wu.push(async (e) => {
    e ? (t.Iu.Mo(), // This will start the write stream if necessary.
    await Lr(t)) : (await t.Iu.stop(), t.fu.length > 0 && (T("RemoteStore", `Stopping write stream with ${t.fu.length} pending writes`), t.fu = []));
  })), t.Iu;
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Wo {
  constructor(e, n, s, r, i) {
    this.asyncQueue = e, this.timerId = n, this.targetTimeMs = s, this.op = r, this.removalCallback = i, this.deferred = new Me(), this.then = this.deferred.promise.then.bind(this.deferred.promise), // It's normal for the deferred promise to be canceled (due to cancellation)
    // and so we attach a dummy catch callback to avoid
    // 'UnhandledPromiseRejectionWarning' log spam.
    this.deferred.promise.catch((o) => {
    });
  }
  /**
   * Creates and returns a DelayedOperation that has been scheduled to be
   * executed on the provided asyncQueue after the provided delayMs.
   *
   * @param asyncQueue - The queue to schedule the operation on.
   * @param id - A Timer ID identifying the type of operation this is.
   * @param delayMs - The delay (ms) before the operation should be scheduled.
   * @param op - The operation to run.
   * @param removalCallback - A callback to be called synchronously once the
   *   operation is executed or canceled, notifying the AsyncQueue to remove it
   *   from its delayedOperations list.
   *   PORTING NOTE: This exists to prevent making removeDelayedOperation() and
   *   the DelayedOperation class public.
   */
  static createAndSchedule(e, n, s, r, i) {
    const o = Date.now() + s, a = new Wo(e, n, o, r, i);
    return a.start(s), a;
  }
  /**
   * Starts the timer. This is called immediately after construction by
   * createAndSchedule().
   */
  start(e) {
    this.timerHandle = setTimeout(() => this.handleDelayElapsed(), e);
  }
  /**
   * Queues the operation to run immediately (if it hasn't already been run or
   * canceled).
   */
  skipDelay() {
    return this.handleDelayElapsed();
  }
  /**
   * Cancels the operation if it hasn't already been executed or canceled. The
   * promise will be rejected.
   *
   * As long as the operation has not yet been run, calling cancel() provides a
   * guarantee that the operation will not be run.
   */
  cancel(e) {
    this.timerHandle !== null && (this.clearTimeout(), this.deferred.reject(new w(g.CANCELLED, "Operation cancelled" + (e ? ": " + e : ""))));
  }
  handleDelayElapsed() {
    this.asyncQueue.enqueueAndForget(() => this.timerHandle !== null ? (this.clearTimeout(), this.op().then((e) => this.deferred.resolve(e))) : Promise.resolve());
  }
  clearTimeout() {
    this.timerHandle !== null && (this.removalCallback(this), clearTimeout(this.timerHandle), this.timerHandle = null);
  }
}
function Ho(t, e) {
  if (Qe("AsyncQueue", `${e}: ${t}`), as(t))
    return new w(g.UNAVAILABLE, `${e}: ${t}`);
  throw t;
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Jt {
  /** The default ordering is by key if the comparator is omitted */
  constructor(e) {
    this.comparator = e ? (n, s) => e(n, s) || I.comparator(n.key, s.key) : (n, s) => I.comparator(n.key, s.key), this.keyedMap = Sn(), this.sortedSet = new ee(this.comparator);
  }
  /**
   * Returns an empty copy of the existing DocumentSet, using the same
   * comparator.
   */
  static emptySet(e) {
    return new Jt(e.comparator);
  }
  has(e) {
    return this.keyedMap.get(e) != null;
  }
  get(e) {
    return this.keyedMap.get(e);
  }
  first() {
    return this.sortedSet.minKey();
  }
  last() {
    return this.sortedSet.maxKey();
  }
  isEmpty() {
    return this.sortedSet.isEmpty();
  }
  /**
   * Returns the index of the provided key in the document set, or -1 if the
   * document key is not present in the set;
   */
  indexOf(e) {
    const n = this.keyedMap.get(e);
    return n ? this.sortedSet.indexOf(n) : -1;
  }
  get size() {
    return this.sortedSet.size;
  }
  /** Iterates documents in order defined by "comparator" */
  forEach(e) {
    this.sortedSet.inorderTraversal((n, s) => (e(n), !1));
  }
  /** Inserts or updates a document with the same key */
  add(e) {
    const n = this.delete(e.key);
    return n.copy(n.keyedMap.insert(e.key, e), n.sortedSet.insert(e, null));
  }
  /** Deletes a document with a given key */
  delete(e) {
    const n = this.get(e);
    return n ? this.copy(this.keyedMap.remove(e), this.sortedSet.remove(n)) : this;
  }
  isEqual(e) {
    if (!(e instanceof Jt) || this.size !== e.size)
      return !1;
    const n = this.sortedSet.getIterator(), s = e.sortedSet.getIterator();
    for (; n.hasNext(); ) {
      const r = n.getNext().key, i = s.getNext().key;
      if (!r.isEqual(i))
        return !1;
    }
    return !0;
  }
  toString() {
    const e = [];
    return this.forEach((n) => {
      e.push(n.toString());
    }), e.length === 0 ? "DocumentSet ()" : `DocumentSet (
  ` + e.join(`  
`) + `
)`;
  }
  copy(e, n) {
    const s = new Jt();
    return s.comparator = this.comparator, s.keyedMap = e, s.sortedSet = n, s;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class lc {
  constructor() {
    this.Tu = new ee(I.comparator);
  }
  track(e) {
    const n = e.doc.key, s = this.Tu.get(n);
    s ? (
      // Merge the new change with the existing change.
      e.type !== 0 && s.type === 3 ? this.Tu = this.Tu.insert(n, e) : e.type === 3 && s.type !== 1 ? this.Tu = this.Tu.insert(n, {
        type: s.type,
        doc: e.doc
      }) : e.type === 2 && s.type === 2 ? this.Tu = this.Tu.insert(n, {
        type: 2,
        doc: e.doc
      }) : e.type === 2 && s.type === 0 ? this.Tu = this.Tu.insert(n, {
        type: 0,
        doc: e.doc
      }) : e.type === 1 && s.type === 0 ? this.Tu = this.Tu.remove(n) : e.type === 1 && s.type === 2 ? this.Tu = this.Tu.insert(n, {
        type: 1,
        doc: s.doc
      }) : e.type === 0 && s.type === 1 ? this.Tu = this.Tu.insert(n, {
        type: 2,
        doc: e.doc
      }) : (
        // This includes these cases, which don't make sense:
        // Added->Added
        // Removed->Removed
        // Modified->Added
        // Removed->Modified
        // Metadata->Added
        // Removed->Metadata
        A()
      )
    ) : this.Tu = this.Tu.insert(n, e);
  }
  Eu() {
    const e = [];
    return this.Tu.inorderTraversal((n, s) => {
      e.push(s);
    }), e;
  }
}
class cn {
  constructor(e, n, s, r, i, o, a, c, u) {
    this.query = e, this.docs = n, this.oldDocs = s, this.docChanges = r, this.mutatedKeys = i, this.fromCache = o, this.syncStateChanged = a, this.excludesMetadataChanges = c, this.hasCachedResults = u;
  }
  /** Returns a view snapshot as if all documents in the snapshot were added. */
  static fromInitialDocuments(e, n, s, r, i) {
    const o = [];
    return n.forEach((a) => {
      o.push({
        type: 0,
        doc: a
      });
    }), new cn(
      e,
      n,
      Jt.emptySet(n),
      o,
      s,
      r,
      /* syncStateChanged= */
      !0,
      /* excludesMetadataChanges= */
      !1,
      i
    );
  }
  get hasPendingWrites() {
    return !this.mutatedKeys.isEmpty();
  }
  isEqual(e) {
    if (!(this.fromCache === e.fromCache && this.hasCachedResults === e.hasCachedResults && this.syncStateChanged === e.syncStateChanged && this.mutatedKeys.isEqual(e.mutatedKeys) && Cr(this.query, e.query) && this.docs.isEqual(e.docs) && this.oldDocs.isEqual(e.oldDocs)))
      return !1;
    const n = this.docChanges, s = e.docChanges;
    if (n.length !== s.length)
      return !1;
    for (let r = 0; r < n.length; r++)
      if (n[r].type !== s[r].type || !n[r].doc.isEqual(s[r].doc))
        return !1;
    return !0;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class T_ {
  constructor() {
    this.Au = void 0, this.listeners = [];
  }
}
class S_ {
  constructor() {
    this.queries = new pn((e) => vl(e), Cr), this.onlineState = "Unknown", this.Ru = /* @__PURE__ */ new Set();
  }
}
async function nh(t, e) {
  const n = D(t), s = e.query;
  let r = !1, i = n.queries.get(s);
  if (i || (r = !0, i = new T_()), r)
    try {
      i.Au = await n.onListen(s);
    } catch (o) {
      const a = Ho(o, `Initialization of query '${ji(e.query)}' failed`);
      return void e.onError(a);
    }
  n.queries.set(s, i), i.listeners.push(e), // Run global snapshot listeners if a consistent snapshot has been emitted.
  e.bu(n.onlineState), i.Au && e.Pu(i.Au) && zo(n);
}
async function sh(t, e) {
  const n = D(t), s = e.query;
  let r = !1;
  const i = n.queries.get(s);
  if (i) {
    const o = i.listeners.indexOf(e);
    o >= 0 && (i.listeners.splice(o, 1), r = i.listeners.length === 0);
  }
  if (r)
    return n.queries.delete(s), n.onUnlisten(s);
}
function I_(t, e) {
  const n = D(t);
  let s = !1;
  for (const r of e) {
    const i = r.query, o = n.queries.get(i);
    if (o) {
      for (const a of o.listeners)
        a.Pu(r) && (s = !0);
      o.Au = r;
    }
  }
  s && zo(n);
}
function b_(t, e, n) {
  const s = D(t), r = s.queries.get(e);
  if (r)
    for (const i of r.listeners)
      i.onError(n);
  s.queries.delete(e);
}
function zo(t) {
  t.Ru.forEach((e) => {
    e.next();
  });
}
class rh {
  constructor(e, n, s) {
    this.query = e, this.vu = n, /**
     * Initial snapshots (e.g. from cache) may not be propagated to the wrapped
     * observer. This flag is set to true once we've actually raised an event.
     */
    this.Vu = !1, this.Su = null, this.onlineState = "Unknown", this.options = s || {};
  }
  /**
   * Applies the new ViewSnapshot to this listener, raising a user-facing event
   * if applicable (depending on what changed, whether the user has opted into
   * metadata-only changes, etc.). Returns true if a user-facing event was
   * indeed raised.
   */
  Pu(e) {
    if (!this.options.includeMetadataChanges) {
      const s = [];
      for (const r of e.docChanges)
        r.type !== 3 && s.push(r);
      e = new cn(
        e.query,
        e.docs,
        e.oldDocs,
        s,
        e.mutatedKeys,
        e.fromCache,
        e.syncStateChanged,
        /* excludesMetadataChanges= */
        !0,
        e.hasCachedResults
      );
    }
    let n = !1;
    return this.Vu ? this.Du(e) && (this.vu.next(e), n = !0) : this.Cu(e, this.onlineState) && (this.xu(e), n = !0), this.Su = e, n;
  }
  onError(e) {
    this.vu.error(e);
  }
  /** Returns whether a snapshot was raised. */
  bu(e) {
    this.onlineState = e;
    let n = !1;
    return this.Su && !this.Vu && this.Cu(this.Su, e) && (this.xu(this.Su), n = !0), n;
  }
  Cu(e, n) {
    if (!e.fromCache)
      return !0;
    const s = n !== "Offline";
    return (!this.options.Nu || !s) && (!e.docs.isEmpty() || e.hasCachedResults || n === "Offline");
  }
  Du(e) {
    if (e.docChanges.length > 0)
      return !0;
    const n = this.Su && this.Su.hasPendingWrites !== e.hasPendingWrites;
    return !(!e.syncStateChanged && !n) && this.options.includeMetadataChanges === !0;
  }
  xu(e) {
    e = cn.fromInitialDocuments(e.query, e.docs, e.mutatedKeys, e.fromCache, e.hasCachedResults), this.Vu = !0, this.vu.next(e);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ih {
  constructor(e) {
    this.key = e;
  }
}
class oh {
  constructor(e) {
    this.key = e;
  }
}
class A_ {
  constructor(e, n) {
    this.query = e, this.qu = n, this.Uu = null, this.hasCachedResults = !1, /**
     * A flag whether the view is current with the backend. A view is considered
     * current after it has seen the current flag from the backend and did not
     * lose consistency within the watch stream (e.g. because of an existence
     * filter mismatch).
     */
    this.current = !1, /** Documents in the view but not in the remote target */
    this.Ku = M(), /** Document Keys that have local changes */
    this.mutatedKeys = M(), this.Gu = Tl(e), this.Qu = new Jt(this.Gu);
  }
  /**
   * The set of remote documents that the server has told us belongs to the target associated with
   * this view.
   */
  get ju() {
    return this.qu;
  }
  /**
   * Iterates over a set of doc changes, applies the query limit, and computes
   * what the new results should be, what the changes were, and whether we may
   * need to go back to the local cache for more results. Does not make any
   * changes to the view.
   * @param docChanges - The doc changes to apply to this view.
   * @param previousChanges - If this is being called with a refill, then start
   *        with this set of docs and changes instead of the current view.
   * @returns a new set of docs, changes, and refill flag.
   */
  Wu(e, n) {
    const s = n ? n.zu : new lc(), r = n ? n.Qu : this.Qu;
    let i = n ? n.mutatedKeys : this.mutatedKeys, o = r, a = !1;
    const c = this.query.limitType === "F" && r.size === this.query.limit ? r.last() : null, u = this.query.limitType === "L" && r.size === this.query.limit ? r.first() : null;
    if (e.inorderTraversal((l, h) => {
      const d = r.get(l), p = Nr(this.query, h) ? h : null, m = !!d && this.mutatedKeys.has(d.key), S = !!p && (p.hasLocalMutations || // We only consider committed mutations for documents that were
      // mutated during the lifetime of the view.
      this.mutatedKeys.has(p.key) && p.hasCommittedMutations);
      let P = !1;
      d && p ? d.data.isEqual(p.data) ? m !== S && (s.track({
        type: 3,
        doc: p
      }), P = !0) : this.Hu(d, p) || (s.track({
        type: 2,
        doc: p
      }), P = !0, (c && this.Gu(p, c) > 0 || u && this.Gu(p, u) < 0) && // This doc moved from inside the limit to outside the limit.
      // That means there may be some other doc in the local cache
      // that should be included instead.
      (a = !0)) : !d && p ? (s.track({
        type: 0,
        doc: p
      }), P = !0) : d && !p && (s.track({
        type: 1,
        doc: d
      }), P = !0, (c || u) && // A doc was removed from a full limit query. We'll need to
      // requery from the local cache to see if we know about some other
      // doc that should be in the results.
      (a = !0)), P && (p ? (o = o.add(p), i = S ? i.add(l) : i.delete(l)) : (o = o.delete(l), i = i.delete(l)));
    }), this.query.limit !== null)
      for (; o.size > this.query.limit; ) {
        const l = this.query.limitType === "F" ? o.last() : o.first();
        o = o.delete(l.key), i = i.delete(l.key), s.track({
          type: 1,
          doc: l
        });
      }
    return {
      Qu: o,
      zu: s,
      $i: a,
      mutatedKeys: i
    };
  }
  Hu(e, n) {
    return e.hasLocalMutations && n.hasCommittedMutations && !n.hasLocalMutations;
  }
  /**
   * Updates the view with the given ViewDocumentChanges and optionally updates
   * limbo docs and sync state from the provided target change.
   * @param docChanges - The set of changes to make to the view's docs.
   * @param updateLimboDocuments - Whether to update limbo documents based on
   *        this change.
   * @param targetChange - A target change to apply for computing limbo docs and
   *        sync state.
   * @returns A new ViewChange with the given docs, changes, and sync state.
   */
  // PORTING NOTE: The iOS/Android clients always compute limbo document changes.
  applyChanges(e, n, s) {
    const r = this.Qu;
    this.Qu = e.Qu, this.mutatedKeys = e.mutatedKeys;
    const i = e.zu.Eu();
    i.sort((u, l) => function(h, d) {
      const p = (m) => {
        switch (m) {
          case 0:
            return 1;
          case 2:
          case 3:
            return 2;
          case 1:
            return 0;
          default:
            return A();
        }
      };
      return p(h) - p(d);
    }(u.type, l.type) || this.Gu(u.doc, l.doc)), this.Ju(s);
    const o = n ? this.Yu() : [], a = this.Ku.size === 0 && this.current ? 1 : 0, c = a !== this.Uu;
    return this.Uu = a, i.length !== 0 || c ? {
      snapshot: new cn(
        this.query,
        e.Qu,
        r,
        i,
        e.mutatedKeys,
        a === 0,
        c,
        /* excludesMetadataChanges= */
        !1,
        !!s && s.resumeToken.approximateByteSize() > 0
      ),
      Xu: o
    } : {
      Xu: o
    };
  }
  /**
   * Applies an OnlineState change to the view, potentially generating a
   * ViewChange if the view's syncState changes as a result.
   */
  bu(e) {
    return this.current && e === "Offline" ? (
      // If we're offline, set `current` to false and then call applyChanges()
      // to refresh our syncState and generate a ViewChange as appropriate. We
      // are guaranteed to get a new TargetChange that sets `current` back to
      // true once the client is back online.
      (this.current = !1, this.applyChanges(
        {
          Qu: this.Qu,
          zu: new lc(),
          mutatedKeys: this.mutatedKeys,
          $i: !1
        },
        /* updateLimboDocuments= */
        !1
      ))
    ) : {
      Xu: []
    };
  }
  /**
   * Returns whether the doc for the given key should be in limbo.
   */
  Zu(e) {
    return !this.qu.has(e) && // The local store doesn't think it's a result, so it shouldn't be in limbo.
    !!this.Qu.has(e) && !this.Qu.get(e).hasLocalMutations;
  }
  /**
   * Updates syncedDocuments, current, and limbo docs based on the given change.
   * Returns the list of changes to which docs are in limbo.
   */
  Ju(e) {
    e && (e.addedDocuments.forEach((n) => this.qu = this.qu.add(n)), e.modifiedDocuments.forEach((n) => {
    }), e.removedDocuments.forEach((n) => this.qu = this.qu.delete(n)), this.current = e.current);
  }
  Yu() {
    if (!this.current)
      return [];
    const e = this.Ku;
    this.Ku = M(), this.Qu.forEach((s) => {
      this.Zu(s.key) && (this.Ku = this.Ku.add(s.key));
    });
    const n = [];
    return e.forEach((s) => {
      this.Ku.has(s) || n.push(new oh(s));
    }), this.Ku.forEach((s) => {
      e.has(s) || n.push(new ih(s));
    }), n;
  }
  /**
   * Update the in-memory state of the current view with the state read from
   * persistence.
   *
   * We update the query view whenever a client's primary status changes:
   * - When a client transitions from primary to secondary, it can miss
   *   LocalStorage updates and its query views may temporarily not be
   *   synchronized with the state on disk.
   * - For secondary to primary transitions, the client needs to update the list
   *   of `syncedDocuments` since secondary clients update their query views
   *   based purely on synthesized RemoteEvents.
   *
   * @param queryResult.documents - The documents that match the query according
   * to the LocalStore.
   * @param queryResult.remoteKeys - The keys of the documents that match the
   * query according to the backend.
   *
   * @returns The ViewChange that resulted from this synchronization.
   */
  // PORTING NOTE: Multi-tab only.
  tc(e) {
    this.qu = e.Hi, this.Ku = M();
    const n = this.Wu(e.documents);
    return this.applyChanges(
      n,
      /*updateLimboDocuments=*/
      !0
    );
  }
  /**
   * Returns a view snapshot as if this query was just listened to. Contains
   * a document add for every existing document and the `fromCache` and
   * `hasPendingWrites` status of the already established view.
   */
  // PORTING NOTE: Multi-tab only.
  ec() {
    return cn.fromInitialDocuments(this.query, this.Qu, this.mutatedKeys, this.Uu === 0, this.hasCachedResults);
  }
}
class C_ {
  constructor(e, n, s) {
    this.query = e, this.targetId = n, this.view = s;
  }
}
class N_ {
  constructor(e) {
    this.key = e, /**
     * Set to true once we've received a document. This is used in
     * getRemoteKeysForTarget() and ultimately used by WatchChangeAggregator to
     * decide whether it needs to manufacture a delete event for the target once
     * the target is CURRENT.
     */
    this.nc = !1;
  }
}
class O_ {
  constructor(e, n, s, r, i, o) {
    this.localStore = e, this.remoteStore = n, this.eventManager = s, this.sharedClientState = r, this.currentUser = i, this.maxConcurrentLimboResolutions = o, this.sc = {}, this.ic = new pn((a) => vl(a), Cr), this.rc = /* @__PURE__ */ new Map(), /**
     * The keys of documents that are in limbo for which we haven't yet started a
     * limbo resolution query. The strings in this set are the result of calling
     * `key.path.canonicalString()` where `key` is a `DocumentKey` object.
     *
     * The `Set` type was chosen because it provides efficient lookup and removal
     * of arbitrary elements and it also maintains insertion order, providing the
     * desired queue-like FIFO semantics.
     */
    this.oc = /* @__PURE__ */ new Set(), /**
     * Keeps track of the target ID for each document that is in limbo with an
     * active target.
     */
    this.uc = new ee(I.comparator), /**
     * Keeps track of the information about an active limbo resolution for each
     * active target ID that was started for the purpose of limbo resolution.
     */
    this.cc = /* @__PURE__ */ new Map(), this.ac = new xo(), /** Stores user completion handlers, indexed by User and BatchId. */
    this.hc = {}, /** Stores user callbacks waiting for all pending writes to be acknowledged. */
    this.lc = /* @__PURE__ */ new Map(), this.fc = an.vn(), this.onlineState = "Unknown", // The primary state is set to `true` or `false` immediately after Firestore
    // startup. In the interim, a client should only be considered primary if
    // `isPrimary` is true.
    this.dc = void 0;
  }
  get isPrimaryClient() {
    return this.dc === !0;
  }
}
async function R_(t, e) {
  const n = B_(t);
  let s, r;
  const i = n.ic.get(e);
  if (i)
    s = i.targetId, n.sharedClientState.addLocalQueryTarget(s), r = i.view.ec();
  else {
    const o = await Zy(n.localStore, qe(e));
    n.isPrimaryClient && Kl(n.remoteStore, o);
    const a = n.sharedClientState.addLocalQueryTarget(o.targetId);
    s = o.targetId, r = await k_(n, e, s, a === "current", o.resumeToken);
  }
  return r;
}
async function k_(t, e, n, s, r) {
  t._c = (h, d, p) => async function(m, S, P, te) {
    let Q = S.view.Wu(P);
    Q.$i && // The query has a limit and some docs were removed, so we need
    // to re-run the query against the local store to make sure we
    // didn't lose any good docs that had been past the limit.
    (Q = await oc(
      m.localStore,
      S.query,
      /* usePreviousResults= */
      !1
    ).then(({ documents: Pe }) => S.view.Wu(Pe, Q)));
    const De = te && te.targetChanges.get(S.targetId), Ce = S.view.applyChanges(
      Q,
      /* updateLimboDocuments= */
      m.isPrimaryClient,
      De
    );
    return dc(m, S.targetId, Ce.Xu), Ce.snapshot;
  }(t, h, d, p);
  const i = await oc(
    t.localStore,
    e,
    /* usePreviousResults= */
    !0
  ), o = new A_(e, i.Hi), a = o.Wu(i.documents), c = ls.createSynthesizedTargetChangeForCurrentChange(n, s && t.onlineState !== "Offline", r), u = o.applyChanges(
    a,
    /* updateLimboDocuments= */
    t.isPrimaryClient,
    c
  );
  dc(t, n, u.Xu);
  const l = new C_(e, n, o);
  return t.ic.set(e, l), t.rc.has(n) ? t.rc.get(n).push(e) : t.rc.set(n, [e]), u.snapshot;
}
async function D_(t, e) {
  const n = D(t), s = n.ic.get(e), r = n.rc.get(s.targetId);
  if (r.length > 1)
    return n.rc.set(s.targetId, r.filter((i) => !Cr(i, e))), void n.ic.delete(e);
  n.isPrimaryClient ? (n.sharedClientState.removeLocalQueryTarget(s.targetId), n.sharedClientState.isActiveQueryTarget(s.targetId) || await zi(
    n.localStore,
    s.targetId,
    /*keepPersistedTargetData=*/
    !1
  ).then(() => {
    n.sharedClientState.clearQueryState(s.targetId), Jl(n.remoteStore, s.targetId), Qi(n, s.targetId);
  }).catch(os)) : (Qi(n, s.targetId), await zi(
    n.localStore,
    s.targetId,
    /*keepPersistedTargetData=*/
    !0
  ));
}
async function P_(t, e, n) {
  const s = q_(t);
  try {
    const r = await function(i, o) {
      const a = D(i), c = X.now(), u = o.reduce((d, p) => d.add(p.key), M());
      let l, h;
      return a.persistence.runTransaction("Locally write mutations", "readwrite", (d) => {
        let p = Ke(), m = M();
        return a.Gi.getEntries(d, u).next((S) => {
          p = S, p.forEach((P, te) => {
            te.isValidDocument() || (m = m.add(P));
          });
        }).next(() => a.localDocuments.getOverlayedDocuments(d, p)).next((S) => {
          l = S;
          const P = [];
          for (const te of o) {
            const Q = ry(te, l.get(te.key).overlayedDocument);
            Q != null && // NOTE: The base state should only be applied if there's some
            // existing document to override, so use a Precondition of
            // exists=true
            P.push(new mt(te.key, Q, _l(Q.value.mapValue), Ne.exists(!0)));
          }
          return a.mutationQueue.addMutationBatch(d, c, P, o);
        }).next((S) => {
          h = S;
          const P = S.applyToLocalDocumentSet(l, m);
          return a.documentOverlayCache.saveOverlays(d, S.batchId, P);
        });
      }).then(() => ({
        batchId: h.batchId,
        changes: Pl(l)
      }));
    }(s.localStore, e);
    s.sharedClientState.addPendingMutation(r.batchId), function(i, o, a) {
      let c = i.hc[i.currentUser.toKey()];
      c || (c = new ee(B)), c = c.insert(o, a), i.hc[i.currentUser.toKey()] = c;
    }(s, r.batchId, n), await ds(s, r.changes), await Lr(s.remoteStore);
  } catch (r) {
    const i = Ho(r, "Failed to persist write");
    n.reject(i);
  }
}
async function ah(t, e) {
  const n = D(t);
  try {
    const s = await Jy(n.localStore, e);
    e.targetChanges.forEach((r, i) => {
      const o = n.cc.get(i);
      o && // Since this is a limbo resolution lookup, it's for a single document
      // and it could be added, modified, or removed, but not a combination.
      (j(r.addedDocuments.size + r.modifiedDocuments.size + r.removedDocuments.size <= 1), r.addedDocuments.size > 0 ? o.nc = !0 : r.modifiedDocuments.size > 0 ? j(o.nc) : r.removedDocuments.size > 0 && (j(o.nc), o.nc = !1));
    }), await ds(n, s, e);
  } catch (s) {
    await os(s);
  }
}
function hc(t, e, n) {
  const s = D(t);
  if (s.isPrimaryClient && n === 0 || !s.isPrimaryClient && n === 1) {
    const r = [];
    s.ic.forEach((i, o) => {
      const a = o.view.bu(e);
      a.snapshot && r.push(a.snapshot);
    }), function(i, o) {
      const a = D(i);
      a.onlineState = o;
      let c = !1;
      a.queries.forEach((u, l) => {
        for (const h of l.listeners)
          h.bu(o) && (c = !0);
      }), c && zo(a);
    }(s.eventManager, e), r.length && s.sc.Wo(r), s.onlineState = e, s.isPrimaryClient && s.sharedClientState.setOnlineState(e);
  }
}
async function L_(t, e, n) {
  const s = D(t);
  s.sharedClientState.updateQueryState(e, "rejected", n);
  const r = s.cc.get(e), i = r && r.key;
  if (i) {
    let o = new ee(I.comparator);
    o = o.insert(i, de.newNoDocument(i, k.min()));
    const a = M().add(i), c = new kr(
      k.min(),
      /* targetChanges= */
      /* @__PURE__ */ new Map(),
      /* targetMismatches= */
      new Z(B),
      o,
      a
    );
    await ah(s, c), // Since this query failed, we won't want to manually unlisten to it.
    // We only remove it from bookkeeping after we successfully applied the
    // RemoteEvent. If `applyRemoteEvent()` throws, we want to re-listen to
    // this query when the RemoteStore restarts the Watch stream, which should
    // re-trigger the target failure.
    s.uc = s.uc.remove(i), s.cc.delete(e), Qo(s);
  } else
    await zi(
      s.localStore,
      e,
      /* keepPersistedTargetData */
      !1
    ).then(() => Qi(s, e, n)).catch(os);
}
async function F_(t, e) {
  const n = D(t), s = e.batch.batchId;
  try {
    const r = await Ky(n.localStore, e);
    uh(
      n,
      s,
      /*error=*/
      null
    ), ch(n, s), n.sharedClientState.updateMutationState(s, "acknowledged"), await ds(n, r);
  } catch (r) {
    await os(r);
  }
}
async function M_(t, e, n) {
  const s = D(t);
  try {
    const r = await function(i, o) {
      const a = D(i);
      return a.persistence.runTransaction("Reject batch", "readwrite-primary", (c) => {
        let u;
        return a.mutationQueue.lookupMutationBatch(c, o).next((l) => (j(l !== null), u = l.keys(), a.mutationQueue.removeMutationBatch(c, l))).next(() => a.mutationQueue.performConsistencyCheck(c)).next(() => a.documentOverlayCache.removeOverlaysForBatchId(c, u, o)).next(() => a.localDocuments.recalculateAndSaveOverlaysForDocumentKeys(c, u)).next(() => a.localDocuments.getDocuments(c, u));
      });
    }(s.localStore, e);
    uh(s, e, n), ch(s, e), s.sharedClientState.updateMutationState(e, "rejected", n), await ds(s, r);
  } catch (r) {
    await os(r);
  }
}
function ch(t, e) {
  (t.lc.get(e) || []).forEach((n) => {
    n.resolve();
  }), t.lc.delete(e);
}
function uh(t, e, n) {
  const s = D(t);
  let r = s.hc[s.currentUser.toKey()];
  if (r) {
    const i = r.get(e);
    i && (n ? i.reject(n) : i.resolve(), r = r.remove(e)), s.hc[s.currentUser.toKey()] = r;
  }
}
function Qi(t, e, n = null) {
  t.sharedClientState.removeLocalQueryTarget(e);
  for (const s of t.rc.get(e))
    t.ic.delete(s), n && t.sc.wc(s, n);
  t.rc.delete(e), t.isPrimaryClient && t.ac.ls(e).forEach((s) => {
    t.ac.containsKey(s) || // We removed the last reference for this key
    lh(t, s);
  });
}
function lh(t, e) {
  t.oc.delete(e.path.canonicalString());
  const n = t.uc.get(e);
  n !== null && (Jl(t.remoteStore, n), t.uc = t.uc.remove(e), t.cc.delete(n), Qo(t));
}
function dc(t, e, n) {
  for (const s of n)
    s instanceof ih ? (t.ac.addReference(s.key, e), $_(t, s)) : s instanceof oh ? (T("SyncEngine", "Document no longer in limbo: " + s.key), t.ac.removeReference(s.key, e), t.ac.containsKey(s.key) || // We removed the last reference for this key
    lh(t, s.key)) : A();
}
function $_(t, e) {
  const n = e.key, s = n.path.canonicalString();
  t.uc.get(n) || t.oc.has(s) || (T("SyncEngine", "New document in limbo: " + n), t.oc.add(s), Qo(t));
}
function Qo(t) {
  for (; t.oc.size > 0 && t.uc.size < t.maxConcurrentLimboResolutions; ) {
    const e = t.oc.values().next().value;
    t.oc.delete(e);
    const n = new I(W.fromString(e)), s = t.fc.next();
    t.cc.set(s, new N_(n)), t.uc = t.uc.insert(n, s), Kl(t.remoteStore, new Nt(qe(Lo(n.path)), s, 2, Ro.at));
  }
}
async function ds(t, e, n) {
  const s = D(t), r = [], i = [], o = [];
  s.ic.isEmpty() || (s.ic.forEach((a, c) => {
    o.push(s._c(c, e, n).then((u) => {
      if (
        // If there are changes, or we are handling a global snapshot, notify
        // secondary clients to update query state.
        (u || n) && s.isPrimaryClient && s.sharedClientState.updateQueryState(c.targetId, u?.fromCache ? "not-current" : "current"), u
      ) {
        r.push(u);
        const l = qo.Ci(c.targetId, u);
        i.push(l);
      }
    }));
  }), await Promise.all(o), s.sc.Wo(r), await async function(a, c) {
    const u = D(a);
    try {
      await u.persistence.runTransaction("notifyLocalViewChanges", "readwrite", (l) => y.forEach(c, (h) => y.forEach(h.Si, (d) => u.persistence.referenceDelegate.addReference(l, h.targetId, d)).next(() => y.forEach(h.Di, (d) => u.persistence.referenceDelegate.removeReference(l, h.targetId, d)))));
    } catch (l) {
      if (!as(l))
        throw l;
      T("LocalStore", "Failed to update sequence numbers: " + l);
    }
    for (const l of c) {
      const h = l.targetId;
      if (!l.fromCache) {
        const d = u.qi.get(h), p = d.snapshotVersion, m = d.withLastLimboFreeSnapshotVersion(p);
        u.qi = u.qi.insert(h, m);
      }
    }
  }(s.localStore, i));
}
async function U_(t, e) {
  const n = D(t);
  if (!n.currentUser.isEqual(e)) {
    T("SyncEngine", "User change. New user:", e.toKey());
    const s = await Wl(n.localStore, e);
    n.currentUser = e, // Fails tasks waiting for pending writes requested by previous user.
    function(r, i) {
      r.lc.forEach((o) => {
        o.forEach((a) => {
          a.reject(new w(g.CANCELLED, i));
        });
      }), r.lc.clear();
    }(n, "'waitForPendingWrites' promise is rejected due to a user change."), // TODO(b/114226417): Consider calling this only in the primary tab.
    n.sharedClientState.handleUserChange(e, s.removedBatchIds, s.addedBatchIds), await ds(n, s.ji);
  }
}
function x_(t, e) {
  const n = D(t), s = n.cc.get(e);
  if (s && s.nc)
    return M().add(s.key);
  {
    let r = M();
    const i = n.rc.get(e);
    if (!i)
      return r;
    for (const o of i) {
      const a = n.ic.get(o);
      r = r.unionWith(a.view.ju);
    }
    return r;
  }
}
function B_(t) {
  const e = D(t);
  return e.remoteStore.remoteSyncer.applyRemoteEvent = ah.bind(null, e), e.remoteStore.remoteSyncer.getRemoteKeysForTarget = x_.bind(null, e), e.remoteStore.remoteSyncer.rejectListen = L_.bind(null, e), e.sc.Wo = I_.bind(null, e.eventManager), e.sc.wc = b_.bind(null, e.eventManager), e;
}
function q_(t) {
  const e = D(t);
  return e.remoteStore.remoteSyncer.applySuccessfulWrite = F_.bind(null, e), e.remoteStore.remoteSyncer.rejectFailedWrite = M_.bind(null, e), e;
}
class V_ {
  constructor() {
    this.synchronizeTabs = !1;
  }
  async initialize(e) {
    this.yt = Dr(e.databaseInfo.databaseId), this.sharedClientState = this.gc(e), this.persistence = this.yc(e), await this.persistence.start(), this.localStore = this.Ic(e), this.gcScheduler = this.Tc(e, this.localStore), this.indexBackfillerScheduler = this.Ec(e, this.localStore);
  }
  Tc(e, n) {
    return null;
  }
  Ec(e, n) {
    return null;
  }
  Ic(e) {
    return Qy(this.persistence, new Hy(), e.initialUser, this.yt);
  }
  yc(e) {
    return new Gy(Bo.Bs, this.yt);
  }
  gc(e) {
    return new t_();
  }
  async terminate() {
    this.gcScheduler && this.gcScheduler.stop(), await this.sharedClientState.shutdown(), await this.persistence.shutdown();
  }
}
class j_ {
  async initialize(e, n) {
    this.localStore || (this.localStore = e.localStore, this.sharedClientState = e.sharedClientState, this.datastore = this.createDatastore(n), this.remoteStore = this.createRemoteStore(n), this.eventManager = this.createEventManager(n), this.syncEngine = this.createSyncEngine(
      n,
      /* startAsPrimary=*/
      !e.synchronizeTabs
    ), this.sharedClientState.onlineStateHandler = (s) => hc(
      this.syncEngine,
      s,
      1
      /* OnlineStateSource.SharedClientState */
    ), this.remoteStore.remoteSyncer.handleCredentialChange = U_.bind(null, this.syncEngine), await v_(this.remoteStore, this.syncEngine.isPrimaryClient));
  }
  createEventManager(e) {
    return new S_();
  }
  createDatastore(e) {
    const n = Dr(e.databaseInfo.databaseId), s = (r = e.databaseInfo, new i_(r));
    var r;
    return function(i, o, a, c) {
      return new c_(i, o, a, c);
    }(e.authCredentials, e.appCheckCredentials, s, n);
  }
  createRemoteStore(e) {
    return n = this.localStore, s = this.datastore, r = e.asyncQueue, i = (a) => hc(
      this.syncEngine,
      a,
      0
      /* OnlineStateSource.RemoteStore */
    ), o = cc.C() ? new cc() : new n_(), new h_(n, s, r, i, o);
    var n, s, r, i, o;
  }
  createSyncEngine(e, n) {
    return function(s, r, i, o, a, c, u) {
      const l = new O_(s, r, i, o, a, c);
      return u && (l.dc = !0), l;
    }(this.localStore, this.remoteStore, this.eventManager, this.sharedClientState, e.initialUser, e.maxConcurrentLimboResolutions, n);
  }
  terminate() {
    return async function(e) {
      const n = D(e);
      T("RemoteStore", "RemoteStore shutting down."), n._u.add(
        5
        /* OfflineCause.Shutdown */
      ), await hs(n), n.mu.shutdown(), // Set the OnlineState to Unknown (rather than Offline) to avoid potentially
      // triggering spurious listener events with cached data, etc.
      n.gu.set(
        "Unknown"
        /* OnlineState.Unknown */
      );
    }(this.remoteStore);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function hh(t, e, n) {
  if (!n)
    throw new w(g.INVALID_ARGUMENT, `Function ${t}() cannot be called with an empty ${e}.`);
}
function G_(t, e, n, s) {
  if (e === !0 && s === !0)
    throw new w(g.INVALID_ARGUMENT, `${t} and ${n} cannot be used together.`);
}
function fc(t) {
  if (!I.isDocumentKey(t))
    throw new w(g.INVALID_ARGUMENT, `Invalid document reference. Document references must have an even number of segments, but ${t} has ${t.length}.`);
}
function pc(t) {
  if (I.isDocumentKey(t))
    throw new w(g.INVALID_ARGUMENT, `Invalid collection reference. Collection references must have an odd number of segments, but ${t} has ${t.length}.`);
}
function Fr(t) {
  if (t === void 0)
    return "undefined";
  if (t === null)
    return "null";
  if (typeof t == "string")
    return t.length > 20 && (t = `${t.substring(0, 20)}...`), JSON.stringify(t);
  if (typeof t == "number" || typeof t == "boolean")
    return "" + t;
  if (typeof t == "object") {
    if (t instanceof Array)
      return "an array";
    {
      const e = (
        /** try to get the constructor name for an object. */
        function(n) {
          return n.constructor ? n.constructor.name : null;
        }(t)
      );
      return e ? `a custom ${e} object` : "an object";
    }
  }
  return typeof t == "function" ? "a function" : A();
}
function Re(t, e) {
  if ("_delegate" in t && // Unwrap Compat types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (t = t._delegate), !(t instanceof e)) {
    if (e.name === t.constructor.name)
      throw new w(g.INVALID_ARGUMENT, "Type does not match the expected instance. Did you pass a reference from a different Firestore SDK?");
    {
      const n = Fr(t);
      throw new w(g.INVALID_ARGUMENT, `Expected type '${e.name}', but it was: ${n}`);
    }
  }
  return t;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const gc = /* @__PURE__ */ new Map();
class mc {
  constructor(e) {
    var n;
    if (e.host === void 0) {
      if (e.ssl !== void 0)
        throw new w(g.INVALID_ARGUMENT, "Can't provide ssl option if host option is not set");
      this.host = "firestore.googleapis.com", this.ssl = !0;
    } else
      this.host = e.host, this.ssl = (n = e.ssl) === null || n === void 0 || n;
    if (this.credentials = e.credentials, this.ignoreUndefinedProperties = !!e.ignoreUndefinedProperties, e.cacheSizeBytes === void 0)
      this.cacheSizeBytes = 41943040;
    else {
      if (e.cacheSizeBytes !== -1 && e.cacheSizeBytes < 1048576)
        throw new w(g.INVALID_ARGUMENT, "cacheSizeBytes must be at least 1048576");
      this.cacheSizeBytes = e.cacheSizeBytes;
    }
    this.experimentalForceLongPolling = !!e.experimentalForceLongPolling, this.experimentalAutoDetectLongPolling = !!e.experimentalAutoDetectLongPolling, this.useFetchStreams = !!e.useFetchStreams, G_("experimentalForceLongPolling", e.experimentalForceLongPolling, "experimentalAutoDetectLongPolling", e.experimentalAutoDetectLongPolling);
  }
  isEqual(e) {
    return this.host === e.host && this.ssl === e.ssl && this.credentials === e.credentials && this.cacheSizeBytes === e.cacheSizeBytes && this.experimentalForceLongPolling === e.experimentalForceLongPolling && this.experimentalAutoDetectLongPolling === e.experimentalAutoDetectLongPolling && this.ignoreUndefinedProperties === e.ignoreUndefinedProperties && this.useFetchStreams === e.useFetchStreams;
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Mr {
  /** @hideconstructor */
  constructor(e, n, s, r) {
    this._authCredentials = e, this._appCheckCredentials = n, this._databaseId = s, this._app = r, /**
     * Whether it's a Firestore or Firestore Lite instance.
     */
    this.type = "firestore-lite", this._persistenceKey = "(lite)", this._settings = new mc({}), this._settingsFrozen = !1;
  }
  /**
   * The {@link @firebase/app#FirebaseApp} associated with this `Firestore` service
   * instance.
   */
  get app() {
    if (!this._app)
      throw new w(g.FAILED_PRECONDITION, "Firestore was not initialized using the Firebase SDK. 'app' is not available");
    return this._app;
  }
  get _initialized() {
    return this._settingsFrozen;
  }
  get _terminated() {
    return this._terminateTask !== void 0;
  }
  _setSettings(e) {
    if (this._settingsFrozen)
      throw new w(g.FAILED_PRECONDITION, "Firestore has already been started and its settings can no longer be changed. You can only modify settings before calling any other methods on a Firestore object.");
    this._settings = new mc(e), e.credentials !== void 0 && (this._authCredentials = function(n) {
      if (!n)
        return new vm();
      switch (n.type) {
        case "gapi":
          const s = n.client;
          return new bm(s, n.sessionIndex || "0", n.iamToken || null, n.authTokenFactory || null);
        case "provider":
          return n.client;
        default:
          throw new w(g.INVALID_ARGUMENT, "makeAuthCredentialsProvider failed due to invalid credential type");
      }
    }(e.credentials));
  }
  _getSettings() {
    return this._settings;
  }
  _freezeSettings() {
    return this._settingsFrozen = !0, this._settings;
  }
  _delete() {
    return this._terminateTask || (this._terminateTask = this._terminate()), this._terminateTask;
  }
  /** Returns a JSON-serializable representation of this `Firestore` instance. */
  toJSON() {
    return {
      app: this._app,
      databaseId: this._databaseId,
      settings: this._settings
    };
  }
  /**
   * Terminates all components used by this client. Subclasses can override
   * this method to clean up their own dependencies, but must also call this
   * method.
   *
   * Only ever called once.
   */
  _terminate() {
    return function(e) {
      const n = gc.get(e);
      n && (T("ComponentProvider", "Removing Datastore"), gc.delete(e), n.terminate());
    }(this), Promise.resolve();
  }
}
function W_(t, e, n, s = {}) {
  var r;
  const i = (t = Re(t, Mr))._getSettings();
  if (i.host !== "firestore.googleapis.com" && i.host !== e && Mi("Host has been set in both settings() and useEmulator(), emulator host will be used"), t._setSettings(Object.assign(Object.assign({}, i), {
    host: `${e}:${n}`,
    ssl: !1
  })), s.mockUserToken) {
    let o, a;
    if (typeof s.mockUserToken == "string")
      o = s.mockUserToken, a = he.MOCK_USER;
    else {
      o = ep(s.mockUserToken, (r = t._app) === null || r === void 0 ? void 0 : r.options.projectId);
      const c = s.mockUserToken.sub || s.mockUserToken.user_id;
      if (!c)
        throw new w(g.INVALID_ARGUMENT, "mockUserToken must contain 'sub' or 'user_id' field!");
      a = new he(c);
    }
    t._authCredentials = new Tm(new al(o, a));
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class we {
  /** @hideconstructor */
  constructor(e, n, s) {
    this.converter = n, this._key = s, /** The type of this Firestore reference. */
    this.type = "document", this.firestore = e;
  }
  get _path() {
    return this._key.path;
  }
  /**
   * The document's identifier within its collection.
   */
  get id() {
    return this._key.path.lastSegment();
  }
  /**
   * A string representing the path of the referenced document (relative
   * to the root of the database).
   */
  get path() {
    return this._key.path.canonicalString();
  }
  /**
   * The collection this `DocumentReference` belongs to.
   */
  get parent() {
    return new ct(this.firestore, this.converter, this._key.path.popLast());
  }
  withConverter(e) {
    return new we(this.firestore, e, this._key);
  }
}
class mn {
  // This is the lite version of the Query class in the main SDK.
  /** @hideconstructor protected */
  constructor(e, n, s) {
    this.converter = n, this._query = s, /** The type of this Firestore reference. */
    this.type = "query", this.firestore = e;
  }
  withConverter(e) {
    return new mn(this.firestore, e, this._query);
  }
}
class ct extends mn {
  /** @hideconstructor */
  constructor(e, n, s) {
    super(e, n, Lo(s)), this._path = s, /** The type of this Firestore reference. */
    this.type = "collection";
  }
  /** The collection's identifier. */
  get id() {
    return this._query.path.lastSegment();
  }
  /**
   * A string representing the path of the referenced collection (relative
   * to the root of the database).
   */
  get path() {
    return this._query.path.canonicalString();
  }
  /**
   * A reference to the containing `DocumentReference` if this is a
   * subcollection. If this isn't a subcollection, the reference is null.
   */
  get parent() {
    const e = this._path.popLast();
    return e.isEmpty() ? null : new we(
      this.firestore,
      /* converter= */
      null,
      new I(e)
    );
  }
  withConverter(e) {
    return new ct(this.firestore, e, this._path);
  }
}
function $r(t, e, ...n) {
  if (t = Ee(t), hh("collection", "path", e), t instanceof Mr) {
    const s = W.fromString(e, ...n);
    return pc(s), new ct(
      t,
      /* converter= */
      null,
      s
    );
  }
  {
    if (!(t instanceof we || t instanceof ct))
      throw new w(g.INVALID_ARGUMENT, "Expected first argument to collection() to be a CollectionReference, a DocumentReference or FirebaseFirestore");
    const s = t._path.child(W.fromString(e, ...n));
    return pc(s), new ct(
      t.firestore,
      /* converter= */
      null,
      s
    );
  }
}
function Ur(t, e, ...n) {
  if (t = Ee(t), // We allow omission of 'pathString' but explicitly prohibit passing in both
  // 'undefined' and 'null'.
  arguments.length === 1 && (e = cl.R()), hh("doc", "path", e), t instanceof Mr) {
    const s = W.fromString(e, ...n);
    return fc(s), new we(
      t,
      /* converter= */
      null,
      new I(s)
    );
  }
  {
    if (!(t instanceof we || t instanceof ct))
      throw new w(g.INVALID_ARGUMENT, "Expected first argument to collection() to be a CollectionReference, a DocumentReference or FirebaseFirestore");
    const s = t._path.child(W.fromString(e, ...n));
    return fc(s), new we(t.firestore, t instanceof ct ? t.converter : null, new I(s));
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class dh {
  constructor(e) {
    this.observer = e, /**
     * When set to true, will not raise future events. Necessary to deal with
     * async detachment of listener.
     */
    this.muted = !1;
  }
  next(e) {
    this.observer.next && this.Rc(this.observer.next, e);
  }
  error(e) {
    this.observer.error ? this.Rc(this.observer.error, e) : Qe("Uncaught Error in snapshot listener:", e.toString());
  }
  bc() {
    this.muted = !0;
  }
  Rc(e, n) {
    this.muted || setTimeout(() => {
      this.muted || e(n);
    }, 0);
  }
}
class H_ {
  /** @hideconstructor */
  constructor(e, n) {
    this._data = n, /** A type string to uniquely identify instances of this class. */
    this.type = "AggregateQuerySnapshot", this.query = e;
  }
  /**
   * Returns the results of the aggregations performed over the underlying
   * query.
   *
   * The keys of the returned object will be the same as those of the
   * `AggregateSpec` object specified to the aggregation method, and the values
   * will be the corresponding aggregation result.
   *
   * @returns The results of the aggregations performed over the underlying
   * query.
   */
  data() {
    return this._data;
  }
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class z_ {
  constructor(e, n, s) {
    this.query = e, this.datastore = n, this.userDataWriter = s;
  }
  run() {
    return u_(this.datastore, this.query._query).then((e) => {
      j(e[0] !== void 0);
      const n = Object.entries(e[0]).filter(([s, r]) => s === "count_alias").map(([s, r]) => this.userDataWriter.convertValue(r))[0];
      return j(typeof n == "number"), Promise.resolve(new H_(this.query, {
        count: n
      }));
    });
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Q_ {
  constructor(e, n, s, r) {
    this.authCredentials = e, this.appCheckCredentials = n, this.asyncQueue = s, this.databaseInfo = r, this.user = he.UNAUTHENTICATED, this.clientId = cl.R(), this.authCredentialListener = () => Promise.resolve(), this.appCheckCredentialListener = () => Promise.resolve(), this.authCredentials.start(s, async (i) => {
      T("FirestoreClient", "Received user=", i.uid), await this.authCredentialListener(i), this.user = i;
    }), this.appCheckCredentials.start(s, (i) => (T("FirestoreClient", "Received new app check token=", i), this.appCheckCredentialListener(i, this.user)));
  }
  async getConfiguration() {
    return {
      asyncQueue: this.asyncQueue,
      databaseInfo: this.databaseInfo,
      clientId: this.clientId,
      authCredentials: this.authCredentials,
      appCheckCredentials: this.appCheckCredentials,
      initialUser: this.user,
      maxConcurrentLimboResolutions: 100
    };
  }
  setCredentialChangeListener(e) {
    this.authCredentialListener = e;
  }
  setAppCheckTokenChangeListener(e) {
    this.appCheckCredentialListener = e;
  }
  /**
   * Checks that the client has not been terminated. Ensures that other methods on
   * this class cannot be called after the client is terminated.
   */
  verifyNotTerminated() {
    if (this.asyncQueue.isShuttingDown)
      throw new w(g.FAILED_PRECONDITION, "The client has already been terminated.");
  }
  terminate() {
    this.asyncQueue.enterRestrictedMode();
    const e = new Me();
    return this.asyncQueue.enqueueAndForgetEvenWhileRestricted(async () => {
      try {
        this.onlineComponents && await this.onlineComponents.terminate(), this.offlineComponents && await this.offlineComponents.terminate(), // The credentials provider must be terminated after shutting down the
        // RemoteStore as it will prevent the RemoteStore from retrieving auth
        // tokens.
        this.authCredentials.shutdown(), this.appCheckCredentials.shutdown(), e.resolve();
      } catch (n) {
        const s = Ho(n, "Failed to shutdown persistence");
        e.reject(s);
      }
    }), e.promise;
  }
}
async function K_(t, e) {
  t.asyncQueue.verifyOperationInProgress(), T("FirestoreClient", "Initializing OfflineComponentProvider");
  const n = await t.getConfiguration();
  await e.initialize(n);
  let s = n.initialUser;
  t.setCredentialChangeListener(async (r) => {
    s.isEqual(r) || (await Wl(e.localStore, r), s = r);
  }), // When a user calls clearPersistence() in one client, all other clients
  // need to be terminated to allow the delete to succeed.
  e.persistence.setDatabaseDeletedListener(() => t.terminate()), t.offlineComponents = e;
}
async function J_(t, e) {
  t.asyncQueue.verifyOperationInProgress();
  const n = await Y_(t);
  T("FirestoreClient", "Initializing OnlineComponentProvider");
  const s = await t.getConfiguration();
  await e.initialize(n, s), // The CredentialChangeListener of the online component provider takes
  // precedence over the offline component provider.
  t.setCredentialChangeListener((r) => uc(e.remoteStore, r)), t.setAppCheckTokenChangeListener((r, i) => uc(e.remoteStore, i)), t.onlineComponents = e;
}
async function Y_(t) {
  return t.offlineComponents || (T("FirestoreClient", "Using default OfflineComponentProvider"), await K_(t, new V_())), t.offlineComponents;
}
async function xr(t) {
  return t.onlineComponents || (T("FirestoreClient", "Using default OnlineComponentProvider"), await J_(t, new j_())), t.onlineComponents;
}
function X_(t) {
  return xr(t).then((e) => e.remoteStore);
}
function Z_(t) {
  return xr(t).then((e) => e.syncEngine);
}
function ew(t) {
  return xr(t).then((e) => e.datastore);
}
async function fh(t) {
  const e = await xr(t), n = e.eventManager;
  return n.onListen = R_.bind(null, e.syncEngine), n.onUnlisten = D_.bind(null, e.syncEngine), n;
}
function tw(t, e, n = {}) {
  const s = new Me();
  return t.asyncQueue.enqueueAndForget(async () => function(r, i, o, a, c) {
    const u = new dh({
      next: (h) => {
        i.enqueueAndForget(() => sh(r, l));
        const d = h.docs.has(o);
        !d && h.fromCache ? (
          // TODO(dimond): If we're online and the document doesn't
          // exist then we resolve with a doc.exists set to false. If
          // we're offline however, we reject the Promise in this
          // case. Two options: 1) Cache the negative response from
          // the server so we can deliver that even when you're
          // offline 2) Actually reject the Promise in the online case
          // if the document doesn't exist.
          c.reject(new w(g.UNAVAILABLE, "Failed to get document because the client is offline."))
        ) : d && h.fromCache && a && a.source === "server" ? c.reject(new w(g.UNAVAILABLE, 'Failed to get document from server. (However, this document does exist in the local cache. Run again without setting source to "server" to retrieve the cached document.)')) : c.resolve(h);
      },
      error: (h) => c.reject(h)
    }), l = new rh(Lo(o.path), u, {
      includeMetadataChanges: !0,
      Nu: !0
    });
    return nh(r, l);
  }(await fh(t), t.asyncQueue, e, n, s)), s.promise;
}
function nw(t, e, n = {}) {
  const s = new Me();
  return t.asyncQueue.enqueueAndForget(async () => function(r, i, o, a, c) {
    const u = new dh({
      next: (h) => {
        i.enqueueAndForget(() => sh(r, l)), h.fromCache && a.source === "server" ? c.reject(new w(g.UNAVAILABLE, 'Failed to get documents from server. (However, these documents may exist in the local cache. Run again without setting source to "server" to retrieve the cached documents.)')) : c.resolve(h);
      },
      error: (h) => c.reject(h)
    }), l = new rh(o, u, {
      includeMetadataChanges: !0,
      Nu: !0
    });
    return nh(r, l);
  }(await fh(t), t.asyncQueue, e, n, s)), s.promise;
}
class sw {
  constructor() {
    this.Bc = Promise.resolve(), // A list of retryable operations. Retryable operations are run in order and
    // retried with backoff.
    this.Lc = [], // Is this AsyncQueue being shut down? Once it is set to true, it will not
    // be changed again.
    this.qc = !1, // Operations scheduled to be queued in the future. Operations are
    // automatically removed after they are run or canceled.
    this.Uc = [], // visible for testing
    this.Kc = null, // Flag set while there's an outstanding AsyncQueue operation, used for
    // assertion sanity-checks.
    this.Gc = !1, // Enabled during shutdown on Safari to prevent future access to IndexedDB.
    this.Qc = !1, // List of TimerIds to fast-forward delays for.
    this.jc = [], // Backoff timer used to schedule retries for retryable operations
    this.xo = new zl(
      this,
      "async_queue_retry"
      /* TimerId.AsyncQueueRetry */
    ), // Visibility handler that triggers an immediate retry of all retryable
    // operations. Meant to speed up recovery when we regain file system access
    // after page comes into foreground.
    this.Wc = () => {
      const n = gi();
      n && T("AsyncQueue", "Visibility state changed to " + n.visibilityState), this.xo.Po();
    };
    const e = gi();
    e && typeof e.addEventListener == "function" && e.addEventListener("visibilitychange", this.Wc);
  }
  get isShuttingDown() {
    return this.qc;
  }
  /**
   * Adds a new operation to the queue without waiting for it to complete (i.e.
   * we ignore the Promise result).
   */
  enqueueAndForget(e) {
    this.enqueue(e);
  }
  enqueueAndForgetEvenWhileRestricted(e) {
    this.zc(), // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.Hc(e);
  }
  enterRestrictedMode(e) {
    if (!this.qc) {
      this.qc = !0, this.Qc = e || !1;
      const n = gi();
      n && typeof n.removeEventListener == "function" && n.removeEventListener("visibilitychange", this.Wc);
    }
  }
  enqueue(e) {
    if (this.zc(), this.qc)
      return new Promise(() => {
      });
    const n = new Me();
    return this.Hc(() => this.qc && this.Qc ? Promise.resolve() : (e().then(n.resolve, n.reject), n.promise)).then(() => n.promise);
  }
  enqueueRetryable(e) {
    this.enqueueAndForget(() => (this.Lc.push(e), this.Jc()));
  }
  /**
   * Runs the next operation from the retryable queue. If the operation fails,
   * reschedules with backoff.
   */
  async Jc() {
    if (this.Lc.length !== 0) {
      try {
        await this.Lc[0](), this.Lc.shift(), this.xo.reset();
      } catch (e) {
        if (!as(e))
          throw e;
        T("AsyncQueue", "Operation failed with retryable error: " + e);
      }
      this.Lc.length > 0 && // If there are additional operations, we re-schedule `retryNextOp()`.
      // This is necessary to run retryable operations that failed during
      // their initial attempt since we don't know whether they are already
      // enqueued. If, for example, `op1`, `op2`, `op3` are enqueued and `op1`
      // needs to  be re-run, we will run `op1`, `op1`, `op2` using the
      // already enqueued calls to `retryNextOp()`. `op3()` will then run in the
      // call scheduled here.
      // Since `backoffAndRun()` cancels an existing backoff and schedules a
      // new backoff on every call, there is only ever a single additional
      // operation in the queue.
      this.xo.Ro(() => this.Jc());
    }
  }
  Hc(e) {
    const n = this.Bc.then(() => (this.Gc = !0, e().catch((s) => {
      this.Kc = s, this.Gc = !1;
      const r = (
        /**
        * Chrome includes Error.message in Error.stack. Other browsers do not.
        * This returns expected output of message + stack when available.
        * @param error - Error or FirestoreError
        */
        function(i) {
          let o = i.message || "";
          return i.stack && (o = i.stack.includes(i.message) ? i.stack : i.message + `
` + i.stack), o;
        }(s)
      );
      throw Qe("INTERNAL UNHANDLED ERROR: ", r), s;
    }).then((s) => (this.Gc = !1, s))));
    return this.Bc = n, n;
  }
  enqueueAfterDelay(e, n, s) {
    this.zc(), // Fast-forward delays for timerIds that have been overriden.
    this.jc.indexOf(e) > -1 && (n = 0);
    const r = Wo.createAndSchedule(this, e, n, s, (i) => this.Yc(i));
    return this.Uc.push(r), r;
  }
  zc() {
    this.Kc && A();
  }
  verifyOperationInProgress() {
  }
  /**
   * Waits until all currently queued tasks are finished executing. Delayed
   * operations are not run.
   */
  async Xc() {
    let e;
    do
      e = this.Bc, await e;
    while (e !== this.Bc);
  }
  /**
   * For Tests: Determine if a delayed operation with a particular TimerId
   * exists.
   */
  Zc(e) {
    for (const n of this.Uc)
      if (n.timerId === e)
        return !0;
    return !1;
  }
  /**
   * For Tests: Runs some or all delayed operations early.
   *
   * @param lastTimerId - Delayed operations up to and including this TimerId
   * will be drained. Pass TimerId.All to run all delayed operations.
   * @returns a Promise that resolves once all operations have been run.
   */
  ta(e) {
    return this.Xc().then(() => {
      this.Uc.sort((n, s) => n.targetTimeMs - s.targetTimeMs);
      for (const n of this.Uc)
        if (n.skipDelay(), e !== "all" && n.timerId === e)
          break;
      return this.Xc();
    });
  }
  /**
   * For Tests: Skip all subsequent delays for a timer id.
   */
  ea(e) {
    this.jc.push(e);
  }
  /** Called once a DelayedOperation is run or canceled. */
  Yc(e) {
    const n = this.Uc.indexOf(e);
    this.Uc.splice(n, 1);
  }
}
class Bt extends Mr {
  /** @hideconstructor */
  constructor(e, n, s, r) {
    super(e, n, s, r), /**
     * Whether it's a {@link Firestore} or Firestore Lite instance.
     */
    this.type = "firestore", this._queue = new sw(), this._persistenceKey = r?.name || "[DEFAULT]";
  }
  _terminate() {
    return this._firestoreClient || // The client must be initialized to ensure that all subsequent API
    // usage throws an exception.
    ph(this), this._firestoreClient.terminate();
  }
}
function rw(t, e, n) {
  n || (n = "(default)");
  const s = ur(t, "firestore");
  if (s.isInitialized(n)) {
    const r = s.getImmediate({
      identifier: n
    }), i = s.getOptions(n);
    if (Dn(i, e))
      return r;
    throw new w(g.FAILED_PRECONDITION, "initializeFirestore() has already been called with different options. To avoid this error, call initializeFirestore() with the same options as when it was originally called, or call getFirestore() to return the already initialized instance.");
  }
  if (e.cacheSizeBytes !== void 0 && e.cacheSizeBytes !== -1 && e.cacheSizeBytes < 1048576)
    throw new w(g.INVALID_ARGUMENT, "cacheSizeBytes must be at least 1048576");
  return s.initialize({
    options: e,
    instanceIdentifier: n
  });
}
function iw(t, e) {
  const n = typeof t == "object" ? t : lr(), s = typeof t == "string" ? t : e || "(default)", r = ur(n, "firestore").getImmediate({
    identifier: s
  });
  if (!r._initialized) {
    const i = Yf("firestore");
    i && W_(r, ...i);
  }
  return r;
}
function Br(t) {
  return t._firestoreClient || ph(t), t._firestoreClient.verifyNotTerminated(), t._firestoreClient;
}
function ph(t) {
  var e;
  const n = t._freezeSettings(), s = function(r, i, o, a) {
    return new Fm(r, i, o, a.host, a.ssl, a.experimentalForceLongPolling, a.experimentalAutoDetectLongPolling, a.useFetchStreams);
  }(t._databaseId, ((e = t._app) === null || e === void 0 ? void 0 : e.options.appId) || "", t._persistenceKey, n);
  t._firestoreClient = new Q_(t._authCredentials, t._appCheckCredentials, t._queue, s);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class un {
  /** @hideconstructor */
  constructor(e) {
    this._byteString = e;
  }
  /**
   * Creates a new `Bytes` object from the given Base64 string, converting it to
   * bytes.
   *
   * @param base64 - The Base64 string used to create the `Bytes` object.
   */
  static fromBase64String(e) {
    try {
      return new un(ye.fromBase64String(e));
    } catch (n) {
      throw new w(g.INVALID_ARGUMENT, "Failed to construct data from Base64 string: " + n);
    }
  }
  /**
   * Creates a new `Bytes` object from the given Uint8Array.
   *
   * @param array - The Uint8Array used to create the `Bytes` object.
   */
  static fromUint8Array(e) {
    return new un(ye.fromUint8Array(e));
  }
  /**
   * Returns the underlying bytes as a Base64-encoded string.
   *
   * @returns The Base64-encoded string created from the `Bytes` object.
   */
  toBase64() {
    return this._byteString.toBase64();
  }
  /**
   * Returns the underlying bytes in a new `Uint8Array`.
   *
   * @returns The Uint8Array created from the `Bytes` object.
   */
  toUint8Array() {
    return this._byteString.toUint8Array();
  }
  /**
   * Returns a string representation of the `Bytes` object.
   *
   * @returns A string representation of the `Bytes` object.
   */
  toString() {
    return "Bytes(base64: " + this.toBase64() + ")";
  }
  /**
   * Returns true if this `Bytes` object is equal to the provided one.
   *
   * @param other - The `Bytes` object to compare against.
   * @returns true if this `Bytes` object is equal to the provided one.
   */
  isEqual(e) {
    return this._byteString.isEqual(e._byteString);
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class qr {
  /**
   * Creates a `FieldPath` from the provided field names. If more than one field
   * name is provided, the path will point to a nested field in a document.
   *
   * @param fieldNames - A list of field names.
   */
  constructor(...e) {
    for (let n = 0; n < e.length; ++n)
      if (e[n].length === 0)
        throw new w(g.INVALID_ARGUMENT, "Invalid field name at argument $(i + 1). Field names must not be empty.");
    this._internalPath = new fe(e);
  }
  /**
   * Returns true if this `FieldPath` is equal to the provided one.
   *
   * @param other - The `FieldPath` to compare against.
   * @returns true if this `FieldPath` is equal to the provided one.
   */
  isEqual(e) {
    return this._internalPath.isEqual(e._internalPath);
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ko {
  /**
   * @param _methodName - The public API endpoint that returns this class.
   * @hideconstructor
   */
  constructor(e) {
    this._methodName = e;
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Jo {
  /**
   * Creates a new immutable `GeoPoint` object with the provided latitude and
   * longitude values.
   * @param latitude - The latitude as number between -90 and 90.
   * @param longitude - The longitude as number between -180 and 180.
   */
  constructor(e, n) {
    if (!isFinite(e) || e < -90 || e > 90)
      throw new w(g.INVALID_ARGUMENT, "Latitude must be a number between -90 and 90, but was: " + e);
    if (!isFinite(n) || n < -180 || n > 180)
      throw new w(g.INVALID_ARGUMENT, "Longitude must be a number between -180 and 180, but was: " + n);
    this._lat = e, this._long = n;
  }
  /**
   * The latitude of this `GeoPoint` instance.
   */
  get latitude() {
    return this._lat;
  }
  /**
   * The longitude of this `GeoPoint` instance.
   */
  get longitude() {
    return this._long;
  }
  /**
   * Returns true if this `GeoPoint` is equal to the provided one.
   *
   * @param other - The `GeoPoint` to compare against.
   * @returns true if this `GeoPoint` is equal to the provided one.
   */
  isEqual(e) {
    return this._lat === e._lat && this._long === e._long;
  }
  /** Returns a JSON-serializable representation of this GeoPoint. */
  toJSON() {
    return {
      latitude: this._lat,
      longitude: this._long
    };
  }
  /**
   * Actually private to JS consumers of our API, so this function is prefixed
   * with an underscore.
   */
  _compareTo(e) {
    return B(this._lat, e._lat) || B(this._long, e._long);
  }
}
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const ow = /^__.*__$/;
class aw {
  constructor(e, n, s) {
    this.data = e, this.fieldMask = n, this.fieldTransforms = s;
  }
  toMutation(e, n) {
    return this.fieldMask !== null ? new mt(e, this.data, this.fieldMask, n, this.fieldTransforms) : new us(e, this.data, n, this.fieldTransforms);
  }
}
class gh {
  constructor(e, n, s) {
    this.data = e, this.fieldMask = n, this.fieldTransforms = s;
  }
  toMutation(e, n) {
    return new mt(e, this.data, this.fieldMask, n, this.fieldTransforms);
  }
}
function mh(t) {
  switch (t) {
    case 0:
    case 2:
    case 1:
      return !0;
    case 3:
    case 4:
      return !1;
    default:
      throw A();
  }
}
class Yo {
  /**
   * Initializes a ParseContext with the given source and path.
   *
   * @param settings - The settings for the parser.
   * @param databaseId - The database ID of the Firestore instance.
   * @param serializer - The serializer to use to generate the Value proto.
   * @param ignoreUndefinedProperties - Whether to ignore undefined properties
   * rather than throw.
   * @param fieldTransforms - A mutable list of field transforms encountered
   * while parsing the data.
   * @param fieldMask - A mutable list of field paths encountered while parsing
   * the data.
   *
   * TODO(b/34871131): We don't support array paths right now, so path can be
   * null to indicate the context represents any location within an array (in
   * which case certain features will not work and errors will be somewhat
   * compromised).
   */
  constructor(e, n, s, r, i, o) {
    this.settings = e, this.databaseId = n, this.yt = s, this.ignoreUndefinedProperties = r, // Minor hack: If fieldTransforms is undefined, we assume this is an
    // external call and we need to validate the entire path.
    i === void 0 && this.na(), this.fieldTransforms = i || [], this.fieldMask = o || [];
  }
  get path() {
    return this.settings.path;
  }
  get sa() {
    return this.settings.sa;
  }
  /** Returns a new context with the specified settings overwritten. */
  ia(e) {
    return new Yo(Object.assign(Object.assign({}, this.settings), e), this.databaseId, this.yt, this.ignoreUndefinedProperties, this.fieldTransforms, this.fieldMask);
  }
  ra(e) {
    var n;
    const s = (n = this.path) === null || n === void 0 ? void 0 : n.child(e), r = this.ia({
      path: s,
      oa: !1
    });
    return r.ua(e), r;
  }
  ca(e) {
    var n;
    const s = (n = this.path) === null || n === void 0 ? void 0 : n.child(e), r = this.ia({
      path: s,
      oa: !1
    });
    return r.na(), r;
  }
  aa(e) {
    return this.ia({
      path: void 0,
      oa: !0
    });
  }
  ha(e) {
    return nr(e, this.settings.methodName, this.settings.la || !1, this.path, this.settings.fa);
  }
  /** Returns 'true' if 'fieldPath' was traversed when creating this context. */
  contains(e) {
    return this.fieldMask.find((n) => e.isPrefixOf(n)) !== void 0 || this.fieldTransforms.find((n) => e.isPrefixOf(n.field)) !== void 0;
  }
  na() {
    if (this.path)
      for (let e = 0; e < this.path.length; e++)
        this.ua(this.path.get(e));
  }
  ua(e) {
    if (e.length === 0)
      throw this.ha("Document fields must not be empty");
    if (mh(this.sa) && ow.test(e))
      throw this.ha('Document fields cannot begin and end with "__"');
  }
}
class cw {
  constructor(e, n, s) {
    this.databaseId = e, this.ignoreUndefinedProperties = n, this.yt = s || Dr(e);
  }
  /** Creates a new top-level parse context. */
  da(e, n, s, r = !1) {
    return new Yo({
      sa: e,
      methodName: n,
      fa: s,
      path: fe.emptyPath(),
      oa: !1,
      la: r
    }, this.databaseId, this.yt, this.ignoreUndefinedProperties);
  }
}
function Xo(t) {
  const e = t._freezeSettings(), n = Dr(t._databaseId);
  return new cw(t._databaseId, !!e.ignoreUndefinedProperties, n);
}
function uw(t, e, n, s, r, i = {}) {
  const o = t.da(i.merge || i.mergeFields ? 2 : 0, e, n, r);
  Zo("Data must be an object, but it was:", o, s);
  const a = yh(s, o);
  let c, u;
  if (i.merge)
    c = new Se(o.fieldMask), u = o.fieldTransforms;
  else if (i.mergeFields) {
    const l = [];
    for (const h of i.mergeFields) {
      const d = Ki(e, h, n);
      if (!o.contains(d))
        throw new w(g.INVALID_ARGUMENT, `Field '${d}' is specified in your field mask but missing from your input data.`);
      wh(l, d) || l.push(d);
    }
    c = new Se(l), u = o.fieldTransforms.filter((h) => c.covers(h.field));
  } else
    c = null, u = o.fieldTransforms;
  return new aw(new ve(a), c, u);
}
class Vr extends Ko {
  _toFieldTransform(e) {
    if (e.sa !== 2)
      throw e.sa === 1 ? e.ha(`${this._methodName}() can only appear at the top level of your update data`) : e.ha(`${this._methodName}() cannot be used with set() unless you pass {merge:true}`);
    return e.fieldMask.push(e.path), null;
  }
  isEqual(e) {
    return e instanceof Vr;
  }
}
function lw(t, e, n, s) {
  const r = t.da(1, e, n);
  Zo("Data must be an object, but it was:", r, s);
  const i = [], o = ve.empty();
  xt(s, (c, u) => {
    const l = ea(e, c, n);
    u = Ee(u);
    const h = r.ca(l);
    if (u instanceof Vr)
      i.push(l);
    else {
      const d = fs(u, h);
      d != null && (i.push(l), o.set(l, d));
    }
  });
  const a = new Se(i);
  return new gh(o, a, r.fieldTransforms);
}
function hw(t, e, n, s, r, i) {
  const o = t.da(1, e, n), a = [Ki(e, s, n)], c = [r];
  if (i.length % 2 != 0)
    throw new w(g.INVALID_ARGUMENT, `Function ${e}() needs to be called with an even number of arguments that alternate between field names and values.`);
  for (let d = 0; d < i.length; d += 2)
    a.push(Ki(e, i[d])), c.push(i[d + 1]);
  const u = [], l = ve.empty();
  for (let d = a.length - 1; d >= 0; --d)
    if (!wh(u, a[d])) {
      const p = a[d];
      let m = c[d];
      m = Ee(m);
      const S = o.ca(p);
      if (m instanceof Vr)
        u.push(p);
      else {
        const P = fs(m, S);
        P != null && (u.push(p), l.set(p, P));
      }
    }
  const h = new Se(u);
  return new gh(l, h, o.fieldTransforms);
}
function dw(t, e, n, s = !1) {
  return fs(n, t.da(s ? 4 : 3, e));
}
function fs(t, e) {
  if (_h(
    // Unwrap the API type from the Compat SDK. This will return the API type
    // from firestore-exp.
    t = Ee(t)
  ))
    return Zo("Unsupported field value:", e, t), yh(t, e);
  if (t instanceof Ko)
    return function(n, s) {
      if (!mh(s.sa))
        throw s.ha(`${n._methodName}() can only be used with update() and set()`);
      if (!s.path)
        throw s.ha(`${n._methodName}() is not currently supported inside arrays`);
      const r = n._toFieldTransform(s);
      r && s.fieldTransforms.push(r);
    }(t, e), null;
  if (t === void 0 && e.ignoreUndefinedProperties)
    return null;
  if (
    // If context.path is null we are inside an array and we don't support
    // field mask paths more granular than the top-level array.
    e.path && e.fieldMask.push(e.path), t instanceof Array
  ) {
    if (e.settings.oa && e.sa !== 4)
      throw e.ha("Nested arrays are not supported");
    return function(n, s) {
      const r = [];
      let i = 0;
      for (const o of n) {
        let a = fs(o, s.aa(i));
        a == null && // Just include nulls in the array for fields being replaced with a
        // sentinel.
        (a = {
          nullValue: "NULL_VALUE"
        }), r.push(a), i++;
      }
      return {
        arrayValue: {
          values: r
        }
      };
    }(t, e);
  }
  return function(n, s) {
    if ((n = Ee(n)) === null)
      return {
        nullValue: "NULL_VALUE"
      };
    if (typeof n == "number")
      return Xm(s.yt, n);
    if (typeof n == "boolean")
      return {
        booleanValue: n
      };
    if (typeof n == "string")
      return {
        stringValue: n
      };
    if (n instanceof Date) {
      const r = X.fromDate(n);
      return {
        timestampValue: er(s.yt, r)
      };
    }
    if (n instanceof X) {
      const r = new X(n.seconds, 1e3 * Math.floor(n.nanoseconds / 1e3));
      return {
        timestampValue: er(s.yt, r)
      };
    }
    if (n instanceof Jo)
      return {
        geoPointValue: {
          latitude: n.latitude,
          longitude: n.longitude
        }
      };
    if (n instanceof un)
      return {
        bytesValue: Ul(s.yt, n._byteString)
      };
    if (n instanceof we) {
      const r = s.databaseId, i = n.firestore._databaseId;
      if (!i.isEqual(r))
        throw s.ha(`Document reference is for database ${i.projectId}/${i.database} but should be for database ${r.projectId}/${r.database}`);
      return {
        referenceValue: $o(n.firestore._databaseId || s.databaseId, n._key.path)
      };
    }
    throw s.ha(`Unsupported field value: ${Fr(n)}`);
  }(t, e);
}
function yh(t, e) {
  const n = {};
  return ul(t) ? (
    // If we encounter an empty object, we explicitly add it to the update
    // mask to ensure that the server creates a map entry.
    e.path && e.path.length > 0 && e.fieldMask.push(e.path)
  ) : xt(t, (s, r) => {
    const i = fs(r, e.ra(s));
    i != null && (n[s] = i);
  }), {
    mapValue: {
      fields: n
    }
  };
}
function _h(t) {
  return !(typeof t != "object" || t === null || t instanceof Array || t instanceof Date || t instanceof X || t instanceof Jo || t instanceof un || t instanceof we || t instanceof Ko);
}
function Zo(t, e, n) {
  if (!_h(n) || !function(s) {
    return typeof s == "object" && s !== null && (Object.getPrototypeOf(s) === Object.prototype || Object.getPrototypeOf(s) === null);
  }(n)) {
    const s = Fr(n);
    throw s === "an object" ? e.ha(t + " a custom object") : e.ha(t + " " + s);
  }
}
function Ki(t, e, n) {
  if (
    // If required, replace the FieldPath Compat class with with the firestore-exp
    // FieldPath.
    (e = Ee(e)) instanceof qr
  )
    return e._internalPath;
  if (typeof e == "string")
    return ea(t, e);
  throw nr(
    "Field path arguments must be of type string or ",
    t,
    /* hasConverter= */
    !1,
    /* path= */
    void 0,
    n
  );
}
const fw = new RegExp("[~\\*/\\[\\]]");
function ea(t, e, n) {
  if (e.search(fw) >= 0)
    throw nr(
      `Invalid field path (${e}). Paths must not contain '~', '*', '/', '[', or ']'`,
      t,
      /* hasConverter= */
      !1,
      /* path= */
      void 0,
      n
    );
  try {
    return new qr(...e.split("."))._internalPath;
  } catch {
    throw nr(
      `Invalid field path (${e}). Paths must not be empty, begin with '.', end with '.', or contain '..'`,
      t,
      /* hasConverter= */
      !1,
      /* path= */
      void 0,
      n
    );
  }
}
function nr(t, e, n, s, r) {
  const i = s && !s.isEmpty(), o = r !== void 0;
  let a = `Function ${e}() called with invalid data`;
  n && (a += " (via `toFirestore()`)"), a += ". ";
  let c = "";
  return (i || o) && (c += " (found", i && (c += ` in field ${s}`), o && (c += ` in document ${r}`), c += ")"), new w(g.INVALID_ARGUMENT, a + t + c);
}
function wh(t, e) {
  return t.some((n) => n.isEqual(e));
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Eh {
  // Note: This class is stripped down version of the DocumentSnapshot in
  // the legacy SDK. The changes are:
  // - No support for SnapshotMetadata.
  // - No support for SnapshotOptions.
  /** @hideconstructor protected */
  constructor(e, n, s, r, i) {
    this._firestore = e, this._userDataWriter = n, this._key = s, this._document = r, this._converter = i;
  }
  /** Property of the `DocumentSnapshot` that provides the document's ID. */
  get id() {
    return this._key.path.lastSegment();
  }
  /**
   * The `DocumentReference` for the document included in the `DocumentSnapshot`.
   */
  get ref() {
    return new we(this._firestore, this._converter, this._key);
  }
  /**
   * Signals whether or not the document at the snapshot's location exists.
   *
   * @returns true if the document exists.
   */
  exists() {
    return this._document !== null;
  }
  /**
   * Retrieves all fields in the document as an `Object`. Returns `undefined` if
   * the document doesn't exist.
   *
   * @returns An `Object` containing all fields in the document or `undefined`
   * if the document doesn't exist.
   */
  data() {
    if (this._document) {
      if (this._converter) {
        const e = new pw(
          this._firestore,
          this._userDataWriter,
          this._key,
          this._document,
          /* converter= */
          null
        );
        return this._converter.fromFirestore(e);
      }
      return this._userDataWriter.convertValue(this._document.data.value);
    }
  }
  /**
   * Retrieves the field specified by `fieldPath`. Returns `undefined` if the
   * document or field doesn't exist.
   *
   * @param fieldPath - The path (for example 'foo' or 'foo.bar') to a specific
   * field.
   * @returns The data at the specified field location or undefined if no such
   * field exists in the document.
   */
  // We are using `any` here to avoid an explicit cast by our users.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(e) {
    if (this._document) {
      const n = this._document.data.field(ta("DocumentSnapshot.get", e));
      if (n !== null)
        return this._userDataWriter.convertValue(n);
    }
  }
}
class pw extends Eh {
  /**
   * Retrieves all fields in the document as an `Object`.
   *
   * @override
   * @returns An `Object` containing all fields in the document.
   */
  data() {
    return super.data();
  }
}
function ta(t, e) {
  return typeof e == "string" ? ea(t, e) : e instanceof qr ? e._internalPath : e._delegate._internalPath;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function gw(t) {
  if (t.limitType === "L" && t.explicitOrderBy.length === 0)
    throw new w(g.UNIMPLEMENTED, "limitToLast() queries require specifying at least one orderBy() clause");
}
class na {
}
class mw extends na {
}
function vh(t, e, ...n) {
  let s = [];
  e instanceof na && s.push(e), s = s.concat(n), function(r) {
    const i = r.filter((a) => a instanceof sa).length, o = r.filter((a) => a instanceof jr).length;
    if (i > 1 || i > 0 && o > 0)
      throw new w(g.INVALID_ARGUMENT, "InvalidQuery. When using composite filters, you cannot use more than one filter at the top level. Consider nesting the multiple filters within an `and(...)` statement. For example: change `query(query, where(...), or(...))` to `query(query, and(where(...), or(...)))`.");
  }(s);
  for (const r of s)
    t = r._apply(t);
  return t;
}
class jr extends mw {
  /**
   * @internal
   */
  constructor(e, n, s) {
    super(), this._field = e, this._op = n, this._value = s, /** The type of this query constraint */
    this.type = "where";
  }
  static _create(e, n, s) {
    return new jr(e, n, s);
  }
  _apply(e) {
    const n = this._parse(e);
    return Th(e._query, n), new mn(e.firestore, e.converter, qi(e._query, n));
  }
  _parse(e) {
    const n = Xo(e.firestore);
    return function(r, i, o, a, c, u, l) {
      let h;
      if (c.isKeyField()) {
        if (u === "array-contains" || u === "array-contains-any")
          throw new w(g.INVALID_ARGUMENT, `Invalid Query. You can't perform '${u}' queries on documentId().`);
        if (u === "in" || u === "not-in") {
          _c(l, u);
          const d = [];
          for (const p of l)
            d.push(yc(a, r, p));
          h = {
            arrayValue: {
              values: d
            }
          };
        } else
          h = yc(a, r, l);
      } else
        u !== "in" && u !== "not-in" && u !== "array-contains-any" || _c(l, u), h = dw(
          o,
          i,
          l,
          /* allowArrays= */
          u === "in" || u === "not-in"
        );
      return Y.create(c, u, h);
    }(e._query, "where", n, e.firestore._databaseId, this._field, this._op, this._value);
  }
}
function Te(t, e, n) {
  const s = e, r = ta("where", t);
  return jr._create(r, s, n);
}
class sa extends na {
  /**
   * @internal
   */
  constructor(e, n) {
    super(), this.type = e, this._queryConstraints = n;
  }
  static _create(e, n) {
    return new sa(e, n);
  }
  _parse(e) {
    const n = this._queryConstraints.map((s) => s._parse(e)).filter((s) => s.getFilters().length > 0);
    return n.length === 1 ? n[0] : Oe.create(n, this._getOperator());
  }
  _apply(e) {
    const n = this._parse(e);
    return n.getFilters().length === 0 ? e : (function(s, r) {
      let i = s;
      const o = r.getFlattenedFilters();
      for (const a of o)
        Th(i, a), i = qi(i, a);
    }(e._query, n), new mn(e.firestore, e.converter, qi(e._query, n)));
  }
  _getQueryConstraints() {
    return this._queryConstraints;
  }
  _getOperator() {
    return this.type === "and" ? "and" : "or";
  }
}
function yc(t, e, n) {
  if (typeof (n = Ee(n)) == "string") {
    if (n === "")
      throw new w(g.INVALID_ARGUMENT, "Invalid query. When querying with documentId(), you must provide a valid document ID, but it was an empty string.");
    if (!El(e) && n.indexOf("/") !== -1)
      throw new w(g.INVALID_ARGUMENT, `Invalid query. When querying a collection by documentId(), you must provide a plain document ID, but '${n}' contains a '/' character.`);
    const s = e.path.child(W.fromString(n));
    if (!I.isDocumentKey(s))
      throw new w(g.INVALID_ARGUMENT, `Invalid query. When querying a collection group by documentId(), the value provided must result in a valid document path, but '${s}' is not because it has an odd number of segments (${s.length}).`);
    return Ga(t, new I(s));
  }
  if (n instanceof we)
    return Ga(t, n._key);
  throw new w(g.INVALID_ARGUMENT, `Invalid query. When querying with documentId(), you must provide a valid string or a DocumentReference, but it was: ${Fr(n)}.`);
}
function _c(t, e) {
  if (!Array.isArray(t) || t.length === 0)
    throw new w(g.INVALID_ARGUMENT, `Invalid Query. A non-empty array is required for '${e.toString()}' filters.`);
  if (t.length > 10)
    throw new w(g.INVALID_ARGUMENT, `Invalid Query. '${e.toString()}' filters support a maximum of 10 elements in the value array.`);
}
function Th(t, e) {
  if (e.isInequality()) {
    const s = Fo(t), r = e.field;
    if (s !== null && !s.isEqual(r))
      throw new w(g.INVALID_ARGUMENT, `Invalid query. All where filters with an inequality (<, <=, !=, not-in, >, or >=) must be on the same field. But you have inequality filters on '${s.toString()}' and '${r.toString()}'`);
    const i = wl(t);
    i !== null && yw(t, r, i);
  }
  const n = function(s, r) {
    for (const i of s)
      for (const o of i.getFlattenedFilters())
        if (r.indexOf(o.op) >= 0)
          return o.op;
    return null;
  }(t.filters, function(s) {
    switch (s) {
      case "!=":
        return [
          "!=",
          "not-in"
          /* Operator.NOT_IN */
        ];
      case "array-contains":
        return [
          "array-contains",
          "array-contains-any",
          "not-in"
          /* Operator.NOT_IN */
        ];
      case "in":
        return [
          "array-contains-any",
          "in",
          "not-in"
          /* Operator.NOT_IN */
        ];
      case "array-contains-any":
        return [
          "array-contains",
          "array-contains-any",
          "in",
          "not-in"
          /* Operator.NOT_IN */
        ];
      case "not-in":
        return [
          "array-contains",
          "array-contains-any",
          "in",
          "not-in",
          "!="
          /* Operator.NOT_EQUAL */
        ];
      default:
        return [];
    }
  }(e.op));
  if (n !== null)
    throw n === e.op ? new w(g.INVALID_ARGUMENT, `Invalid query. You cannot use more than one '${e.op.toString()}' filter.`) : new w(g.INVALID_ARGUMENT, `Invalid query. You cannot use '${e.op.toString()}' filters with '${n.toString()}' filters.`);
}
function yw(t, e, n) {
  if (!n.isEqual(e))
    throw new w(g.INVALID_ARGUMENT, `Invalid query. You have a where filter with an inequality (<, <=, !=, not-in, >, or >=) on field '${e.toString()}' and so you must also use '${e.toString()}' as your first argument to orderBy(), but your first orderBy() is on field '${n.toString()}' instead.`);
}
class _w {
  convertValue(e, n = "none") {
    switch (Lt(e)) {
      case 0:
        return null;
      case 1:
        return e.booleanValue;
      case 2:
        return J(e.integerValue || e.doubleValue);
      case 3:
        return this.convertTimestamp(e.timestampValue);
      case 4:
        return this.convertServerTimestamp(e, n);
      case 5:
        return e.stringValue;
      case 6:
        return this.convertBytes(sn(e.bytesValue));
      case 7:
        return this.convertReference(e.referenceValue);
      case 8:
        return this.convertGeoPoint(e.geoPointValue);
      case 9:
        return this.convertArray(e.arrayValue, n);
      case 10:
        return this.convertObject(e.mapValue, n);
      default:
        throw A();
    }
  }
  convertObject(e, n) {
    const s = {};
    return xt(e.fields, (r, i) => {
      s[r] = this.convertValue(i, n);
    }), s;
  }
  convertGeoPoint(e) {
    return new Jo(J(e.latitude), J(e.longitude));
  }
  convertArray(e, n) {
    return (e.values || []).map((s) => this.convertValue(s, n));
  }
  convertServerTimestamp(e, n) {
    switch (n) {
      case "previous":
        const s = hl(e);
        return s == null ? null : this.convertValue(s, n);
      case "estimate":
        return this.convertTimestamp(qn(e));
      default:
        return null;
    }
  }
  convertTimestamp(e) {
    const n = ht(e);
    return new X(n.seconds, n.nanos);
  }
  convertDocumentKey(e, n) {
    const s = W.fromString(e);
    j(Gl(s));
    const r = new Bn(s.get(1), s.get(3)), i = new I(s.popFirst(5));
    return r.isEqual(n) || // TODO(b/64130202): Somehow support foreign references.
    Qe(`Document ${i} contains a document reference within a different database (${r.projectId}/${r.database}) which is not supported. It will be treated as a reference in the current database (${n.projectId}/${n.database}) instead.`), i;
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function ww(t, e, n) {
  let s;
  return s = t ? n && (n.merge || n.mergeFields) ? t.toFirestore(e, n) : t.toFirestore(e) : e, s;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class In {
  /** @hideconstructor */
  constructor(e, n) {
    this.hasPendingWrites = e, this.fromCache = n;
  }
  /**
   * Returns true if this `SnapshotMetadata` is equal to the provided one.
   *
   * @param other - The `SnapshotMetadata` to compare against.
   * @returns true if this `SnapshotMetadata` is equal to the provided one.
   */
  isEqual(e) {
    return this.hasPendingWrites === e.hasPendingWrites && this.fromCache === e.fromCache;
  }
}
class Sh extends Eh {
  /** @hideconstructor protected */
  constructor(e, n, s, r, i, o) {
    super(e, n, s, r, o), this._firestore = e, this._firestoreImpl = e, this.metadata = i;
  }
  /**
   * Returns whether or not the data exists. True if the document exists.
   */
  exists() {
    return super.exists();
  }
  /**
   * Retrieves all fields in the document as an `Object`. Returns `undefined` if
   * the document doesn't exist.
   *
   * By default, `serverTimestamp()` values that have not yet been
   * set to their final value will be returned as `null`. You can override
   * this by passing an options object.
   *
   * @param options - An options object to configure how data is retrieved from
   * the snapshot (for example the desired behavior for server timestamps that
   * have not yet been set to their final value).
   * @returns An `Object` containing all fields in the document or `undefined` if
   * the document doesn't exist.
   */
  data(e = {}) {
    if (this._document) {
      if (this._converter) {
        const n = new Ps(
          this._firestore,
          this._userDataWriter,
          this._key,
          this._document,
          this.metadata,
          /* converter= */
          null
        );
        return this._converter.fromFirestore(n, e);
      }
      return this._userDataWriter.convertValue(this._document.data.value, e.serverTimestamps);
    }
  }
  /**
   * Retrieves the field specified by `fieldPath`. Returns `undefined` if the
   * document or field doesn't exist.
   *
   * By default, a `serverTimestamp()` that has not yet been set to
   * its final value will be returned as `null`. You can override this by
   * passing an options object.
   *
   * @param fieldPath - The path (for example 'foo' or 'foo.bar') to a specific
   * field.
   * @param options - An options object to configure how the field is retrieved
   * from the snapshot (for example the desired behavior for server timestamps
   * that have not yet been set to their final value).
   * @returns The data at the specified field location or undefined if no such
   * field exists in the document.
   */
  // We are using `any` here to avoid an explicit cast by our users.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(e, n = {}) {
    if (this._document) {
      const s = this._document.data.field(ta("DocumentSnapshot.get", e));
      if (s !== null)
        return this._userDataWriter.convertValue(s, n.serverTimestamps);
    }
  }
}
class Ps extends Sh {
  /**
   * Retrieves all fields in the document as an `Object`.
   *
   * By default, `serverTimestamp()` values that have not yet been
   * set to their final value will be returned as `null`. You can override
   * this by passing an options object.
   *
   * @override
   * @param options - An options object to configure how data is retrieved from
   * the snapshot (for example the desired behavior for server timestamps that
   * have not yet been set to their final value).
   * @returns An `Object` containing all fields in the document.
   */
  data(e = {}) {
    return super.data(e);
  }
}
class Ew {
  /** @hideconstructor */
  constructor(e, n, s, r) {
    this._firestore = e, this._userDataWriter = n, this._snapshot = r, this.metadata = new In(r.hasPendingWrites, r.fromCache), this.query = s;
  }
  /** An array of all the documents in the `QuerySnapshot`. */
  get docs() {
    const e = [];
    return this.forEach((n) => e.push(n)), e;
  }
  /** The number of documents in the `QuerySnapshot`. */
  get size() {
    return this._snapshot.docs.size;
  }
  /** True if there are no documents in the `QuerySnapshot`. */
  get empty() {
    return this.size === 0;
  }
  /**
   * Enumerates all of the documents in the `QuerySnapshot`.
   *
   * @param callback - A callback to be called with a `QueryDocumentSnapshot` for
   * each document in the snapshot.
   * @param thisArg - The `this` binding for the callback.
   */
  forEach(e, n) {
    this._snapshot.docs.forEach((s) => {
      e.call(n, new Ps(this._firestore, this._userDataWriter, s.key, s, new In(this._snapshot.mutatedKeys.has(s.key), this._snapshot.fromCache), this.query.converter));
    });
  }
  /**
   * Returns an array of the documents changes since the last snapshot. If this
   * is the first snapshot, all documents will be in the list as 'added'
   * changes.
   *
   * @param options - `SnapshotListenOptions` that control whether metadata-only
   * changes (i.e. only `DocumentSnapshot.metadata` changed) should trigger
   * snapshot events.
   */
  docChanges(e = {}) {
    const n = !!e.includeMetadataChanges;
    if (n && this._snapshot.excludesMetadataChanges)
      throw new w(g.INVALID_ARGUMENT, "To include metadata changes with your document changes, you must also pass { includeMetadataChanges:true } to onSnapshot().");
    return this._cachedChanges && this._cachedChangesIncludeMetadataChanges === n || (this._cachedChanges = /** Calculates the array of `DocumentChange`s for a given `ViewSnapshot`. */
    function(s, r) {
      if (s._snapshot.oldDocs.isEmpty()) {
        let i = 0;
        return s._snapshot.docChanges.map((o) => {
          const a = new Ps(s._firestore, s._userDataWriter, o.doc.key, o.doc, new In(s._snapshot.mutatedKeys.has(o.doc.key), s._snapshot.fromCache), s.query.converter);
          return o.doc, {
            type: "added",
            doc: a,
            oldIndex: -1,
            newIndex: i++
          };
        });
      }
      {
        let i = s._snapshot.oldDocs;
        return s._snapshot.docChanges.filter((o) => r || o.type !== 3).map((o) => {
          const a = new Ps(s._firestore, s._userDataWriter, o.doc.key, o.doc, new In(s._snapshot.mutatedKeys.has(o.doc.key), s._snapshot.fromCache), s.query.converter);
          let c = -1, u = -1;
          return o.type !== 0 && (c = i.indexOf(o.doc.key), i = i.delete(o.doc.key)), o.type !== 1 && (i = i.add(o.doc), u = i.indexOf(o.doc.key)), {
            type: vw(o.type),
            doc: a,
            oldIndex: c,
            newIndex: u
          };
        });
      }
    }(this, n), this._cachedChangesIncludeMetadataChanges = n), this._cachedChanges;
  }
}
function vw(t) {
  switch (t) {
    case 0:
      return "added";
    case 2:
    case 3:
      return "modified";
    case 1:
      return "removed";
    default:
      return A();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Tw(t) {
  t = Re(t, we);
  const e = Re(t.firestore, Bt);
  return tw(Br(e), t._key).then((n) => Aw(e, t, n));
}
class ra extends _w {
  constructor(e) {
    super(), this.firestore = e;
  }
  convertBytes(e) {
    return new un(e);
  }
  convertReference(e) {
    const n = this.convertDocumentKey(e, this.firestore._databaseId);
    return new we(
      this.firestore,
      /* converter= */
      null,
      n
    );
  }
}
function Ih(t) {
  t = Re(t, mn);
  const e = Re(t.firestore, Bt), n = Br(e), s = new ra(e);
  return gw(t._query), nw(n, t._query).then((r) => new Ew(e, s, t, r));
}
function Sw(t, e, n) {
  t = Re(t, we);
  const s = Re(t.firestore, Bt), r = ww(t.converter, e, n);
  return ia(s, [uw(Xo(s), "setDoc", t._key, r, t.converter !== null, n).toMutation(t._key, Ne.none())]);
}
function Iw(t, e, n, ...s) {
  t = Re(t, we);
  const r = Re(t.firestore, Bt), i = Xo(r);
  let o;
  return o = typeof // For Compat types, we have to "extract" the underlying types before
  // performing validation.
  (e = Ee(e)) == "string" || e instanceof qr ? hw(i, "updateDoc", t._key, e, n, s) : lw(i, "updateDoc", t._key, e), ia(r, [o.toMutation(t._key, Ne.exists(!0))]);
}
function bw(t) {
  return ia(Re(t.firestore, Bt), [new Mo(t._key, Ne.none())]);
}
function ia(t, e) {
  return function(n, s) {
    const r = new Me();
    return n.asyncQueue.enqueueAndForget(async () => P_(await Z_(n), s, r)), r.promise;
  }(Br(t), e);
}
function Aw(t, e, n) {
  const s = n.docs.get(e._key), r = new ra(t);
  return new Sh(t, r, e._key, s, new In(n.hasPendingWrites, n.fromCache), e.converter);
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Cw(t) {
  const e = Re(t.firestore, Bt);
  return function(n, s, r) {
    const i = new Me();
    return n.asyncQueue.enqueueAndForget(async () => {
      try {
        if (yt(await X_(n))) {
          const o = await ew(n), a = new z_(s, o, r).run();
          i.resolve(a);
        } else
          i.reject(new w(g.UNAVAILABLE, "Failed to get count result because the client is offline."));
      } catch (o) {
        i.reject(o);
      }
    }), i.promise;
  }(Br(e), t, new ra(e));
}
(function(t, e = !0) {
  (function(n) {
    fn = n;
  })(Jn), en(new kt("firestore", (n, { instanceIdentifier: s, options: r }) => {
    const i = n.getProvider("app").getImmediate(), o = new Bt(new Sm(n.getProvider("auth-internal")), new Cm(n.getProvider("app-check-internal")), function(a, c) {
      if (!Object.prototype.hasOwnProperty.apply(a.options, ["projectId"]))
        throw new w(g.INVALID_ARGUMENT, '"projectId" not provided in firebase.initializeApp.');
      return new Bn(a.options.projectId, c);
    }(i, s), i);
    return r = Object.assign({
      useFetchStreams: e
    }, r), o._setSettings(r), o;
  }, "PUBLIC").setMultipleInstances(!0)), at(Ba, "3.8.3", t), // BUILD_TARGET will be replaced by values like esm5, esm2017, cjs5, etc during the compilation
  at(Ba, "3.8.3", "esm2017");
})();
var U = /* @__PURE__ */ ((t) => (t.Plans = "Plans", t.Audit = "Audit", t.Comments = "Comments", t.CustomGeoboundaries = "CustomGeoboundaries", t.Clients = "Clients", t.Brands = "Brands", t.SharedPlans = "SharedPlans", t))(U || {});
function oa(t, e) {
  var n = {};
  for (var s in t)
    Object.prototype.hasOwnProperty.call(t, s) && e.indexOf(s) < 0 && (n[s] = t[s]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var r = 0, s = Object.getOwnPropertySymbols(t); r < s.length; r++)
      e.indexOf(s[r]) < 0 && Object.prototype.propertyIsEnumerable.call(t, s[r]) && (n[s[r]] = t[s[r]]);
  return n;
}
function bh() {
  return {
    [
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    ]: "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized. Please be sure to call `initializeAuth` or `getAuth` before starting any other Firebase SDK."
  };
}
const Nw = bh, Ah = new Qn("auth", "Firebase", bh());
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const wc = new io("@firebase/auth");
function Ls(t, ...e) {
  wc.logLevel <= x.ERROR && wc.error(`Auth (${Jn}): ${t}`, ...e);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Je(t, ...e) {
  throw aa(t, ...e);
}
function Ue(t, ...e) {
  return aa(t, ...e);
}
function Ow(t, e, n) {
  const s = Object.assign(Object.assign({}, Nw()), { [e]: n });
  return new Qn("auth", "Firebase", s).create(e, {
    appName: t.name
  });
}
function aa(t, ...e) {
  if (typeof t != "string") {
    const n = e[0], s = [...e.slice(1)];
    return s[0] && (s[0].appName = t.name), t._errorFactory.create(n, ...s);
  }
  return Ah.create(t, ...e);
}
function C(t, e, ...n) {
  if (!t)
    throw aa(e, ...n);
}
function We(t) {
  const e = "INTERNAL ASSERTION FAILED: " + t;
  throw Ls(e), new Error(e);
}
function Ye(t, e) {
  t || We(e);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Ec = /* @__PURE__ */ new Map();
function He(t) {
  Ye(t instanceof Function, "Expected a class definition");
  let e = Ec.get(t);
  return e ? (Ye(e instanceof t, "Instance stored in cache mismatched with class"), e) : (e = new t(), Ec.set(t, e), e);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Rw(t, e) {
  const n = ur(t, "auth");
  if (n.isInitialized()) {
    const r = n.getImmediate(), i = n.getOptions();
    if (Dn(i, e ?? {}))
      return r;
    Je(
      r,
      "already-initialized"
      /* AuthErrorCode.ALREADY_INITIALIZED */
    );
  }
  return n.initialize({ options: e });
}
function kw(t, e) {
  const n = e?.persistence || [], s = (Array.isArray(n) ? n : [n]).map(He);
  e?.errorMap && t._updateErrorMap(e.errorMap), t._initializeWithPersistence(s, e?.popupRedirectResolver);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Ji() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.href) || "";
}
function Dw() {
  return vc() === "http:" || vc() === "https:";
}
function vc() {
  var t;
  return typeof self < "u" && ((t = self.location) === null || t === void 0 ? void 0 : t.protocol) || null;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Pw() {
  return typeof navigator < "u" && navigator && "onLine" in navigator && typeof navigator.onLine == "boolean" && // Apply only for traditional web apps and Chrome extensions.
  // This is especially true for Cordova apps which have unreliable
  // navigator.onLine behavior unless cordova-plugin-network-information is
  // installed which overwrites the native navigator.onLine value and
  // defines navigator.connection.
  (Dw() || np() || "connection" in navigator) ? navigator.onLine : !0;
}
function Lw() {
  if (typeof navigator > "u")
    return null;
  const t = navigator;
  return (
    // Most reliable, but only supported in Chrome/Firefox.
    t.languages && t.languages[0] || // Supported in most browsers, but returns the language of the browser
    // UI, not the language set in browser settings.
    t.language || // Couldn't determine language.
    null
  );
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ps {
  constructor(e, n) {
    this.shortDelay = e, this.longDelay = n, Ye(n > e, "Short delay should be less than long delay!"), this.isMobile = tp() || sp();
  }
  get() {
    return Pw() ? this.isMobile ? this.longDelay : this.shortDelay : Math.min(5e3, this.shortDelay);
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function ca(t, e) {
  Ye(t.emulator, "Emulator should always be set here");
  const { url: n } = t.emulator;
  return e ? `${n}${e.startsWith("/") ? e.slice(1) : e}` : n;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ch {
  static initialize(e, n, s) {
    this.fetchImpl = e, n && (this.headersImpl = n), s && (this.responseImpl = s);
  }
  static fetch() {
    if (this.fetchImpl)
      return this.fetchImpl;
    if (typeof self < "u" && "fetch" in self)
      return self.fetch;
    We("Could not find fetch implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static headers() {
    if (this.headersImpl)
      return this.headersImpl;
    if (typeof self < "u" && "Headers" in self)
      return self.Headers;
    We("Could not find Headers implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
  static response() {
    if (this.responseImpl)
      return this.responseImpl;
    if (typeof self < "u" && "Response" in self)
      return self.Response;
    We("Could not find Response implementation, make sure you call FetchProvider.initialize() with an appropriate polyfill");
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Fw = {
  // Custom token errors.
  CREDENTIAL_MISMATCH: "custom-token-mismatch",
  // This can only happen if the SDK sends a bad request.
  MISSING_CUSTOM_TOKEN: "internal-error",
  // Create Auth URI errors.
  INVALID_IDENTIFIER: "invalid-email",
  // This can only happen if the SDK sends a bad request.
  MISSING_CONTINUE_URI: "internal-error",
  // Sign in with email and password errors (some apply to sign up too).
  INVALID_PASSWORD: "wrong-password",
  // This can only happen if the SDK sends a bad request.
  MISSING_PASSWORD: "internal-error",
  // Sign up with email and password errors.
  EMAIL_EXISTS: "email-already-in-use",
  PASSWORD_LOGIN_DISABLED: "operation-not-allowed",
  // Verify assertion for sign in with credential errors:
  INVALID_IDP_RESPONSE: "invalid-credential",
  INVALID_PENDING_TOKEN: "invalid-credential",
  FEDERATED_USER_ID_ALREADY_LINKED: "credential-already-in-use",
  // This can only happen if the SDK sends a bad request.
  MISSING_REQ_TYPE: "internal-error",
  // Send Password reset email errors:
  EMAIL_NOT_FOUND: "user-not-found",
  RESET_PASSWORD_EXCEED_LIMIT: "too-many-requests",
  EXPIRED_OOB_CODE: "expired-action-code",
  INVALID_OOB_CODE: "invalid-action-code",
  // This can only happen if the SDK sends a bad request.
  MISSING_OOB_CODE: "internal-error",
  // Operations that require ID token in request:
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "requires-recent-login",
  INVALID_ID_TOKEN: "invalid-user-token",
  TOKEN_EXPIRED: "user-token-expired",
  USER_NOT_FOUND: "user-token-expired",
  // Other errors.
  TOO_MANY_ATTEMPTS_TRY_LATER: "too-many-requests",
  // Phone Auth related errors.
  INVALID_CODE: "invalid-verification-code",
  INVALID_SESSION_INFO: "invalid-verification-id",
  INVALID_TEMPORARY_PROOF: "invalid-credential",
  MISSING_SESSION_INFO: "missing-verification-id",
  SESSION_EXPIRED: "code-expired",
  // Other action code errors when additional settings passed.
  // MISSING_CONTINUE_URI is getting mapped to INTERNAL_ERROR above.
  // This is OK as this error will be caught by client side validation.
  MISSING_ANDROID_PACKAGE_NAME: "missing-android-pkg-name",
  UNAUTHORIZED_DOMAIN: "unauthorized-continue-uri",
  // getProjectConfig errors when clientId is passed.
  INVALID_OAUTH_CLIENT_ID: "invalid-oauth-client-id",
  // User actions (sign-up or deletion) disabled errors.
  ADMIN_ONLY_OPERATION: "admin-restricted-operation",
  // Multi factor related errors.
  INVALID_MFA_PENDING_CREDENTIAL: "invalid-multi-factor-session",
  MFA_ENROLLMENT_NOT_FOUND: "multi-factor-info-not-found",
  MISSING_MFA_ENROLLMENT_ID: "missing-multi-factor-info",
  MISSING_MFA_PENDING_CREDENTIAL: "missing-multi-factor-session",
  SECOND_FACTOR_EXISTS: "second-factor-already-in-use",
  SECOND_FACTOR_LIMIT_EXCEEDED: "maximum-second-factor-count-exceeded",
  // Blocking functions related errors.
  BLOCKING_FUNCTION_ERROR_RESPONSE: "internal-error"
  /* AuthErrorCode.INTERNAL_ERROR */
};
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Mw = new ps(3e4, 6e4);
function Nh(t, e) {
  return t.tenantId && !e.tenantId ? Object.assign(Object.assign({}, e), { tenantId: t.tenantId }) : e;
}
async function Gr(t, e, n, s, r = {}) {
  return Oh(t, r, async () => {
    let i = {}, o = {};
    s && (e === "GET" ? o = s : i = {
      body: JSON.stringify(s)
    });
    const a = Kn(Object.assign({ key: t.config.apiKey }, o)).slice(1), c = await t._getAdditionalHeaders();
    return c[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/json", t.languageCode && (c[
      "X-Firebase-Locale"
      /* HttpHeader.X_FIREBASE_LOCALE */
    ] = t.languageCode), Ch.fetch()(kh(t, t.config.apiHost, n, a), Object.assign({
      method: e,
      headers: c,
      referrerPolicy: "no-referrer"
    }, i));
  });
}
async function Oh(t, e, n) {
  t._canInitEmulator = !1;
  const s = Object.assign(Object.assign({}, Fw), e);
  try {
    const r = new $w(t), i = await Promise.race([
      n(),
      r.promise
    ]);
    r.clearNetworkTimeout();
    const o = await i.json();
    if ("needConfirmation" in o)
      throw Cs(t, "account-exists-with-different-credential", o);
    if (i.ok && !("errorMessage" in o))
      return o;
    {
      const a = i.ok ? o.errorMessage : o.error.message, [c, u] = a.split(" : ");
      if (c === "FEDERATED_USER_ID_ALREADY_LINKED")
        throw Cs(t, "credential-already-in-use", o);
      if (c === "EMAIL_EXISTS")
        throw Cs(t, "email-already-in-use", o);
      if (c === "USER_DISABLED")
        throw Cs(t, "user-disabled", o);
      const l = s[c] || c.toLowerCase().replace(/[_\s]+/g, "-");
      if (u)
        throw Ow(t, l, u);
      Je(t, l);
    }
  } catch (r) {
    if (r instanceof Xe)
      throw r;
    Je(
      t,
      "network-request-failed"
      /* AuthErrorCode.NETWORK_REQUEST_FAILED */
    );
  }
}
async function Rh(t, e, n, s, r = {}) {
  const i = await Gr(t, e, n, s, r);
  return "mfaPendingCredential" in i && Je(t, "multi-factor-auth-required", {
    _serverResponse: i
  }), i;
}
function kh(t, e, n, s) {
  const r = `${e}${n}?${s}`;
  return t.config.emulator ? ca(t.config, r) : `${t.config.apiScheme}://${r}`;
}
class $w {
  constructor(e) {
    this.auth = e, this.timer = null, this.promise = new Promise((n, s) => {
      this.timer = setTimeout(() => s(Ue(
        this.auth,
        "network-request-failed"
        /* AuthErrorCode.NETWORK_REQUEST_FAILED */
      )), Mw.get());
    });
  }
  clearNetworkTimeout() {
    clearTimeout(this.timer);
  }
}
function Cs(t, e, n) {
  const s = {
    appName: t.name
  };
  n.email && (s.email = n.email), n.phoneNumber && (s.phoneNumber = n.phoneNumber);
  const r = Ue(t, e, s);
  return r.customData._tokenResponse = n, r;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function Uw(t, e) {
  return Gr(t, "POST", "/v1/accounts:delete", e);
}
async function xw(t, e) {
  return Gr(t, "POST", "/v1/accounts:lookup", e);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Rn(t) {
  if (t)
    try {
      const e = new Date(Number(t));
      if (!isNaN(e.getTime()))
        return e.toUTCString();
    } catch {
    }
}
async function Bw(t, e = !1) {
  const n = Ee(t), s = await n.getIdToken(e), r = ua(s);
  C(
    r && r.exp && r.auth_time && r.iat,
    n.auth,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = typeof r.firebase == "object" ? r.firebase : void 0, o = i?.sign_in_provider;
  return {
    claims: r,
    token: s,
    authTime: Rn(mi(r.auth_time)),
    issuedAtTime: Rn(mi(r.iat)),
    expirationTime: Rn(mi(r.exp)),
    signInProvider: o || null,
    signInSecondFactor: i?.sign_in_second_factor || null
  };
}
function mi(t) {
  return Number(t) * 1e3;
}
function ua(t) {
  const [e, n, s] = t.split(".");
  if (e === void 0 || n === void 0 || s === void 0)
    return Ls("JWT malformed, contained fewer than 3 sections"), null;
  try {
    const r = Yc(n);
    return r ? JSON.parse(r) : (Ls("Failed to decode base64 JWT payload"), null);
  } catch (r) {
    return Ls("Caught error parsing JWT payload as JSON", r?.toString()), null;
  }
}
function qw(t) {
  const e = ua(t);
  return C(
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), C(
    typeof e.exp < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), C(
    typeof e.iat < "u",
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), Number(e.exp) - Number(e.iat);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function Wn(t, e, n = !1) {
  if (n)
    return e;
  try {
    return await e;
  } catch (s) {
    throw s instanceof Xe && Vw(s) && t.auth.currentUser === t && await t.auth.signOut(), s;
  }
}
function Vw({ code: t }) {
  return t === "auth/user-disabled" || t === "auth/user-token-expired";
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class jw {
  constructor(e) {
    this.user = e, this.isRunning = !1, this.timerId = null, this.errorBackoff = 3e4;
  }
  _start() {
    this.isRunning || (this.isRunning = !0, this.schedule());
  }
  _stop() {
    this.isRunning && (this.isRunning = !1, this.timerId !== null && clearTimeout(this.timerId));
  }
  getInterval(e) {
    var n;
    if (e) {
      const s = this.errorBackoff;
      return this.errorBackoff = Math.min(
        this.errorBackoff * 2,
        96e4
        /* Duration.RETRY_BACKOFF_MAX */
      ), s;
    } else {
      this.errorBackoff = 3e4;
      const r = ((n = this.user.stsTokenManager.expirationTime) !== null && n !== void 0 ? n : 0) - Date.now() - 3e5;
      return Math.max(0, r);
    }
  }
  schedule(e = !1) {
    if (!this.isRunning)
      return;
    const n = this.getInterval(e);
    this.timerId = setTimeout(async () => {
      await this.iteration();
    }, n);
  }
  async iteration() {
    try {
      await this.user.getIdToken(!0);
    } catch (e) {
      e?.code === "auth/network-request-failed" && this.schedule(
        /* wasError */
        !0
      );
      return;
    }
    this.schedule();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Dh {
  constructor(e, n) {
    this.createdAt = e, this.lastLoginAt = n, this._initializeTime();
  }
  _initializeTime() {
    this.lastSignInTime = Rn(this.lastLoginAt), this.creationTime = Rn(this.createdAt);
  }
  _copy(e) {
    this.createdAt = e.createdAt, this.lastLoginAt = e.lastLoginAt, this._initializeTime();
  }
  toJSON() {
    return {
      createdAt: this.createdAt,
      lastLoginAt: this.lastLoginAt
    };
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function sr(t) {
  var e;
  const n = t.auth, s = await t.getIdToken(), r = await Wn(t, xw(n, { idToken: s }));
  C(
    r?.users.length,
    n,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  );
  const i = r.users[0];
  t._notifyReloadListener(i);
  const o = !((e = i.providerUserInfo) === null || e === void 0) && e.length ? Hw(i.providerUserInfo) : [], a = Ww(t.providerData, o), c = t.isAnonymous, u = !(t.email && i.passwordHash) && !a?.length, l = c ? u : !1, h = {
    uid: i.localId,
    displayName: i.displayName || null,
    photoURL: i.photoUrl || null,
    email: i.email || null,
    emailVerified: i.emailVerified || !1,
    phoneNumber: i.phoneNumber || null,
    tenantId: i.tenantId || null,
    providerData: a,
    metadata: new Dh(i.createdAt, i.lastLoginAt),
    isAnonymous: l
  };
  Object.assign(t, h);
}
async function Gw(t) {
  const e = Ee(t);
  await sr(e), await e.auth._persistUserIfCurrent(e), e.auth._notifyListenersIfCurrent(e);
}
function Ww(t, e) {
  return [...t.filter((s) => !e.some((r) => r.providerId === s.providerId)), ...e];
}
function Hw(t) {
  return t.map((e) => {
    var { providerId: n } = e, s = oa(e, ["providerId"]);
    return {
      providerId: n,
      uid: s.rawId || "",
      displayName: s.displayName || null,
      email: s.email || null,
      phoneNumber: s.phoneNumber || null,
      photoURL: s.photoUrl || null
    };
  });
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function zw(t, e) {
  const n = await Oh(t, {}, async () => {
    const s = Kn({
      grant_type: "refresh_token",
      refresh_token: e
    }).slice(1), { tokenApiHost: r, apiKey: i } = t.config, o = kh(t, r, "/v1/token", `key=${i}`), a = await t._getAdditionalHeaders();
    return a[
      "Content-Type"
      /* HttpHeader.CONTENT_TYPE */
    ] = "application/x-www-form-urlencoded", Ch.fetch()(o, {
      method: "POST",
      headers: a,
      body: s
    });
  });
  return {
    accessToken: n.access_token,
    expiresIn: n.expires_in,
    refreshToken: n.refresh_token
  };
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Hn {
  constructor() {
    this.refreshToken = null, this.accessToken = null, this.expirationTime = null;
  }
  get isExpired() {
    return !this.expirationTime || Date.now() > this.expirationTime - 3e4;
  }
  updateFromServerResponse(e) {
    C(
      e.idToken,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), C(
      typeof e.idToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), C(
      typeof e.refreshToken < "u",
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const n = "expiresIn" in e && typeof e.expiresIn < "u" ? Number(e.expiresIn) : qw(e.idToken);
    this.updateTokensAndExpiration(e.idToken, e.refreshToken, n);
  }
  async getToken(e, n = !1) {
    return C(
      !this.accessToken || this.refreshToken,
      e,
      "user-token-expired"
      /* AuthErrorCode.TOKEN_EXPIRED */
    ), !n && this.accessToken && !this.isExpired ? this.accessToken : this.refreshToken ? (await this.refresh(e, this.refreshToken), this.accessToken) : null;
  }
  clearRefreshToken() {
    this.refreshToken = null;
  }
  async refresh(e, n) {
    const { accessToken: s, refreshToken: r, expiresIn: i } = await zw(e, n);
    this.updateTokensAndExpiration(s, r, Number(i));
  }
  updateTokensAndExpiration(e, n, s) {
    this.refreshToken = n || null, this.accessToken = e || null, this.expirationTime = Date.now() + s * 1e3;
  }
  static fromJSON(e, n) {
    const { refreshToken: s, accessToken: r, expirationTime: i } = n, o = new Hn();
    return s && (C(typeof s == "string", "internal-error", {
      appName: e
    }), o.refreshToken = s), r && (C(typeof r == "string", "internal-error", {
      appName: e
    }), o.accessToken = r), i && (C(typeof i == "number", "internal-error", {
      appName: e
    }), o.expirationTime = i), o;
  }
  toJSON() {
    return {
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      expirationTime: this.expirationTime
    };
  }
  _assign(e) {
    this.accessToken = e.accessToken, this.refreshToken = e.refreshToken, this.expirationTime = e.expirationTime;
  }
  _clone() {
    return Object.assign(new Hn(), this.toJSON());
  }
  _performRefresh() {
    return We("not implemented");
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Ze(t, e) {
  C(typeof t == "string" || typeof t > "u", "internal-error", { appName: e });
}
class Ot {
  constructor(e) {
    var { uid: n, auth: s, stsTokenManager: r } = e, i = oa(e, ["uid", "auth", "stsTokenManager"]);
    this.providerId = "firebase", this.proactiveRefresh = new jw(this), this.reloadUserInfo = null, this.reloadListener = null, this.uid = n, this.auth = s, this.stsTokenManager = r, this.accessToken = r.accessToken, this.displayName = i.displayName || null, this.email = i.email || null, this.emailVerified = i.emailVerified || !1, this.phoneNumber = i.phoneNumber || null, this.photoURL = i.photoURL || null, this.isAnonymous = i.isAnonymous || !1, this.tenantId = i.tenantId || null, this.providerData = i.providerData ? [...i.providerData] : [], this.metadata = new Dh(i.createdAt || void 0, i.lastLoginAt || void 0);
  }
  async getIdToken(e) {
    const n = await Wn(this, this.stsTokenManager.getToken(this.auth, e));
    return C(
      n,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.accessToken !== n && (this.accessToken = n, await this.auth._persistUserIfCurrent(this), this.auth._notifyListenersIfCurrent(this)), n;
  }
  getIdTokenResult(e) {
    return Bw(this, e);
  }
  reload() {
    return Gw(this);
  }
  _assign(e) {
    this !== e && (C(
      this.uid === e.uid,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.displayName = e.displayName, this.photoURL = e.photoURL, this.email = e.email, this.emailVerified = e.emailVerified, this.phoneNumber = e.phoneNumber, this.isAnonymous = e.isAnonymous, this.tenantId = e.tenantId, this.providerData = e.providerData.map((n) => Object.assign({}, n)), this.metadata._copy(e.metadata), this.stsTokenManager._assign(e.stsTokenManager));
  }
  _clone(e) {
    return new Ot(Object.assign(Object.assign({}, this), { auth: e, stsTokenManager: this.stsTokenManager._clone() }));
  }
  _onReload(e) {
    C(
      !this.reloadListener,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.reloadListener = e, this.reloadUserInfo && (this._notifyReloadListener(this.reloadUserInfo), this.reloadUserInfo = null);
  }
  _notifyReloadListener(e) {
    this.reloadListener ? this.reloadListener(e) : this.reloadUserInfo = e;
  }
  _startProactiveRefresh() {
    this.proactiveRefresh._start();
  }
  _stopProactiveRefresh() {
    this.proactiveRefresh._stop();
  }
  async _updateTokensIfNecessary(e, n = !1) {
    let s = !1;
    e.idToken && e.idToken !== this.stsTokenManager.accessToken && (this.stsTokenManager.updateFromServerResponse(e), s = !0), n && await sr(this), await this.auth._persistUserIfCurrent(this), s && this.auth._notifyListenersIfCurrent(this);
  }
  async delete() {
    const e = await this.getIdToken();
    return await Wn(this, Uw(this.auth, { idToken: e })), this.stsTokenManager.clearRefreshToken(), this.auth.signOut();
  }
  toJSON() {
    return Object.assign(Object.assign({
      uid: this.uid,
      email: this.email || void 0,
      emailVerified: this.emailVerified,
      displayName: this.displayName || void 0,
      isAnonymous: this.isAnonymous,
      photoURL: this.photoURL || void 0,
      phoneNumber: this.phoneNumber || void 0,
      tenantId: this.tenantId || void 0,
      providerData: this.providerData.map((e) => Object.assign({}, e)),
      stsTokenManager: this.stsTokenManager.toJSON(),
      // Redirect event ID must be maintained in case there is a pending
      // redirect event.
      _redirectEventId: this._redirectEventId
    }, this.metadata.toJSON()), {
      // Required for compatibility with the legacy SDK (go/firebase-auth-sdk-persistence-parsing):
      apiKey: this.auth.config.apiKey,
      appName: this.auth.name
    });
  }
  get refreshToken() {
    return this.stsTokenManager.refreshToken || "";
  }
  static _fromJSON(e, n) {
    var s, r, i, o, a, c, u, l;
    const h = (s = n.displayName) !== null && s !== void 0 ? s : void 0, d = (r = n.email) !== null && r !== void 0 ? r : void 0, p = (i = n.phoneNumber) !== null && i !== void 0 ? i : void 0, m = (o = n.photoURL) !== null && o !== void 0 ? o : void 0, S = (a = n.tenantId) !== null && a !== void 0 ? a : void 0, P = (c = n._redirectEventId) !== null && c !== void 0 ? c : void 0, te = (u = n.createdAt) !== null && u !== void 0 ? u : void 0, Q = (l = n.lastLoginAt) !== null && l !== void 0 ? l : void 0, { uid: De, emailVerified: Ce, isAnonymous: Pe, providerData: Et, stsTokenManager: vt } = n;
    C(
      De && vt,
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const Ve = Hn.fromJSON(this.name, vt);
    C(
      typeof De == "string",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), Ze(h, e.name), Ze(d, e.name), C(
      typeof Ce == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), C(
      typeof Pe == "boolean",
      e,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), Ze(p, e.name), Ze(m, e.name), Ze(S, e.name), Ze(P, e.name), Ze(te, e.name), Ze(Q, e.name);
    const _n = new Ot({
      uid: De,
      auth: e,
      email: d,
      emailVerified: Ce,
      displayName: h,
      isAnonymous: Pe,
      photoURL: m,
      phoneNumber: p,
      tenantId: S,
      stsTokenManager: Ve,
      createdAt: te,
      lastLoginAt: Q
    });
    return Et && Array.isArray(Et) && (_n.providerData = Et.map((Yr) => Object.assign({}, Yr))), P && (_n._redirectEventId = P), _n;
  }
  /**
   * Initialize a User from an idToken server response
   * @param auth
   * @param idTokenResponse
   */
  static async _fromIdTokenResponse(e, n, s = !1) {
    const r = new Hn();
    r.updateFromServerResponse(n);
    const i = new Ot({
      uid: n.localId,
      auth: e,
      stsTokenManager: r,
      isAnonymous: s
    });
    return await sr(i), i;
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Ph {
  constructor() {
    this.type = "NONE", this.storage = {};
  }
  async _isAvailable() {
    return !0;
  }
  async _set(e, n) {
    this.storage[e] = n;
  }
  async _get(e) {
    const n = this.storage[e];
    return n === void 0 ? null : n;
  }
  async _remove(e) {
    delete this.storage[e];
  }
  _addListener(e, n) {
  }
  _removeListener(e, n) {
  }
}
Ph.type = "NONE";
const Tc = Ph;
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Fs(t, e, n) {
  return `firebase:${t}:${e}:${n}`;
}
class Yt {
  constructor(e, n, s) {
    this.persistence = e, this.auth = n, this.userKey = s;
    const { config: r, name: i } = this.auth;
    this.fullUserKey = Fs(this.userKey, r.apiKey, i), this.fullPersistenceKey = Fs("persistence", r.apiKey, i), this.boundEventHandler = n._onStorageEvent.bind(n), this.persistence._addListener(this.fullUserKey, this.boundEventHandler);
  }
  setCurrentUser(e) {
    return this.persistence._set(this.fullUserKey, e.toJSON());
  }
  async getCurrentUser() {
    const e = await this.persistence._get(this.fullUserKey);
    return e ? Ot._fromJSON(this.auth, e) : null;
  }
  removeCurrentUser() {
    return this.persistence._remove(this.fullUserKey);
  }
  savePersistenceForRedirect() {
    return this.persistence._set(this.fullPersistenceKey, this.persistence.type);
  }
  async setPersistence(e) {
    if (this.persistence === e)
      return;
    const n = await this.getCurrentUser();
    if (await this.removeCurrentUser(), this.persistence = e, n)
      return this.setCurrentUser(n);
  }
  delete() {
    this.persistence._removeListener(this.fullUserKey, this.boundEventHandler);
  }
  static async create(e, n, s = "authUser") {
    if (!n.length)
      return new Yt(He(Tc), e, s);
    const r = (await Promise.all(n.map(async (u) => {
      if (await u._isAvailable())
        return u;
    }))).filter((u) => u);
    let i = r[0] || He(Tc);
    const o = Fs(s, e.config.apiKey, e.name);
    let a = null;
    for (const u of n)
      try {
        const l = await u._get(o);
        if (l) {
          const h = Ot._fromJSON(e, l);
          u !== i && (a = h), i = u;
          break;
        }
      } catch {
      }
    const c = r.filter((u) => u._shouldAllowMigration);
    return !i._shouldAllowMigration || !c.length ? new Yt(i, e, s) : (i = c[0], a && await i._set(o, a.toJSON()), await Promise.all(n.map(async (u) => {
      if (u !== i)
        try {
          await u._remove(o);
        } catch {
        }
    })), new Yt(i, e, s));
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Sc(t) {
  const e = t.toLowerCase();
  if (e.includes("opera/") || e.includes("opr/") || e.includes("opios/"))
    return "Opera";
  if (Mh(e))
    return "IEMobile";
  if (e.includes("msie") || e.includes("trident/"))
    return "IE";
  if (e.includes("edge/"))
    return "Edge";
  if (Lh(e))
    return "Firefox";
  if (e.includes("silk/"))
    return "Silk";
  if (Uh(e))
    return "Blackberry";
  if (xh(e))
    return "Webos";
  if (la(e))
    return "Safari";
  if ((e.includes("chrome/") || Fh(e)) && !e.includes("edge/"))
    return "Chrome";
  if ($h(e))
    return "Android";
  {
    const n = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/, s = t.match(n);
    if (s?.length === 2)
      return s[1];
  }
  return "Other";
}
function Lh(t = me()) {
  return /firefox\//i.test(t);
}
function la(t = me()) {
  const e = t.toLowerCase();
  return e.includes("safari/") && !e.includes("chrome/") && !e.includes("crios/") && !e.includes("android");
}
function Fh(t = me()) {
  return /crios\//i.test(t);
}
function Mh(t = me()) {
  return /iemobile/i.test(t);
}
function $h(t = me()) {
  return /android/i.test(t);
}
function Uh(t = me()) {
  return /blackberry/i.test(t);
}
function xh(t = me()) {
  return /webos/i.test(t);
}
function Wr(t = me()) {
  return /iphone|ipad|ipod/i.test(t) || /macintosh/i.test(t) && /mobile/i.test(t);
}
function Qw(t = me()) {
  var e;
  return Wr(t) && !!(!((e = window.navigator) === null || e === void 0) && e.standalone);
}
function Kw() {
  return rp() && document.documentMode === 10;
}
function Bh(t = me()) {
  return Wr(t) || $h(t) || xh(t) || Uh(t) || /windows phone/i.test(t) || Mh(t);
}
function Jw() {
  try {
    return !!(window && window !== window.top);
  } catch {
    return !1;
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function qh(t, e = []) {
  let n;
  switch (t) {
    case "Browser":
      n = Sc(me());
      break;
    case "Worker":
      n = `${Sc(me())}-${t}`;
      break;
    default:
      n = t;
  }
  const s = e.length ? e.join(",") : "FirebaseCore-web";
  return `${n}/JsCore/${Jn}/${s}`;
}
/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Yw {
  constructor(e) {
    this.auth = e, this.queue = [];
  }
  pushCallback(e, n) {
    const s = (i) => new Promise((o, a) => {
      try {
        const c = e(i);
        o(c);
      } catch (c) {
        a(c);
      }
    });
    s.onAbort = n, this.queue.push(s);
    const r = this.queue.length - 1;
    return () => {
      this.queue[r] = () => Promise.resolve();
    };
  }
  async runMiddleware(e) {
    if (this.auth.currentUser === e)
      return;
    const n = [];
    try {
      for (const s of this.queue)
        await s(e), s.onAbort && n.push(s.onAbort);
    } catch (s) {
      n.reverse();
      for (const r of n)
        try {
          r();
        } catch {
        }
      throw this.auth._errorFactory.create("login-blocked", {
        originalMessage: s?.message
      });
    }
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Xw {
  constructor(e, n, s) {
    this.app = e, this.heartbeatServiceProvider = n, this.config = s, this.currentUser = null, this.emulatorConfig = null, this.operations = Promise.resolve(), this.authStateSubscription = new Ic(this), this.idTokenSubscription = new Ic(this), this.beforeStateQueue = new Yw(this), this.redirectUser = null, this.isProactiveRefreshEnabled = !1, this._canInitEmulator = !0, this._isInitialized = !1, this._deleted = !1, this._initializationPromise = null, this._popupRedirectResolver = null, this._errorFactory = Ah, this.lastNotifiedUid = void 0, this.languageCode = null, this.tenantId = null, this.settings = { appVerificationDisabledForTesting: !1 }, this.frameworks = [], this.name = e.name, this.clientVersion = s.sdkClientVersion;
  }
  _initializeWithPersistence(e, n) {
    return n && (this._popupRedirectResolver = He(n)), this._initializationPromise = this.queue(async () => {
      var s, r;
      if (!this._deleted && (this.persistenceManager = await Yt.create(this, e), !this._deleted)) {
        if (!((s = this._popupRedirectResolver) === null || s === void 0) && s._shouldInitProactively)
          try {
            await this._popupRedirectResolver._initialize(this);
          } catch {
          }
        await this.initializeCurrentUser(n), this.lastNotifiedUid = ((r = this.currentUser) === null || r === void 0 ? void 0 : r.uid) || null, !this._deleted && (this._isInitialized = !0);
      }
    }), this._initializationPromise;
  }
  /**
   * If the persistence is changed in another window, the user manager will let us know
   */
  async _onStorageEvent() {
    if (this._deleted)
      return;
    const e = await this.assertedPersistence.getCurrentUser();
    if (!(!this.currentUser && !e)) {
      if (this.currentUser && e && this.currentUser.uid === e.uid) {
        this._currentUser._assign(e), await this.currentUser.getIdToken();
        return;
      }
      await this._updateCurrentUser(
        e,
        /* skipBeforeStateCallbacks */
        !0
      );
    }
  }
  async initializeCurrentUser(e) {
    var n;
    const s = await this.assertedPersistence.getCurrentUser();
    let r = s, i = !1;
    if (e && this.config.authDomain) {
      await this.getOrInitRedirectPersistenceManager();
      const o = (n = this.redirectUser) === null || n === void 0 ? void 0 : n._redirectEventId, a = r?._redirectEventId, c = await this.tryRedirectSignIn(e);
      (!o || o === a) && c?.user && (r = c.user, i = !0);
    }
    if (!r)
      return this.directlySetCurrentUser(null);
    if (!r._redirectEventId) {
      if (i)
        try {
          await this.beforeStateQueue.runMiddleware(r);
        } catch (o) {
          r = s, this._popupRedirectResolver._overrideRedirectResult(this, () => Promise.reject(o));
        }
      return r ? this.reloadAndSetCurrentUserOrClear(r) : this.directlySetCurrentUser(null);
    }
    return C(
      this._popupRedirectResolver,
      this,
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), await this.getOrInitRedirectPersistenceManager(), this.redirectUser && this.redirectUser._redirectEventId === r._redirectEventId ? this.directlySetCurrentUser(r) : this.reloadAndSetCurrentUserOrClear(r);
  }
  async tryRedirectSignIn(e) {
    let n = null;
    try {
      n = await this._popupRedirectResolver._completeRedirectFn(this, e, !0);
    } catch {
      await this._setRedirectUser(null);
    }
    return n;
  }
  async reloadAndSetCurrentUserOrClear(e) {
    try {
      await sr(e);
    } catch (n) {
      if (n?.code !== "auth/network-request-failed")
        return this.directlySetCurrentUser(null);
    }
    return this.directlySetCurrentUser(e);
  }
  useDeviceLanguage() {
    this.languageCode = Lw();
  }
  async _delete() {
    this._deleted = !0;
  }
  async updateCurrentUser(e) {
    const n = e ? Ee(e) : null;
    return n && C(
      n.auth.config.apiKey === this.config.apiKey,
      this,
      "invalid-user-token"
      /* AuthErrorCode.INVALID_AUTH */
    ), this._updateCurrentUser(n && n._clone(this));
  }
  async _updateCurrentUser(e, n = !1) {
    if (!this._deleted)
      return e && C(
        this.tenantId === e.tenantId,
        this,
        "tenant-id-mismatch"
        /* AuthErrorCode.TENANT_ID_MISMATCH */
      ), n || await this.beforeStateQueue.runMiddleware(e), this.queue(async () => {
        await this.directlySetCurrentUser(e), this.notifyAuthListeners();
      });
  }
  async signOut() {
    return await this.beforeStateQueue.runMiddleware(null), (this.redirectPersistenceManager || this._popupRedirectResolver) && await this._setRedirectUser(null), this._updateCurrentUser(
      null,
      /* skipBeforeStateCallbacks */
      !0
    );
  }
  setPersistence(e) {
    return this.queue(async () => {
      await this.assertedPersistence.setPersistence(He(e));
    });
  }
  _getPersistence() {
    return this.assertedPersistence.persistence.type;
  }
  _updateErrorMap(e) {
    this._errorFactory = new Qn("auth", "Firebase", e());
  }
  onAuthStateChanged(e, n, s) {
    return this.registerStateListener(this.authStateSubscription, e, n, s);
  }
  beforeAuthStateChanged(e, n) {
    return this.beforeStateQueue.pushCallback(e, n);
  }
  onIdTokenChanged(e, n, s) {
    return this.registerStateListener(this.idTokenSubscription, e, n, s);
  }
  toJSON() {
    var e;
    return {
      apiKey: this.config.apiKey,
      authDomain: this.config.authDomain,
      appName: this.name,
      currentUser: (e = this._currentUser) === null || e === void 0 ? void 0 : e.toJSON()
    };
  }
  async _setRedirectUser(e, n) {
    const s = await this.getOrInitRedirectPersistenceManager(n);
    return e === null ? s.removeCurrentUser() : s.setCurrentUser(e);
  }
  async getOrInitRedirectPersistenceManager(e) {
    if (!this.redirectPersistenceManager) {
      const n = e && He(e) || this._popupRedirectResolver;
      C(
        n,
        this,
        "argument-error"
        /* AuthErrorCode.ARGUMENT_ERROR */
      ), this.redirectPersistenceManager = await Yt.create(
        this,
        [He(n._redirectPersistence)],
        "redirectUser"
        /* KeyName.REDIRECT_USER */
      ), this.redirectUser = await this.redirectPersistenceManager.getCurrentUser();
    }
    return this.redirectPersistenceManager;
  }
  async _redirectUserForId(e) {
    var n, s;
    return this._isInitialized && await this.queue(async () => {
    }), ((n = this._currentUser) === null || n === void 0 ? void 0 : n._redirectEventId) === e ? this._currentUser : ((s = this.redirectUser) === null || s === void 0 ? void 0 : s._redirectEventId) === e ? this.redirectUser : null;
  }
  async _persistUserIfCurrent(e) {
    if (e === this.currentUser)
      return this.queue(async () => this.directlySetCurrentUser(e));
  }
  /** Notifies listeners only if the user is current */
  _notifyListenersIfCurrent(e) {
    e === this.currentUser && this.notifyAuthListeners();
  }
  _key() {
    return `${this.config.authDomain}:${this.config.apiKey}:${this.name}`;
  }
  _startProactiveRefresh() {
    this.isProactiveRefreshEnabled = !0, this.currentUser && this._currentUser._startProactiveRefresh();
  }
  _stopProactiveRefresh() {
    this.isProactiveRefreshEnabled = !1, this.currentUser && this._currentUser._stopProactiveRefresh();
  }
  /** Returns the current user cast as the internal type */
  get _currentUser() {
    return this.currentUser;
  }
  notifyAuthListeners() {
    var e, n;
    if (!this._isInitialized)
      return;
    this.idTokenSubscription.next(this.currentUser);
    const s = (n = (e = this.currentUser) === null || e === void 0 ? void 0 : e.uid) !== null && n !== void 0 ? n : null;
    this.lastNotifiedUid !== s && (this.lastNotifiedUid = s, this.authStateSubscription.next(this.currentUser));
  }
  registerStateListener(e, n, s, r) {
    if (this._deleted)
      return () => {
      };
    const i = typeof n == "function" ? n : n.next.bind(n), o = this._isInitialized ? Promise.resolve() : this._initializationPromise;
    return C(
      o,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), o.then(() => i(this.currentUser)), typeof n == "function" ? e.addObserver(n, s, r) : e.addObserver(n);
  }
  /**
   * Unprotected (from race conditions) method to set the current user. This
   * should only be called from within a queued callback. This is necessary
   * because the queue shouldn't rely on another queued callback.
   */
  async directlySetCurrentUser(e) {
    this.currentUser && this.currentUser !== e && this._currentUser._stopProactiveRefresh(), e && this.isProactiveRefreshEnabled && e._startProactiveRefresh(), this.currentUser = e, e ? await this.assertedPersistence.setCurrentUser(e) : await this.assertedPersistence.removeCurrentUser();
  }
  queue(e) {
    return this.operations = this.operations.then(e, e), this.operations;
  }
  get assertedPersistence() {
    return C(
      this.persistenceManager,
      this,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.persistenceManager;
  }
  _logFramework(e) {
    !e || this.frameworks.includes(e) || (this.frameworks.push(e), this.frameworks.sort(), this.clientVersion = qh(this.config.clientPlatform, this._getFrameworks()));
  }
  _getFrameworks() {
    return this.frameworks;
  }
  async _getAdditionalHeaders() {
    var e;
    const n = {
      [
        "X-Client-Version"
        /* HttpHeader.X_CLIENT_VERSION */
      ]: this.clientVersion
    };
    this.app.options.appId && (n[
      "X-Firebase-gmpid"
      /* HttpHeader.X_FIREBASE_GMPID */
    ] = this.app.options.appId);
    const s = await ((e = this.heartbeatServiceProvider.getImmediate({
      optional: !0
    })) === null || e === void 0 ? void 0 : e.getHeartbeatsHeader());
    return s && (n[
      "X-Firebase-Client"
      /* HttpHeader.X_FIREBASE_CLIENT */
    ] = s), n;
  }
}
function Hr(t) {
  return Ee(t);
}
class Ic {
  constructor(e) {
    this.auth = e, this.observer = null, this.addObserver = hp((n) => this.observer = n);
  }
  get next() {
    return C(
      this.observer,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), this.observer.next.bind(this.observer);
  }
}
function Zw(t, e, n) {
  const s = Hr(t);
  C(
    s._canInitEmulator,
    s,
    "emulator-config-failed"
    /* AuthErrorCode.EMULATOR_CONFIG_FAILED */
  ), C(
    /^https?:\/\//.test(e),
    s,
    "invalid-emulator-scheme"
    /* AuthErrorCode.INVALID_EMULATOR_SCHEME */
  );
  const r = !!n?.disableWarnings, i = Vh(e), { host: o, port: a } = eE(e), c = a === null ? "" : `:${a}`;
  s.config.emulator = { url: `${i}//${o}${c}/` }, s.settings.appVerificationDisabledForTesting = !0, s.emulatorConfig = Object.freeze({
    host: o,
    port: a,
    protocol: i.replace(":", ""),
    options: Object.freeze({ disableWarnings: r })
  }), r || tE();
}
function Vh(t) {
  const e = t.indexOf(":");
  return e < 0 ? "" : t.substr(0, e + 1);
}
function eE(t) {
  const e = Vh(t), n = /(\/\/)?([^?#/]+)/.exec(t.substr(e.length));
  if (!n)
    return { host: "", port: null };
  const s = n[2].split("@").pop() || "", r = /^(\[[^\]]+\])(:|$)/.exec(s);
  if (r) {
    const i = r[1];
    return { host: i, port: bc(s.substr(i.length + 1)) };
  } else {
    const [i, o] = s.split(":");
    return { host: i, port: bc(o) };
  }
}
function bc(t) {
  if (!t)
    return null;
  const e = Number(t);
  return isNaN(e) ? null : e;
}
function tE() {
  function t() {
    const e = document.createElement("p"), n = e.style;
    e.innerText = "Running in emulator mode. Do not use with production credentials.", n.position = "fixed", n.width = "100%", n.backgroundColor = "#ffffff", n.border = ".1em solid #000000", n.color = "#b50000", n.bottom = "0px", n.left = "0px", n.margin = "0px", n.zIndex = "10000", n.textAlign = "center", e.classList.add("firebase-emulator-warning"), document.body.appendChild(e);
  }
  typeof console < "u" && typeof console.info == "function" && console.info("WARNING: You are using the Auth Emulator, which is intended for local testing only.  Do not use with production credentials."), typeof window < "u" && typeof document < "u" && (document.readyState === "loading" ? window.addEventListener("DOMContentLoaded", t) : t());
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class jh {
  /** @internal */
  constructor(e, n) {
    this.providerId = e, this.signInMethod = n;
  }
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns a JSON-serializable representation of this object.
   */
  toJSON() {
    return We("not implemented");
  }
  /** @internal */
  _getIdTokenResponse(e) {
    return We("not implemented");
  }
  /** @internal */
  _linkToIdToken(e, n) {
    return We("not implemented");
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    return We("not implemented");
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function Xt(t, e) {
  return Rh(t, "POST", "/v1/accounts:signInWithIdp", Nh(t, e));
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const nE = "http://localhost";
class Ft extends jh {
  constructor() {
    super(...arguments), this.pendingToken = null;
  }
  /** @internal */
  static _fromParams(e) {
    const n = new Ft(e.providerId, e.signInMethod);
    return e.idToken || e.accessToken ? (e.idToken && (n.idToken = e.idToken), e.accessToken && (n.accessToken = e.accessToken), e.nonce && !e.pendingToken && (n.nonce = e.nonce), e.pendingToken && (n.pendingToken = e.pendingToken)) : e.oauthToken && e.oauthTokenSecret ? (n.accessToken = e.oauthToken, n.secret = e.oauthTokenSecret) : Je(
      "argument-error"
      /* AuthErrorCode.ARGUMENT_ERROR */
    ), n;
  }
  /** {@inheritdoc AuthCredential.toJSON}  */
  toJSON() {
    return {
      idToken: this.idToken,
      accessToken: this.accessToken,
      secret: this.secret,
      nonce: this.nonce,
      pendingToken: this.pendingToken,
      providerId: this.providerId,
      signInMethod: this.signInMethod
    };
  }
  /**
   * Static method to deserialize a JSON representation of an object into an
   * {@link  AuthCredential}.
   *
   * @param json - Input can be either Object or the stringified representation of the object.
   * When string is provided, JSON.parse would be called first.
   *
   * @returns If the JSON input does not represent an {@link  AuthCredential}, null is returned.
   */
  static fromJSON(e) {
    const n = typeof e == "string" ? JSON.parse(e) : e, { providerId: s, signInMethod: r } = n, i = oa(n, ["providerId", "signInMethod"]);
    if (!s || !r)
      return null;
    const o = new Ft(s, r);
    return o.idToken = i.idToken || void 0, o.accessToken = i.accessToken || void 0, o.secret = i.secret, o.nonce = i.nonce, o.pendingToken = i.pendingToken || null, o;
  }
  /** @internal */
  _getIdTokenResponse(e) {
    const n = this.buildRequest();
    return Xt(e, n);
  }
  /** @internal */
  _linkToIdToken(e, n) {
    const s = this.buildRequest();
    return s.idToken = n, Xt(e, s);
  }
  /** @internal */
  _getReauthenticationResolver(e) {
    const n = this.buildRequest();
    return n.autoCreate = !1, Xt(e, n);
  }
  buildRequest() {
    const e = {
      requestUri: nE,
      returnSecureToken: !0
    };
    if (this.pendingToken)
      e.pendingToken = this.pendingToken;
    else {
      const n = {};
      this.idToken && (n.id_token = this.idToken), this.accessToken && (n.access_token = this.accessToken), this.secret && (n.oauth_token_secret = this.secret), n.providerId = this.providerId, this.nonce && !this.pendingToken && (n.nonce = this.nonce), e.postBody = Kn(n);
    }
    return e;
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Gh {
  /**
   * Constructor for generic OAuth providers.
   *
   * @param providerId - Provider for which credentials should be generated.
   */
  constructor(e) {
    this.providerId = e, this.defaultLanguageCode = null, this.customParameters = {};
  }
  /**
   * Set the language gode.
   *
   * @param languageCode - language code
   */
  setDefaultLanguage(e) {
    this.defaultLanguageCode = e;
  }
  /**
   * Sets the OAuth custom parameters to pass in an OAuth request for popup and redirect sign-in
   * operations.
   *
   * @remarks
   * For a detailed list, check the reserved required OAuth 2.0 parameters such as `client_id`,
   * `redirect_uri`, `scope`, `response_type`, and `state` are not allowed and will be ignored.
   *
   * @param customOAuthParameters - The custom OAuth parameters to pass in the OAuth request.
   */
  setCustomParameters(e) {
    return this.customParameters = e, this;
  }
  /**
   * Retrieve the current list of {@link CustomParameters}.
   */
  getCustomParameters() {
    return this.customParameters;
  }
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class gs extends Gh {
  constructor() {
    super(...arguments), this.scopes = [];
  }
  /**
   * Add an OAuth scope to the credential.
   *
   * @param scope - Provider OAuth scope to add.
   */
  addScope(e) {
    return this.scopes.includes(e) || this.scopes.push(e), this;
  }
  /**
   * Retrieve the current list of OAuth scopes.
   */
  getScopes() {
    return [...this.scopes];
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class et extends gs {
  constructor() {
    super(
      "facebook.com"
      /* ProviderId.FACEBOOK */
    );
  }
  /**
   * Creates a credential for Facebook.
   *
   * @example
   * ```javascript
   * // `event` from the Facebook auth.authResponseChange callback.
   * const credential = FacebookAuthProvider.credential(event.authResponse.accessToken);
   * const result = await signInWithCredential(credential);
   * ```
   *
   * @param accessToken - Facebook access token.
   */
  static credential(e) {
    return Ft._fromParams({
      providerId: et.PROVIDER_ID,
      signInMethod: et.FACEBOOK_SIGN_IN_METHOD,
      accessToken: e
    });
  }
  /**
   * Used to extract the underlying {@link OAuthCredential} from a {@link UserCredential}.
   *
   * @param userCredential - The user credential.
   */
  static credentialFromResult(e) {
    return et.credentialFromTaggedObject(e);
  }
  /**
   * Used to extract the underlying {@link OAuthCredential} from a {@link AuthError} which was
   * thrown during a sign-in, link, or reauthenticate operation.
   *
   * @param userCredential - The user credential.
   */
  static credentialFromError(e) {
    return et.credentialFromTaggedObject(e.customData || {});
  }
  static credentialFromTaggedObject({ _tokenResponse: e }) {
    if (!e || !("oauthAccessToken" in e) || !e.oauthAccessToken)
      return null;
    try {
      return et.credential(e.oauthAccessToken);
    } catch {
      return null;
    }
  }
}
et.FACEBOOK_SIGN_IN_METHOD = "facebook.com";
et.PROVIDER_ID = "facebook.com";
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class tt extends gs {
  constructor() {
    super(
      "google.com"
      /* ProviderId.GOOGLE */
    ), this.addScope("profile");
  }
  /**
   * Creates a credential for Google. At least one of ID token and access token is required.
   *
   * @example
   * ```javascript
   * // \`googleUser\` from the onsuccess Google Sign In callback.
   * const credential = GoogleAuthProvider.credential(googleUser.getAuthResponse().id_token);
   * const result = await signInWithCredential(credential);
   * ```
   *
   * @param idToken - Google ID token.
   * @param accessToken - Google access token.
   */
  static credential(e, n) {
    return Ft._fromParams({
      providerId: tt.PROVIDER_ID,
      signInMethod: tt.GOOGLE_SIGN_IN_METHOD,
      idToken: e,
      accessToken: n
    });
  }
  /**
   * Used to extract the underlying {@link OAuthCredential} from a {@link UserCredential}.
   *
   * @param userCredential - The user credential.
   */
  static credentialFromResult(e) {
    return tt.credentialFromTaggedObject(e);
  }
  /**
   * Used to extract the underlying {@link OAuthCredential} from a {@link AuthError} which was
   * thrown during a sign-in, link, or reauthenticate operation.
   *
   * @param userCredential - The user credential.
   */
  static credentialFromError(e) {
    return tt.credentialFromTaggedObject(e.customData || {});
  }
  static credentialFromTaggedObject({ _tokenResponse: e }) {
    if (!e)
      return null;
    const { oauthIdToken: n, oauthAccessToken: s } = e;
    if (!n && !s)
      return null;
    try {
      return tt.credential(n, s);
    } catch {
      return null;
    }
  }
}
tt.GOOGLE_SIGN_IN_METHOD = "google.com";
tt.PROVIDER_ID = "google.com";
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class nt extends gs {
  constructor() {
    super(
      "github.com"
      /* ProviderId.GITHUB */
    );
  }
  /**
   * Creates a credential for Github.
   *
   * @param accessToken - Github access token.
   */
  static credential(e) {
    return Ft._fromParams({
      providerId: nt.PROVIDER_ID,
      signInMethod: nt.GITHUB_SIGN_IN_METHOD,
      accessToken: e
    });
  }
  /**
   * Used to extract the underlying {@link OAuthCredential} from a {@link UserCredential}.
   *
   * @param userCredential - The user credential.
   */
  static credentialFromResult(e) {
    return nt.credentialFromTaggedObject(e);
  }
  /**
   * Used to extract the underlying {@link OAuthCredential} from a {@link AuthError} which was
   * thrown during a sign-in, link, or reauthenticate operation.
   *
   * @param userCredential - The user credential.
   */
  static credentialFromError(e) {
    return nt.credentialFromTaggedObject(e.customData || {});
  }
  static credentialFromTaggedObject({ _tokenResponse: e }) {
    if (!e || !("oauthAccessToken" in e) || !e.oauthAccessToken)
      return null;
    try {
      return nt.credential(e.oauthAccessToken);
    } catch {
      return null;
    }
  }
}
nt.GITHUB_SIGN_IN_METHOD = "github.com";
nt.PROVIDER_ID = "github.com";
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class st extends gs {
  constructor() {
    super(
      "twitter.com"
      /* ProviderId.TWITTER */
    );
  }
  /**
   * Creates a credential for Twitter.
   *
   * @param token - Twitter access token.
   * @param secret - Twitter secret.
   */
  static credential(e, n) {
    return Ft._fromParams({
      providerId: st.PROVIDER_ID,
      signInMethod: st.TWITTER_SIGN_IN_METHOD,
      oauthToken: e,
      oauthTokenSecret: n
    });
  }
  /**
   * Used to extract the underlying {@link OAuthCredential} from a {@link UserCredential}.
   *
   * @param userCredential - The user credential.
   */
  static credentialFromResult(e) {
    return st.credentialFromTaggedObject(e);
  }
  /**
   * Used to extract the underlying {@link OAuthCredential} from a {@link AuthError} which was
   * thrown during a sign-in, link, or reauthenticate operation.
   *
   * @param userCredential - The user credential.
   */
  static credentialFromError(e) {
    return st.credentialFromTaggedObject(e.customData || {});
  }
  static credentialFromTaggedObject({ _tokenResponse: e }) {
    if (!e)
      return null;
    const { oauthAccessToken: n, oauthTokenSecret: s } = e;
    if (!n || !s)
      return null;
    try {
      return st.credential(n, s);
    } catch {
      return null;
    }
  }
}
st.TWITTER_SIGN_IN_METHOD = "twitter.com";
st.PROVIDER_ID = "twitter.com";
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Mt {
  constructor(e) {
    this.user = e.user, this.providerId = e.providerId, this._tokenResponse = e._tokenResponse, this.operationType = e.operationType;
  }
  static async _fromIdTokenResponse(e, n, s, r = !1) {
    const i = await Ot._fromIdTokenResponse(e, s, r), o = Ac(s);
    return new Mt({
      user: i,
      providerId: o,
      _tokenResponse: s,
      operationType: n
    });
  }
  static async _forOperation(e, n, s) {
    await e._updateTokensIfNecessary(
      s,
      /* reload */
      !0
    );
    const r = Ac(s);
    return new Mt({
      user: e,
      providerId: r,
      _tokenResponse: s,
      operationType: n
    });
  }
}
function Ac(t) {
  return t.providerId ? t.providerId : "phoneNumber" in t ? "phone" : null;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class rr extends Xe {
  constructor(e, n, s, r) {
    var i;
    super(n.code, n.message), this.operationType = s, this.user = r, Object.setPrototypeOf(this, rr.prototype), this.customData = {
      appName: e.name,
      tenantId: (i = e.tenantId) !== null && i !== void 0 ? i : void 0,
      _serverResponse: n.customData._serverResponse,
      operationType: s
    };
  }
  static _fromErrorAndOperation(e, n, s, r) {
    return new rr(e, n, s, r);
  }
}
function Wh(t, e, n, s) {
  return (e === "reauthenticate" ? n._getReauthenticationResolver(t) : n._getIdTokenResponse(t)).catch((i) => {
    throw i.code === "auth/multi-factor-auth-required" ? rr._fromErrorAndOperation(t, i, e, s) : i;
  });
}
async function sE(t, e, n = !1) {
  const s = await Wn(t, e._linkToIdToken(t.auth, await t.getIdToken()), n);
  return Mt._forOperation(t, "link", s);
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function rE(t, e, n = !1) {
  const { auth: s } = t, r = "reauthenticate";
  try {
    const i = await Wn(t, Wh(s, r, e, t), n);
    C(
      i.idToken,
      s,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const o = ua(i.idToken);
    C(
      o,
      s,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    );
    const { sub: a } = o;
    return C(
      t.uid === a,
      s,
      "user-mismatch"
      /* AuthErrorCode.USER_MISMATCH */
    ), Mt._forOperation(t, r, i);
  } catch (i) {
    throw i?.code === "auth/user-not-found" && Je(
      s,
      "user-mismatch"
      /* AuthErrorCode.USER_MISMATCH */
    ), i;
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function iE(t, e, n = !1) {
  const s = "signIn", r = await Wh(t, s, e), i = await Mt._fromIdTokenResponse(t, s, r);
  return n || await t._updateCurrentUser(i.user), i;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function oE(t, e) {
  return Rh(t, "POST", "/v1/accounts:signInWithCustomToken", Nh(t, e));
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function aE(t, e) {
  const n = Hr(t), s = await oE(n, {
    token: e,
    returnSecureToken: !0
  }), r = await Mt._fromIdTokenResponse(n, "signIn", s);
  return await n._updateCurrentUser(r.user), r;
}
function cE(t, e, n, s) {
  return Ee(t).onIdTokenChanged(e, n, s);
}
function uE(t, e, n) {
  return Ee(t).beforeAuthStateChanged(e, n);
}
const ir = "__sak";
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Hh {
  constructor(e, n) {
    this.storageRetriever = e, this.type = n;
  }
  _isAvailable() {
    try {
      return this.storage ? (this.storage.setItem(ir, "1"), this.storage.removeItem(ir), Promise.resolve(!0)) : Promise.resolve(!1);
    } catch {
      return Promise.resolve(!1);
    }
  }
  _set(e, n) {
    return this.storage.setItem(e, JSON.stringify(n)), Promise.resolve();
  }
  _get(e) {
    const n = this.storage.getItem(e);
    return Promise.resolve(n ? JSON.parse(n) : null);
  }
  _remove(e) {
    return this.storage.removeItem(e), Promise.resolve();
  }
  get storage() {
    return this.storageRetriever();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function lE() {
  const t = me();
  return la(t) || Wr(t);
}
const hE = 1e3, dE = 10;
class zh extends Hh {
  constructor() {
    super(
      () => window.localStorage,
      "LOCAL"
      /* PersistenceType.LOCAL */
    ), this.boundEventHandler = (e, n) => this.onStorageEvent(e, n), this.listeners = {}, this.localCache = {}, this.pollTimer = null, this.safariLocalStorageNotSynced = lE() && Jw(), this.fallbackToPolling = Bh(), this._shouldAllowMigration = !0;
  }
  forAllChangedKeys(e) {
    for (const n of Object.keys(this.listeners)) {
      const s = this.storage.getItem(n), r = this.localCache[n];
      s !== r && e(n, r, s);
    }
  }
  onStorageEvent(e, n = !1) {
    if (!e.key) {
      this.forAllChangedKeys((o, a, c) => {
        this.notifyListeners(o, c);
      });
      return;
    }
    const s = e.key;
    if (n ? this.detachListener() : this.stopPolling(), this.safariLocalStorageNotSynced) {
      const o = this.storage.getItem(s);
      if (e.newValue !== o)
        e.newValue !== null ? this.storage.setItem(s, e.newValue) : this.storage.removeItem(s);
      else if (this.localCache[s] === e.newValue && !n)
        return;
    }
    const r = () => {
      const o = this.storage.getItem(s);
      !n && this.localCache[s] === o || this.notifyListeners(s, o);
    }, i = this.storage.getItem(s);
    Kw() && i !== e.newValue && e.newValue !== e.oldValue ? setTimeout(r, dE) : r();
  }
  notifyListeners(e, n) {
    this.localCache[e] = n;
    const s = this.listeners[e];
    if (s)
      for (const r of Array.from(s))
        r(n && JSON.parse(n));
  }
  startPolling() {
    this.stopPolling(), this.pollTimer = setInterval(() => {
      this.forAllChangedKeys((e, n, s) => {
        this.onStorageEvent(
          new StorageEvent("storage", {
            key: e,
            oldValue: n,
            newValue: s
          }),
          /* poll */
          !0
        );
      });
    }, hE);
  }
  stopPolling() {
    this.pollTimer && (clearInterval(this.pollTimer), this.pollTimer = null);
  }
  attachListener() {
    window.addEventListener("storage", this.boundEventHandler);
  }
  detachListener() {
    window.removeEventListener("storage", this.boundEventHandler);
  }
  _addListener(e, n) {
    Object.keys(this.listeners).length === 0 && (this.fallbackToPolling ? this.startPolling() : this.attachListener()), this.listeners[e] || (this.listeners[e] = /* @__PURE__ */ new Set(), this.localCache[e] = this.storage.getItem(e)), this.listeners[e].add(n);
  }
  _removeListener(e, n) {
    this.listeners[e] && (this.listeners[e].delete(n), this.listeners[e].size === 0 && delete this.listeners[e]), Object.keys(this.listeners).length === 0 && (this.detachListener(), this.stopPolling());
  }
  // Update local cache on base operations:
  async _set(e, n) {
    await super._set(e, n), this.localCache[e] = JSON.stringify(n);
  }
  async _get(e) {
    const n = await super._get(e);
    return this.localCache[e] = JSON.stringify(n), n;
  }
  async _remove(e) {
    await super._remove(e), delete this.localCache[e];
  }
}
zh.type = "LOCAL";
const fE = zh;
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Qh extends Hh {
  constructor() {
    super(
      () => window.sessionStorage,
      "SESSION"
      /* PersistenceType.SESSION */
    );
  }
  _addListener(e, n) {
  }
  _removeListener(e, n) {
  }
}
Qh.type = "SESSION";
const Kh = Qh;
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function pE(t) {
  return Promise.all(t.map(async (e) => {
    try {
      return {
        fulfilled: !0,
        value: await e
      };
    } catch (n) {
      return {
        fulfilled: !1,
        reason: n
      };
    }
  }));
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class zr {
  constructor(e) {
    this.eventTarget = e, this.handlersMap = {}, this.boundEventHandler = this.handleEvent.bind(this);
  }
  /**
   * Obtain an instance of a Receiver for a given event target, if none exists it will be created.
   *
   * @param eventTarget - An event target (such as window or self) through which the underlying
   * messages will be received.
   */
  static _getInstance(e) {
    const n = this.receivers.find((r) => r.isListeningto(e));
    if (n)
      return n;
    const s = new zr(e);
    return this.receivers.push(s), s;
  }
  isListeningto(e) {
    return this.eventTarget === e;
  }
  /**
   * Fans out a MessageEvent to the appropriate listeners.
   *
   * @remarks
   * Sends an {@link Status.ACK} upon receipt and a {@link Status.DONE} once all handlers have
   * finished processing.
   *
   * @param event - The MessageEvent.
   *
   */
  async handleEvent(e) {
    const n = e, { eventId: s, eventType: r, data: i } = n.data, o = this.handlersMap[r];
    if (!o?.size)
      return;
    n.ports[0].postMessage({
      status: "ack",
      eventId: s,
      eventType: r
    });
    const a = Array.from(o).map(async (u) => u(n.origin, i)), c = await pE(a);
    n.ports[0].postMessage({
      status: "done",
      eventId: s,
      eventType: r,
      response: c
    });
  }
  /**
   * Subscribe an event handler for a particular event.
   *
   * @param eventType - Event name to subscribe to.
   * @param eventHandler - The event handler which should receive the events.
   *
   */
  _subscribe(e, n) {
    Object.keys(this.handlersMap).length === 0 && this.eventTarget.addEventListener("message", this.boundEventHandler), this.handlersMap[e] || (this.handlersMap[e] = /* @__PURE__ */ new Set()), this.handlersMap[e].add(n);
  }
  /**
   * Unsubscribe an event handler from a particular event.
   *
   * @param eventType - Event name to unsubscribe from.
   * @param eventHandler - Optinoal event handler, if none provided, unsubscribe all handlers on this event.
   *
   */
  _unsubscribe(e, n) {
    this.handlersMap[e] && n && this.handlersMap[e].delete(n), (!n || this.handlersMap[e].size === 0) && delete this.handlersMap[e], Object.keys(this.handlersMap).length === 0 && this.eventTarget.removeEventListener("message", this.boundEventHandler);
  }
}
zr.receivers = [];
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function ha(t = "", e = 10) {
  let n = "";
  for (let s = 0; s < e; s++)
    n += Math.floor(Math.random() * 10);
  return t + n;
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class gE {
  constructor(e) {
    this.target = e, this.handlers = /* @__PURE__ */ new Set();
  }
  /**
   * Unsubscribe the handler and remove it from our tracking Set.
   *
   * @param handler - The handler to unsubscribe.
   */
  removeMessageHandler(e) {
    e.messageChannel && (e.messageChannel.port1.removeEventListener("message", e.onMessage), e.messageChannel.port1.close()), this.handlers.delete(e);
  }
  /**
   * Send a message to the Receiver located at {@link target}.
   *
   * @remarks
   * We'll first wait a bit for an ACK , if we get one we will wait significantly longer until the
   * receiver has had a chance to fully process the event.
   *
   * @param eventType - Type of event to send.
   * @param data - The payload of the event.
   * @param timeout - Timeout for waiting on an ACK from the receiver.
   *
   * @returns An array of settled promises from all the handlers that were listening on the receiver.
   */
  async _send(e, n, s = 50) {
    const r = typeof MessageChannel < "u" ? new MessageChannel() : null;
    if (!r)
      throw new Error(
        "connection_unavailable"
        /* _MessageError.CONNECTION_UNAVAILABLE */
      );
    let i, o;
    return new Promise((a, c) => {
      const u = ha("", 20);
      r.port1.start();
      const l = setTimeout(() => {
        c(new Error(
          "unsupported_event"
          /* _MessageError.UNSUPPORTED_EVENT */
        ));
      }, s);
      o = {
        messageChannel: r,
        onMessage(h) {
          const d = h;
          if (d.data.eventId === u)
            switch (d.data.status) {
              case "ack":
                clearTimeout(l), i = setTimeout(
                  () => {
                    c(new Error(
                      "timeout"
                      /* _MessageError.TIMEOUT */
                    ));
                  },
                  3e3
                  /* _TimeoutDuration.COMPLETION */
                );
                break;
              case "done":
                clearTimeout(i), a(d.data.response);
                break;
              default:
                clearTimeout(l), clearTimeout(i), c(new Error(
                  "invalid_response"
                  /* _MessageError.INVALID_RESPONSE */
                ));
                break;
            }
        }
      }, this.handlers.add(o), r.port1.addEventListener("message", o.onMessage), this.target.postMessage({
        eventType: e,
        eventId: u,
        data: n
      }, [r.port2]);
    }).finally(() => {
      o && this.removeMessageHandler(o);
    });
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function xe() {
  return window;
}
function mE(t) {
  xe().location.href = t;
}
/**
 * @license
 * Copyright 2020 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function Jh() {
  return typeof xe().WorkerGlobalScope < "u" && typeof xe().importScripts == "function";
}
async function yE() {
  if (!navigator?.serviceWorker)
    return null;
  try {
    return (await navigator.serviceWorker.ready).active;
  } catch {
    return null;
  }
}
function _E() {
  var t;
  return ((t = navigator?.serviceWorker) === null || t === void 0 ? void 0 : t.controller) || null;
}
function wE() {
  return Jh() ? self : null;
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Yh = "firebaseLocalStorageDb", EE = 1, or = "firebaseLocalStorage", Xh = "fbase_key";
class ms {
  constructor(e) {
    this.request = e;
  }
  toPromise() {
    return new Promise((e, n) => {
      this.request.addEventListener("success", () => {
        e(this.request.result);
      }), this.request.addEventListener("error", () => {
        n(this.request.error);
      });
    });
  }
}
function Qr(t, e) {
  return t.transaction([or], e ? "readwrite" : "readonly").objectStore(or);
}
function vE() {
  const t = indexedDB.deleteDatabase(Yh);
  return new ms(t).toPromise();
}
function Yi() {
  const t = indexedDB.open(Yh, EE);
  return new Promise((e, n) => {
    t.addEventListener("error", () => {
      n(t.error);
    }), t.addEventListener("upgradeneeded", () => {
      const s = t.result;
      try {
        s.createObjectStore(or, { keyPath: Xh });
      } catch (r) {
        n(r);
      }
    }), t.addEventListener("success", async () => {
      const s = t.result;
      s.objectStoreNames.contains(or) ? e(s) : (s.close(), await vE(), e(await Yi()));
    });
  });
}
async function Cc(t, e, n) {
  const s = Qr(t, !0).put({
    [Xh]: e,
    value: n
  });
  return new ms(s).toPromise();
}
async function TE(t, e) {
  const n = Qr(t, !1).get(e), s = await new ms(n).toPromise();
  return s === void 0 ? null : s.value;
}
function Nc(t, e) {
  const n = Qr(t, !0).delete(e);
  return new ms(n).toPromise();
}
const SE = 800, IE = 3;
class Zh {
  constructor() {
    this.type = "LOCAL", this._shouldAllowMigration = !0, this.listeners = {}, this.localCache = {}, this.pollTimer = null, this.pendingWrites = 0, this.receiver = null, this.sender = null, this.serviceWorkerReceiverAvailable = !1, this.activeServiceWorker = null, this._workerInitializationPromise = this.initializeServiceWorkerMessaging().then(() => {
    }, () => {
    });
  }
  async _openDb() {
    return this.db ? this.db : (this.db = await Yi(), this.db);
  }
  async _withRetries(e) {
    let n = 0;
    for (; ; )
      try {
        const s = await this._openDb();
        return await e(s);
      } catch (s) {
        if (n++ > IE)
          throw s;
        this.db && (this.db.close(), this.db = void 0);
      }
  }
  /**
   * IndexedDB events do not propagate from the main window to the worker context.  We rely on a
   * postMessage interface to send these events to the worker ourselves.
   */
  async initializeServiceWorkerMessaging() {
    return Jh() ? this.initializeReceiver() : this.initializeSender();
  }
  /**
   * As the worker we should listen to events from the main window.
   */
  async initializeReceiver() {
    this.receiver = zr._getInstance(wE()), this.receiver._subscribe("keyChanged", async (e, n) => ({
      keyProcessed: (await this._poll()).includes(n.key)
    })), this.receiver._subscribe("ping", async (e, n) => [
      "keyChanged"
      /* _EventType.KEY_CHANGED */
    ]);
  }
  /**
   * As the main window, we should let the worker know when keys change (set and remove).
   *
   * @remarks
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/ready | ServiceWorkerContainer.ready}
   * may not resolve.
   */
  async initializeSender() {
    var e, n;
    if (this.activeServiceWorker = await yE(), !this.activeServiceWorker)
      return;
    this.sender = new gE(this.activeServiceWorker);
    const s = await this.sender._send(
      "ping",
      {},
      800
      /* _TimeoutDuration.LONG_ACK */
    );
    s && !((e = s[0]) === null || e === void 0) && e.fulfilled && !((n = s[0]) === null || n === void 0) && n.value.includes(
      "keyChanged"
      /* _EventType.KEY_CHANGED */
    ) && (this.serviceWorkerReceiverAvailable = !0);
  }
  /**
   * Let the worker know about a changed key, the exact key doesn't technically matter since the
   * worker will just trigger a full sync anyway.
   *
   * @remarks
   * For now, we only support one service worker per page.
   *
   * @param key - Storage key which changed.
   */
  async notifyServiceWorker(e) {
    if (!(!this.sender || !this.activeServiceWorker || _E() !== this.activeServiceWorker))
      try {
        await this.sender._send(
          "keyChanged",
          { key: e },
          // Use long timeout if receiver has previously responded to a ping from us.
          this.serviceWorkerReceiverAvailable ? 800 : 50
          /* _TimeoutDuration.ACK */
        );
      } catch {
      }
  }
  async _isAvailable() {
    try {
      if (!indexedDB)
        return !1;
      const e = await Yi();
      return await Cc(e, ir, "1"), await Nc(e, ir), !0;
    } catch {
    }
    return !1;
  }
  async _withPendingWrite(e) {
    this.pendingWrites++;
    try {
      await e();
    } finally {
      this.pendingWrites--;
    }
  }
  async _set(e, n) {
    return this._withPendingWrite(async () => (await this._withRetries((s) => Cc(s, e, n)), this.localCache[e] = n, this.notifyServiceWorker(e)));
  }
  async _get(e) {
    const n = await this._withRetries((s) => TE(s, e));
    return this.localCache[e] = n, n;
  }
  async _remove(e) {
    return this._withPendingWrite(async () => (await this._withRetries((n) => Nc(n, e)), delete this.localCache[e], this.notifyServiceWorker(e)));
  }
  async _poll() {
    const e = await this._withRetries((r) => {
      const i = Qr(r, !1).getAll();
      return new ms(i).toPromise();
    });
    if (!e)
      return [];
    if (this.pendingWrites !== 0)
      return [];
    const n = [], s = /* @__PURE__ */ new Set();
    for (const { fbase_key: r, value: i } of e)
      s.add(r), JSON.stringify(this.localCache[r]) !== JSON.stringify(i) && (this.notifyListeners(r, i), n.push(r));
    for (const r of Object.keys(this.localCache))
      this.localCache[r] && !s.has(r) && (this.notifyListeners(r, null), n.push(r));
    return n;
  }
  notifyListeners(e, n) {
    this.localCache[e] = n;
    const s = this.listeners[e];
    if (s)
      for (const r of Array.from(s))
        r(n);
  }
  startPolling() {
    this.stopPolling(), this.pollTimer = setInterval(async () => this._poll(), SE);
  }
  stopPolling() {
    this.pollTimer && (clearInterval(this.pollTimer), this.pollTimer = null);
  }
  _addListener(e, n) {
    Object.keys(this.listeners).length === 0 && this.startPolling(), this.listeners[e] || (this.listeners[e] = /* @__PURE__ */ new Set(), this._get(e)), this.listeners[e].add(n);
  }
  _removeListener(e, n) {
    this.listeners[e] && (this.listeners[e].delete(n), this.listeners[e].size === 0 && delete this.listeners[e]), Object.keys(this.listeners).length === 0 && this.stopPolling();
  }
}
Zh.type = "LOCAL";
const bE = Zh;
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function AE() {
  var t, e;
  return (e = (t = document.getElementsByTagName("head")) === null || t === void 0 ? void 0 : t[0]) !== null && e !== void 0 ? e : document;
}
function CE(t) {
  return new Promise((e, n) => {
    const s = document.createElement("script");
    s.setAttribute("src", t), s.onload = e, s.onerror = (r) => {
      const i = Ue(
        "internal-error"
        /* AuthErrorCode.INTERNAL_ERROR */
      );
      i.customData = r, n(i);
    }, s.type = "text/javascript", s.charset = "UTF-8", AE().appendChild(s);
  });
}
function NE(t) {
  return `__${t}${Math.floor(Math.random() * 1e6)}`;
}
new ps(3e4, 6e4);
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function OE(t, e) {
  return e ? He(e) : (C(
    t._popupRedirectResolver,
    t,
    "argument-error"
    /* AuthErrorCode.ARGUMENT_ERROR */
  ), t._popupRedirectResolver);
}
/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class da extends jh {
  constructor(e) {
    super(
      "custom",
      "custom"
      /* ProviderId.CUSTOM */
    ), this.params = e;
  }
  _getIdTokenResponse(e) {
    return Xt(e, this._buildIdpRequest());
  }
  _linkToIdToken(e, n) {
    return Xt(e, this._buildIdpRequest(n));
  }
  _getReauthenticationResolver(e) {
    return Xt(e, this._buildIdpRequest());
  }
  _buildIdpRequest(e) {
    const n = {
      requestUri: this.params.requestUri,
      sessionId: this.params.sessionId,
      postBody: this.params.postBody,
      tenantId: this.params.tenantId,
      pendingToken: this.params.pendingToken,
      returnSecureToken: !0,
      returnIdpCredential: !0
    };
    return e && (n.idToken = e), n;
  }
}
function RE(t) {
  return iE(t.auth, new da(t), t.bypassAuthState);
}
function kE(t) {
  const { auth: e, user: n } = t;
  return C(
    n,
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), rE(n, new da(t), t.bypassAuthState);
}
async function DE(t) {
  const { auth: e, user: n } = t;
  return C(
    n,
    e,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), sE(n, new da(t), t.bypassAuthState);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class ed {
  constructor(e, n, s, r, i = !1) {
    this.auth = e, this.resolver = s, this.user = r, this.bypassAuthState = i, this.pendingPromise = null, this.eventManager = null, this.filter = Array.isArray(n) ? n : [n];
  }
  execute() {
    return new Promise(async (e, n) => {
      this.pendingPromise = { resolve: e, reject: n };
      try {
        this.eventManager = await this.resolver._initialize(this.auth), await this.onExecution(), this.eventManager.registerConsumer(this);
      } catch (s) {
        this.reject(s);
      }
    });
  }
  async onAuthEvent(e) {
    const { urlResponse: n, sessionId: s, postBody: r, tenantId: i, error: o, type: a } = e;
    if (o) {
      this.reject(o);
      return;
    }
    const c = {
      auth: this.auth,
      requestUri: n,
      sessionId: s,
      tenantId: i || void 0,
      postBody: r || void 0,
      user: this.user,
      bypassAuthState: this.bypassAuthState
    };
    try {
      this.resolve(await this.getIdpTask(a)(c));
    } catch (u) {
      this.reject(u);
    }
  }
  onError(e) {
    this.reject(e);
  }
  getIdpTask(e) {
    switch (e) {
      case "signInViaPopup":
      case "signInViaRedirect":
        return RE;
      case "linkViaPopup":
      case "linkViaRedirect":
        return DE;
      case "reauthViaPopup":
      case "reauthViaRedirect":
        return kE;
      default:
        Je(
          this.auth,
          "internal-error"
          /* AuthErrorCode.INTERNAL_ERROR */
        );
    }
  }
  resolve(e) {
    Ye(this.pendingPromise, "Pending promise was never set"), this.pendingPromise.resolve(e), this.unregisterAndCleanUp();
  }
  reject(e) {
    Ye(this.pendingPromise, "Pending promise was never set"), this.pendingPromise.reject(e), this.unregisterAndCleanUp();
  }
  unregisterAndCleanUp() {
    this.eventManager && this.eventManager.unregisterConsumer(this), this.pendingPromise = null, this.cleanUp();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const PE = new ps(2e3, 1e4);
class Ht extends ed {
  constructor(e, n, s, r, i) {
    super(e, n, r, i), this.provider = s, this.authWindow = null, this.pollId = null, Ht.currentPopupAction && Ht.currentPopupAction.cancel(), Ht.currentPopupAction = this;
  }
  async executeNotNull() {
    const e = await this.execute();
    return C(
      e,
      this.auth,
      "internal-error"
      /* AuthErrorCode.INTERNAL_ERROR */
    ), e;
  }
  async onExecution() {
    Ye(this.filter.length === 1, "Popup operations only handle one event");
    const e = ha();
    this.authWindow = await this.resolver._openPopup(
      this.auth,
      this.provider,
      this.filter[0],
      // There's always one, see constructor
      e
    ), this.authWindow.associatedEvent = e, this.resolver._originValidation(this.auth).catch((n) => {
      this.reject(n);
    }), this.resolver._isIframeWebStorageSupported(this.auth, (n) => {
      n || this.reject(Ue(
        this.auth,
        "web-storage-unsupported"
        /* AuthErrorCode.WEB_STORAGE_UNSUPPORTED */
      ));
    }), this.pollUserCancellation();
  }
  get eventId() {
    var e;
    return ((e = this.authWindow) === null || e === void 0 ? void 0 : e.associatedEvent) || null;
  }
  cancel() {
    this.reject(Ue(
      this.auth,
      "cancelled-popup-request"
      /* AuthErrorCode.EXPIRED_POPUP_REQUEST */
    ));
  }
  cleanUp() {
    this.authWindow && this.authWindow.close(), this.pollId && window.clearTimeout(this.pollId), this.authWindow = null, this.pollId = null, Ht.currentPopupAction = null;
  }
  pollUserCancellation() {
    const e = () => {
      var n, s;
      if (!((s = (n = this.authWindow) === null || n === void 0 ? void 0 : n.window) === null || s === void 0) && s.closed) {
        this.pollId = window.setTimeout(
          () => {
            this.pollId = null, this.reject(Ue(
              this.auth,
              "popup-closed-by-user"
              /* AuthErrorCode.POPUP_CLOSED_BY_USER */
            ));
          },
          2e3
          /* _Timeout.AUTH_EVENT */
        );
        return;
      }
      this.pollId = window.setTimeout(e, PE.get());
    };
    e();
  }
}
Ht.currentPopupAction = null;
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const LE = "pendingRedirect", Ms = /* @__PURE__ */ new Map();
class FE extends ed {
  constructor(e, n, s = !1) {
    super(e, [
      "signInViaRedirect",
      "linkViaRedirect",
      "reauthViaRedirect",
      "unknown"
      /* AuthEventType.UNKNOWN */
    ], n, void 0, s), this.eventId = null;
  }
  /**
   * Override the execute function; if we already have a redirect result, then
   * just return it.
   */
  async execute() {
    let e = Ms.get(this.auth._key());
    if (!e) {
      try {
        const s = await ME(this.resolver, this.auth) ? await super.execute() : null;
        e = () => Promise.resolve(s);
      } catch (n) {
        e = () => Promise.reject(n);
      }
      Ms.set(this.auth._key(), e);
    }
    return this.bypassAuthState || Ms.set(this.auth._key(), () => Promise.resolve(null)), e();
  }
  async onAuthEvent(e) {
    if (e.type === "signInViaRedirect")
      return super.onAuthEvent(e);
    if (e.type === "unknown") {
      this.resolve(null);
      return;
    }
    if (e.eventId) {
      const n = await this.auth._redirectUserForId(e.eventId);
      if (n)
        return this.user = n, super.onAuthEvent(e);
      this.resolve(null);
    }
  }
  async onExecution() {
  }
  cleanUp() {
  }
}
async function ME(t, e) {
  const n = xE(e), s = UE(t);
  if (!await s._isAvailable())
    return !1;
  const r = await s._get(n) === "true";
  return await s._remove(n), r;
}
function $E(t, e) {
  Ms.set(t._key(), e);
}
function UE(t) {
  return He(t._redirectPersistence);
}
function xE(t) {
  return Fs(LE, t.config.apiKey, t.name);
}
async function BE(t, e, n = !1) {
  const s = Hr(t), r = OE(s, e), o = await new FE(s, r, n).execute();
  return o && !n && (delete o.user._redirectEventId, await s._persistUserIfCurrent(o.user), await s._setRedirectUser(null, e)), o;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const qE = 10 * 60 * 1e3;
class VE {
  constructor(e) {
    this.auth = e, this.cachedEventUids = /* @__PURE__ */ new Set(), this.consumers = /* @__PURE__ */ new Set(), this.queuedRedirectEvent = null, this.hasHandledPotentialRedirect = !1, this.lastProcessedEventTime = Date.now();
  }
  registerConsumer(e) {
    this.consumers.add(e), this.queuedRedirectEvent && this.isEventForConsumer(this.queuedRedirectEvent, e) && (this.sendToConsumer(this.queuedRedirectEvent, e), this.saveEventToCache(this.queuedRedirectEvent), this.queuedRedirectEvent = null);
  }
  unregisterConsumer(e) {
    this.consumers.delete(e);
  }
  onEvent(e) {
    if (this.hasEventBeenHandled(e))
      return !1;
    let n = !1;
    return this.consumers.forEach((s) => {
      this.isEventForConsumer(e, s) && (n = !0, this.sendToConsumer(e, s), this.saveEventToCache(e));
    }), this.hasHandledPotentialRedirect || !jE(e) || (this.hasHandledPotentialRedirect = !0, n || (this.queuedRedirectEvent = e, n = !0)), n;
  }
  sendToConsumer(e, n) {
    var s;
    if (e.error && !td(e)) {
      const r = ((s = e.error.code) === null || s === void 0 ? void 0 : s.split("auth/")[1]) || "internal-error";
      n.onError(Ue(this.auth, r));
    } else
      n.onAuthEvent(e);
  }
  isEventForConsumer(e, n) {
    const s = n.eventId === null || !!e.eventId && e.eventId === n.eventId;
    return n.filter.includes(e.type) && s;
  }
  hasEventBeenHandled(e) {
    return Date.now() - this.lastProcessedEventTime >= qE && this.cachedEventUids.clear(), this.cachedEventUids.has(Oc(e));
  }
  saveEventToCache(e) {
    this.cachedEventUids.add(Oc(e)), this.lastProcessedEventTime = Date.now();
  }
}
function Oc(t) {
  return [t.type, t.eventId, t.sessionId, t.tenantId].filter((e) => e).join("-");
}
function td({ type: t, error: e }) {
  return t === "unknown" && e?.code === "auth/no-auth-event";
}
function jE(t) {
  switch (t.type) {
    case "signInViaRedirect":
    case "linkViaRedirect":
    case "reauthViaRedirect":
      return !0;
    case "unknown":
      return td(t);
    default:
      return !1;
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
async function GE(t, e = {}) {
  return Gr(t, "GET", "/v1/projects", e);
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const WE = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/, HE = /^https?/;
async function zE(t) {
  if (t.config.emulator)
    return;
  const { authorizedDomains: e } = await GE(t);
  for (const n of e)
    try {
      if (QE(n))
        return;
    } catch {
    }
  Je(
    t,
    "unauthorized-domain"
    /* AuthErrorCode.INVALID_ORIGIN */
  );
}
function QE(t) {
  const e = Ji(), { protocol: n, hostname: s } = new URL(e);
  if (t.startsWith("chrome-extension://")) {
    const o = new URL(t);
    return o.hostname === "" && s === "" ? n === "chrome-extension:" && t.replace("chrome-extension://", "") === e.replace("chrome-extension://", "") : n === "chrome-extension:" && o.hostname === s;
  }
  if (!HE.test(n))
    return !1;
  if (WE.test(t))
    return s === t;
  const r = t.replace(/\./g, "\\.");
  return new RegExp("^(.+\\." + r + "|" + r + ")$", "i").test(s);
}
/**
 * @license
 * Copyright 2020 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const KE = new ps(3e4, 6e4);
function Rc() {
  const t = xe().___jsl;
  if (t?.H) {
    for (const e of Object.keys(t.H))
      if (t.H[e].r = t.H[e].r || [], t.H[e].L = t.H[e].L || [], t.H[e].r = [...t.H[e].L], t.CP)
        for (let n = 0; n < t.CP.length; n++)
          t.CP[n] = null;
  }
}
function JE(t) {
  return new Promise((e, n) => {
    var s, r, i;
    function o() {
      Rc(), gapi.load("gapi.iframes", {
        callback: () => {
          e(gapi.iframes.getContext());
        },
        ontimeout: () => {
          Rc(), n(Ue(
            t,
            "network-request-failed"
            /* AuthErrorCode.NETWORK_REQUEST_FAILED */
          ));
        },
        timeout: KE.get()
      });
    }
    if (!((r = (s = xe().gapi) === null || s === void 0 ? void 0 : s.iframes) === null || r === void 0) && r.Iframe)
      e(gapi.iframes.getContext());
    else if (!((i = xe().gapi) === null || i === void 0) && i.load)
      o();
    else {
      const a = NE("iframefcb");
      return xe()[a] = () => {
        gapi.load ? o() : n(Ue(
          t,
          "network-request-failed"
          /* AuthErrorCode.NETWORK_REQUEST_FAILED */
        ));
      }, CE(`https://apis.google.com/js/api.js?onload=${a}`).catch((c) => n(c));
    }
  }).catch((e) => {
    throw $s = null, e;
  });
}
let $s = null;
function YE(t) {
  return $s = $s || JE(t), $s;
}
/**
 * @license
 * Copyright 2020 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const XE = new ps(5e3, 15e3), ZE = "__/auth/iframe", ev = "emulator/auth/iframe", tv = {
  style: {
    position: "absolute",
    top: "-100px",
    width: "1px",
    height: "1px"
  },
  "aria-hidden": "true",
  tabindex: "-1"
}, nv = /* @__PURE__ */ new Map([
  ["identitytoolkit.googleapis.com", "p"],
  ["staging-identitytoolkit.sandbox.googleapis.com", "s"],
  ["test-identitytoolkit.sandbox.googleapis.com", "t"]
  // test
]);
function sv(t) {
  const e = t.config;
  C(
    e.authDomain,
    t,
    "auth-domain-config-required"
    /* AuthErrorCode.MISSING_AUTH_DOMAIN */
  );
  const n = e.emulator ? ca(e, ev) : `https://${t.config.authDomain}/${ZE}`, s = {
    apiKey: e.apiKey,
    appName: t.name,
    v: Jn
  }, r = nv.get(t.config.apiHost);
  r && (s.eid = r);
  const i = t._getFrameworks();
  return i.length && (s.fw = i.join(",")), `${n}?${Kn(s).slice(1)}`;
}
async function rv(t) {
  const e = await YE(t), n = xe().gapi;
  return C(
    n,
    t,
    "internal-error"
    /* AuthErrorCode.INTERNAL_ERROR */
  ), e.open({
    where: document.body,
    url: sv(t),
    messageHandlersFilter: n.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
    attributes: tv,
    dontclear: !0
  }, (s) => new Promise(async (r, i) => {
    await s.restyle({
      // Prevent iframe from closing on mouse out.
      setHideOnLeave: !1
    });
    const o = Ue(
      t,
      "network-request-failed"
      /* AuthErrorCode.NETWORK_REQUEST_FAILED */
    ), a = xe().setTimeout(() => {
      i(o);
    }, XE.get());
    function c() {
      xe().clearTimeout(a), r(s);
    }
    s.ping(c).then(c, () => {
      i(o);
    });
  }));
}
/**
 * @license
 * Copyright 2020 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const iv = {
  location: "yes",
  resizable: "yes",
  statusbar: "yes",
  toolbar: "no"
}, ov = 500, av = 600, cv = "_blank", uv = "http://localhost";
class kc {
  constructor(e) {
    this.window = e, this.associatedEvent = null;
  }
  close() {
    if (this.window)
      try {
        this.window.close();
      } catch {
      }
  }
}
function lv(t, e, n, s = ov, r = av) {
  const i = Math.max((window.screen.availHeight - r) / 2, 0).toString(), o = Math.max((window.screen.availWidth - s) / 2, 0).toString();
  let a = "";
  const c = Object.assign(Object.assign({}, iv), {
    width: s.toString(),
    height: r.toString(),
    top: i,
    left: o
  }), u = me().toLowerCase();
  n && (a = Fh(u) ? cv : n), Lh(u) && (e = e || uv, c.scrollbars = "yes");
  const l = Object.entries(c).reduce((d, [p, m]) => `${d}${p}=${m},`, "");
  if (Qw(u) && a !== "_self")
    return hv(e || "", a), new kc(null);
  const h = window.open(e || "", a, l);
  C(
    h,
    t,
    "popup-blocked"
    /* AuthErrorCode.POPUP_BLOCKED */
  );
  try {
    h.focus();
  } catch {
  }
  return new kc(h);
}
function hv(t, e) {
  const n = document.createElement("a");
  n.href = t, n.target = e;
  const s = document.createEvent("MouseEvent");
  s.initMouseEvent("click", !0, !0, window, 1, 0, 0, 0, 0, !1, !1, !1, !1, 1, null), n.dispatchEvent(s);
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const dv = "__/auth/handler", fv = "emulator/auth/handler";
function Dc(t, e, n, s, r, i) {
  C(
    t.config.authDomain,
    t,
    "auth-domain-config-required"
    /* AuthErrorCode.MISSING_AUTH_DOMAIN */
  ), C(
    t.config.apiKey,
    t,
    "invalid-api-key"
    /* AuthErrorCode.INVALID_API_KEY */
  );
  const o = {
    apiKey: t.config.apiKey,
    appName: t.name,
    authType: n,
    redirectUrl: s,
    v: Jn,
    eventId: r
  };
  if (e instanceof Gh) {
    e.setDefaultLanguage(t.languageCode), o.providerId = e.providerId || "", lp(e.getCustomParameters()) || (o.customParameters = JSON.stringify(e.getCustomParameters()));
    for (const [c, u] of Object.entries(i || {}))
      o[c] = u;
  }
  if (e instanceof gs) {
    const c = e.getScopes().filter((u) => u !== "");
    c.length > 0 && (o.scopes = c.join(","));
  }
  t.tenantId && (o.tid = t.tenantId);
  const a = o;
  for (const c of Object.keys(a))
    a[c] === void 0 && delete a[c];
  return `${pv(t)}?${Kn(a).slice(1)}`;
}
function pv({ config: t }) {
  return t.emulator ? ca(t, fv) : `https://${t.authDomain}/${dv}`;
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const yi = "webStorageSupport";
class gv {
  constructor() {
    this.eventManagers = {}, this.iframes = {}, this.originValidationPromises = {}, this._redirectPersistence = Kh, this._completeRedirectFn = BE, this._overrideRedirectResult = $E;
  }
  // Wrapping in async even though we don't await anywhere in order
  // to make sure errors are raised as promise rejections
  async _openPopup(e, n, s, r) {
    var i;
    Ye((i = this.eventManagers[e._key()]) === null || i === void 0 ? void 0 : i.manager, "_initialize() not called before _openPopup()");
    const o = Dc(e, n, s, Ji(), r);
    return lv(e, o, ha());
  }
  async _openRedirect(e, n, s, r) {
    return await this._originValidation(e), mE(Dc(e, n, s, Ji(), r)), new Promise(() => {
    });
  }
  _initialize(e) {
    const n = e._key();
    if (this.eventManagers[n]) {
      const { manager: r, promise: i } = this.eventManagers[n];
      return r ? Promise.resolve(r) : (Ye(i, "If manager is not set, promise should be"), i);
    }
    const s = this.initAndGetManager(e);
    return this.eventManagers[n] = { promise: s }, s.catch(() => {
      delete this.eventManagers[n];
    }), s;
  }
  async initAndGetManager(e) {
    const n = await rv(e), s = new VE(e);
    return n.register("authEvent", (r) => (C(
      r?.authEvent,
      e,
      "invalid-auth-event"
      /* AuthErrorCode.INVALID_AUTH_EVENT */
    ), {
      status: s.onEvent(r.authEvent) ? "ACK" : "ERROR"
      /* GapiOutcome.ERROR */
    }), gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER), this.eventManagers[e._key()] = { manager: s }, this.iframes[e._key()] = n, s;
  }
  _isIframeWebStorageSupported(e, n) {
    this.iframes[e._key()].send(yi, { type: yi }, (r) => {
      var i;
      const o = (i = r?.[0]) === null || i === void 0 ? void 0 : i[yi];
      o !== void 0 && n(!!o), Je(
        e,
        "internal-error"
        /* AuthErrorCode.INTERNAL_ERROR */
      );
    }, gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER);
  }
  _originValidation(e) {
    const n = e._key();
    return this.originValidationPromises[n] || (this.originValidationPromises[n] = zE(e)), this.originValidationPromises[n];
  }
  get _shouldInitProactively() {
    return Bh() || la() || Wr();
  }
}
const mv = gv;
var Pc = "@firebase/auth", Lc = "0.21.3";
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class yv {
  constructor(e) {
    this.auth = e, this.internalListeners = /* @__PURE__ */ new Map();
  }
  getUid() {
    var e;
    return this.assertAuthConfigured(), ((e = this.auth.currentUser) === null || e === void 0 ? void 0 : e.uid) || null;
  }
  async getToken(e) {
    return this.assertAuthConfigured(), await this.auth._initializationPromise, this.auth.currentUser ? { accessToken: await this.auth.currentUser.getIdToken(e) } : null;
  }
  addAuthTokenListener(e) {
    if (this.assertAuthConfigured(), this.internalListeners.has(e))
      return;
    const n = this.auth.onIdTokenChanged((s) => {
      e(s?.stsTokenManager.accessToken || null);
    });
    this.internalListeners.set(e, n), this.updateProactiveRefresh();
  }
  removeAuthTokenListener(e) {
    this.assertAuthConfigured();
    const n = this.internalListeners.get(e);
    n && (this.internalListeners.delete(e), n(), this.updateProactiveRefresh());
  }
  assertAuthConfigured() {
    C(
      this.auth._initializationPromise,
      "dependent-sdk-initialized-before-auth"
      /* AuthErrorCode.DEPENDENT_SDK_INIT_BEFORE_AUTH */
    );
  }
  updateProactiveRefresh() {
    this.internalListeners.size > 0 ? this.auth._startProactiveRefresh() : this.auth._stopProactiveRefresh();
  }
}
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function _v(t) {
  switch (t) {
    case "Node":
      return "node";
    case "ReactNative":
      return "rn";
    case "Worker":
      return "webworker";
    case "Cordova":
      return "cordova";
    default:
      return;
  }
}
function wv(t) {
  en(new kt(
    "auth",
    (e, { options: n }) => {
      const s = e.getProvider("app").getImmediate(), r = e.getProvider("heartbeat"), { apiKey: i, authDomain: o } = s.options;
      return ((a, c) => {
        C(i && !i.includes(":"), "invalid-api-key", { appName: a.name }), C(!o?.includes(":"), "argument-error", {
          appName: a.name
        });
        const u = {
          apiKey: i,
          authDomain: o,
          clientPlatform: t,
          apiHost: "identitytoolkit.googleapis.com",
          tokenApiHost: "securetoken.googleapis.com",
          apiScheme: "https",
          sdkClientVersion: qh(t)
        }, l = new Xw(a, c, u);
        return kw(l, n), l;
      })(s, r);
    },
    "PUBLIC"
    /* ComponentType.PUBLIC */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  ).setInstanceCreatedCallback((e, n, s) => {
    e.getProvider(
      "auth-internal"
      /* _ComponentName.AUTH_INTERNAL */
    ).initialize();
  })), en(new kt(
    "auth-internal",
    (e) => {
      const n = Hr(e.getProvider(
        "auth"
        /* _ComponentName.AUTH */
      ).getImmediate());
      return ((s) => new yv(s))(n);
    },
    "PRIVATE"
    /* ComponentType.PRIVATE */
  ).setInstantiationMode(
    "EXPLICIT"
    /* InstantiationMode.EXPLICIT */
  )), at(Pc, Lc, _v(t)), at(Pc, Lc, "esm2017");
}
/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const Ev = 5 * 60, vv = Zc("authIdTokenMaxAge") || Ev;
let Fc = null;
const Tv = (t) => async (e) => {
  const n = e && await e.getIdTokenResult(), s = n && (new Date().getTime() - Date.parse(n.issuedAtTime)) / 1e3;
  if (s && s > vv)
    return;
  const r = n?.token;
  Fc !== r && (Fc = r, await fetch(t, {
    method: r ? "POST" : "DELETE",
    headers: r ? {
      Authorization: `Bearer ${r}`
    } : {}
  }));
};
function Sv(t = lr()) {
  const e = ur(t, "auth");
  if (e.isInitialized())
    return e.getImmediate();
  const n = Rw(t, {
    popupRedirectResolver: mv,
    persistence: [
      bE,
      fE,
      Kh
    ]
  }), s = Zc("authTokenSyncURL");
  if (s) {
    const i = Tv(s);
    uE(n, i, () => i(n.currentUser)), cE(n, (o) => i(o));
  }
  const r = Xc("auth");
  return r && Zw(n, `http://${r}`), n;
}
wv(
  "Browser"
  /* ClientPlatform.BROWSER */
);
var Iv = "firebase", bv = "9.17.1";
/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
at(Iv, bv, "app");
async function Av(t) {
  return new Promise((e) => setTimeout(e, t));
}
async function Cv(t, e, n) {
  const s = `Bearer ${n}`;
  try {
    const r = ce(
      t,
      e,
      re.FirebaseToken
    );
    if (!r)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for getting firebase token. Check metadata file 🔴🔴🔴"
      );
    const a = await (await fetch(r, {
      headers: { Authorization: s }
    })).json();
    if (!a || !a.firebase_token)
      throw new Error("No token returned");
    return a.firebase_token;
  } catch (r) {
    throw new Error(`Cloud function error ${q(r)}`);
  }
}
function Nv(t) {
  const e = Mc(t);
  return {
    expiration: e.exp,
    userId: e.uid,
    userAccountId: e.claims.user_account_id
  };
}
function Ov(t) {
  if (t === void 0 || t.length < 15)
    return !1;
  const { expiration: e } = Nv(t), n = new Date().getTime() / 1e3;
  return e > n;
}
async function Rv(t, e, n) {
  let i = "", o = 0;
  for (; o < 5; )
    try {
      return i = await Cv(t, e, n), i;
    } catch {
      o++, console.warn(
        `Had to retry calling cloud function for retreiving afirebase token. Attempt ${o} of ${5}. Waitting ${300}ms before retrying.`
      ), await Av(300);
    }
  throw new Error(
    `🔴🔴🔴 Not able to retrieve token for firebase after ${o} attemps🔴🔴🔴`
  );
}
function nd(t) {
  const e = localStorage.getItem(qc(t));
  if (e !== null)
    return e;
}
function kv(t, e) {
  localStorage.setItem(qc(t), e);
}
function Dv(t) {
  const e = nd(t);
  return e === void 0 ? !1 : Ov(e);
}
function Pv(t, e) {
  const n = Kd(t, e), s = nu(n);
  rw(s, { experimentalForceLongPolling: !0 });
}
async function sd(t, e, n) {
  let s = "";
  return Dv(t) ? s = nd(t) : (s = await Rv(t, e, n), kv(t, s)), s;
}
async function Lv(t, e, n) {
  try {
    Pv(t, e);
    const s = lr(), r = await sd(
      t,
      e,
      n
    ), i = Sv(s), o = i.currentUser;
    if (o)
      return console.debug(
        "👉    Firebase operations: Already logged in --> Re-use credentials"
      ), o.uid;
    console.debug("👉     Firebase operations: User not logged --> Signning in");
    const a = await aE(i, r);
    if (console.debug(
      "👉         Logged in - Firebase operations: User not logged --> Signning in"
    ), !a)
      throw new Error("No user credentials");
    return a.user.uid;
  } catch (s) {
    throw new Error(
      `Error signing in with custom token ${q(s)}`
    );
  }
}
async function Fv(t, e, n) {
  return await sd(t, e, n);
}
async function qt(t, e) {
  const n = V.getInstance().getToken();
  if (n === void 0 || n.length < 15)
    throw new Error("Trying to get DB Firestore, but no Carto3 token provided");
  return await Lv(t, e, n), iw(lr());
}
let Mv = (t = 21) => crypto.getRandomValues(new Uint8Array(t)).reduce((e, n) => (n &= 63, n < 36 ? e += n.toString(36) : n < 62 ? e += (n - 26).toString(36).toUpperCase() : n > 62 ? e += "-" : e += "_", e), "");
function Vt(t, e, n, s) {
  const i = e.isTesting() ? "Test_" : "", o = Hd(t), a = n, c = `${i}${o}_${a[0]}`;
  return a.shift(), s && a.push(s), { collectionPath: c, collectionPathSegments: a };
}
function rd(t, e = !1) {
  const n = X.now();
  return e && (t.created_at = n), t.modified_at = n, { ...t };
}
function id(t) {
  const e = { ...t };
  return e.created_at = t.created_at?.toDate(), e.modified_at = t.modified_at?.toDate(), e;
}
function od(t) {
  const e = [];
  return t.forEach((n) => {
    let s = n.data();
    s = id(s), s.id = n.id, e.push(s);
  }), e;
}
async function $v(t, e, ...n) {
  try {
    const s = await qt(t, e), { collectionPath: r, collectionPathSegments: i } = Vt(
      t,
      e,
      n
    ), o = await Ih(
      $r(s, r, ...i)
    );
    return od(o);
  } catch (s) {
    throw new Error(`Error getting elemnts ${q(s)}`);
  }
}
async function Kr(t, e, n, ...s) {
  try {
    const r = await qt(t, e), { collectionPath: i, collectionPathSegments: o } = Vt(
      t,
      e,
      s,
      n
    );
    let c = (await Tw(
      Ur(r, i, ...o)
    )).data();
    return c = id(c), c.id = n, c;
  } catch (r) {
    throw new Error(
      `Error getting element with id: ${n} ${q(r)}`
    );
  }
}
async function Ie(t, e, { collectionFullPath: n, queryConstraints: s }) {
  try {
    const r = await qt(t, e), { collectionPath: i, collectionPathSegments: o } = Vt(
      t,
      e,
      n
    ), a = $r(
      r,
      i,
      ...o
    ), c = vh(a, ...s), u = await Ih(c);
    return od(u);
  } catch (r) {
    throw new Error(
      `Error getting elements using queries ${q(r)}`
    );
  }
}
async function Uv(t, e, { collectionFullPath: n, queryConstraints: s }) {
  try {
    const r = await qt(t, e), { collectionPath: i, collectionPathSegments: o } = Vt(
      t,
      e,
      n
    ), a = $r(
      r,
      i,
      ...o
    ), c = vh(a, ...s);
    return (await Cw(c)).data().count;
  } catch (r) {
    throw new Error(
      `Error getting elements count using queries ${q(r)}`
    );
  }
}
async function yn(t, e, n, ...s) {
  try {
    const r = await qt(t, e), i = rd(
      n,
      !0
    ), { collectionPath: o, collectionPathSegments: a } = Vt(
      t,
      e,
      s
    ), c = $r(
      r,
      o,
      ...a
    ), u = Mv(), l = Ur(c, u), h = i;
    return h.id = u, await Sw(l, h), u;
  } catch (r) {
    throw new Error(`Error creating element
 ${q(r)}`);
  }
}
async function Jr(t, e, n, s, ...r) {
  try {
    const i = await qt(t, e), o = rd(n);
    delete o.id;
    const { collectionPath: a, collectionPathSegments: c } = Vt(
      t,
      e,
      r,
      s
    );
    await Iw(
      Ur(i, a, ...c),
      o
    );
  } catch (i) {
    throw new Error(
      `Error updating element with id: ${s} ${q(i)}`
    );
  }
}
async function ad(t, e, n, ...s) {
  try {
    const r = await qt(t, e), { collectionPath: i, collectionPathSegments: o } = Vt(
      t,
      e,
      s,
      n
    );
    await bw(Ur(r, i, ...o));
  } catch (r) {
    throw new Error(
      `Error deleting element with id: ${n} ${q(r)}`
    );
  }
}
async function xv(t, e) {
  return await $v(
    t,
    e,
    U.Plans
  );
}
async function Bv(t, e, n) {
  return await Kr(
    t,
    e,
    n,
    U.Plans
  );
}
async function qv(t, e, n) {
  return await yn(
    t,
    e,
    n,
    U.Plans
  );
}
async function Vv(t, e, n, s) {
  await Jr(t, e, s, n, U.Plans);
}
async function jv(t, e, n) {
  await ad(t, e, n, U.Plans);
}
async function Gv(t, e, n) {
  let s = [Te("is_public", "==", !0)];
  n && (s = [...s, Te("agency", "==", n)]);
  const r = {
    collectionFullPath: [U.Plans],
    queryConstraints: s
  };
  return await Ie(t, e, r);
}
async function Wv(t, e, n) {
  const s = {
    collectionFullPath: [U.Plans],
    queryConstraints: [Te("created_by", "==", n)]
  };
  return await Ie(t, e, s);
}
async function Hv(t, e, n) {
  const s = {
    collectionFullPath: [U.Plans],
    queryConstraints: [Te("aida_id", "==", n)]
  };
  return (await Ie(t, e, s))[0] || null;
}
async function zv(t, e, n) {
  return await Fv(t, e, n);
}
async function Qv(t, e, n) {
  const s = {
    collectionFullPath: [U.Plans],
    queryConstraints: [
      Te("custom_geoboundaries", "array-contains", n)
    ]
  };
  return await Ie(t, e, s);
}
async function Kv(t, e, n) {
  const s = {
    collectionFullPath: [U.Plans],
    queryConstraints: [Te("custom_pois", "array-contains", n)]
  };
  return await Ie(t, e, s);
}
const tI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  createPlan: qv,
  deletePlan: jv,
  getFirebaseToken: zv,
  getPlan: Bv,
  getPlanByAidaId: Hv,
  getPlans: xv,
  getPlansByCustomGeoboundaryId: Qv,
  getPlansByCustomPoiId: Kv,
  getPlansByUserId: Wv,
  getPublicPlans: Gv,
  updatePlan: Vv
}, Symbol.toStringTag, { value: "Module" }));
function Jv(t, e) {
  switch (zd(t)) {
    case wi.Month:
      return e.map((s) => {
        const r = s.year_month_or_week;
        return {
          dateFormat: r,
          humanFormat: r.substring(0, 7)
        };
      });
    case wi.Week:
      return e.map((s) => {
        const r = s.year_month_or_week, { year: i, week: o } = Xd(new Date(r));
        return {
          dateFormat: `${r}`,
          humanFormat: `${o}, ${i}`
        };
      });
    default:
      throw new Error("Invalid year_month_week provided data");
  }
}
async function Yv(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.audiences_classes
  );
  let r = "";
  n && n.country_ids && (r = F({
    columnName: "country_id",
    ids: n.country_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.WHERE
  }));
  const i = `
  SELECT DISTINCT(year_month_or_week)
  FROM ${s}
  ${r}
  ORDER BY year_month_or_week DESC`, o = await b(
    i,
    t,
    e
  );
  return Jv(t, o);
}
async function Xv(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.audiences_classes
  ), r = n, o = `SELECT
  ${zn(
    t.data_model.audiences_classes.columns,
    ft.Filterable
  )}
  FROM ${s}
  WHERE
    year_month_or_week = "${r}"
  ORDER BY audience ASC`;
  return await b(o, t, e);
}
async function Zv(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.audiences_classes
  ), r = n.delivery;
  let i = "";
  n.country_ids && n.country_ids.length > 0 && (i = F({
    columnName: "country_id",
    ids: n.country_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }));
  const o = `SELECT
    attr1, attr2, attr3, attr4, attr5, attr6, name, uid
  FROM ${s}
  WHERE
  year_month_or_week = "${r}"
  ${i}
  group by attr1, attr2, attr3, attr4, attr5, attr6, name, uid
  order by attr1, attr2, attr3, attr4, attr5, attr6, name`;
  return await b(
    o,
    t,
    e
  );
}
async function eT(t, e, n, s, r) {
  const i = v(
    t,
    e,
    t.data_model.audiences
  ), o = v(
    t,
    e,
    t.data_model.audiences_classes
  ), a = F({
    columnName: "class_uid",
    ids: r,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), c = F({
    columnName: "asset_id",
    ids: [s],
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), u = `
  SELECT
    c.name as name,
    index as value
  FROM  ${i} a
  JOIN  ${o} c
  ON a.class_uid = c.uid
  WHERE a.year_month_or_week = "${n}"
  AND c.year_month_or_week = "${n}"
  ${a}
  ${c}`;
  return await b(
    u,
    t,
    e
  );
}
const nI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getAudienceIndexByAssetAndAudienceClasses: eT,
  getClasses: Xv,
  getClassesV2: Zv,
  getDeliveries: Yv
}, Symbol.toStringTag, { value: "Module" }));
function _t(t, e) {
  return t.countries.length === 1 ? " 1 = 1 " : ` country_id IN (${t.countries.filter((s) => e.includes(s.id)).map((s) => s.id).join(",")}) `;
}
function tT(t, e, n, s, r) {
  const i = "pois_from_categories", o = n.categories;
  if (o.length === 0)
    return { cteName: i, cte: "" };
  const a = r.length > 0 ? r : [
    "p.geoid",
    "p.location_name as name",
    "p.street_address as address",
    "p.geom",
    "p.country_id as country_id"
  ], c = v(
    t,
    e,
    t.data_model.pois
  ), u = `( ${Rt(
    "sub_category",
    o,
    O.EQUALS,
    R.NONE
  )} OR ( sub_category IS NULL AND ${Rt(
    "top_category",
    o,
    O.EQUALS,
    R.NONE
  )} ) ) `, l = `${i} AS (
    SELECT ${a.join(", ")}
    FROM ${c} p
    WHERE ${_t(t, s)}
      AND ${u}
    ),
    `;
  return { cteName: i, cte: l };
}
function nT(t, e, n, s, r) {
  const i = "pois_from_brands", o = n.brands, a = r.length > 0 ? r : [
    "p.geoid",
    "p.location_name as name",
    "p.street_address as address",
    "p.geom",
    "p.country_id as country_id"
  ];
  if (o.length === 0)
    return { cteName: i, cte: "" };
  const c = v(
    t,
    e,
    t.data_model.pois
  ), u = Rt(
    "brands",
    o,
    O.EQUALS,
    R.NONE
  ), l = `${i} AS (
    SELECT ${a.join(", ")}
    FROM ${c} p
    WHERE ${_t(t, s)}
      AND ${u}
    ),
    `;
  return { cteName: i, cte: l };
}
function sT(t, e, n, s, r) {
  const i = "pois_from_tags", o = n.tags, a = r.length > 0 ? r : [
    "p.geoid",
    "p.location_name as name",
    "p.street_address as address",
    "p.geom",
    "p.country_id as country_id"
  ];
  if (o.length === 0)
    return { cteName: i, cte: "" };
  const c = v(
    t,
    e,
    t.data_model.pois
  ), u = Yd(
    "category_tags",
    o
  ), l = `${i} AS (
    SELECT ${a.join(", ")}
    FROM ${c} p
    WHERE ${_t(t, s)}
      AND ${u}
    ),
    `;
  return { cteName: i, cte: l };
}
function rT(t, e, n, s, r) {
  const i = "pois_from_locations", o = n.locations;
  if (o.length === 0)
    return { cteName: i, cte: "" };
  const a = v(
    t,
    e,
    t.data_model.pois
  ), c = r.length > 0 ? r : [
    "p.geoid",
    "p.location_name as name",
    "p.street_address as address",
    "p.geom",
    "p.country_id as country_id"
  ], l = `(${o.map(
    (d) => `NORMALIZE_AND_CASEFOLD(location_name) = NORMALIZE_AND_CASEFOLD("${d}")`
  ).join(" OR ")})`, h = `${i} AS (
    SELECT ${c.join(", ")}
    FROM ${a} p
    WHERE ${_t(t, s)}
      AND ${l}
    ),
    `;
  return { cteName: i, cte: h };
}
function iT(t, e, n) {
  const s = "pois_from_custom_pois_groups", r = n.custom_POIs;
  if (r.length === 0)
    return { cteName: s, cte: "" };
  let i = "", o = "";
  if (t.countries.length > 1) {
    const l = v(
      t,
      e,
      t.data_model.regions
    );
    i = ", regions.country_id as country_id", o = `LEFT JOIN ${l} regions
    ON type_id = 2 AND ST_INTERSECTS(regions.geom, pois_unnested.geom)
    `;
  } else
    i = `, ${t.countries[0].id} as country_id`;
  const a = v(
    t,
    e,
    t.data_model.custom_pois
  ), c = Rt(
    "custom_pois.id",
    r,
    O.EQUALS,
    R.NONE
  ), u = `${s} AS (
    SELECT
      pois_unnested.geom as geom,
      pois_unnested.name as name,
      pois_unnested.address as address,
      ST_ASTEXT(pois_unnested.geom) as geoid
      ${i}
    FROM ${a} custom_pois,
    UNNEST(pois) as pois_unnested
    ${o}
    WHERE
      ${c}
    ),
    `;
  return { cteName: s, cte: u };
}
function oT(t, e, n, s, r, ...i) {
  const o = "all_pois", a = "all_pois_isochrones";
  let c = "";
  const u = i.filter((m) => m.cte !== ""), l = u.length;
  let h = s.length > 0 ? s : ["geoid", "name", "geom", "address", "country_id"];
  for (let m = 0; m < l; m++) {
    const S = u[m];
    S.cteName === "pois_from_custom_pois_groups" && r && (h = t.features.export.columnsCustomPOIs), c += `
      SELECT ${h.join(", ")}
      FROM ${S.cteName}
    `, h.includes("geom") && (c += " WHERE geom IS NOT NULL"), m < l - 1 && (c += " UNION ALL ");
  }
  let d = `
    ${o} AS (
      ${c}
    )
  `;
  if (n.method !== "RADIUS" && !r) {
    const m = v(
      t,
      e,
      t.data_model.pois_isochrones
    );
    d = `
      ${d},

      ${a} AS (
        SELECT
          p.*,
          i.geom as isochrone
        FROM ${o} p
        LEFT JOIN ${m} i
          ON p.geoid = i.geoid
            AND i.method = '${n.method}'
            AND i.time = ${n.distance}
      )
    `;
  }
  let p = "";
  return n.method === "RADIUS" || r ? p = `
      JOIN ${o}
        ON ST_DISTANCE(assets.geom,  ${o}.geom) < ${n.max_distance}
        AND ST_DISTANCE(assets.geom,  ${o}.geom) >= ${n.min_distance}
    ` : p = `
      JOIN ${a} i
        ON ST_INTERSECTS(assets.geom,  i.isochrone)
    `, { cte: d, join: p };
}
function aT(t) {
  return t.categories.length > 0 || t.brands.length > 0 || t.tags.length > 0 || t.locations.length > 0 || t.custom_POIs.length > 0;
}
function Xi(t, e, n, s, r = [], i = [], o = !1) {
  const a = tT(
    t,
    e,
    n,
    s,
    r
  ), c = nT(
    t,
    e,
    n,
    s,
    r
  ), u = sT(
    t,
    e,
    n,
    s,
    r
  ), l = rT(
    t,
    e,
    n,
    s,
    r
  ), h = iT(t, e, n), { cte: d, join: p } = oT(
    t,
    e,
    n,
    i,
    o,
    a,
    c,
    u,
    l,
    h
  );
  return { partialQuery: `
  WITH
  ${a.cte}
  ${c.cte}
  ${u.cte}
  ${l.cte}
  ${h.cte}
  ${d}
  `, allPoisJoin: p };
}
function cT(t, e, n, s, r) {
  if (!aT(n))
    return "";
  if (n.method === "RADIUS") {
    const i = v(
      t,
      e,
      t.data_model.assets
    );
    let o = i;
    const { partialQuery: a, allPoisJoin: c } = Xi(
      t,
      e,
      n,
      s
    );
    let u = "", l = "", h = "";
    return n.max_assets_per_poi > 0 && (o = "assetsPreFiltered", h = `
        ${o} AS (
          SELECT id, geom
          FROM ${i}
          WHERE id IN ('${r.join("','")}')
        ),
      `, u = ", rank() over (partition by all_pois.geoid order by ST_DISTANCE(all_pois.geom, assets.geom), id) as rank", l = ` WHERE rank <= ${n.max_assets_per_poi}`), `
      ${a},
      ${h}
      pre_assets AS (
        SELECT
          distinct(assets.id) as asset_id
          ${u}
        FROM ${o} assets
        ${c}
      )

      SELECT distinct(asset_id)
      FROM pre_assets
      ${l}
    `;
  } else {
    const i = v(
      t,
      e,
      t.data_model.assets
    ), o = ["p.geoid", "p.geom", "p.country_id"], a = ["geoid", "geom", "country_id"], { partialQuery: c, allPoisJoin: u } = Xi(
      t,
      e,
      n,
      s,
      o,
      a
    );
    return `
    ${c}

    SELECT distinct(id) as asset_id
    FROM ${i} assets
    ${u}
    `;
  }
}
async function uT(t, e, n, s, r) {
  const i = cT(
    t,
    e,
    n,
    s,
    r
  );
  return i === "" ? [] : (await b(i, t, e)).map((a) => a.asset_id);
}
function ys(t, e, n, s, r = [], i = [], o = !1) {
  return Xi(
    t,
    e,
    n,
    s,
    r,
    i,
    o
  );
}
const sI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  filterAssetsByPois: uT,
  getFilterPOIsPartialQuery: ys
}, Symbol.toStringTag, { value: "Module" }));
function _s(t, e) {
  const n = e.isTesting(), s = V.getInstance().getToken(), i = Mc(s)[t.auth0_user_key];
  if (!i && n)
    return "test@carto.com";
  if (!i) {
    const o = "No user email found in token";
    throw new Error(o);
  }
  return i;
}
function lT(t, e = !1) {
  return (e ? t.toLocaleLowerCase() : t).trim().replaceAll("'", "\\'").replaceAll("`", "\\`").replaceAll("´", "\\'");
}
async function hT(t, e, n, s = !1) {
  const r = so(t), i = v(
    t,
    e,
    t.data_model.custom_pois
  ), o = _s(t, e), a = s ? new Date().getTime() : 0;
  let c = `(user_email = '${o}' OR shared = true) `;
  r && n && (c = `${c} AND EXISTS (
      SELECT *
      FROM UNNEST(countries) AS c
      WHERE c in (${n.join(",")}))`);
  const u = `SELECT id, name, ARRAY_LENGTH(pois) as occurrences, shared, user_email, sub_category, NULL as top_category
  FROM ${i} t1
  LEFT JOIN
    UNNEST(sub_categories) as sub_category
  WHERE ${c}
  order by sub_category
  /* ${a} */`;
  return await $t(
    u,
    t,
    e,
    ut
  );
}
async function dT(t, e, n = !1) {
  const s = v(
    t,
    e,
    t.data_model.custom_pois
  ), r = _s(t, e), i = n ? new Date().getTime() : 0;
  let o = "";
  so(t) && (o = ", countries");
  const c = `SELECT id, name, shared, ARRAY_LENGTH(pois) as occurrences, user_email, description, modified ${o}
  FROM ${s} t1
  WHERE user_email = '${r}' OR shared = true
  ORDER BY modified DESC
  /* ${i} */`;
  return await $t(
    c,
    t,
    e,
    ut
  );
}
async function fT(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.custom_pois
  ), r = _s(t, e), i = F({
    columnName: "id",
    ids: [n.id],
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), o = `
    WITH pois AS (
      SELECT
        pois
      FROM
        ${s}
      WHERE (user_email = '${r}' OR shared = true)
      ${i}
    )
    SELECT
      name, geom, address
    FROM pois as p
    CROSS JOIN UNNEST(p.pois)
    ORDER BY name
  `;
  return await $t(
    o,
    t,
    e,
    ut
  );
}
async function pT(t, e, n) {
  const s = `SELECT top_category, sub_category, count(*) as occurrences
  FROM ${to(
    t,
    e,
    t.data_model.pois,
    !1
  )}
  WHERE ${_t(t, n)}
  AND top_category IS NOT NULL
  group by top_category, sub_category
  order by top_category, sub_category`, r = await b(s, t, e);
  if (r.length === 0)
    return [];
  r[0].sub_category === null && r.shift();
  const i = r.map((a) => a.sub_category === null ? {
    ...a,
    sub_category: a.top_category
  } : a);
  return [
    ...new Map(
      i.map((a) => [
        `${a.top_category}||${a.sub_category}`,
        a
      ])
    ).values()
  ];
}
async function gT(t, e, n) {
  const s = `SELECT brands, count(*) as occurrences
  FROM ${to(
    t,
    e,
    t.data_model.pois,
    !1
  )}
  WHERE ${_t(t, n)}
  AND brands IS NOT NULL
  GROUP BY brands
  ORDER BY brands`;
  return await b(s, t, e);
}
async function mT(t, e, n) {
  const s = `SELECT category_tags as tags, count(*) as occurrences
  FROM ${v(t, e, t.data_model.pois)}
  WHERE ${_t(t, n)}
  AND category_tags IS NOT NULL
  GROUP BY category_tags
  ORDER BY category_tags`;
  return await b(s, t, e);
}
async function yT(t, e, n, s) {
  const r = to(
    e,
    n,
    e.data_model.pois,
    !0
  ), i = lT(t, !0), o = `SELECT *
  FROM ${r}
  WHERE ${_t(e, s)}
  AND normalized_location_name LIKE '%${i}%'
  order by occurrences desc
  limit 50`;
  return await b(o, e, n);
}
function cd(t, e, n) {
  const s = _s(t, e);
  let r = {};
  return n.countries && n.countries.length > 0 && (r = { countries: n.countries }), JSON.stringify({
    id: n.id,
    name: n.name,
    shared: n.shared,
    user_email: s,
    pois: n.customPOIs,
    sub_categories: n.sub_categories,
    description: n.description,
    action: n.action,
    ...r
  });
}
function _T(t, e, n) {
  const s = _s(t, e), r = {
    apiBaseUrl: t.base_url
  }, i = {
    ...n,
    user_email: s,
    meta: r,
    action: "geocoding"
  };
  return JSON.stringify(i);
}
async function wT(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`, i = cd(t, e, n);
  n.action = "create";
  try {
    const o = ce(
      t,
      e,
      re.BigQueryCustomPOIS
    );
    if (!o)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for getting managing custom POIs in BQ. Check .env file 🔴🔴🔴"
      );
    return await (await fetch(o, {
      method: "POST",
      body: i,
      headers: { Authorization: r }
    })).json();
  } catch (o) {
    throw new Error(`Cloud function error ${q(o)}`);
  }
}
async function ET(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`, i = _T(
    t,
    e,
    n
  );
  try {
    const o = ce(
      t,
      e,
      re.BigQueryCustomPOIS
    );
    if (!o)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for getting managing custom POIs Address in BQ. Check .env file 🔴🔴🔴"
      );
    return (await (await fetch(o, {
      method: "POST",
      body: i,
      headers: { Authorization: r }
    })).json()).job;
  } catch (o) {
    throw new Error(`Cloud function error ${q(o)}`);
  }
}
async function vT(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`;
  n.action = "update";
  const i = cd(t, e, n);
  try {
    const o = ce(
      t,
      e,
      re.BigQueryCustomPOIS
    );
    if (!o)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for getting managing custom POIs in BQ. Check .env file 🔴🔴🔴"
      );
    return await (await fetch(o, {
      method: "POST",
      body: i,
      headers: { Authorization: r }
    })).json();
  } catch (o) {
    throw new Error(`Cloud function error ${q(o)}`);
  }
}
async function TT(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`, i = JSON.stringify({
    id: n,
    action: "delete"
  });
  try {
    const o = ce(
      t,
      e,
      re.BigQueryCustomPOIS
    );
    if (!o)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for getting managing custom POIs in BQ. Check .env file 🔴🔴🔴"
      );
    return await (await fetch(o, {
      method: "POST",
      body: i,
      headers: { Authorization: r }
    })).json();
  } catch (o) {
    throw new Error(`Cloud function error ${q(o)}`);
  }
}
function ST(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.custom_pois
  ), r = F({
    columnName: "id",
    ids: n,
    equalityOperator: O.EQUALS,
    logicalOperator: R.NONE
  });
  return `
    SELECT
      custom_pois.name as id,
      pois_unnested.geom as geom,
      pois_unnested.name as location_name,
      pois_unnested.address as street_address
    FROM ${s} custom_pois,
    UNNEST(pois) as pois_unnested
    WHERE
      ${r}
  `;
}
async function IT(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`, i = {
    jobId: n,
    action: "status_geocoding_job"
  };
  try {
    const o = ce(
      t,
      e,
      re.BigQueryCustomPOIS
    );
    if (!o)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for getting managing custom POIs Address in BQ. Check .env file 🔴🔴🔴"
      );
    return await (await fetch(o, {
      method: "POST",
      body: JSON.stringify(i),
      headers: { Authorization: r }
    })).json();
  } catch (o) {
    throw new Error(`Cloud function error ${q(o)}`);
  }
}
async function bT(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.custom_pois_address_tmp
  ), r = F({
    columnName: "id",
    ids: [n],
    equalityOperator: O.EQUALS,
    logicalOperator: R.NONE
  }), i = `SELECT count(*) as total
  FROM ${s}
  WHERE ${r}`;
  return (await b(i, t, e))[0].total;
}
async function AT(t, e, n) {
  const s = zn(
    t.data_model.custom_pois_address_tmp.columns,
    ft.Filterable
  ), r = v(
    t,
    e,
    t.data_model.custom_pois_address_tmp
  ), i = F({
    columnName: "id",
    ids: [n],
    equalityOperator: O.EQUALS,
    logicalOperator: R.NONE
  }), o = `SELECT ${s}
  FROM ${r}
  WHERE ${i}
  AND geom IS NULL`;
  return await b(
    o,
    t,
    e
  );
}
const rI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  checkStatusCustomPoisAddressJob: IT,
  deleteCustomPOIsGroup: TT,
  fetchCustomPOIsLayerSource: ST,
  getBrands: gT,
  getCategories: pT,
  getCountCustomPOISGeocoded: bT,
  getCustomPOIsUnableToGeocode: AT,
  getCustomPois: hT,
  getCustomPoisDataset: fT,
  getCustomPoisList: dT,
  getLocationsByPattern: yT,
  getTags: mT,
  updateCustomPOIsGroup: vT,
  uploadCustomPOIsAddressGroupJob: ET,
  uploadCustomPOIsGroup: wT
}, Symbol.toStringTag, { value: "Module" })), ud = /* @__PURE__ */ new Map([
  [0, [1]],
  [1, [0, 2, 3]],
  [2, [0, 1, 2, 3]]
]);
function fa(t, e, n, s) {
  return `
    SELECT
    uid AS class_id,
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    name,
    audience
  FROM
    ${t}
  WHERE
    ${e}
    ${s}
    ${n}
  ORDER BY
    attr1,
    attr2,
    attr3,
    attr4,
    attr5,
    attr6,
    name
  `;
}
function CT(t, e, n) {
  const s = F({
    columnName: "type",
    ids: ud.get(
      0
      /* Demographics */
    ) ?? [],
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  });
  return fa(
    t,
    e,
    n,
    s
  );
}
function NT(t, e, n) {
  return fa(t, e, n, "");
}
function OT(t, e, n) {
  const s = F({
    columnName: "type",
    ids: ud.get(
      1
      /* Overview */
    ) ?? [],
    equalityOperator: O.EQUALS,
    logicalOperator: R.OR
  });
  return fa(
    t,
    e,
    n,
    `AND ( type IS NULL ${s} )`
  );
}
function RT(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.audiences
  ), r = v(
    t,
    e,
    t.data_model.audiences_classes
  ), i = F({
    columnName: "year_month_or_week",
    ids: [n.delivery],
    equalityOperator: O.EQUALS,
    logicalOperator: R.NONE
  }), o = F({
    columnName: "asset_id",
    ids: n.assets_id,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), a = F({
    columnName: "country_id",
    ids: n.country_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND,
    normalizedStringSearch: !1,
    returnEmptyIfIdsEmpty: !0
  });
  return `WITH
  audiences_classes AS (
    ${CT(
    r,
    i,
    a
  )}
  )
  select
    dac.audience as name, AVG(index) as index, AVG(market) as market, AVG(panel) as panel
  from ${s} audiences
  JOIN
    audiences_classes dac
  ON
    dac.class_id = audiences.class_uid
  WHERE
    ${i}
    ${o}
    ${a}
  GROUP BY dac.audience
  ORDER BY dac.audience
  `;
}
function kT(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.audiences
  ), r = v(
    t,
    e,
    t.data_model.audiences_classes
  ), i = F({
    columnName: "year_month_or_week",
    ids: [n.delivery],
    equalityOperator: O.EQUALS,
    logicalOperator: R.NONE
  }), o = F({
    columnName: "asset_id",
    ids: n.assets_id,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), a = F({
    columnName: "country_id",
    ids: n.country_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND,
    normalizedStringSearch: !1,
    returnEmptyIfIdsEmpty: !0
  });
  return `WITH
  audiences_classes AS (
    ${NT(
    r,
    i,
    a
  )}
  )
  select
    dac.audience as name, AVG(index) as index, AVG(market) as market, AVG(panel) as panel
  from ${s} audiences
  JOIN
    audiences_classes dac
  ON
    dac.class_id = audiences.class_uid
  WHERE
    ${i}
    ${o}
    ${a}
  GROUP BY dac.audience
  ORDER BY dac.audience
  `;
}
function DT(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.audiences
  ), r = v(
    t,
    e,
    t.data_model.audiences_classes
  ), i = F({
    columnName: "year_month_or_week",
    ids: [n.delivery],
    equalityOperator: O.EQUALS,
    logicalOperator: R.NONE
  }), o = F({
    columnName: "asset_id",
    ids: n.assets_id,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), a = F({
    columnName: "country_id",
    ids: n.country_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND,
    normalizedStringSearch: !1,
    returnEmptyIfIdsEmpty: !0
  });
  return `WITH
  audiences_classes AS (
    ${OT(
    r,
    i,
    a
  )}
  )
  select
    dac.audience as name, AVG(index) as index, AVG(market) as market, AVG(panel) as panel
  from ${s} audiences
  JOIN
    audiences_classes dac
  ON
    dac.class_id = audiences.class_uid
  WHERE
    ${i}
    ${o}
    ${a}
  GROUP BY dac.audience
  ORDER BY dac.audience
  `;
}
function PT(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.panelcounts
  ), r = F({
    columnName: "asset_id",
    ids: n.assets_id,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), i = F({
    columnName: "country_id",
    ids: n.country_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND,
    normalizedStringSearch: !1,
    returnEmptyIfIdsEmpty: !0
  });
  return `WITH
  deliveries AS (
    select distinct (year_month_or_week)
    from ${s}
    where
     true
     ${i}
  )
  select
    EXTRACT(YEAR FROM year_month_or_week) as year,
    EXTRACT(WEEK FROM year_month_or_week) as week,
    coalesce(sum(week_contacts),0) as impressions,
    coalesce(sum(week_uniques),0) as uniques
  from ${s}
  WHERE
    year_month_or_week in (select * from deliveries)
    ${r}
    ${i}
  GROUP BY year_month_or_week
  ORDER BY year_month_or_week
  `;
}
async function LT(t, e, n) {
  const s = RT(t, e, n);
  return await b(s, t, e);
}
async function FT(t, e, n) {
  const s = kT(t, e, n);
  return await b(s, t, e);
}
async function MT(t, e, n) {
  const s = DT(t, e, n);
  return await b(s, t, e);
}
async function $T(t, e, n) {
  const s = PT(t, e, n);
  return await b(
    s,
    t,
    e
  );
}
const iI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getDemographics: LT,
  getImpressions: $T,
  getOverview: FT,
  getTopIndex: MT
}, Symbol.toStringTag, { value: "Module" }));
function pa(t, e) {
  const n = ce(
    t,
    e,
    re.SharedPlan
  );
  if (!n)
    throw new Error(
      "🔴🔴🔴 No cloud function url define for managing shared plans. Check .metadata file 🔴🔴🔴"
    );
  return n;
}
async function UT(t, e, n) {
  const s = await ld(
    t,
    e,
    n
  );
  return await b(
    s,
    t,
    e
  );
}
async function xT(t, e, n, s) {
  try {
    const r = new URLSearchParams(window.location.search).get("appVersion") || t.flavour, i = new URLSearchParams({
      action: be.SharedExportAudiencesAssets,
      appVersion: r,
      planId: s
    });
    let o = pa(t, e);
    return o += `?${i.toString()}`, await (await fetch(o, {
      method: "POST",
      body: JSON.stringify({
        filter: n
      })
    })).json();
  } catch (r) {
    throw new Error(`Cloud function error ${q(r)}`);
  }
}
async function BT(t, e, n, s) {
  const r = hd(
    t,
    e,
    n,
    s
  );
  return await b(r, t, e);
}
async function qT(t, e, n, s, r) {
  try {
    const i = new URLSearchParams(window.location.search).get("appVersion") || t.flavour, o = new URLSearchParams({
      action: be.SharedExportPoisAssets,
      appVersion: i,
      planId: r
    });
    let a = pa(t, e);
    return a += `?${o.toString()}`, await (await fetch(a, {
      method: "POST",
      body: JSON.stringify({
        filter: n,
        countryIds: s
      })
    })).json();
  } catch (i) {
    throw new Error(`Cloud function error ${q(i)}`);
  }
}
function VT(t, e, n) {
  if (!n.geoboundaries && !n.polygonGeom)
    return "";
  const s = [], i = Object.values(
    n.geoboundaries
  ).flat().filter((a) => a.has_geometry).map((a) => a.id);
  if (i.length) {
    const c = `
      SELECT geom
      FROM ${v(
      t,
      e,
      t.data_model.regions
    )}
      WHERE id IN (${i.join(",")})
    `;
    s.push(c);
  }
  if (n.polygonGeom && n.polygonGeom.features.length > 0) {
    const c = `
      SELECT *
      FROM
        UNNEST([${(n.polygonGeom?.features.map((u) => `ST_GEOGFROM('${JSON.stringify(u.geometry)}')`)).join(",")}]) as geom
    `;
    s.push(c);
  }
  return s.length ? `,
    filter_region AS (
      ${s.join(" UNION ALL ")}
    ),
    regions_pois AS (
      SELECT
      *
      from all_pois t1
      JOIN filter_region t2
        ON ST_INTERSECTS(t1.geom, t2.geom)
    )
  ` : "";
}
async function jT(t, e, n, s) {
  const r = dd(
    t,
    e,
    n,
    s
  );
  return await b(r, t, e);
}
async function GT(t, e, n, s, r) {
  try {
    const i = new URLSearchParams(window.location.search).get("appVersion") || t.flavour, o = new URLSearchParams({
      action: be.SharedExportAllPois,
      appVersion: i,
      planId: r
    });
    let a = pa(t, e);
    return a += `?${o.toString()}`, await (await fetch(a, {
      method: "POST",
      body: JSON.stringify({
        filter: n,
        countryIds: s
      })
    })).json();
  } catch (i) {
    throw new Error(`Cloud function error ${q(i)}`);
  }
}
async function ld(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.audiences
  ), r = v(
    t,
    e,
    t.data_model.audiences_classes
  );
  let i = n.delivery;
  if (!n.delivery) {
    const h = `
      SELECT MAX(year_month_or_week) lastDate
      FROM ${s}
      WHERE year_month_or_week IS NOT NULL
    `;
    i = (await b(
      h,
      t,
      e
    ))[0].lastDate;
  }
  const o = F({
    columnName: "audiences.year_month_or_week",
    ids: [i],
    equalityOperator: O.EQUALS,
    logicalOperator: R.NONE
  }), a = F({
    columnName: "classes.year_month_or_week",
    ids: [i],
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), c = F({
    columnName: "class_uid",
    ids: n.audience_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND
  }), u = F({
    columnName: "asset_id",
    ids: n.asset_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.AND,
    returnEmptyIfIdsEmpty: !0
  });
  return `
  SELECT audiences.asset_id,
    ARRAY_AGG(STRUCT(classes.audience as class, audiences.index as index) ORDER BY classes.audience) as audiences_values
  FROM ${s} audiences
  JOIN ${r} classes
  ON audiences.class_uid = classes.uid
  WHERE
  ${o}
  ${a}
  ${c}
  ${u}
  GROUP BY audiences.asset_id
  `;
}
function hd(t, e, n, s) {
  const r = v(
    t,
    e,
    t.data_model.assets
  ), i = Vc(t), o = jc(t), { partialQuery: a } = ys(
    t,
    e,
    n,
    s,
    i,
    o,
    !0
  ), c = F({
    columnName: "assets.id",
    ids: n.asset_ids,
    equalityOperator: O.EQUALS,
    logicalOperator: R.NONE,
    returnEmptyIfIdsEmpty: !0
  });
  return `
    ${a},

    distances_between_assets_and_pois AS (
      SELECT
          assets.id as asset_id,
          pois_filtered.* except(geom),
          ST_DISTANCE(assets.geom, pois_filtered.geom) as distance
      FROM ${r} assets, all_pois pois_filtered
      WHERE ${c}
    ),

    ranked_distances AS (
      SELECT
        *,
        ROW_NUMBER() OVER (PARTITION BY asset_id ORDER BY distance ASC) AS rn
      FROM distances_between_assets_and_pois
    )

    SELECT
     *
    FROM ranked_distances
    WHERE rn = 1
    ORDER BY asset_id
  `;
}
function dd(t, e, n, s) {
  const r = Vc(t), i = jc(t), { partialQuery: o } = ys(
    t,
    e,
    n,
    s,
    r,
    i,
    !0
  ), a = VT(
    t,
    e,
    n
  );
  return `
    ${o}
    ${a}


    SELECT
     id,
     name,
     address,
     zip_code
    FROM ${a.length ? "regions_pois" : "all_pois"}
    ORDER BY id
  `;
}
const oI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getAllPOIsValuesForRegions: jT,
  getAudiencesIndexValuesForAssests: UT,
  getClosestPOIsValuesForAssets: BT,
  getClosestPOIsValuesForSharedAssets: qT,
  getQueryAllPOIsValuesForRegions: dd,
  getQueryAudiencesIndexValuesForAssests: ld,
  getQueryClosestPOIsValuesForAssets: hd,
  getSharedAllPOIsValuesForRegions: GT,
  getSharedAudiencesIndexValuesForAssests: xT
}, Symbol.toStringTag, { value: "Module" }));
function WT(t, e) {
  const n = ce(
    t,
    e,
    re.Geocoding
  );
  if (!n)
    throw new Error(
      "🔴🔴🔴 No cloud function url define for geocoding in shared plans. Check .metadata file 🔴🔴🔴"
    );
  return n;
}
async function HT(t, e, n, s, r, i, o, a, c) {
  const u = r.length ? `&country=${r}` : "";
  let l = `${n}/v3/lds/geocoding/geocode`;
  if (l += `?address=${encodeURIComponent(s)}`, l += u, l += `&limit=${i}`, !o) {
    const d = {
      Authorization: `Bearer ${V.getInstance().getToken()}`
    }, m = await (await fetch(l, {
      method: "GET",
      headers: d
    })).json();
    if (m[0].error)
      throw new Error(m[0].error);
    return m[0].value;
  }
  try {
    let h = WT(t, e);
    const d = new URLSearchParams({
      appVersion: t.flavour,
      planId: a,
      geocodingUrl: encodeURIComponent(l),
      password: c
    });
    return h += `?${d.toString()}`, await (await fetch(h, {
      method: "GET"
    })).json();
  } catch (h) {
    throw new Error(`Cloud function error ${q(h)}`);
  }
}
const aI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  geocoding: HT
}, Symbol.toStringTag, { value: "Module" }));
async function zT(t, e, n) {
  return await yn(
    t,
    e,
    n,
    U.Audit
  );
}
const cI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  createEvent: zT
}, Symbol.toStringTag, { value: "Module" }));
function fd(t, e, n) {
  let s = "";
  return n.length > 0 && (s = `
    join ${v(
    t,
    e,
    t.data_model.regions
  )} r
      on p.country_id = r.country_id
        and r.id in (${n.join(",")})
        and ST_INTERSECTS(p.geom, r.geom)
    `), `
  , all_pois_geoboundaries AS (
    select p.*
    from all_pois_isochrones p
    ${s}
  ), `;
}
async function QT(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.pois_isochrones
  ), r = t.plan_filters.widget_proximity.pois_isochrones_fields.map(
    (l) => `p.${l}`
  ), i = t.plan_filters.widget_proximity.pois_isochrones_fields, { partialQuery: o } = ys(
    t,
    e,
    n.proximity,
    n.countryIds,
    r,
    i
  ), a = fd(
    t,
    e,
    n.geoboundaries
  ), c = `
    ${o}
    ${a}

    isochrones AS (
        SELECT geoid, country_id, geom
        FROM  ${s}
        WHERE country_id in (${n.countryIds.join(",")})
        AND method = '${n.proximity.method}'
        AND time = ${n.proximity.distance}
    )

    SELECT
      t1.geoid,
      t1.country_id,
      t1.geom
    FROM all_pois_geoboundaries t1
    LEFT JOIN isochrones t2
    ON t1.geoid = t2.geoid AND t1.country_id = t2.country_id
    WHERE
      t2.geom IS NULL
      AND ST_Y(t1.geom) BETWEEN -90 AND 90
      AND ST_X(t1.geom) BETWEEN -180 AND 180
  `;
  return {
    count: (await $t(
      c,
      t,
      e,
      ut
    )).length,
    query: c
  };
}
async function KT(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.pois_isochrones
  ), r = [], i = t.plan_filters.widget_proximity.pois_isochrones_fields, { partialQuery: o } = ys(
    t,
    e,
    n.proximity,
    n.countryIds,
    r,
    i
  ), a = fd(
    t,
    e,
    n.geoboundaries
  ), c = `
    ${o}
    ${a}

    isochrones AS (
        SELECT geoid, country_id, geom
        FROM  ${s}
        WHERE country_id in (${n.countryIds.join(",")})
        AND method = '${n.proximity.method}'
        AND time = ${n.proximity.distance}
    )

    SELECT
      ST_ASGEOJSON(t2.geom) as geometry
    FROM all_pois_geoboundaries t1
    LEFT JOIN isochrones t2
    ON t1.geoid = t2.geoid AND t1.country_id = t2.country_id
    WHERE
      t2.geom IS NOT NULL
      AND ST_Y(t1.geom) BETWEEN -90 AND 90
      AND ST_X(t1.geom) BETWEEN -180 AND 180
  `;
  return await $t(
    c,
    t,
    e,
    ut
  );
}
async function JT(t) {
  const e = V.getInstance().getToken(), n = `${t.base_url}/v3/lds/stats`, s = {
    Authorization: `Bearer ${e}`
  }, i = await (await fetch(n, {
    method: "GET",
    headers: s
  })).json();
  if (i[0].error)
    throw new Error(i[0].error);
  return i[0];
}
async function YT(t, e, n, s) {
  const i = `Bearer ${V.getInstance().getToken()}`;
  try {
    const o = ce(
      t,
      e,
      re.Isochrones
    );
    if (!o)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for calculating isochrones 🔴🔴🔴"
      );
    const a = {
      bq_project: ar(t, e),
      base_url: t.base_url,
      method: n.proximity.method,
      distance: n.proximity.distance,
      pois_query: s,
      action: "create"
    };
    return (await (await fetch(o, {
      method: "POST",
      body: JSON.stringify(a),
      headers: { Authorization: i }
    })).json()).job;
  } catch (o) {
    throw new Error(`Cloud function error ${q(o)}`);
  }
}
async function XT(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`, i = {
    jobId: n,
    action: "status"
  };
  try {
    const o = ce(
      t,
      e,
      re.Isochrones
    );
    if (!o)
      throw new Error(
        "🔴🔴🔴 No cloud function url define for calculating isochrones 🔴🔴🔴"
      );
    return await (await fetch(o, {
      method: "POST",
      body: JSON.stringify(i),
      headers: { Authorization: r }
    })).json();
  } catch (o) {
    throw new Error(`Cloud function error ${q(o)}`);
  }
}
const uI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  checkStatusPoisIsochronesJob: XT,
  createPoisIsochronesJob: YT,
  getLdsStats: JT,
  getPoisWithIsochrone: KT,
  getPoisWithoutIsochrone: QT
}, Symbol.toStringTag, { value: "Module" }));
function wt(t, e) {
  const n = ce(
    t,
    e,
    re.SharedPlan
  );
  if (!n)
    throw new Error(
      "🔴🔴🔴 No cloud function url define for managin shared plans. Check .metadata file 🔴🔴🔴"
    );
  return n;
}
async function ZT(t, e, n, s) {
  const r = new URLSearchParams({
    action: be.PasswordRequired,
    planId: n,
    appVersion: s
  });
  let i = !1, o = !0;
  try {
    let a = wt(t, e);
    a += `?${r.toString()}`;
    const c = await fetch(a, { method: "GET" });
    c.ok && (i = !0, o = (await c.json()).passwordRequired);
  } catch (a) {
    throw new Error(`Cloud function error ${q(a)}`);
  }
  return { isActive: i, isPasswordRequired: o };
}
async function eS(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`, i = new URLSearchParams({
    action: be.Share,
    planId: n.planId
  }), o = {};
  n.password && (o.password = n.password);
  const a = n.package;
  a && a.showPrice !== void 0 && (o.showPrice = a.showPrice ? "true" : "false");
  try {
    let c = wt(t, e);
    return c += `?${i.toString()}`, await (await fetch(c, {
      method: "POST",
      headers: { Authorization: r },
      body: JSON.stringify(o)
    })).json();
  } catch (c) {
    throw new Error(`Cloud function error ${q(c)}`);
  }
}
async function tS(t, e, n) {
  const s = new URLSearchParams({
    action: be.Plan,
    planId: n.planId,
    appVersion: n.appVersion
  });
  n.password && s.append("password", n.password);
  try {
    let r = wt(t, e);
    return r += `?${s.toString()}`, await (await fetch(r, { method: "GET" })).json();
  } catch (r) {
    throw new Error(`Cloud function error ${q(r)}`);
  }
}
async function nS(t, e, n) {
  const s = new URLSearchParams({
    action: be.Assets,
    planId: n.planId,
    appVersion: n.appVersion
  });
  n.password && s.append("password", n.password), n.assetsOffset !== void 0 && (s.delete("action"), s.append("action", be.AssetsPagination), s.append("assetsOffset", n.assetsOffset.toString()), s.append("token", n.token || ""), s.append("iv", n.iv || ""));
  try {
    let r = wt(t, e);
    r += `?${s.toString()}`;
    const i = await fetch(r, { method: "GET" }), o = await i.json();
    if (i.ok)
      return o;
    throw new Error(o);
  } catch (r) {
    throw new Error(`Cloud function error ${q(r)}`);
  }
}
async function sS(t, e, n) {
  const s = new URLSearchParams({
    action: be.Geoboundary,
    planId: n.planId,
    appVersion: n.appVersion
  });
  n.password && s.append("password", n.password);
  try {
    let r = wt(t, e);
    return r += `?${s.toString()}`, await (await fetch(r, { method: "GET" })).json();
  } catch (r) {
    throw new Error(`Cloud function error ${q(r)}`);
  }
}
async function rS(t, e, n, s) {
  const r = new URLSearchParams({
    action: be.CustomPOIs,
    planId: n.planId,
    appVersion: n.appVersion,
    activeSubPlanFiltersIndex: s.toString()
  });
  n.password && r.append("password", n.password);
  try {
    let i = wt(t, e);
    i += `?${r.toString()}`;
    const o = await fetch(i, { method: "GET" }), a = await o.json();
    if (o.ok)
      return a;
    throw new Error(a);
  } catch (i) {
    throw new Error(`Cloud function error ${q(i)}`);
  }
}
async function iS(t, e, n, s) {
  const r = new URLSearchParams({
    action: be.Asset,
    planId: n.planId,
    appVersion: n.appVersion,
    assetId: s
  });
  n.password && r.append("password", n.password);
  try {
    let i = wt(t, e);
    i += `?${r.toString()}`;
    const o = await fetch(i, { method: "GET" }), a = await o.json();
    if (o.ok)
      return a;
    throw new Error(a);
  } catch (i) {
    throw new Error(`Cloud function error ${q(i)}`);
  }
}
async function oS(t, e, n) {
  const s = new URLSearchParams({
    action: be.Insights,
    planId: n.planId,
    appVersion: n.appVersion
  });
  n.password && s.append("password", n.password);
  try {
    let r = wt(t, e);
    r += `?${s.toString()}`;
    const i = await fetch(r, { method: "GET" }), o = await i.json();
    if (i.ok)
      return o;
    throw new Error(o);
  } catch (r) {
    throw new Error(`Cloud function error ${q(r)}`);
  }
}
async function aS(t, e, n) {
  return await Kr(
    t,
    e,
    n,
    U.SharedPlans
  );
}
async function cS(t, e, n) {
  const s = [Te("planId", "==", n)], r = {
    collectionFullPath: [U.SharedPlans],
    queryConstraints: s
  }, o = (await Ie(t, e, r))[0];
  return o ? (o.expiracyDate = o.expiracyDate.toDate(), o.creationDate = o.creationDate.toDate(), o) : null;
}
async function uS(t, e, n, s) {
  await Jr(
    t,
    e,
    s,
    n,
    U.SharedPlans
  );
}
const lI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  createSharePlan: eS,
  getAssetById: iS,
  getAssets: nS,
  getCustomPOIs: rS,
  getGeometries: sS,
  getInsights: oS,
  getPlan: tS,
  getSharedPlanContent: aS,
  getSharedPlanContentFromPlanId: cS,
  isPasswordRequired: ZT,
  updateSharedPlanContent: uS
}, Symbol.toStringTag, { value: "Module" }));
async function lS(t, e) {
  const n = v(
    t,
    e,
    t.data_model.packages
  ), r = `SELECT
    ${t.data_model.packages.columns.map((o) => o.id).join(", ")}
  FROM ${n}
  ORDER BY package ASC`;
  return await b(r, t, e);
}
async function hS(t, e, n, s, r) {
  const o = `
    SELECT
      (AVG(availability) * 100) as availability,
    FROM ${v(
    t,
    e,
    t.data_model.packages_availability
  )}
    WHERE package_id = ${r} and date between '${n}' and '${s}'
  `, c = (await b(o, t, e))[0].availability;
  return c == null ? (console.warn(
    `Availability for package ${r} between ${n} and ${s} is null. Behaving as it is available.`
  ), 100) : Math.floor(c) || 0;
}
async function dS(t, e) {
  if (!t.data_model.packages)
    throw new Error("Packages classification is not defined");
  const s = `
  SELECT
    ARRAY_AGG(DISTINCT l1 ORDER BY l1) as l1,
    ARRAY_AGG(DISTINCT u_l2 ORDER BY u_l2) as l2,
    ARRAY_AGG(DISTINCT u_l3 ORDER BY u_l3) as l3,
    ARRAY_AGG(DISTINCT u_l4 ORDER BY u_l4) as l4,
  FROM ${v(
    t,
    e,
    t.data_model.packages
  )} pc,
  UNNEST(pc.l2) as u_l2,
  UNNEST(pc.l3) as u_l3,
  UNNEST(pc.l4) as u_l4
  WHERE l1 IS NOT NULL;
  `;
  return (await b(s, t, e))[0];
}
const hI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getAllPackagesWithAllProperties: lS,
  getPackageAvailability: hS,
  getPackageClassificationValues: dS
}, Symbol.toStringTag, { value: "Module" }));
async function fS(t, e) {
  let n = ce(
    t,
    e,
    re.Auth0
  );
  if (!n)
    throw new Error(
      "🔴🔴🔴 No cloud function url define for getting Auth0 roles. Check .env file 🔴🔴🔴"
    );
  n += "roles";
  const r = `Bearer ${V.getInstance().getToken()}`, o = await (await fetch(n, {
    method: "GET",
    headers: {
      Authorization: r
    }
  })).json();
  if (o.error)
    throw new Error(o.error);
  return o;
}
async function pS(t, e) {
  let n = ce(
    t,
    e,
    re.Auth0
  );
  if (!n)
    throw new Error(
      "🔴🔴🔴 No cloud function url define for getting Auth0 users. Check .env file 🔴🔴🔴"
    );
  n += "users-by-role";
  const r = `Bearer ${V.getInstance().getToken()}`, o = await (await fetch(n, {
    method: "GET",
    headers: {
      Authorization: r
    }
  })).json();
  if (o.error)
    throw new Error(o.error);
  return o;
}
const dI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getAuth0Roles: fS,
  getAuth0Users: pS
}, Symbol.toStringTag, { value: "Module" }));
async function gS(t, e, n) {
  const s = {
    collectionFullPath: [U.Comments],
    queryConstraints: [Te("plan_id", "==", n)]
  };
  return await Ie(
    t,
    e,
    s
  );
}
async function mS(t, e, n) {
  const s = {
    collectionFullPath: [U.Comments],
    queryConstraints: [Te("plan_id", "==", n)]
  };
  return await Uv(t, e, s);
}
async function yS(t, e, n) {
  try {
    return await yn(
      t,
      e,
      n,
      U.Comments
    );
  } catch (s) {
    const r = `Could not save comment from plan ${n.plan_id}`;
    throw console.error(`${r}`, s), new Error(r);
  }
}
async function _S(t, e, n, s) {
  const r = await Kr(
    t,
    e,
    n,
    U.Comments
  );
  r.attachments || (r.attachments = []), r.attachments.push(s), await Jr(
    t,
    e,
    r,
    n,
    U.Comments
  );
}
const fI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  addAttachmentToComment: _S,
  createComment: yS,
  getComments: gS,
  getCommentsCount: mS
}, Symbol.toStringTag, { value: "Module" }));
function ws(t, e) {
  const n = ce(
    t,
    e,
    re.CustomGeoboundaries
  );
  if (!n)
    throw new Error(
      "🔴🔴🔴 No cloud function url define for uploading custom geoboundaries files 🔴🔴🔴"
    );
  return n;
}
async function wS(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`, i = ws(t, e), o = {
    fileName: n,
    action: ln.GetSignedUrl
  };
  return (await (await fetch(i, {
    method: "POST",
    body: JSON.stringify(o),
    headers: { Authorization: r }
  })).json()).signed_url;
}
async function ES(t, e) {
  await fetch(t, {
    method: "PUT",
    body: e,
    headers: {
      "content-type": "application/octet-stream"
    }
  });
}
async function vS(t, e, n, s) {
  const i = `Bearer ${V.getInstance().getToken()}`, o = ws(t, e), a = {
    fileName: s,
    fileId: n,
    action: ln.StoreFileData
  };
  return await (await fetch(o, {
    method: "POST",
    body: JSON.stringify(a),
    headers: { Authorization: i }
  })).json();
}
async function TS(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`, i = ws(t, e), o = {
    jobId: n,
    action: ln.CheckStatusStoreFileData
  };
  return await (await fetch(i, {
    method: "POST",
    body: JSON.stringify(o),
    headers: { Authorization: r }
  })).json();
}
async function SS(t, e, n, s) {
  const i = `Bearer ${V.getInstance().getToken()}`, o = ws(t, e), a = {
    fileId: n,
    fileName: s,
    action: ln.ProcessFileData
  };
  return await (await fetch(o, {
    method: "POST",
    body: JSON.stringify(a),
    headers: { Authorization: i }
  })).json();
}
async function IS(t, e, n) {
  const r = `Bearer ${V.getInstance().getToken()}`, i = ws(t, e), o = {
    fileId: n,
    action: ln.DeleteFileData
  };
  return await (await fetch(i, {
    method: "POST",
    body: JSON.stringify(o),
    headers: { Authorization: r }
  })).json();
}
function bS(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.custom_geoboundaries
  ), r = n.map((o) => `'${o}'`).join(",");
  return `
    SELECT ST_ASGEOJSON(geoms) as geom
    FROM ${s} , UNNEST(geom) as geoms
    WHERE id IN (${r})
  `;
}
function AS(t, e, n) {
  return `
    SELECT COUNT(*) as occurrences FROM ${v(
    t,
    e,
    t.data_model.custom_geoboundaries
  )}
    WHERE id = '${n}' AND ARRAY_LENGTH(geom) > 0
  `;
}
async function CS(t, e, n) {
  const s = {
    collectionFullPath: [U.CustomGeoboundaries],
    queryConstraints: [Te("is_public", "==", !0)]
  }, r = await Ie(
    t,
    e,
    s
  ), i = {
    collectionFullPath: [U.CustomGeoboundaries],
    queryConstraints: [
      Te("created_by", "==", n),
      Te("is_public", "==", !1)
    ]
  }, o = await Ie(
    t,
    e,
    i
  );
  return [...r, ...o];
}
async function NS(t, e, n) {
  return await Kr(
    t,
    e,
    n,
    U.CustomGeoboundaries
  );
}
async function OS(t, e, n) {
  return await yn(
    t,
    e,
    n,
    U.CustomGeoboundaries
  );
}
async function RS(t, e, n, s) {
  await Jr(
    t,
    e,
    s,
    n,
    U.CustomGeoboundaries
  );
}
async function kS(t, e, n) {
  await ad(
    t,
    e,
    n,
    U.CustomGeoboundaries
  );
}
async function DS(t, e, n) {
  return await wS(t, e, n);
}
async function PS(t, e) {
  await ES(t, e);
}
async function LS(t, e, n, s) {
  return await vS(t, e, n, s);
}
async function FS(t, e, n) {
  return await TS(t, e, n);
}
async function MS(t, e, n, s) {
  return await SS(t, e, n, s);
}
async function $S(t, e, n) {
  return await IS(t, e, n);
}
async function US(t, e, n) {
  const s = bS(
    t,
    e,
    n
  );
  return (await b(s, t, e)).map((o) => JSON.parse(o.geom));
}
async function xS(t, e, n) {
  const s = AS(
    t,
    e,
    n
  );
  return (await $t(
    s,
    t,
    e
  ))[0].occurrences === 1;
}
const pI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  checkStatusStoreFileDataJob: FS,
  createCustomGeoboundary: OS,
  deleteCustomGeoboundary: kS,
  deleteStoredFileData: $S,
  getCustomGeoboundaries: CS,
  getCustomGeoboundary: NS,
  getGeometriesCustomFileGeometries: US,
  getSignedURLUploadFile: DS,
  isGeometryForFileIdAvailable: xS,
  moveStoredRawDataAndClean: MS,
  storeFileDataJob: LS,
  updateCustomGeoboundary: RS,
  uploadFileWithSignedURL: PS
}, Symbol.toStringTag, { value: "Module" }));
function BS(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.custom_geoboundaries
  ), r = n.map((o) => `'${o}'`).join(",");
  return `
    SELECT ST_ASGEOJSON(geoms) as geom
    FROM ${s} , UNNEST(geom) as geoms
    WHERE id IN (${r})
  `;
}
async function qS(t, e, n) {
  const s = BS(
    t,
    e,
    n
  );
  return (await b(s, t, e)).map((o) => JSON.parse(o.geom));
}
const gI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getGeometriesCustomFileGeometries: qS
}, Symbol.toStringTag, { value: "Module" }));
async function VS(t, e) {
  const n = {
    collectionFullPath: [U.Clients],
    queryConstraints: []
  };
  return await Ie(
    t,
    e,
    n
  );
}
async function jS(t, e, n) {
  try {
    await yn(
      t,
      e,
      n,
      U.Clients
    );
  } catch (s) {
    const r = `Could not save client with name ${n.name}`;
    throw console.error(`${r}`, s), new Error(r);
  }
}
const mI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  createClient: jS,
  getAllClients: VS
}, Symbol.toStringTag, { value: "Module" }));
async function GS(t, e, n, s) {
  if (n === s) {
    const u = {
      collectionFullPath: [U.Brands],
      queryConstraints: []
    };
    return await Ie(t, e, u);
  }
  const r = {
    collectionFullPath: [U.Brands],
    queryConstraints: [Te("agency", "==", s)]
  }, i = {
    collectionFullPath: [U.Brands],
    queryConstraints: [Te("agency", "==", n)]
  }, o = [];
  return o.push(Ie(t, e, r)), o.push(Ie(t, e, i)), (await Promise.all(o)).flat();
}
async function WS(t, e, n) {
  try {
    await yn(
      t,
      e,
      n,
      U.Brands
    );
  } catch (s) {
    const r = `Could not save brand with name ${n.name}`;
    throw console.error(`${r}`, s), new Error(r);
  }
}
const yI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  createBrand: WS,
  getAllBrands: GS
}, Symbol.toStringTag, { value: "Module" }));
async function HS(t, e, n) {
  const s = V.getInstance().getToken(), { apiBaseUrl: r } = no(t, e), i = Jd(t), o = {
    accessToken: s,
    apiBaseUrl: r
  };
  await xc({
    abortController: new AbortController().signal,
    credentials: o,
    method: rt.Post,
    body: n,
    url: i
  });
}
const _I = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  sendCartoMetricEvent: HS
}, Symbol.toStringTag, { value: "Module" }));
async function zS(t, e, n, s, r, i) {
  const o = ce(t, e, re.SignedUrls);
  if (!o)
    throw new Error(
      "🔴🔴🔴 No cloud function url define for managing signed urls. Check .env file 🔴🔴🔴"
    );
  const c = new URLSearchParams({
    action: i,
    bucketName: s,
    fileName: n,
    type: r
  }).toString(), l = `Bearer ${V.getInstance().getToken()}`;
  return (await (await fetch(`${o}?${c}`, {
    method: "GET",
    headers: { Authorization: l }
  })).json()).url;
}
const wI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getSignedUrlForFile: zS
}, Symbol.toStringTag, { value: "Module" }));
function _i(t, e) {
  if (cr(e)) {
    const n = F({
      columnName: "element",
      ids: e,
      equalityOperator: O.EQUALS,
      logicalOperator: R.NONE
    });
    return `
      EXISTS (
        SELECT 1
        FROM UNNEST(${t}) AS element
        WHERE${n}
      )
    `;
  }
  return null;
}
function QS(t) {
  const e = [];
  if (t.digital !== void 0 && e.push(`digital = ${t.digital}`), cr(t.l1)) {
    const s = F({
      columnName: "l1",
      ids: t.l1,
      equalityOperator: O.EQUALS,
      logicalOperator: R.NONE
    });
    e.push(s);
  }
  e.push(_i("l2", t.l2)), e.push(_i("l3", t.l3)), e.push(_i("l4", t.l4));
  const n = e.filter((s) => s !== null).join(" OR ");
  return n ? ` AND (${n})` : "";
}
async function KS(t, e, n) {
  const s = v(
    t,
    e,
    t.data_model.packages
  ), r = v(
    t,
    e,
    t.data_model.packages_availability
  ), i = v(
    t,
    e,
    t.data_model.assets
  );
  let o = `
    availability AS (
      SELECT
        package_id,
        NULL as availability,
      FROM ${r}
      group by package_id
    ),
    `;
  const a = n.dateRange;
  a && (o = `
    availability AS (
      SELECT
        package_id,
        (AVG(availability) * 100) as availability,
      FROM ${r}
      WHERE date between '${a[0]}' and '${a[1]}'
      group by package_id
    ),
    `);
  const c = n.priceRange, u = QS(n.panelType), l = `
  WITH ${o}
  panels AS (
    SELECT
      package_id, package, panels, gross_price
      FROM ${s}
      WHERE gross_price >= ${c[0]} AND gross_price <= ${c[1]}
      ${u}
  ),
  impressions AS (
    SELECT p.package_id, SUM(a.impressions) as impressions
    FROM ${s} p
    JOIN ${i} a
      ON p.package_id IN UNNEST(a.packages)
    group by p.package_id
  )
  Select
    p.package_id as id,
    a.availability,
    p.package as name,
    p.panels,
    p.gross_price as price,
    i.impressions
  FROM panels p
  LEFT JOIN availability a ON p.package_id = a.package_id
  LEFT JOIN impressions i ON p.package_id = i.package_id
  ORDER BY p.package ASC
  `;
  return await b(
    l,
    t,
    e
  );
}
const EI = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getAllPackagesWithAllProperties: KS
}, Symbol.toStringTag, { value: "Module" }));
export {
  XS as AssetsService,
  nI as AudienceService,
  cI as AuditService,
  dI as Auth0Service,
  V as AuthManager,
  yI as BrandService,
  Os as CacheManager,
  _I as CartoMetricEventService,
  mI as ClientService,
  fI as CommentService,
  pI as CustomGeoboundaryService,
  oI as ExportService,
  ft as FilterOption,
  ZS as GeoboundaryService,
  aI as GeocodingService,
  gI as GeometryService,
  iI as InsightService,
  uI as IsochroneService,
  EI as PackageBrowserService,
  hI as PackagesService,
  tI as PlanService,
  rI as PoiBrowserService,
  sI as PoiService,
  lI as SharePlanService,
  wI as SignedUrlService,
  no as getCartoCredentials,
  Gd as getColunmsIdFromModel,
  Vd as getTablesWithLastUpdate,
  Pv as initializeFirebaseApp
};
