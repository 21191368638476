export const EXAMPLE = 'layerExample'
export const CLUSTER_ASSETS = 'clusterAssetsLayer'
export const ASSETS = 'assetsLayer'
export const EXCLUDED_ASSETS = 'excludedAssetsLayer'
export const EXCLUDED_CLUSTER_ASSETS = 'excludedClusterAssetsLayer'
export const HIGHLIGHTED_ASSETS = 'highlightedAssetsLayer'
export const POI_LAYER = 'poiLayer'
export const ISOCHRONES_LAYER = 'isochronesLayer'
export const CUSTOM_POI_LAYER = 'customPoiLayer'
export const BOUNDARIES_LAYER = 'boundariesLayer'
export const ASSETS_IDS_LAYER = 'assetsIdsLayer'
export const GEOCODER_LAYER = 'geocoderLayer'
export const DISTRIBUTION_BOUNDARIES_LAYER = 'distributionBoundariesLayer'
export const EDITABLE_LAYER = 'editableLayer'
export const EDITABLE_DATA_LAYER = 'editableDataLayer'

export const ZOOM_LT_8 = ['<', ['zoom'], 8]
export const ZOOM_GTE_8 = ['>=', ['zoom'], 8]

export const MARKERS_BY_L2 = [
  // spain
  386,
  'marker-0',
  387,
  'marker-2',
  388,
  'marker-4',
  389,
  'marker-6',
  470,
  'marker-8',
  484,
  'marker-10',
  497,
  'marker-12',
  498,
  'marker-14',
  // uk
  370,
  'marker-0',
  371,
  'marker-1',
  372,
  'marker-2',
  373,
  'marker-3',
  374,
  'marker-4',
  375,
  'marker-5',
  376,
  'marker-6',
  377,
  'marker-7',
  378,
  'marker-8',
  379,
  'marker-9',
  380,
  'marker-10',
  381,
  'marker-11',
  382,
  'marker-12',
  383,
  'marker-13',
  384,
  'marker-14',
  385,
  'marker-15',
  471,
  'marker-16',
  481,
  'marker-17',
  490,
  'marker-18',
  491,
  'marker-19',
  496,
  'marker',
  // sweden
  390,
  'marker-0',
  391,
  'marker-1',
  392,
  'marker-2',
  393,
  'marker-3',
  394,
  'marker-4',
  395,
  'marker-5',
  396,
  'marker-6',
  397,
  'marker-7',
  398,
  'marker-8',
  399,
  'marker-9',
  400,
  'marker-10',
  401,
  'marker-11',
  402,
  'marker-12',
  403,
  'marker-13',
  404,
  'marker-14',
  405,
  'marker-15',
  406,
  'marker-16',
  407,
  'marker-17',
  408,
  'marker-18',
]

export const L2_COLORS = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  // '#c7c7c7', - Color for excluded assets
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
]
