import Vue from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AppShell from './app-shell/AppShell.vue'
import router from './router'
import store from './store'

import './styles/style.scss'
import i18n from './i18n'
import authService from './api/authService'

// Import the Auth0 configuration
import authConfig from '../auth_config.json'
import { Auth0Plugin } from './auth'
import { RawLocation } from 'vue-router'
import { getRedirectUri, getRequireComponent } from './constants'
import { initializeFirebaseApp } from '@workspaces/services'
import { getAppMetadata } from './plan/metadata/metadata'
import { getCartoAppConfiguration } from './plan/metadata/metadata.helper'
import { TrackJS } from 'trackjs'
import { setDefaultCredentials } from '@deck.gl/carto/typed'
import BrowserEnvironmentResolver from './helpers/environment.helper'
import { initializeUserPermissionManager } from '@/helpers/auth-browser.helper'
import SharedPlanPasswordManager from '@/helpers/sharedPlanPassword.helper'
;(async () => {
  try {
    const redirectUri = getRedirectUri()
    const isTesting = BrowserEnvironmentResolver.getInstance().isTesting()
    console.debug(`🕵🏻 Testing mode enabled ${isTesting}`)
    const metadata = getAppMetadata()
    console.debug(`👉 Initializing app with flavour ${metadata.flavour}`)
    window.document.title = metadata.app_title

    if (
      isTesting ||
      location.host.indexOf('localhost') === 0 ||
      location.host.indexOf('127.0.0.1') === 0
    ) {
      console.debug('👉 Not registering to TrackJS')
    } else {
      const version = `${process.env.VUE_APP_ENV}-${metadata.flavour}`
      console.debug(`👉 Registering app in TrackJS as version: ${version}`)
      TrackJS.install({
        token: 'cf7a7cd8411140689ea94e92d9fba842',
        application: 'carto-ps-radarview-us',
        console: { display: true, watch: ['error', 'warn'] },
        network: { error: false },
        version,
      })
    }

    const cartoAppConfiguration = getCartoAppConfiguration(
      metadata,
      BrowserEnvironmentResolver.getInstance(),
    )
    console.debug('👉 Carto app configuration', cartoAppConfiguration)

    const url = window.location.href
    const queryParams = new URLSearchParams(url)
    const sharedPlan = queryParams.get('shared')

    if (sharedPlan !== 'true') {
      console.debug('👉 Registering Auth0 plugin')
      Vue.use(Auth0Plugin, {
        domain: authConfig.domain,
        clientId: cartoAppConfiguration.clientId,
        redirectUri,
        cacheLocation: authConfig.cacheLocation,
        scopes: authConfig.scopes,
        audience: authConfig.audience,
        organization: cartoAppConfiguration.organization,
        onRedirectCallback: (appState: { targetUrl: RawLocation }) => {
          router.push(
            appState && appState.targetUrl
              ? appState.targetUrl
              : window.location.pathname,
          )
        },
      })
    } else {
      console.debug(
        '👉 Not registering Auth0 plugin as detected shared plan url',
      )
      const environment = BrowserEnvironmentResolver.getInstance()
      const sharedPlanId = queryParams.get('planId')
      if (sharedPlanId === null) {
        throw new Error('Shared plan id is required')
      }
      const sharedPlanPasswordManager = SharedPlanPasswordManager.getInstance()
      await sharedPlanPasswordManager.init(metadata, environment, sharedPlanId)
      const shareTileToken = environment.getEnvironmentVariable(
        'VUE_APP_SHARE_TILESET_TOKEN',
      )
      setDefaultCredentials({
        apiVersion: 'v3',
        apiBaseUrl: getAppMetadata().base_url,
        accessToken: shareTileToken,
      })
    }

    Vue.config.productionTip = false

    // Ignore Airship components
    Vue.config.ignoredElements = [/as-\w+/]

    // Error handler
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    window.onerror = (message, source, lineno, colno, error) => {
      // message: error message (string). Available as event (sic!) in HTML onerror="" handler.
      // source: URL of the script where the error was raised (string)
      // lineno: Line number where error was raised (number)
      // colno: Column number for the line where the error occurred (number)
      // error: Error Object (object)
      onError({
        err: message,
        source,
        info: `in line ${lineno}:${colno}`,
      })
    }

    Vue.config.errorHandler = (err, vm, info) => {
      // err: error trace
      // vm: component in which error occured
      // info: Vue specific error information such as lifecycle hooks, events etc.
      onError({
        err,
        source: vm,
        info,
      })
    }

    const requireComponent = getRequireComponent()

    requireComponent.keys().forEach((fileName: string) => {
      // Get component config
      const componentConfig = requireComponent(fileName)
      const fileNameSplitted = fileName.split('/')
      const chunk = fileNameSplitted.pop() || ''
      const componentName = chunk.replace(/\.\w+$/, '')

      // Register component globally
      Vue.component(componentName, componentConfig.default || componentConfig)
    })

    initializeFirebaseApp(metadata, BrowserEnvironmentResolver.getInstance())

    if (sharedPlan !== 'true') {
      await authService.login()
    } else {
      console.debug('👉 Not logging in as detected shared plan url')
      await initializeUserPermissionManager()
    }

    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(AppShell),
    }).$mount('#app')

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    function onError({ err, source, info }) {
      /* eslint-disable */
      console.error('Error: ', err.message, err)
      console.error('Source: ', source)
      console.error('Extra info: ', info)
      /* eslint-enable */
    }
  } catch (err) {
    console.error(err)
    process.exit(1)
  }
})()

export const bus = new Vue()
