import { Package } from '.'
import { Auditable, Entity } from './entity.type'

export enum PlanState {
  Draft = 'PLAN_STATE_DRAFT',
  Planned = 'PLAN_STATE_PLANNED',
  Pending = 'PLAN_STATE_PENDING',
  Confirmed = 'PLAN_STATE_CONFIRMED',
}

interface Aida {
  aida_id: string
}

export interface Legend {
  poisVisibility: boolean
  poisBufferVisibility: boolean
  boundariesVisibility: boolean
  assetsIdsLayerVisibility: boolean
  colorizeAssets: boolean
  productClass: string
  canAssetsExcludedLayerBeShown: boolean
  excludeAssets: boolean
  facingOrientationsIcons: boolean
  angleNorthMap: boolean
  hiddenProductsId: any[]
}

export interface Plan extends Entity, Auditable, Aida {
  name: string
  description: string
  filters?: any
  created_by: string
  count: number
  impressions: number
  is_public: boolean
  package?: string | Package.PlanPackage | null
  agency: string | null
  custom_geoboundaries?: string[]
  custom_pois?: string[]
  brand: string | null
  client: string | null
  poisColors?: string
  legend?: Legend
}
